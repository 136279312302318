<template>
  <n-full-screen overlay>
    <navigation
      @offerCommute="offerCommute"
      @requestCommute="requestCommute"
      :has-activities="hasActivities"
      :is-authenticated="isAuthenticated"
      :is-offer-loading="isOfferLoading"
    />
    <n-theme type="driver"  class="stopToggle">
      <n-layout>
        <n-switch v-model="showStops" shadow centerText textColor="dark" class="span-6">{{ $t('main.start.showStops') }} <n-help :text="$t('main.start.showStopsHelp')" /></n-switch>
      </n-layout>
    </n-theme>
    <nav-drawer v-if="isAuthenticated" />
    <update-sheet v-if="isUpdateAvailable && !overviewLoadedWhenUpdateAvailable" />
    <native-update-sheet v-else-if="isNativeUpdateAvailable && !overviewLoadedWhenUpdateAvailable" />
    <unauthenticated-container
      v-else-if="!isAuthenticated"
      @requestCommute="requestCommute"
      @offerCommute="offerCommute"
    />
    <no-activities-container
      v-else-if="!hasActivities"
      @requestCommute="requestCommute"
      @offerCommute="offerCommute"
      :is-offer-loading="isOfferLoading"
    />
    <overview-sheet v-else :start-page="overviewSheetStart"/>

    <n-theme type="driver">
      <provide-trip-sheet
        @rideNow="onRideNowClick"
        @createRide="onCreateRideClick"
        @addressSelected="onProvideAddressSelected"
        @addressError="onProvideAddressError"
      />

      <for-drivers-sheet />
    </n-theme>

    <n-dialog />
  </n-full-screen>
</template>

<script>
import store from "@/store";
import { mapState } from 'vuex';
import { EventBus } from '@/vendor/events';
import NavDrawer from "@/screens/main/start/navDrawer";
import Navigation from "@/screens/main/start/navigation";
import UpdateSheet from "@/screens/main/start/updateSheet";
import NativeUpdateSheet from "@/screens/main/start/nativeUpdateSheet";
import OverviewSheet from "@/screens/main/start/overviewSheet";
import ForDriversSheet from '@/screens/main/start/forDriversSheet';
import ProvideTripSheet from '@/screens/main/start/provideTripSheet';
import { namespacedTypes as appTypes } from '@/store/modules/app-types';
import { namespacedTypes as userTypes, } from "@/store/modules/user-types";
import NoActivitiesContainer from "@/screens/main/start/noActivitiesContainer";
import UnauthenticatedContainer from "@/screens/main/start/unauthenticatedContainer";
import { namespacedTypes as namespacedCommute, } from "@/store/modules/commute-types";

const sheetDismissalTimeInMilliseconds = 250;

export default {
  name: 'mainStartIndex',
  components: {
    NavDrawer,
    Navigation,
    UpdateSheet,
    NativeUpdateSheet,
    OverviewSheet,
    ForDriversSheet,
    ProvideTripSheet,
    NoActivitiesContainer,
    UnauthenticatedContainer
  },
  data() {
    return {
      overviewSheetStart: 0,
      isOfferLoading: false,
      lastAddressSelectedAt: null,
      overviewLoadedWhenUpdateAvailable: false,
    }
  },
  computed: {
    ...mapState('app', ['isUpdateAvailable', 'isNativeUpdateAvailable']),
    isAuthenticated() {
      return store.getters['user/isLoggedIn'];
    },
    hasActivities() {
      return this.$store.getters['commute/HAS_ACTIVITY'];
    },
    showStops: {
      get() {
        return this.$store.state.app.showStops;
      },
      set(value) {
        this.$store.commit('app/SET_SHOW_STOPS', value);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.tab) {
        vm.overviewSheetStart = to.params.tab;
      }

      if (vm.isAuthenticated) {
        vm.$store.dispatch(namespacedCommute.UPDATE_OVERVIEW);
        vm.$store.dispatch(namespacedCommute.FETCH_RECURRING);
      }

      vm.isOfferLoading = false;
      vm.overviewLoadedWhenUpdateAvailable = false;
    });
  },
  mounted() {
    this.$store.dispatch(appTypes.GET_CURRENT_LOCATION);

    EventBus.$on('show-offer-commute', this.offerCommute);
    EventBus.$on('content-update-available', this.onUpdateAvailable);
  },
  beforeDestroy() {
    EventBus.$off('show-offer-commute', this.offerCommute);
    EventBus.$off('content-update-available', this.onUpdateAvailable);
  },
  methods: {
    onUpdateAvailable() {
      if (this.$route?.name !== 'main.start' || !this.isAuthenticated || !this.hasActivities) {
        return;
      }

      this.overviewLoadedWhenUpdateAvailable = true;
    },
    onProvideAddressSelected() {
      this.isOfferLoading = true;

      this.lastAddressSelectedAt = Date.now();
    },
    onProvideAddressError() {
      this.isOfferLoading = false;
    },
    offerCommute() {
      if (this.isAuthenticated) {
        if (this.$store.getters[userTypes.HAS_FEATURE_FLAG]('OnDemandTrip')) {
          return EventBus.$emit('provide-trip-sheet-trigger');
        } else {
          return this.$router.push({ name: 'main.provide' });
        }
      } else {
        EventBus.$emit('show-login-sheet', {
            doAfterLogin: () => EventBus.$emit('show-offer-commute')
        });
      }
    },
    requestCommute() {
      this.$router.push({ name: 'main.demand', params: { 'should-trigger-survey': true }});
    },
    onRideNowClick(selectedDestination) {
      let delay = sheetDismissalTimeInMilliseconds;

      if (this.lastAddressSelectedAt) {
        const diffMilliseconds = Date.now() - this.lastAddressSelectedAt;

        if (diffMilliseconds < sheetDismissalTimeInMilliseconds) {
          delay = sheetDismissalTimeInMilliseconds - diffMilliseconds;
        } else {
          delay = 0;
        }
      }

      setTimeout(() => {
        this.$router.push({
          name: 'main.active-trip',
          params: { selectedDestination },
        });
      }, delay);
    },
    onCreateRideClick() {
      setTimeout(() => {
        this.$router.push({ name: 'main.provide' });
      }, sheetDismissalTimeInMilliseconds);
    },
  },
  watch: {
    isAuthenticated() {
      this.$forceUpdate();
    },
  },
};
</script>
<style>
.stopToggle {
  position: absolute;
  top: 4em;
  margin-top: var(--edge-padding-top);
}
</style>
