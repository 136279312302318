import { onDemandOptions } from '@/constants';
import { differenceInSeconds } from 'date-fns';

/**
 * @param {Date|string|null} locationUpdatedAt
 * @returns {boolean}
 */
export const isLocationWithinLifetime = (locationUpdatedAt) => {
    if (!locationUpdatedAt) {
        return false;
    }

    const lifetimeSeconds = onDemandOptions.locationLifetime;

    const sinceLastUpdateSeconds = differenceInSeconds(
      new Date(),
      new Date(locationUpdatedAt)
    );

    return sinceLastUpdateSeconds <= lifetimeSeconds;
};
