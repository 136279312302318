<template>
  <div class="theme" :class="type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "passenger",
      validator: (type) => ["passenger", "driver", "deactivated"].includes(type),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.theme {
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

.passenger {
  --color-accent: var(--color-green);
  --color-accent-faded: var(--color-green-light);
}

.driver {
  --color-accent: var(--color-blue);
  --color-accent-faded: var(--color-blue-light);
}

.deactivated {
  --color-accent: var(--color-grey-dark);
}

</style>
