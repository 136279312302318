<template>
  <div class="view-container">
    <n-nav-bar :title="$t('messages.title')" />
    <n-layout
      :spacing-y="false"
      :spacing-top="true"
    >
      <span><n-spinner v-if="loading" /></span>
      <n-button
        class="span-5"
        right
        inverted
        size="md"
        @click="markAll"
      >
        {{ $t('notifications.mark-all') }}
      </n-button>
    </n-layout>

    <n-scrollable>
      <n-layout :row-gap="0">
        <n-grid
          v-for="conversation in conversations"
          :key="conversation.id"
          class="span-6 message-container feedback"
          :row-gap="0"
          @click.native="$router.push({ name: 'userConversation', params: { userId: conversation.participant.id }})"
        >
          <profile-picture :src="conversation.participant.image_url" />
          <div class="span-5">
            <n-grid :row-gap="1">
              <div class="span-6 message-info">
                <n-text
                  ellipsis
                  class="message-user"
                >
                  {{ conversation.participant.is_deleted ? $t('chat.deleted-user.name') : conversation.participant.name }}
                </n-text>
                <div class="message-time time-wrap">
                  <n-text
                    color="grey-dark"
                    preset="label-2"
                    align="right"
                  >
                    {{ dateFormat(conversation.created_at) }}
                  </n-text>
                  <n-icon
                    name="chevron-right"
                    size="sm"
                    color="grey-dark"
                    outline
                  />
                </div>
              </div>
              <n-text
                preset="sub"
                ellipses
                :color="isUnread(conversation) ? 'accent' : 'grey-dark'"
                class="span-6">
                {{ conversation.content }}
              </n-text>
            </n-grid>
          </div>
        </n-grid>
      </n-layout>
    </n-scrollable>
  </div>
</template>
<script>
import userApi from '@/api/user';
import { formatDistanceToNow } from '@/vendor/date-fns'
import ProfilePicture from '@/components/shared/profilePicture';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  name: "ConversationsOverview",
  components: { ProfilePicture },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchConversations(); 
    })
  },
  data() {
    return {
      loading: true,
      conversations: [],
    };
  },
  methods: {
    async fetchConversations() {
      this.loading = true;
      try {
        this.conversations = await userApi.getConversations();
      } finally {
        this.loading = false;
      }
    },
    dateFormat(date) {
      return formatDistanceToNow(new Date(date), { addSuffix: true });
    },
    async markAll() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await this.$store.dispatch(userTypes.MARK_ALL_MESSAGES_AS_READ);
      this.loading = false;
    },
    isUnread(conversation) {
      return this.$store.getters[userTypes.HAS_UNREAD_NOTIFICATION_FOR_NAME](
        'NewConversationMessage',
        conversation.participant.id,
      );
    }
  },
};
</script>


<style lang="scss" scoped>
.message-container {
  padding: 1.25rem 0;
  margin-bottom: 0;
  border-bottom: 1px solid var(--color-grey-light);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.message-info {
  display: flex;
}

.message-time {
  flex: 1;
  text-wrap: nowrap;
  padding-left: 0.5rem;
}

.time-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon-wrapper {
    margin-left: 5px;
  }
}
</style>
