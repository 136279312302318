<template>
  <div class="view-container">
    <n-nav-bar :title="$t('travelPass.title')" />
    <n-scrollable>
      <n-layout :spacing-y="false" :spacing-top="true" >
        <n-text class="span-6" color="accent" preset="header">{{
          $t('travelPass.header')
        }}</n-text>

        <n-text class="span-6" color="grey-dark" preset="body">{{
          $t('travelPass.body')
        }}</n-text>
      </n-layout>

      <n-layout :spacing-y="false" :spacing-top="true" v-if="active.length">
        <n-text preset="label-2" class="span-6" color="grey-darker">{{ $t("travelPass.activePassesHeader") }}</n-text>
        <travel-pass-item v-for="travelPass in active" :key="travelPass.id" :travel-pass="travelPass" @removed="refresh"/>
      </n-layout>

      <n-layout>
        <n-button @click="addTravelPass" block size="lg">
          {{ $t('travelPass.add') }}
        </n-button>
      </n-layout>

      <n-layout :spacing-y="false" :spacing-bottom="true" v-if="inactive.length">
        <n-text preset="label-2" class="span-6" color="grey-darker">{{ $t("travelPass.inactivePassesHeader") }}</n-text>
        <travel-pass-item v-for="travelPass in inactive" :key="travelPass.id" :travel-pass="travelPass" @removed="refresh" :no-actions="true" />
      </n-layout>

    </n-scrollable>
    <travel-pass-sheet ref="travelPassSheet" @dismissed="refresh" />
  </div>
</template>

<script>
import TravelPassSheet from '@/sheets/travelPassSheet.vue';
import TravelPassItem from './travelPassItem.vue';
import user from '@/api/user';

export default {
  components: {
    TravelPassSheet,
    TravelPassItem
  },
  data: () => ({
    passes: [],
  }),
  computed: {
    active() {
      return this.passes.filter((pass) => pass.is_active).sort((a, b) => a.period_start - b.period_start);
    },
    inactive() {
      return this.passes.filter((pass) => !pass.is_active).sort((a, b) => a.period_start - b.period_start);;
    } ,
  },
  async created() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      const passes = await user.getTravelPasses();

      this.passes = passes.map((pass) => ({
          ...pass,
          period_start: new Date(pass.period_start),
          period_end: pass.period_end ? new Date(pass.period_end) : null
        })
      );

    },
    addTravelPass() {
      this.$refs.travelPassSheet.open();
    },
  }
};
</script>

