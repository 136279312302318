import { createTypes, createTypesNamespaced } from '@/vendor/utils';

const types = [

  // mutations
  'UPDATE_RECURRING',
  'SET_MARKETPLACE_BOOKING',
  'CLEAR_RECURRING_EDIT',
  'SET_OFFER_EDIT',
  'OVERVIEW_UPDATED',
  'OVERVIEW_DRIVER_TRIP_REMOVED',
  'OVERVIEW_PASSENGER_TRIP_UPDATED',
  'OVERVIEW_DRIVER_TRIP_PASSENGER_UPDATED',
  'HISTORY_UPDATED',
  'HISTORY_PAGED',
  'CLEAR',
  // V4
  'TRIP_DETAILS',
  'OVERVIEW_REQUEST_REMOVED',
  'DRIVER_TRIP_OFFERED_MANUEL',

  // actions
  'DELETE_RECURRING',
  'FETCH_RECURRING',
  'UPDATE_OVERVIEW',
  'UPDATE_HISTORY',
  'MORE_HISTORY',
  'RATE_DRIVER_IN_OVERVIEW',
  'RATE_PASSENGER_IN_OVERVIEW',
  'START_TRIP',
  'END_TRIP',
  // v4
  'CREATE_COMMUTE_REQUEST',
  'OFFER_TRIP',
  //
  'CREATE_AUTO_PASSENGER',
  'DELETE_AUTO_PASSENGER',

  // getters
  'CREATE',
  'OFFER_EDIT',
  'BOOK',
  // V4
  'HAS_ACTIVITY',
];

export const namespace = 'commute';

export const namespacedTypes = createTypesNamespaced(namespace, types);

export default createTypes(types);
