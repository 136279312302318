<template>
  <div
    class="wrapper span-6"
    @click="$refs.input.focus()"
  >
    <n-text
      v-if="label"
      preset="sub"
      class="color-grey-dark"
    >
      {{ label }}
    </n-text>
    <div :class="stateClass">
      <textarea
        ref="input"
        :value="value"
        :style="computedStyles"
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('update', $event.target.value)"
        @focus="focused = true"
        @blur="focused = false"
        @keydown="blockNewline"
      />
      <svg-icon
        v-if="invalid"
        name="close"
        color="var(--color-red-500)"
        size="sm"
        class="icon"
      />
      <svg-icon
        v-if="approved && !invalid"
        name="check"
        color="var(--color-green-500)"
        size="sm"
        class="icon"
      />
      <n-icon
        v-if="!invalid && !approved && icon && !focused"
        :name="icon"
        size="sm"
        class="icon"
      />
    </div>
    <n-text
      v-if="(invalid || approved) && message"
      preset="sub"
      :class="[invalid ? 'message__error' : '', approved ? 'message__success' : '']"
    >
      {{ message }}
    </n-text>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
    prop: 'value'
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    approved: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    autosize: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: [Number],
      'default': null
    },
    maxHeight: {
      type: [Number],
      'default': null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    focused: false,
    maxHeightScroll: false,
    height: 'auto'
  }),
  computed: {
    computedStyles() {
      if (!this.autosize) return {};
      return {
        resize: 'none',
        height: this.height,
        overflow: this.maxHeightScroll ? 'auto' : 'hidden'
      };
    },
    stateClass() {
      return this.invalid
        ? 'inner-wrapper__error'
        : this.approved
          ? 'inner-wrapper__success'
          : this.focused
            ? 'inner-wrapper__focused'
            : 'inner-wrapper__default';
    }
  },
  watch: {
    value() {
      this.$nextTick(this.resize);
    }
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      this.height = `auto`;
      this.$nextTick(() => {
        let contentHeight = this.$refs.input.scrollHeight + 1;
        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        this.height = contentHeight + 'px';
      });
      return this;
    },
    blockNewline(event) {
      const ENTER_KEY = 13;
      if (event.keyCode == ENTER_KEY) {
        event.preventDefault();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

%inner-wrapper {
  width: 100%;
  display: flex;
  border: 1px solid var(--color-grey-500);
  border-radius: 10px;
  background-color: #FBFBFB;
  background-clip: content-box;
  padding: 10px;
}

.inner-wrapper__default {
  @extend %inner-wrapper;
}

.inner-wrapper__success {
  @extend %inner-wrapper;
  border: 1px solid var(--color-green-700);
  box-shadow: 0 0 0 3px var(--color-green-50);
}

.inner-wrapper__focused {
  @extend %inner-wrapper;
  border: 1px solid var(--color-grey-500);
  box-shadow: 0 0 0 3px #B7B7B74D;
}

.inner-wrapper__error {
  @extend %inner-wrapper;
  border: 1px solid var(--color-red-700);
  box-shadow: 0 0 0 3px var(--color-red-50);
}

.icon {
  justify-self: end;
  align-self: end;
}

svg {
  height: 21px;
  fill: var(--color-grey-darker);
}

textarea {
  border: none;
  flex: 1;
  resize: none;
  letter-spacing: var(--letter-spacing-sm);
  font-size: var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  color: var(--color-grey-darker);
}

textarea::placeholder {
  color: var(--color-grey-dark);
}

.message {
  &__success {
    color: var(--color-green-700)
  }

  &__error {
    color: var(--color-red-700)
  }
}
</style>
