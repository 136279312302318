<template>
  <div
    v-if="isOpen"
    class="overlay-container"
  >
    <n-grid
      id="closing-wrapper"
      :y-gap="0"
      class="datetime-picker-container"
    >
      <n-column
        :span="6"
        class="layout"
      >
        <div class="title-box">
          <div class="title-line">
            <n-text-model 
              class="title-text"
              weight="bold" 
            >
              {{ $t('c.searchHeader.timePicker.title') }}
            </n-text-model>
            <svg-icon
              name="close"
              class="close-icon"
              color="#CDCDCD"
              @click="close"
            />
          </div>
          <n-text
            preset="body"
          >
            {{ description ?? $t(`c.searchHeader.timePicker.description.${mode}`) }}
          </n-text>
        </div>
        <div 
          v-if="displayMode"
          class="tabs" 
        >
          <button
            :class="['tab ', mode === 'departure' ? 'mode-selected' : 'mode-unselected']"
            @click="selectMode('departure')"
          >
            {{ $t('c.searchHeader.timePicker.modes.departure') }}
          </button>
          <button
            :class="['tab', mode === 'arrival' ? 'mode-selected' : 'mode-unselected']"
            @click="selectMode('arrival')"
          >
            {{ $t('c.searchHeader.timePicker.modes.arrival') }}
          </button>
        </div>

        <div class="wheel-container">
          <DatetimeWheel 
            :time-selected="timeSelected"
            :only-time="onlyTime"
            @datetimeChange="updateDatetime" 
          />
          <n-button
            class="save-button"
            @click="save"
          >
            {{ $t('c.searchHeader.timePicker.save') }}
          </n-button>
        </div>
      </n-column>
    </n-grid>
    <div
      v-show="hasBackDrop"
      class="backdrop"
      @click="close"
    />
  </div>
</template>

<script>
import DatetimeWheel from '@/components/core/inputs/datetimeWheel.vue';

export default {
  name: 'DatetimePicker',
  components: {
    DatetimeWheel
  },
  props: {
    open: {
      type: Boolean,
      default: true
    },
    timeSelected: {
      type: Date,
      default: null
    },
    timeMode: {
      type: String,
      default: 'departure'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    onlyTime: {
      type: Boolean,
      default: false
    },
    displayMode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.open,
      hasBackDrop: true,
      mode: this.timeMode,
      datetime: this.timeSelected ?? null
    };
  },
  methods: {
    save() {
      this.$emit('datetimeChange', {
        mode: this.mode,
        dateTime: this.datetime
      });
      this.close();
    },
    close() {
      if (!this.isOpen) {
        return;
      }

      const elem = document.getElementById("closing-wrapper");
      elem.style.transition = 'transform 0.6s ease-out';
      elem.style.transform = 'translateY(100%)';
      this.hasBackDrop = false;
      
      this.$emit('dateTimePickerClosed');
      
      setTimeout(() => this.isOpen = false, 600);
    },
    selectMode(mode) {
      this.mode = mode;
      this.$emit('modeChange', mode);
    },
    updateDatetime($event) {
      this.datetime = $event;
    }
  }

};
</script>

<style s- scoped>
.overlay-container {
  position: fixed;
  z-index: 9999;
  bottom: 0;
}

.datetime-picker-container {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  left:0;
  background-color: white;
  padding: 30px;
  border-radius: 20px 20px 0 0;
}

#closing-wrapper {
  transform: translateY(0);
  overflow:hidden;
}

.backdrop {
  top: 0;
  left: 0;
  opacity: 0.25;
  width: 100vw;
  z-index: 9997;
  height: 100vh;
  position: fixed;
  background-color: black;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-line {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 16px;
  }
}

.close-icon {
  width: 24px;
  height: 24px;
}

.title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.tabs {
  width: 100%;
  display: flex;
}

.tab {
  width: 100%;
  height: 32px;
  border: none;
  border-bottom: 1px black solid;
  padding-bottom: 20px;
  background: none;
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);
}

.mode-selected {
  border-bottom-width: 3px;
}

.mode-unselected {
  color: var(--color-grey);
  border-color: var(--color-grey);
}

.wheel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px
}
</style>
