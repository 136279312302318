import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';
import store from '@/store';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

window.Ably = Ably;

/** @type {Echo} */
let echoClient = null;
/**
 * @returns {Echo}
 */
function getEchoClient() {
  if (!echoClient) {
    echoClient = new Echo({
      broadcaster: 'ably',
      auth: {
        headers: {
          Authorization: `Bearer ${store.getters[userTypes.GET_TOKEN]}`,
          Accept: 'application/json',
        },
      },

    });
  }
  return echoClient;
}

export const EchoClient = getEchoClient;
