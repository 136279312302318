/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_bus': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico bus"><path pid="0" data-name="Path 332" d="M3.58 16.74a3.1 3.1 0 001 2.33V21a1 1 0 001 1.05h1.16a1 1 0 001-1.05v-1.11h8.42V21a1 1 0 001.1 1h1.06a1 1 0 001.05-1v-1.93a3.1 3.1 0 001.05-2.33V6.21C20.42 2.53 16.65 2 12 2s-8.42.53-8.42 4.21zm3.68 1.05a1.58 1.58 0 111.58-1.58 1.57 1.57 0 01-1.56 1.58zm9.48 0a1.58 1.58 0 111.58-1.58 1.57 1.57 0 01-1.57 1.58zm1.58-6.32H5.68V6.21h12.64z"/></g>'
  }
})
