/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_position-location-indicator': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 18 position"><path pid="0" data-name="Icon map-location-arrow" d="M15.47 22l-3.08-9.19h-10L21.63 2z"/></g>'
  }
})
