/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_repeat': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M7 7h10v3l4-4-4-4v3H5v6h2zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2z" _fill="#b7b7b7"/>'
  }
})
