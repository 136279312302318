<template>
  <img v-on="$listeners" v-bind="$attrs" :src="source" :class="[`size-${size}`]" />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: (size) => ["sm", "md", "lg"].includes(size),
    },
    src: {
      type: String,
    }
  },
  computed: {
    source() {
      if (this.src && this.src.includes('http')) {
        return this.src;
      }
      if (this.src) {
        return process.env.VUE_APP_IMAGE_URL + this.src;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

img {
  border-radius: 100%;
  object-fit: cover;
  &.size-sm {
    height: 34px;
    width: 34px;
  }
  &.size-md {
    height: 45px;
    width: 45px;
  }
  &.size-lg {
    height: 75px;
    width: 75px;
  }
}

</style>
