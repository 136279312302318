<template>
  <n-theme type="driver">
    <html-sheet
      ref="sheet"
      :title="$t('main.provide.boardPassengersSheet.title')"
      :video="$t('main.provide.boardPassengersSheet.video')"
      :content="$t('main.provide.boardPassengersSheet.content')"
    />
  </n-theme>
</template>

<script>
import { EventBus } from '@/vendor/events';
import HtmlSheet from '@/components/shared/htmlSheet';

export default {
  name: 'mainStartBoardPassengersSheet',
  components: {
    HtmlSheet,
  },
  mounted() {
    EventBus.$on('board-passengers-sheet-trigger', this.trigger);
    EventBus.$on('board-passengers-sheet-dismiss', this.dismiss);
  },
  beforeDestroy() {
    EventBus.$off('board-passengers-sheet-trigger', this.trigger);
    EventBus.$off('board-passengers-sheet-dismiss', this.dismiss);
  },
  methods: {
    trigger() {
      this.$refs.sheet.trigger();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
  },
};
</script>
