<template>
  <n-bottom-sheet
    type="header"
    :title="title"
    class="sheet prioritized-sheet"
    ref="sheet"
    no-layout
  >
    <address-search
      ref="addressSearch"
      class="span-6"
      v-bind="$attrs"
      v-on="componentListeners"
      @input="onInput"
      @select="onSelect"
      @error="onError"
    />
  </n-bottom-sheet>
</template>

<script>
import AddressSearch from '@/components/shared/addressSearch';

export default {
  inheritAttrs: false,
  inheritListeners: false,
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    AddressSearch,
  },
  methods: {
    open() {
      this.$refs.sheet.open();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
    clear() {
      if (this.$refs?.addressSearch) {
        this.$refs.addressSearch.clear();
      }
    },
    onInput(input) {
      this.$emit('input', input);
    },
    onSelect() {
      this.$emit('select');

      this.$nextTick(() => {
        this.dismiss();
      });
    },
    onError() {
      this.$nextTick(() => {
        this.open();
      });
    },
  },
  computed: {
    componentListeners() {
      const { input, ...listeners } = this.$listeners // exclude `input`-listener
      return listeners
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/styleguide.scss';

.sheet.prioritized-sheet {
  z-index: 99;
}

.address-search {
  width: 100%;
}
</style>
