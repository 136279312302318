<template>
  <div class="stats-display">
    <n-icon :name="icon" color="accent" />
    <n-text preset="body" uppercase color="accent">
      {{ text }}
    </n-text>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss">
.stats-display {
  white-space: nowrap;
  display: flex;
  gap: 0.1rem;

  & > .icon-wrapper,
  & > .text-model {
    display: inline-block;
    vertical-align: text-bottom;
  }

  & > .text-model {
    margin-left: 0.25rem;
  }

  & > .icon-wrapper > .icon {
    width: 24px;
    height: 24px;
  }
}
</style>