<template>
  <div id="app" class="styleguide" :style="styles">
    <n-alert />

    <missing-information v-if="shouldShowMissingInformation" />
    <login-sheet />
    <payment-sheet />
    <profile-sheet />
    <howto-slides v-if="isHowtoVisible" @close="onHowtoSlideClose" />
    <board-passengers-sheet />
    <outside-sponsored-area-sheet />
    <action-sheet-portal />
    <keep-alive :include="keepAliveIncludedComponents">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import ProfileSheet from '@/sheets/profileSheet';
  import LoginSheet from "@/screens/main/start/loginSheet";
  import PaymentSheet from '@/sheets/paymentSheet';
  import HowtoSlides from '@/components/howtoSlides';
  import MissingInformation from '@/components/missingInformation';
  import ActionSheetPortal from '@/components/shared/actionSheetPortal';
  import { EventBus } from '@/vendor/events';
  import store from '@/store';
  import { namespacedTypes as userTypes } from '@/store/modules/user-types';
  import BoardPassengersSheet from '@/screens/main/activeTrip/boardPassengersSheet';
  import OutsideSponsoredAreaSheet from '@/components/shared/tripDetails/outsideSponsoredAreaSheet';
  import { setupEchoListeners } from "@/vendor/echoListeners"

  export default {
    components: {
      ProfileSheet,
      PaymentSheet,
      LoginSheet,
      HowtoSlides,
      ActionSheetPortal,
      MissingInformation,
      BoardPassengersSheet,
      OutsideSponsoredAreaSheet
    },
    name: 'app',
    computed: {
      ...mapGetters('user', ['hasMandatoryProperties']),
      ...mapState('app', ['edges', 'menuOpen', 'routerHistory']),
      ...mapGetters('app', {
        keepAliveIncludedComponents: 'KEEP_ALIVE_COMPONENTS',
      }),
      isFullscreenRoute() {
        return this.$route?.meta?.fullscreen ?? true;
      },
      styles() {
        return {
          '--edge-padding-top': this.edges?.top ? `calc(${this.edges.top}px - 1.25rem)` : '0px',
          '--edge-padding-bottom': this.edges?.bottom ? `${this.edges.bottom}px` : '0px',
          'height': this.isFullscreenRoute ? '100vh' : 'auto',
        };
      },
      shouldShowMissingInformation() {
        const routeName = this.$route?.name ?? '';

        return !this.hasMandatoryProperties && !routeName.includes('landing');
      },
    },
    data: () => ({
      isStarting: true,
      isHowtoVisible: false,
      updateRegistrationWaiting: null,
    }),
    created() {
      EventBus.$on('content-update-available', async () => {
        if (this.isStarting) {
          await SWHelper.skipWaiting();
        }

        store.commit('app/SET_UPDATE_AVAILABLE', true);
      });

      setTimeout(() => {
        this.isStarting = false;
      }, 1000);
    },
    mounted() {
      // Globle handler form Axios
      EventBus.$on('error', this.$error.bind(this));
      EventBus.$on('alert', this.$notify.bind(this));
      EventBus.$on('show-howto', () => {
        this.isHowtoVisible = true;
      });

      if (this.$store.state.user.profile.id) {
        setupEchoListeners();
      }
    },
    methods: {
      onHowtoSlideClose() {
        this.isHowtoVisible = false;
      },
    },
  };
</script>

<style lang="scss">
  @import '@/style/main.scss';
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  * {
    box-sizing: border-box;
  }

  textarea {
    outline: none;
  }

  html, body {
    overflow: hidden;
  }

  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
  }

  .view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .text-sm {
    font-size: 0.9rem;
    color: $c-small;
  }
</style>