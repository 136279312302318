<script>
import { mapActions, mapState } from 'vuex';
import { namespace as userNamespace, namespacedTypes as userTypes } from '@/store/modules/user-types';
import { namespace as namespaceCommute, namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
export default {
  render() {
    return this.$slots.default
  },
  methods: {
    ...mapActions({
      cancelTrip: userTypes.CANCEL_COMMUTE_BOOKING,
    }),
    show(driverTripId, passengerTripId, callback) {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.passenger.cancelBookedDialog.title'),
        text: this.$t('tripDetails.passenger.cancelBookedDialog.description'),
        color: 'error',
        cancel: true,
        success: {
          text: this.$t('tripDetails.passenger.cancelBookedDialog.successButton'),
          handler: () => {
              this.cancelTrip({
              driverTripId,
              passengerTripId,
            })
            callback();
          },
        },
      });
    },
  },
}
</script>
