/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_target-spot': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico point"><g data-name="Ellipse 360"><circle pid="0" cx="12" cy="12" r="10" /><circle pid="1" cx="12" cy="12" r="8.57" _fill="white" stroke-width="2"/></g><circle pid="2" data-name="Ellipse 363" cx="12" cy="12" r="4.29"/></g>'
  }
})
