<template>
  <div class="upload-wrapper">
    <div @click="onClick" :class="[`size-${size}`]" ref="container" class="upload-container">
      <n-icon v-if="source === null" class="span-6" color="grey-dark" size="xxl" name="plus-person" type="circle-inverse" />
      <profile-picture v-if="source && !canvas" :src="source" class="span-6" size="lg" />
      <input ref="input" type="file" @change="onFileUpload" accept="image/*"/>
      <canvas ref="canvas" class="image-canvas"></canvas>
      <n-icon v-if="editIcon" class="edit-icon" size="md" name="pencil" outline color="accent" type="circle-inverse" />
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/shared/profilePicture';
import { handleOrientation } from '@/vendor/canvasHelper';
export default {
  components: {
    ProfilePicture
  },
  props: {
    size: {
      type: String,
      default: "md",
      validator: (size) => ["sm", "md", "lg", "xl"].includes(size),
    },
    editIcon: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
    }
  },
  data() {
    return {
      canvas: null,
    }
  },
  computed:{
    source() {
      return this.src;
    }
  },
  methods: {
    onClick() {
      this.$refs.input.click();
    },
    onFileUpload(e) {
      const files = e.target.files;
     if (files.length !== 1) {
        return;
      }
     const file = files[0];
      const processed = new Promise((resolve) => {
        resolve(file);
        return;
      });
      processed
        .then((value) => {
          this.updateImage(value);
          this.$emit('input', value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateImage(value) {
      if (!this.canvas && this.$refs.canvas) {
        this.canvas = this.$refs.canvas;
      }
      this.setImage(value);
    },
    setImage(image) {
      let canvas = this.$refs.canvas;
      canvas.className = 'image-canvas';
      handleOrientation(image, canvas, 'image');
      this.canvas.parentNode.replaceChild(canvas, this.canvas);
      this.canvas = canvas;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.upload-wrapper {
  display: flex;
  justify-content: center;

  .edit-icon {
    height: initial;
    position: absolute;
    align-items: flex-end;
    bottom: 0;
    left: 0;
  }
}

.upload-container {
  position: relative;
}
.upload-container, canvas {
  border-radius: 100%;
  object-fit: cover;
  &.size-sm {
    height: 34px;
    width: 34px;
  }
  &.size-md {
    height: 45px;
    width: 45px;
  }
  &.size-lg {
    height: 75px;
    width: 75px;
  }
  &.size-xl {
    height: 85px;
    width: 85px;
  }
}

input {
    display: none;
}


</style>
