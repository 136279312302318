<template>
<div class="button-wrapper">
  <div class="button-menu" @click="onClick">
    <div v-if="notice" class="notice"></div>
    <div v-if="hasActiveVacation" class="active-vacation"></div>
    <n-icon name="burger-menu" outline color="accent"/>
  </div>
</div>

</template>

<script>
import { EventBus } from '@/vendor/events';
export default {
  props: {
    notice: {
      type: Boolean,
      default: false,
    },
    hasActiveVacation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    onClick() {
      EventBus.$emit('toggle-drawer');
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.button-wrapper {
  position: absolute;
  top: 0;
  right: 0;

  /* make clickable area larger, for mobile users */
  padding: 17px;

  @extend .feedback;
  .button-menu {
    position: relative;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--color-white);
    svg {
      fill: var(--color-green);
      height: 28px;
      width: 28px;
    }
  }
  .notice {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    background-color: var(--color-red);
    z-index: 10;
  }

  .active-vacation {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 100%;
    background-color: var(--color-orange);
    z-index: 09;
  }
}

</style>
