/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_burger-menu': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="burger menu"><path pid="0" data-name="Path 2233" d="M2 17h20zM2 7h20z"/></g>'
  }
})
