<template>
  <div v-on="$listeners" class="container" :class="preset">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    preset: {
      type: String,
      default: "bottom",
      validator: (preset) => ["top", "bottom"].includes(preset),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.container {
  background-color: var(--color-white);
  position: relative;
  width: 100vw;

  &.top {
    box-shadow: 0 0 8px var(--color-shadow);
  }

  &.bottom {
    border-radius: 34px 34px 0 0;
    box-shadow: 0px -3px 6px var(--color-shadow);
  }
}
</style>
