<template>
  <div class="span-6">
    <n-layout class="span-6" v-if="loading">
      <n-spinner class="span-6 flex-center" />
    </n-layout>
    <template v-else>
      <template v-for="trip in driverTrips">
        <n-theme type="driver" :key="trip.id">
          <recurring-trip-activity @delete="deleteTrip(trip)" :key="`${trip.id}`" :trip="trip" :times="getLeaveReturnTime(trip)" />
        </n-theme>
      </template>
      <template v-for="trip in passengerTrips">
        <n-theme type="passenger" :key="trip.id">
          <recurring-trip-activity @delete="deleteTrip(trip)" :key="`${trip.id}`" :trip="trip" :times="getLeaveReturnTime(trip)" />
        </n-theme>
      </template>
    </template>
    <n-dialog />
  </div>
</template>

<script>
import { EventBus } from '@/vendor/events';
import { mapActions, mapState } from 'vuex';
import RecurringTripActivity from '@/components/shared/overview/recurringTripActivity';
import { namespacedTypes as commuteTypes, namespace as commuteNamespace } from '@/store/modules/commute-types';

export default {
  name: 'mainStartOverviewRecurring',
  components: {
    RecurringTripActivity,
  },
  data: () => {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState(commuteNamespace, [
        'recurring',
      ]),
    driverTrips() {
      return Object.values(this.recurring)
        .filter(trip => (trip !== null && trip.role === 'DRIVER'))
        .map(trip => this.parseTrip(trip));
    },
    passengerTrips() {
      return Object.values(this.recurring)
        .filter(trip => (trip !== null && trip.role === 'PASSENGER'))
        .map(trip => this.parseTrip(trip));
    },
  },
  mounted() {
    this.fetchRecurring();

    EventBus.$on('fetch-recurring-overview', this.fetchRecurring);
  },
  beforeDestroy() {
    EventBus.$off('fetch-recurring-overview', this.fetchRecurring);
  },
  methods: {
    ...mapActions({
      fetch: commuteTypes.FETCH_RECURRING,
      delete: commuteTypes.DELETE_RECURRING,
    }),
    fetchRecurring() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.fetch()
        .finally(() => {
          this.loading = false;
        });
    },
    parseTrip(trip) {
      return {
        ...trip,
        passengerTrip: {
          departure_stop: {
            street: trip.from_street,
            zipcode: trip.from_zipcode,
            city: trip.from_city,
          },
          arrival_stop: {
            street: trip.to_street,
            zipcode: trip.to_zipcode,
            city: trip.to_city,
          },
        },
      };
    },
    async deleteTrip(trip) {
      const snackMesssage = trip.role === 'DRIVER' ?
        'overview.recurring.deleteDialog.snackbarDriver' :
        'overview.recurring.deleteDialog.snackbarPassenger';

      await this.delete(trip);

      this.$success(this.$t(snackMesssage));
    },
    /**
     * Gets the time info, for the first day with times
     */
    getLeaveReturnTime(trip) {
      const times = [
        { leaving: trip.monday_leave },
        { leaving: trip.tuesday_leave },
        { leaving: trip.wednesday_leave },
        { leaving: trip.thursday_leave },
        { leaving: trip.friday_leave },
        { leaving: trip.saturday_leave },
        { leaving: trip.sunday_leave },
      ];
      let leaving = null;
      let returning = null;
      for (let i = times.length - 1; i >= 0; i--) {
        const day = times[i];
        if (day.leaving) {
          leaving = day.leaving;
        }
      }
      return {
        leaving: leaving,
      };
    },
  },
}
</script>
