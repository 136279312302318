<template>
  <portal-target name="action-sheet" multiple />
</template>

<script>
import { PortalTarget, Wormhole } from 'portal-vue';
export default {
  components: {
    PortalTarget,
  },
};
</script>

