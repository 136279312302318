<template>
  <GmapCustomMarker
    :marker="{
      lat: group.lat,
      lng: group.lng
    }"
    :delayRepaint="0"
    :offsetY="0"
    @click.native.prevent.stop="$emit('click')"
  >
    <div class="marker">
      {{ group.count }}
    </div>
  </GmapCustomMarker>
</template>

<script>
import GmapCustomMarker from '@/vendor/components/gmap-custom-marker';

export default {
  components: {
    GmapCustomMarker,
  },
  props: {
    group: Object
  },
};
</script>

<style lang="scss" scoped>
  @import '@/style/variables.scss';

  .marker {
    width: 2rem;
    height: 2rem;
    background-color: var(--color-accent);
    border-radius: 100%;
    text-align: center;
    margin-left: -1rem;
    margin-top: -1rem;
    padding: 0.5rem 0;
    color: #fff;
    font-size: 0.75rem;
    font-weight: normal;
  }
</style>
