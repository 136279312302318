import Vue from 'vue';
import VueI18n from 'vue-i18n';

import DA from './languages/danish.json';
import SV from './languages/swedish.json';
import EN from './languages/english.json';
import NL from './languages/dutch.json';
import NB from './languages/bokmal.json';

Vue.use(VueI18n);

// Some browsers can return da-DK, others - just da.
export const browserLang = window.navigator.language.substring(0, 2).toUpperCase();

const i18n = new VueI18n({
  locale: browserLang,
  fallbackLocale: 'EN',
  messages: {
    DA,
    SV,
    EN,
    NL,
    NB
  },
  numberFormats: {
    DA: {
      currency: {
        style: 'currency',
        currency: 'DKK',
      },
      currencyShort: {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      km: {
        style: 'decimal', maximumFractionDigits: 1
      },
    },
    SV: {
      currency: {
        style: 'currency',
        currency: 'SEK',
      },
      currencyShort: {
        style: 'currency',
        currency: 'SEK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      km: {
        style: 'decimal', maximumFractionDigits: 1
      },
    },
    EN: {
      // Currency not needed, always use the user currency - not language.
      km: {
        style: 'decimal', maximumFractionDigits: 1
      },
    },
    NL: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      currencyShort: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      km: {
        style: 'decimal', maximumFractionDigits: 1
      },
    },
    NB: {
      currency: {
        style: 'currency',
        currency: 'NOK',
      },
      currencyShort: {
        style: 'currency',
        currency: 'NOK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      km: {
        style: 'decimal', maximumFractionDigits: 1
      }
    },
  },
  dateTimeFormats: {
    DA: {
      dateWithYear: { year: 'numeric', month: 'long', day: 'numeric' },
      dateWithYearShort: { year: 'numeric', month: 'numeric', day: 'numeric' },
      dateWithTime: { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' },
    },
    EN: {
      dateWithYear: { year: 'numeric', month: 'long', day: 'numeric' },
      dateWithYearShort: { year: 'numeric', month: 'numeric', day: 'numeric' },
      dateWithTime: { year: 'numeric', month: 'numeric', day: 'numeric' },
    }
  },
  silentTranslationWarn: true,
});

// enable using currency for number formatting
i18n.setNumberFormat('DKK', i18n.numberFormats.DA);
i18n.setNumberFormat('SEK', i18n.numberFormats.SV);
i18n.setNumberFormat('EUR', i18n.numberFormats.NL);
i18n.setNumberFormat('NOK', i18n.numberFormats.NB);

export default i18n;
