<template>
  <div :class="!onlyTime ? 'grid-1fr-1fr' : ''">
    <scroll-picker
      v-if="!onlyTime"
      :options="dates"
      :drag-sensitivity="0.8"
      :touch-sensitivity="0.8"
      :value="dateSelected"
      @input="handleDateInput"
    />
    <div class="flex-row">
      <scroll-picker
        :options="hours"
        class="flex-row justify-end vue-scroll-picker-numeric"
        :drag-sensitivity="0.8"
        :touch-sensitivity="0.8"
        :value="hourSelected"
        @input="handleHourInput"
      />
      <scroll-picker
        :options="minutes"
        class="flex-row justify-start vue-scroll-picker-numeric"
        :drag-sensitivity="0.8"
        :touch-sensitivity="0.8"
        :value="minuteSelected"
        @input="handleMinuteInput"
      />
    </div>
  </div>
</template>

<script>
import { ScrollPicker } from 'vue-scroll-picker';
import { addDays, eachDayOfInterval, format, isToday, addMinutes, parse, set } from 'date-fns';

export default {
  name: 'DatetimeWheel',
  components: {
    ScrollPicker,
  },
  props: {
    timeSelected: {
      type: Date,
      default: null,
    },
    onlyTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateSelected: null,
      dateHovered: null,
      hourSelected: null,
      hourHovered: null,
      minuteSelected: null,
      minuteHovered: null,
    };
  },
  computed: {
    selectedDateTime() {
      const date = parse(this.dateSelected, 'yyyy-MM-dd', this.getCurrentDateTime());
      const hour = parseInt(this.hourSelected) || 0;
      const minute = parseInt(this.minuteSelected) || 0;

      return set(date, {
        hours: hour,
        minutes: minute,
        seconds: 0,
        milliseconds: 0,
      });
    },
    earliestAvailableDateTime() {
      const currentDateTime = this.getCurrentDateTime();
      const earliestAvailableDateTime = set(currentDateTime, {
        minutes: Math.ceil(currentDateTime.getMinutes() / 5) * 5,
        seconds: 0,
        milliseconds: 0,
      });

      return earliestAvailableDateTime >= currentDateTime
        ? earliestAvailableDateTime
        : addMinutes(earliestAvailableDateTime, 5);
    },
    dates() {
      return eachDayOfInterval({
        start: this.earliestAvailableDateTime,
        end: addDays(this.earliestAvailableDateTime, 90),
      }).map((date) => ({
        value: format(date, 'yyyy-MM-dd'),
        name: isToday(date)
          ? this.$t('datetime.today')
          : format(date, 'ccc d LLL'),
      }));
    },
    hours() {
      return Array.from({ length: 24 * 3 }, (_, i) => ({
        value: i - 24,
        name: (i % 24).toString().padStart(2, '0'),
      }));
    },
    minutes() {
      return Array.from({length: 12 * 3}, (_, i) => ({
        value: i * 5 - 60,
        name: (i * 5 % 60).toString().padStart(2, '0'),
      }));
    },
  },
  watch: {
    timeSelected: {
      immediate: true,
      handler: 'setDateTime',
    },
  },
  methods: {
    setDateTime(dateTime = null) {
      if (!dateTime || dateTime < this.earliestAvailableDateTime) {
        dateTime = this.earliestAvailableDateTime;
      }

      this.dateSelected = format(dateTime, 'yyyy-MM-dd');
      this.hourSelected = dateTime.getHours() % 24;
      this.minuteSelected = Math.ceil(dateTime.getMinutes() / 5) * 5 % 60;

      this.emitDatetimeChange();
    },
    getCurrentDateTime() {
      return new Date(); // eases time travel
    },
    restrictToFutureDateTime(lockedUnit = null, lockedUnitValue = null) {
      const date = lockedUnit === 'date' ? lockedUnitValue ?? this.dateSelected : this.dateSelected;
      const hour = lockedUnit === 'hour' ? lockedUnitValue ?? this.hourSelected : this.hourSelected;
      const minute = lockedUnit === 'minute' ? lockedUnitValue ?? this.minuteSelected : this.minuteSelected;

      const predictedDateTime = set(
        parse(date, 'yyyy-MM-dd', this.earliestAvailableDateTime),
        {
          hours: hour % 24,
          minutes: minute % 60,
          seconds: 0,
          milliseconds: 0,
        }
      );

      if (predictedDateTime >= this.earliestAvailableDateTime) {
        return;
      }

      if (lockedUnit === 'date') {
        this.hourSelected = this.earliestAvailableDateTime.getHours();
        this.minuteSelected = Math.ceil(this.earliestAvailableDateTime.getMinutes() / 5) * 5 % 60;
      }

      if (lockedUnit === 'hour') {
        this.dateSelected = format(
          addDays(this.earliestAvailableDateTime, 1),
          'yyyy-MM-dd'
        );
      }

      if (lockedUnit === 'minute') {
        this.hourSelected = (this.hourSelected + 1) % 24;

        if (this.hourSelected === 0) {
          this.restrictToFutureDateTime('hour', this.hourSelected);
        }
      }
    },
    onInputChange(type, value) {
      switch(type) {
        case 'date':
          this.dateSelected = value;
          break;
        case 'hour':
          this.hourSelected = value % 24;
          break;
        case 'minute':
          this.minuteSelected = value % 60;
          break;
      }

      this.restrictToFutureDateTime(type, value);
      this.emitDatetimeChange();
    },
    handleDateInput(date) {
      this.onInputChange('date', date);
    },
    handleHourInput(hour) {
      this.onInputChange('hour', hour);
    },
    handleMinuteInput(minute) {
      this.onInputChange('minute', minute);
    },
    emitDatetimeChange() {
      this.$emit('datetimeChange', this.selectedDateTime);
    },
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.grid-1fr-1fr {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
}

.vue-scroll-picker >>> .middle {
  z-index: 0;
  background-color: #e3e1de;
}

.vue-scroll-picker >>> .top {
  border-bottom: none;
  z-index: 2;
}

.vue-scroll-picker >>> .bottom {
  border-top: none;
  z-index: 2;
}

>>> .vue-scroll-picker-list-rotator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  flex-shrink: 0;
}

>>> .vue-scroll-picker-list {
  z-index: 1;
  height: 100%;
}

.vue-scroll-picker-numeric >>> .vue-scroll-picker-list {
  position: relative;
  width: 3rem;
}
</style>
