/* eslint-disable */
// import Alert from '@/legacy/components/Alert';
import i18n from '@/i18n';

export default {
  install(vue, opts) {
    const componentName = 'n-alert';

    const options = Object.assign({
      enabled: true,
    }, (typeof opts !== 'object' ? {} : opts));

    const parseArguments = (args) => {
      let obj = {};
      if (typeof args === 'string') {
        obj.description = args
      } else {
        obj = args;
      }

      return Object.assign({
        type: 'success',
        description: i18n.t('error.generic'),
        delay: 7000,
      }, obj);
    };

    // vue.component(componentName, Alert);

    vue.prototype.$alert = function showAlert(args) {
      if (!options.enabled) {
        return;
      }

      if (args.delay == null && args.action == null) {
        throw "Both delay and action is null on alert. It will never get dismissed.";
      }

      const relationLimit = 12; //+1 19/11/20, +1 25/11/20

      let parent = this;
      let result = null;

      for (let i = 0; i < relationLimit; i++) {
        if (
          result === null &&
          parent.$children[0] &&
          parent.$children[0].$options._componentTag === componentName
        ) {
          result = parent.$children[0];
          break;
        }
        parent = parent.$parent;
      }

      if (result === null) {
        console.error('No alert component found in parent stack');

        return;
      }

      result.show(parseArguments(args, this));
    };

    vue.prototype.$error = function showErrorAlert(args, action) {
      args = parseArguments(args);

      args.type = 'error';
      args.action = action;

      this.$alert(args);
    };

    vue.prototype.$success = function showSuccessAlert(args, action) {
      args = parseArguments(args);
      args.type = 'success';
      args.action = action;

      this.$alert(args);
    };

    vue.prototype.$notify = function showNotifyAlert(args, action) {
      args = parseArguments(args);
      args.type = 'notify';
      args.action = action;
      this.$alert(args);
    };
  }
};
