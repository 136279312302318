<template>
  <div class="view-container">
    <n-theme :type="theme">
      <n-nav-bar :title="loading ? '...' : title" />
    </n-theme>
    <n-theme
      v-if="loading"
      class="spinner-container"
      :type="theme"
    >
      <n-spinner class="span-6 flex-center" />
    </n-theme>
    <n-scrollable v-if="isAllLoaded">
      <n-theme :type="theme">
        <n-layout
          :spacing-y="false"
          :spacing-top="true"
          :bottom-gap="5"
        >
          <n-icon
            :name="icon"
            color="accent"
            class="flex-start"
          />
          <n-text
            preset="title"
            color="accent"
            class="span-3 flex-v-center"
          >
            {{
              trip.departure_time &&
                formatRelativeWithoutTime(trip.departure_time)
            }}
          </n-text>
          <slot name="topRight" />
          <div
            v-if="!$slots['topRight']"
            class="span-2"
          />
          <div />
          <span
            v-if="!trip.public"
            class="span-2"
          >
            <n-text
              preset="label-2"
              color="accent"
              :striked="price.discounted_price != null"
            >{{ price.price }}</n-text>
            <n-text
              v-if="price.discounted_price"
              preset="label-2"
              color="accent"
            >{{ price.discounted_price }}</n-text>
          </span>
          <n-text
            preset="label-2"
            color="accent"
            align="right"
            :class="[trip.public ? 'span-5' : 'span-3']"
          >
            <slot name="topRightInfo" />
          </n-text>
          <div />
          <n-text
            preset="label-2"
            class="span-2"
            color="accent"
          >
            {{
              `${$t('departureShort')} ${
                trip.departure_time && format(trip.departure_time, 'HH:mm')
              }`
            }}
          </n-text>
          <n-text
            preset="label-2"
            class="span-3"
            color="accent"
            align="right"
          >
            {{
              `${$t('arrivalShort')} ${
                trip.arrival_time && format(trip.arrival_time, 'HH:mm')
              }`
            }}
          </n-text>
        </n-layout>
        <slot name="schedule" />
        <base-map
          ref="map"
          class="map-wrapper"
        >
          <template v-if="mapLoaded">
            <template v-for="(route, index) in mapData.routes">
              <n-route
                :key="getMapKey(`r_${index}`)"
                :theme="
                  route.public
                    ? 'deactivated'
                    : route.theme
                      ? route.theme
                      : 'passenger'
                "
                :dashed="isOnDemand"
                :faded="isOnDemand"
                :points="isOnDemand ? [
                  route.route[0],
                  route.route[route.route.length - 1],
                ] : route.route"
              />
            </template>

            <template v-for="(stop, index) in mapData.stops">
              <n-marker
                :key="getMapKey(`stop_${index}`)"
                :type="stop.type"
                :position="stop"
                :theme="theme"
              />
            </template>
            <n-marker
              v-if="currentPosition"
              type="current-position"
              :position="currentPosition"
            />
          </template>
        </base-map>
        <n-layout v-if="contains9292Trips">
          <n-icon
            name="9292"
            size="lg"
            class="span-1"
          />
          <n-text
            class="span-5"
            preset="sub"
            color="grey-dark"
          >
            {{
              $t('marketplace.sheet-bottom.9292')
            }}
          </n-text>
        </n-layout>
        <slot name="custom" />
      </n-theme>
      <n-dialog />
    </n-scrollable>
  </div>
</template>

<script>
import { contains9292Trips } from '@/vendor/utils.js';
import { formatRelativeWithoutTime, format } from '@/vendor/date-fns.js';
import BaseMap from '@/components/shared/map/baseMap.vue';
import NMarker from '@/components/shared/map/marker.vue';
import NRoute from '@/components/shared/map/route.vue';
import { namespacedTypes as appTypes } from '@/store/modules/app-types.js';

let keyIncrement = 0;

export default {
  name: 'TripDetailsWrapper',
  components: { BaseMap, NRoute, NMarker },
  props: {
    theme: {
      type: String,
      default: 'passenger',
    },
    icon: {
      type: String,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    mapFun: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mapLoaded: false,
      driverTrip: null,
      tripId: null,
    };
  },
  computed: {
    mapData() {
      if (this.loading == false && this.mapLoaded) {
        return this.mapFun();
      }
      return { };
    },
    isAllLoaded() {
      return this.loading == false && this.mapLoaded;
    },
    currentPosition() {
      return this.$store.getters[appTypes.LAST_KNOWN_LOCATION];
    },
    contains9292Trips() {
      return contains9292Trips(this.trip);
    },
    isOnDemand() {
      return Boolean(this.trip?.prospect_id);
    },
  },
  watch: {
    mapData(val) {
      if (!val.stops) return;
      this.updateBounds(val.stops);
    },
  },
  mounted() {
    if (!this.mapLoaded) {
      this.loadMap();
    }
    this.$store.dispatch(appTypes.GET_CURRENT_LOCATION);
  },
  methods: {
    formatRelativeWithoutTime,
    format,
    updateBounds(stops) {
      const bounds = new window.google.maps.LatLngBounds();
      stops.forEach((pos) => {
        bounds.extend({ lat: pos.lat, lng: pos.lng });
      });
      setTimeout(() => {
        // If not doing this/using next tick, the map thinks it 100% when doing the fit bounds
        if (typeof this.$refs.map === 'undefined') {
          return;
        }

        this.$refs.map.get().then((map) => {
          map.fitBounds(bounds);
        });
      }, 10);
    },
    async loadMap() {
      await this.$gmapApiPromiseLazy();
      this.mapLoaded = true;
    },
    getMapKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${keyIncrement++}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 300px;
  margin-top: 20px;
}

.spinner-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
