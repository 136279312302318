<template>
  <n-grid>
    <n-text preset="sub" class="span-5" color="warning">{{
      $t('tripDetails.outsideSponsoredArea.note')
    }}</n-text>

    <n-column class="column">
      <n-help @click="trigger" />
    </n-column>
  </n-grid>
</template>

<script>
import { EventBus } from '@/vendor/events';
import HtmlSheet from '@/components/shared/htmlSheet';

export default {
  name: 'outsideSponsoredArea',
  components: {
    HtmlSheet,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trigger() {
      EventBus.$emit('outside-sponsored-area-sheet-trigger');
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  justify-content: flex-end;
}
</style>