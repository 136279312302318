/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_star': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Icon feather-star"><path pid="0" data-name="Icon feather-star-2" d="M12 2.48l3.09 6.27 6.91 1-5 4.88 1.18 6.89L12 18.27l-6.18 3.25L7 14.63 2 9.75l6.91-1z"/></g>'
  }
})
