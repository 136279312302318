/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_chevron-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 01 back"><path pid="0" data-name="Path 2236" d="M7 12L17 2 7 12l10 10z"/></g>'
  }
})
