/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_plus-person': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" data-name="Path 51" d="M14 12a4 4 0 10-4-4 4 4 0 004 4zm0 2c-2.65 0-8 1.35-8 4v2h16v-2c0-2.65-5.35-4-8-4z" _fill="#b7b7b7"/><path pid="1" data-name="Path 52" d="M6 10.67V8H4.67v2.67H2V12h2.67v2.67H6V12h2.67v-1.33z"/>'
  }
})
