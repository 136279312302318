import { createTypes, createTypesNamespaced } from '@/vendor/utils';

const types = [
  // mutations
  'CLEAR',
  'CLOSE_MENU',
  'SET_ONLINE',
  'SET_UI_EDGES',
  'STATUS_BAR_HEIGHT_CHANGED',
  'SET_UPDATE_AVAILABLE',
  'SET_NATIVE_UPDATE_AVAILABLE',
  'NOTIFICATION_OPENED',
  'HISTORY_PUSH',
  'HISTORY_BACK',
  'HISTORY_REPLACE',
  'HISTORY_RESET',
  'GOT_LOCATION',
  'SET_LOGIN_REDIRECT',
  'SET_SHOW_STOPS',
  'SET_LANDING_PAGE_DATA',
  'RESET_LANDING_PAGE_SIGN_UP',
  'SET_FINGERPRINT',

  // actions
  'SET_STATUS_BAR_HEIGHT',
  'GET_CURRENT_LOCATION',
  'DO_AFTER_LOGIN',
  'SEND_REFERRALS',

  // getters
  'KEEP_ALIVE_COMPONENTS',
  'LAST_KNOWN_LOCATION',
];

export const namespace = 'app';

export const namespacedTypes = createTypesNamespaced(namespace, types);

export default createTypes(types);
