<template>
<n-theme :type="theme">
  <n-bottom-sheet type="header" :title="`${user.name} ${user.last_name || ''}`" class="sheet" ref="sheet">
    <n-layout v-if="loading">
      <n-spinner class="span-6 flex-center" />
    </n-layout>
    <template v-slot:top>
      <template v-if="!loading">
        <div class="span-6 profile-info">
          <profile-picture :src="user.image" size="lg" class="profile-picture"/>
          <n-column :span="4">
            <rating size="lg" :stars="user.ratings_average" />
            <n-text preset="sub" color="grey-dark">
              {{ $tc('publicProfile.reviews', user.ratings_count) }} |
              {{ $tc('publicProfile.commuteCount',  user.stats.carpools_completed) }}
            </n-text>
            <n-theme type="driver">
            <div class="phone-verified">
                <n-icon name="verified" size="md" color="accent" />
                <n-text preset="sub" color="accent" class="phone-verified-text">
                  {{ $t('publicProfile.phoneVerified') }}
                </n-text>
            </div>
            </n-theme>
          </n-column>
        </div>
        <n-column :span="6">
          <n-text preset='sub' color='grey-dark'>{{ user.description }}</n-text>
        </n-column>
        <n-seperator class="span-6" />
      </template>
    </template>
    <template v-if="!loading">
      <n-text v-if="driverTrips.length > 0" color="accent" class="span-6" preset="label-1"></n-text>
      <n-grid v-else :y-gap="5" class="span-6" >
        <n-text preset="sub" class="span-6" align="center">{{ $t('publicProfile.noDriverTrips') }}</n-text>
      </n-grid>
      <n-grid v-for="(rec, index) in driverTrips" :key="`driver_${index}`" :set="times = getLeaveReturnTime(rec)">
        <!-- DEPART -->
        <template v-if="rec.monday_leave">
          <n-text color="grey-dark" class="span-2">{{ departTime(times.leaving) }}</n-text>
          <n-text color="grey-dark" class="span-4">{{ rec.from_city }}</n-text>
          <n-text color="grey-dark" class="span-4">{{ rec.to_city }}</n-text>
          <n-seperator class="span-6" />
        </template>
      </n-grid>

      <n-text v-if="passengerTrips.length > 0" color="accent" class="span-6" preset="label-1">{{ $t('publicProfile.passengerCommute') }}</n-text>
      <n-grid v-else :y-gap="5" class="span-6" >
        <n-text class="span-6" preset="sub" align="center">{{ $t('publicProfile.noPassengerTrips') }}</n-text>
      </n-grid>
      <n-grid v-for="(rec, index) in passengerTrips" :key="`passenger_${index}`" :set="times = getLeaveReturnTime(rec)">
        <!-- DEPART -->
        <template v-if="rec.monday_leave">
          <n-text color="grey-dark" class="span-2">{{ departTime(times.leaving) }}</n-text>
          <n-text color="grey-dark" class="span-4">{{ rec.from_city }}</n-text>
          <n-text color="grey-dark" class="span-4">{{ rec.to_city }}</n-text>
          <n-seperator class="span-6" />
        </template>
      </n-grid>
    </template>
  </n-bottom-sheet>
</n-theme>
</template>
<script>

import store from "@/store";
import { EventBus } from '@/vendor/events';
import ProfilePicture from '@/components/shared/profilePicture';
import Rating from '@/components/shared/rating';
import { format } from '@/vendor/date-fns';
import { namespacedTypes as userTypes, namespace as userNamespace } from '@/store/modules/user-types';

export default {
  components: {
    ProfilePicture,
    Rating
  },
  props: {
    theme: {
      type: String,
      default: "passenger",
    }
  },
  data() {
    return {
      loading: true,
      title: null,
      commute: {},
      user: { name: '', last_name: ''},
    }
  },
  computed: {
    passengerTrips() {
      return this.commute.recurring.filter(x => x.role === 'PASSENGER');
    },
    driverTrips() {
      return this.commute.recurring.filter(x => x.role === 'DRIVER');
    },
  },
  mounted() {
    EventBus.$on('open-profile', (userId) => {
      if (!store.getters['user/isLoggedIn']) {
        return;
      }
      this.open(userId);
    });

  },
  methods: {
    async open(userId) {
      if (!this.$refs.sheet) return;
      this.loading = true;
      this.$refs.sheet.open();
      if (this.user && this.user.id === userId) {
        this.loading = false;
        return;
      }
      const profile = await this.$store.dispatch(userTypes.FETCH_PUBLIC_PROFILE, userId);
      this.commute = profile.commute;
      this.user = profile.user;
      this.loading = false;
    },
    /**
     * Gets the time info, for the first day with times
     */
    getLeaveReturnTime(trip) {
        const times = [
          { leaving: trip.monday_leave },
          { leaving: trip.tuesday_leave },
          { leaving: trip.wednesday_leave },
          { leaving: trip.thursday_leave },
          { leaving: trip.friday_leave },
          { leaving: trip.saturday_leave },
          { leaving: trip.sunday_leave },
        ];

        let leaving = null;

        for (let i = times.length - 1; i >= 0; i--) {
          const day = times[i];

          if (day.leaving) {
            leaving = day.leaving;
          }
        }

        return {
          leaving: leaving,
        };
      },
    departTime(time) {
      if (!time) return;
      return time.substring(0, 5); // Get 17:00 from 17:00:00
    },
    /**
     * Add travel time to leaving time to get arrival
     */
    arriveTime(startTime, travelTime) {
      if (!startTime || !travelTime) return;
      const hours = startTime.substring(0, 2);
      const mins = startTime.substring(3,5);
      const date = new Date().setHours(hours, mins, travelTime);
      return format(date, 'HH:mm');
    },
  }
}
</script>
<style scoped>
.phone-verified {
  display: flex;
  align-items: center;
}

.phone-verified-text {
  margin-left: 5px;
}

.profile-picture {
  margin-right: 15px;
}

.profile-info {
  display: flex;
}
</style>
