/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 06 home"><path pid="0" data-name="Icon open-home" d="M12 3.25l-10 7.5h2.5v10h5v-5h5v5h5V10.68l2.5.07z"/></g>'
  }
})
