<template>
  <wrapper
    icon="location-alt"
    theme="passenger"
    :price="formatPrice(trip)"
    :trip="trip"
    :title="title"
    :map-fun="resolveMapData"
    :loading="loading"
  >
    <template #topRight>
      <n-button
        v-if="tripIsBookable"
        :loading="bookingLoading || tripStateLoading"
        @click="book"
      >
        {{
          isOnDemand
            ? $t('tripDetails.search.bookOnDemandButton')
            : $t('tripDetails.search.bookButton')
        }}
      </n-button>
      <n-button
        v-else-if="trip.public && trip.link"
        @click="showPublic"
      >
        {{
          $t('tripDetails.search.showPublicShort')
        }}
      </n-button>
    </template>
    <template #topRightInfo>
      {{ duration }}
    </template>
    <template #schedule>
      <discount-eligibility-warning
        :booking="trip"
      />

      <auto-passenger
        v-if="!trip.public && trip.driver_trip_details?.id"
        :is-loading="tripStateLoading"
        :is-auto-bookable="isAutoBookable"
        :auto-booked="autoBooked"
        :trip-id="trip.driver_trip_details?.id"
        :from-stop-id="trip.departure_stop_id"
        :to-stop-id="trip.arrival_stop_id"
        :date-time="new Date(trip.departure_time)"
        @bookedChanged="autoBookedChanged($event)"
      />
      <vertical-schedule
        class="span-6"
        :legs="trip.legs"
        :destination="toAddress"
      />
      <n-dialog name="search-result" />
    </template>
    <template #custom>
      <n-layout>
        <profile-summary
          v-if="trip.driver"
          :user="trip.driver"
        />
        <n-button
          v-if="tripIsBookable"
          size="lg"
          block
          :loading="bookingLoading"
          @click="book"
        >
          {{
            isOnDemand
              ? $t('tripDetails.search.bookOnDemandButton')
              : $t('tripDetails.search.bookButton')
          }}
        </n-button>
        <n-button
          v-else-if="trip.public && trip.link"
          size="lg"
          block
          @click="showPublic"
        >
          {{ $t('tripDetails.search.showPublicShort') }}
        </n-button>
        <n-button
          v-if="trip.combi && trip.link"
          type="outlined"
          inverted
          block
          @click="showPublic"
        >
          {{ $t('tripDetails.search.showPublic') }}
        </n-button>
      </n-layout>
    </template>
  </wrapper>
</template>
<script>
import { EventBus } from '@/vendor/events';
import { isBefore, parseISO, differenceInSeconds } from 'date-fns';
import { formatDistanceStrict } from '@/vendor/date-fns';
import { formatPrice } from '@/vendor/utils';
import { mapState, mapMutations } from 'vuex';
import { namespacedTypes as commuteTypes } from '@/store/modules/commute-types';
import {
  parseTripToLocation,
  parseTripAddressToLocation,
} from '@/vendor/utils';
import * as mapUtils from '@/vendor/maps';
import apiTrip from '@/api/trip';
import AutoPassenger from '@/components/shared/tripDetails/autoPassenger';
import commuteApi from '@/api/commute';
import DiscountEligibilityWarning from '@/components/shared/tripDetails/discountEligibilityWarning';
import i18n from '@/i18n/index';
import ProfileSummary from '@/components/shared/profileSummary';
import store from '@/store';
import VerticalSchedule from '@/components/shared/tripDetails/verticalSchedule';
import Wrapper from '@/screens/tripDetails/wrapper';
import { triggerFormbricksAction } from '@/plugins/Formbricks';

export default {
  name: 'TripDetailsSearchResult',
  components: {
    VerticalSchedule,
    Wrapper,
    ProfileSummary,
    AutoPassenger,
    DiscountEligibilityWarning,
  },
  beforeRouteEnter(to, from, next) {
    const {
      requestId,
      driverTripId,
      departureStopType,
      departureStopId,
      destinationStopType,
      destinationStopId,
      departureStopPivotId,
      destinationStopPivotId,
      code,
      ref,
    } = to.query;

    const isFromRequestMatch = !!requestId && !!driverTripId;
    const isFromDriverTripPivots = !!driverTripId && Number(departureStopPivotId) > 0 && Number(destinationStopPivotId) > 0;
    const isFromDriverTripStops = !!driverTripId && (Number(departureStopId) > 0 || departureStopId === null) && (Number(destinationStopId) > 0 || destinationStopId === null);
    const isFromTravelSheet = from.name == 'travel-pass' && !!to.params['back'];
    const isFromMarketPlaceSheet = !!to.params['search-result'] && !to.params['back'];
    const isFromOnDemandCode = !!code;
    const isFromRejseplanen = ref === 'rejseplanen'; 

    const shouldTriggerSurvey = !!to.params['should-trigger-survey'];

    next(async (vm) => {
      if (isFromTravelSheet) {
        vm.refetchTrip();
        return;
      }

      try {
        switch (true) {
          case isFromMarketPlaceSheet:
            break;
          case isFromRequestMatch:
            await vm.setFromRequestMatch(driverTripId, requestId);
            break;
          case isFromRejseplanen:
            await vm.setFromDriverTripStops(
              driverTripId,
              'App\\Project\\Commute\\CommuteStop',
              departureStopId,
              'App\\Project\\Commute\\CommuteStop',
              destinationStopId
            );
            break;
          case isFromDriverTripPivots:
            await vm.setFromDriverTripPivots(
              driverTripId,
              departureStopPivotId,
              destinationStopPivotId
            );
            break;
          case isFromDriverTripStops:
            await vm.setFromDriverTripStops(
              driverTripId,
              departureStopType,
              departureStopId,
              destinationStopType,
              destinationStopId
            );
            break;
          case isFromOnDemandCode:
            await vm.setFromOnDemandCode(code);
            break;
          case shouldTriggerSurvey:
            await vm.setShouldTriggerSurvey();
            break;
        }
        vm.fetchState();
      } catch (e) {
        next({ name: 'content-not-found' });
      } finally {
        vm.loading = false;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    EventBus.$off(
      'payment-success-balance-fetched',
      this.showBookingConfirmationDialog
    );
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    const { code: lastCode } = from.query;
    const { code: currentCode } = to.query;

    if (lastCode != currentCode) {
      try {
        await this.setFromOnDemandCode(currentCode);
      } catch (e) {
        next({ name: 'content-not-found' });
      } finally {
        this.loading = false;
      }
    }

    next();
  },

  data() {
    return {
      loading: true,
      bookingLoading: false,

      alreadyBookedId: null,
      tripStateLoading: false,
      isAutoBookable: false,
      autoBooked: null,
    }
  },
  computed: {
    ...mapState('user', {
      profile: 'profile',
    }),
    isOnDemand() {
      return Boolean(this.trip?.prospect_id);
    },
    tripIsBookable() {
      if (this.trip.public) {
        return false;
      }

      let { bookable_until, departure_time } = this.trip;

      if (!bookable_until) {
        bookable_until = departure_time;
      }

      return isBefore(
        new Date(),
        typeof bookable_until === 'string'
          ? parseISO(bookable_until)
          : bookable_until
      );
    },
    duration() {
      if (!this.trip.departure_time || !this.trip.arrival_time) return null;

      return formatDistanceStrict(
        new Date(this.trip.departure_time),
        new Date(this.trip.arrival_time)
      );
    },
    trip() {
      // Used to trick vue to update value after fetching details.
      // https://vuejs.org/v2/guide/reactivity.html#For-Objects
      this.loading;

      return this.$store.state.commute.tripDetails?.trip || {};
    },
    title() {
      return this.toAddress?.completeAddress || '';
    },
    toAddress() {
      // Used to trick vue to update value after fetching details.
      // https://vuejs.org/v2/guide/reactivity.html#For-Objects
      this.loading;

      if (!this.trip.public && this.trip.legs && this.trip.legs.length > 0) {
        return parseTripAddressToLocation(
          this.trip.legs[this.trip.legs.length - 1].destination
        );
      }

      return this.$store.state.commute.tripDetails?.toAddress || {};
    },
  },
  methods: {
    ...mapMutations('app', {
      setLandingPageData: 'SET_LANDING_PAGE_DATA',
    }),
    formatPrice,
    refetchTrip() {
      if (this.$route.query.code) {
        this.setFromOnDemandCode(this.$route.query.code);
        return;
      } else if (this.$store.state.commute.tripDetails.requestId) {
        this.setFromRequestMatch(
          this.trip.id,
          this.$store.state.commute.tripDetails.requestId
        );
      } else {
        this.setFromDriverTripPivots(
          this.trip.id,
          this.trip.departure_stop_id,
          this.trip.arrival_stop_id
        );
      }
    },
    fetchState() {
      if (!this.profile?.id || this.trip.public) {
        return;
      }
      if (this.trip.public) {
        return;
      }

      this.tripStateLoading = true;

      commuteApi
        .driverTripState(this.trip.id)
        .then(({ already_booked_id, is_auto_bookable, auto_booked }) => {
          this.alreadyBookedId = already_booked_id;
          this.isAutoBookable = is_auto_bookable;
          this.autoBooked = auto_booked;

          if (already_booked_id) {
            this.redirectToTripDetails(already_booked_id, this.trip.id);
          }

        })
        .catch(() => {
          this.alreadyBookedId = null;
        })
        .finally(() => {
          this.tripStateLoading = false;
        });
    },
    setFromRequestMatch(driverTripId, requestId) {
      this.loading = true;
      return commuteApi
        .fetchMarketplaceTrip(driverTripId, requestId)
        .then((trip) => {
          //I dunno when trip.request.departure is set and when its not. It might have changed after marketplace refactor.
          //So for now we handle both 'states'
          const fromAddress = trip.request.departure
            ? parseTripAddressToLocation(trip.request.departure)
            : parseTripAddressToLocation(trip.request, 'departure_');
          const toAddress = trip.request.destination
            ? parseTripAddressToLocation(trip.request.destination)
            : parseTripAddressToLocation(trip.request, 'arrival_');

          this.$store.commit(commuteTypes.TRIP_DETAILS, {
            trip: trip,
            fromAddress,
            toAddress,
            requestId: this.requestId,
            seats: trip.request.seats,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFromDriverTripPivots(driverTripId, departureStopId, destinationStopId) {
      this.loading = true;

      return commuteApi
        .fetchMarketplaceTripWithPivots(
          driverTripId,
          departureStopId,
          destinationStopId
        )
        .then((trip) => {
          this.setSearchResult(trip);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFromDriverTripStops(
      driverTripId,
      departureStopType,
      departureStopId,
      destinationStopType,
      destinationStopId
    ) {
      this.loading = true;

      return commuteApi
        .fetchMarketplaceTripWithStops(
          driverTripId,
          departureStopType,
          departureStopId,
          destinationStopType,
          destinationStopId
        )
        .then((trip) => {
          this.setSearchResult(trip);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFromOnDemandCode(code) {
      this.loading = true;

      return apiTrip
        .searchTripCode(code)
        .then(({ trip }) => {
          this.setSearchResult(trip);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setShouldTriggerSurvey() {
      this.shouldTriggerSurvey = true;
    },
    setSearchResult(trip) {
      const tripDetails = {
        trip,
        seats: trip.request ? trip.request.seats : 1,
      };

      // This is from a rejseplanen link. But is it also from other places,
      if (trip.legs && Array.isArray(trip.legs) && trip.legs.length > 0) {
        const tripLocation = trip.legs[0];
        tripDetails.fromAddress = parseTripAddressToLocation(
          tripLocation.origin
        );
        tripDetails.toAddress = parseTripAddressToLocation(
          tripLocation.destination
        );
      }

      if (trip.request) {
        const parsedLocation = parseTripToLocation(trip.request);
        tripDetails.fromAddress = trip.request ?? parsedLocation.fromAddress;
        tripDetails.toAddress = trip.request ?? parsedLocation.toAddress;
      }

      this.$store.commit(commuteTypes.TRIP_DETAILS, tripDetails);
    },
    resolveMapData() {
      const from = this.$store.state.commute.tripDetails?.fromAddress;
      const to = this.$store.state.commute.tripDetails?.toAddress;

      if (!from || !to || !this.trip.legs) return {};

      if (this.trip.public) {
        return mapUtils.publicTripToRoutes(from, to);
      }
      return mapUtils.combiTripToRoutes(this.trip, from, to);
    },
    setOnDemandReferrals() {
      if (!this.isOnDemand) {
        return;
      }

      this.setLandingPageData({
        referrals: {
          utm_source: 'instant-booking',
          utm_medium: 'qr',
          app: true,
          trip_id: this.trip.id,
          driver_id: this.trip.driver?.id,
        },
      });
    },
    book() {
      if (!store.getters['user/isLoggedIn']) {
        this.setOnDemandReferrals();

        EventBus.$emit('show-login-sheet', {
          doAfterLogin: () => {
            this.$router.push({
              name: 'trip-details-search',
              query: {
                driverTripId: this.trip.trip_id,
                departureStopPivotId: this.trip.departure_stop_id,
                destinationStopPivotId: this.trip.destination_stop_id,
              },
            });
          },
        });
        return;
      }

      const currentBalance = this.$store.state.user.profile.balance_available;

      if (currentBalance < this.trip.actual_price) {
        EventBus.$emit(
          'open-payment-sheet',
          this.trip.actual_price,
          this.trip.id,
          this.trip.discount_reason
        );

        EventBus.$on(
          'payment-success-balance-fetched',
          this.showBookingConfirmationDialog
        );

        return;
      }

      this.showBookingConfirmationDialog();
    },
    showBookingConfirmationDialog() {
      const currentBalance = this.$store.state.user.profile.balance_available;

      this.$modal.show('search-result', {
        title: this.$t('tripDetails.search.confirmDialog.title'),
        text: this.$t('tripDetails.search.confirmDialog.description', [
          this.$n(
            currentBalance - this.trip.actual_price,
            'currency',
            this.$store.state.user.profile.currency
          ),
        ]),
        cancel: true,
        success: {
          text: this.$t('tripDetails.search.confirmDialog.successButton'),
          handler: async () => {
            if (this.isOnDemand) {
              this.bookTripProspect();
              return;
            }

            this.bookingLoading = true;

            const reqId = this.$store.state.commute.tripDetails.requestId || null;

            try {
              const data = await commuteApi.marketplaceBook({
                driver_trip_id: this.trip.driver_trip_details.id,
                from_driver_stop_id: this.trip.departure_stop_id,
                to_driver_stop_id: this.trip.arrival_stop_id,
                request_id: reqId,
              });

              this.$router.resetToHome();

              this.redirectToTripDetails(data.id, data.driver_trip_id);
            } catch (e) {
              EventBus.$emit('unhandledResponseError', e);
              return;
            } finally {
              this.bookingLoading = false;
            }

            if (this.shouldTriggerSurvey) {
              const sessionStartedAt = new Date(
                localStorage.getItem('sessionStartTime')
              );

              const tripSearchStartedAt = new Date(
                localStorage.getItem('tripSearchStartTime')
              );

              const attributes = {
                secondsSinceSessionStarted: differenceInSeconds(
                  new Date(),
                  sessionStartedAt
                ),
                secondsSinceSearchStarted: differenceInSeconds(
                  new Date(),
                  tripSearchStartedAt
                ),
                geoInformation: JSON.stringify({
                  from: this.trip.request.departure,
                  to: this.trip.request.destination,
                }),
              };

              triggerFormbricksAction(
                'Action:MarketplaceTrip:Requested',
                attributes
              );
            }

            this.$success(i18n.t('tripDetails.search.snackbar'), {
              text: i18n.t('tripDetails.search.snackbarButton'),
            });
          },
        },
      });
    },
    async bookTripProspect() {
      if (this.bookingLoading) {
        return;
      }

      this.bookingLoading = true;

      let booking;

      try {
        booking = (await apiTrip.bookTrip(this.trip.prospect_id)).trip
          .passenger_trip;
      } catch (e) {
        const { identifier } = e.response?.data || {};
        const translationKey = `bookRideDetails.bookingErrors.${identifier}`;

        this.$error(this.$te(translationKey) ? this.$t(translationKey) : null);

        return;
      } finally {
        this.bookingLoading = false;
      }

      this.redirectToTripDetails(booking.id, booking.driver_trip_id);
    },
    redirectToTripDetails(passengerTripId, driverTripId){
      this.$router.replace({
                name: 'trip-details-passenger',
                params: {
                  passengerTripId,
                  driverTripId,
                },
              });
    },
    showPublic() {
      window.sendNative.openURL(this.trip.link);
    },
    autoBookedChanged(isBooked) {
      this.$store.state.commute.tripDetails.trip.auto_booked = isBooked;
    },
  },
};
</script>
