<template>
  <gmap-polyline
    :path="points"
    :options="{
      strokeColor: strokeColor,
      ...dashOptions,
    }"
  />
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'driver',
      validator: (type) =>
        ['driver', 'passenger', 'deactivated'].includes(type),
    },
    points: {
      type: Array,
      default: () => [],
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    faded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    strokeColor() {
      if (this.theme === 'driver' && !this.faded) {
        return '#2D75F6';
      }

      if (this.theme === 'driver' && this.faded) {
        return '#8FB4F5';
      }

      if (this.theme === 'passenger') {
        return '#09C876';
      }

      return '#B7B7B7';
    },
    dashOptions() {
      if (!this.dashed) {
        return [];
      }

      const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 3,
      };

      return {
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: '0',
            repeat: '15px',
          },
        ],
      };
    },
  },
};
</script>
