import axios from '@/vendor/axios';

export default {
  ping() {
    return axios
      .get('/ping')
      .then((response) => response.data);
  },
  async fetchPostalCode(countryCode, postalCode) {
    return axios.get(`/postal-code/${countryCode}/${postalCode}`)
      .then(response => response.data);
  }
};
