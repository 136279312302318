/**
 * @param from - use unifyPoint
 * @param to - use unifyPoint
 * @return Number - in meters
 */
export function getDistanceBetweenPoints(from, to) {
  /* eslint-disable */
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((to.lat - from.lat) * p) / 2 +
    (c(from.lat * p) * c(to.lat * p) * (1 - c((to.lng - from.lng) * p))) / 2;
  return 12742 * 1000 * Math.asin(Math.sqrt(a));
}

export function getNearestPoint(lat, lng, points, threshold) {
  threshold = threshold || 10000;
  let nearestDistance = Infinity;
  let nearestPoint = null;
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const distance = getDistanceBetweenPoints({lat, lng}, unifyPoint(point));

    if (distance < nearestDistance && distance < threshold) {
      nearestDistance = distance;
      nearestPoint = point;
    }
  }

  /*if ($single !== null) {
    $distance = getDistanceBetweenPoints([$lat, $lng], unifyPoint($single));

    if ($distance < $nearestDistance) {
      $nearestPoint = null;
    }
  }*/

  return nearestPoint;
}

/**
 *
 * @param {Object{lat, lng}} from
 * @param {Object{lat, lng}} to
 * @param {Array{lat, lng}} route
 */
export function sliceRoute(from, to, route, addFromTo = false) {
  let points = route.map(x => {
    return {
      lat: x.lat(),
      lng: x.lng()
    };
  });
  if (from) {
    let startPoint = getNearestPoint(from.lat, from.lng, points);
    points = points.slice(points.indexOf(startPoint), points.length);
  }
  if (to) {
    let endPoint = getNearestPoint(to.lat, to.lng, points);
    points = points.slice(0, points.indexOf(endPoint) + 1);
  }
  if (addFromTo) {
    return [from].concat(points).concat([to]);
  }
  return points;
}

export function publicTripToRoutes(fromAddress, toAddress) {
  return {
    routes:[{
      route: [fromAddress, toAddress],
      public: true,
    }],
    stops: [{...fromAddress, type: 'public-start'}, {...toAddress, type: 'public-end'}],
  };
}

/**
 * @typedef {object} OriginDestination
 * @property {number} lat
 * @property {number} lng
 * @property {string} street
 * @property {string} city
 * @property {string} zipcode
 * @property {string} country
 * @property {string} completeAddress
 *
 * Convert a non public trip to routes and stops of their correct types.
 * @param {*} trip
 * @param {OriginDestination} origin
 * @param {OriginDestination} destination
 */
export function combiTripToRoutes(trip, origin, destination) {
  const firstNonNabogoIndex = trip.legs.findIndex(x => x.type != 'CAR');
  const nabogoLegIndex = trip.legs.findIndex(x => x.type === 'CAR')
  const nabogoLeg = trip.legs[nabogoLegIndex];
  let routes = [];
  let stops = [];
  // Start with public / walking
  if (firstNonNabogoIndex !== -1 && firstNonNabogoIndex < nabogoLegIndex) {
    routes.push(
      {
        route: [origin, nabogoLeg.origin],
        public: true,
      }
    );
    stops.push({...origin, type: 'public-start'});
  }
  // The nabogo trip, slice the driver route to only have the search route
  const slicedRoute = sliceRoute(nabogoLeg.origin, nabogoLeg.destination, window.google.maps.geometry.encoding.decodePath(trip.driver_trip_details.route));
  routes.push({
    route: slicedRoute,
  });
  stops.push({...nabogoLeg.origin, type: 'start'});

  // The end, may end with public
  if (nabogoLegIndex < trip.legs.length - 1) {
    stops.push({...nabogoLeg.destination, type: 'plain'}); // If there is more non nabogo, use a plain
    routes.push({ // Last part public/walk
      route: [nabogoLeg.destination, destination],
      public: true,
    });
    stops.push({...destination, type: 'public-end'});
  } else {
    stops.push({...nabogoLeg.destination, type: 'end'}); // else use the end
  }

  return {routes, stops};
}

export function unifyPoint(point) {
  if (point instanceof Object && point.lat && (point.lng || point.long)) {
    return {lat: Number(point.lat), lng: Number(point.lng || point.long)};
  } else if (point instanceof Array) {
    return {lat: Number(point[0]), lng: Number(point[1])};
  } else {
    console.error('Unknown point type', point);
    throw new Error('Unknown point type');
  }
}
let mapMarkerIncrementingIndex = 0;

export function getMapMarkerIncrementingId(id) {
  return `${id}_${mapMarkerIncrementingIndex++}`;
}
