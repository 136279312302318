<template>
  <n-grid>
    <div class="span-2">
      <img class="travel-pass-img" :src="travelPass.image" />
    </div>
    <div class="span-4">
    <n-grid class="justify-between" :row-gap="0">
        <n-text preset="label-2">
          <span>{{ $d((travelPass.period_start), 'dateWithYearShort') }}</span>
          <span> - </span>
          <span v-if="!!travelPass.period_end">{{ $d((travelPass.period_end), 'dateWithYearShort') }}</span>
        </n-text>
        <div v-if="!noActions">
          <n-action-sheet>
            <template  #default="{ closeSheet }">
            <n-action-sheet-item icon="trash-can" @click.native="closeSheet(deleteTravelPass(travelPass.id))">
              {{ $t('travelPass.delete') }}
            </n-action-sheet-item>
            </template>
            <template #loading>
              <n-action-sheet-item>
                <template #replace>
                  <n-spinner class="icon span-1" color="accent"/>
                  <span class="span-5">{{ $t('travelPass.isDeleting') }}</span>
                </template>
              </n-action-sheet-item>
            </template>
          </n-action-sheet>
        </div>
      </n-grid>
      <n-grid>
        <n-text preset="label-2" class="span-6" color="grey-dark">{{ travelPass.travel_pass }} </n-text>
        <n-text preset="label-2" class="span-6" :color="stateColor(state)">
          {{ $t(`travelPass.states.${state}`) }}
        </n-text>
      </n-grid>
    </div>
  </n-grid>
</template>

<script>
import user from '@/api/user';

export default {
  props: {
    travelPass: {
      type: Object,
      required: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    state() {
      if (this.travelPass.period_end != null && this.travelPass.period_end <= new Date()) {
        return 'EXPIRED';
      } else {
        return this.travelPass.state;
      }
    }
  },
  methods: {
    async deleteTravelPass(travelPassId) {
      await user.deleteTravelPass(travelPassId);
      this.$emit('removed');
    },
    stateColor(state) {
      const stateToColorMap = {
        'APPROVED': 'accent',
        'AWAITING': 'warning',
        'REJECTED': 'error',
        'EXPIRED': 'warning',
      }
      return stateToColorMap[state] ?? 'grey-dark';
    },
  }
};
</script>
<style scoped>
.travel-pass-img {
  max-width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.icon {
  display: flex;
  justify-content: center;
}
</style>
