<template>
  <n-theme type="passenger">
    <n-alert />
    <activity-wrapper
      icon="location-alt"
      :clock="getClock"
      @expandChanged="isExpanded = $event"
    >
      <template #status>
        <n-text
          v-if="hasMatch"
          color="accent"
        >
          {{
            $t('overview.single.request.match')
          }}
        </n-text>
        <n-text
          v-else
          color="accent"
        >
          {{
            $t('overview.single.request.noDeal')
          }}
        </n-text>
      </template>
      <template #action>
        <n-action-sheet v-slot="{closeSheet}">
          <n-action-sheet-item
            icon="trash-can"
            @click.native="closeSheet(deleteRequest)"
          >
            {{ $t('overview.single.passenger.actionSheet.cancelTrip') }}
          </n-action-sheet-item>
          <n-action-sheet-item
            icon="vacation"
            @click.native="closeSheet(() => $router.push({'name': 'vacation'}))"
          >
            {{ $t('overview.actionSheet.vacationSettings') }}
          </n-action-sheet-item>
        </n-action-sheet>
      </template>
      <template #steps>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :time="step.time"
          :address="step.address"
        />
      </template>
      <template
        v-if="hasMatch"
        #extra
      >
        <horizontal-schedule
          class="span-6"
          :legs="request.best_driver_trip.legs"
        />
      </template>
      <template #buttons>
        <template>
          <n-button
            type="outlined"
            class="span-3"
            size="lg"
            inverted
            @click="editRequest"
          >
            {{ $t('overview.single.request.editButton') }}
          </n-button>
          <n-button
            type="outlined"
            class="span-3"
            size="lg"
            inverted
            @click="findMore"
          >
            {{ findMoreText }}
          </n-button>
        </template>
        <template v-if="hasMatch">
          <n-button
            block
            size="lg"
            @click="book"
          >
            {{
              $t('overview.single.request.bookBest')
            }}
          </n-button>
        </template>
      </template>
    </activity-wrapper>
  </n-theme>
</template>

<script>
import {  mapActions } from 'vuex';
import { namespacedTypes as commuteTypes } from '@/store/modules/commute-types';
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import HorizontalSchedule from '@/components/shared/horizontalSchedule';
import Step from '@/components/shared/overview/parts/step';
import { namespacedTypes as userTypes, } from '@/store/modules/user-types';
import i18n from '@/i18n/index';
import { format } from '@/vendor/date-fns';

export default {
  name: 'RequestActivity',
  components: { ActivityWrapper, Step, HorizontalSchedule },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    getClock() {
      return typeof this.request.planned_time === 'string' ? this.request.planned_time : this.request.planned_time.toISOString();
    },
    findMoreText() {
      return this.hasMatch
        ? this.$t('overview.single.request.moreTrips')
        : this.$t('overview.single.request.findTrips');
    },
    steps() {
      const steps = [];

      if (this.hasMatch) {
        let origin = null;
        let destination = null;

        for (let i = 0; i < this.request.best_driver_trip.legs.length; i++) {
          const leg = this.request.best_driver_trip.legs[i];

          if (!origin && leg.origin) {
            origin = leg.origin;
          }

          if (leg.destination) {
            destination = leg.destination;
          }
        }

        steps.push({
          time: format(origin.datetime, 'HH:mm'),
          address: origin.street,
        });

        steps.push({
          time: format(destination.datetime, 'HH.mm'),
          address: destination.street,
        });
      } else {
        steps.push({
          time: format(this.request.planned_time, 'HH:mm'),
          address: this.request.departure.street,
        });
        steps.push({
          time: '',
          address: this.request.arrival.street,
        });
      }
      return steps;
    },
    hasMatch() {
      return this.request.best_driver_trip !== null;
    },
  },
  methods: {
    ...mapActions({
      cancelRequest: userTypes.CANCEL_COMMUTE_REQUEST,
    }),
    async deleteRequest() {
      this.$modal.show('dialog', {
        title: this.$t('overview.single.request.cancelDialogTitle'),
        text: this.$t('overview.single.request.cancelDialogDescription'),
        success: {
          text: this.$t('overview.single.request.cancelDialogSuccessButton'),
          handler: async () => {
             await this.cancelRequest(this.request.id).then(() => {
              this.$store.commit(
                commuteTypes.OVERVIEW_REQUEST_REMOVED,
                this.request.id
              );

              this.$success(i18n.t('overview.single.request.snackbar'));
            });
           
          },
        },
        color: 'error',
        cancel: true,
      });
    },
    editRequest() {
      this.$router.push({
        name: 'edit-request',
        params: { request: this.request },
      });
    },
    /**
     * Convert all the data to the format the demand page understands
     */
    findMore() {
      let from = { ...this.request.departure, completeAddress: this.request.departure.street, parts: { ...this.request.departure } };
      let to = { ...this.request.arrival, completeAddress: this.request.arrival.street, parts: { ...this.request.arrival } };
      this.$router.push({
        name: 'main.demand', params: {
          from,
          to,
          time: new Date(this.request.planned_time),
          seats: this.request.seats,
          requestId: this.request.id,
          single: true,
          shouldTriggerSurvey: true,
          plannedTimeByType: this.request.planned_time_by,
        }
      });
    },
    book() {
      let from = {
        ...this.request.departure,
        completeAddress: this.request.departure.street,
        parts: { ...this.request.departure },
      };
      let to = {
        ...this.request.arrival,
        completeAddress: this.request.arrival.street,
        parts: { ...this.request.arrival },
      };
      this.$store.commit(commuteTypes.TRIP_DETAILS, {
        trip: this.request.best_driver_trip,
        fromAddress: from,
        toAddress: to,
        requestId: this.request.id,
        seats: this.request.seats,
      });
      this.$router.push({
        name: 'trip-details-search',
        query: {
          requestId: this.request.id,
          driverTripId: this.request.best_driver_trip.id,
        },
        params: { 'search-result': true },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';

.match {
  color: #09C876;
}

.schedule {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  > * {
    display: flex;
    width: 100%;
  }
  .buttons {
    justify-content: space-between;
    > * {
      width: 48%;
    }
  }
}

.contextMenu {
  text-align: left;
}
</style>
