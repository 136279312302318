<template>
  <n-theme type="driver">
    <n-bottom-sheet type="header" :title="$t('routeAdjuster.title')" class="sheet" ref="sheet" no-layout fill-height :drag-body="false" @dismissed="dismissed">
      <div class="wrapper span-6">
        <n-top-screen class="top">
          <n-layout>
            <n-info v-if="waypoints.length === 0">{{ $t('routeAdjuster.addVia') }}</n-info>
            <n-info v-if="waypoints.length > 0">{{ $t('routeAdjuster.addMoreVia') }}</n-info>
          </n-layout>
        </n-top-screen>
        <base-map ref="map" class="map" @map-click="mapClick($event)"
        :zoom="zoom"
        @bounds_changed="onBoundsChanged"
        @zoom_changed="onZoomChanged">
          <template v-if="opened">
            <n-marker :position="originalData.start" type="start" theme="driver" key="start"/>
            <n-marker :position="originalData.stop" type="end" theme="driver" key="end"/>
            <n-marker v-if="currentPosition" type="current-position" :position="currentPosition" key="currentpos" />
            <n-route :points="route.path" key="route" />

            <n-marker v-for="stop in route.stops" :position="stop" :theme="stop.deactivated ? 'deactivated' : 'driver'" :key="getMarkerKey(stop.id)" />

            <!-- Helper stops -->
            <n-marker v-for="stop in otherStopsFiltered" :position="stop" theme="driver" :key="getMarkerKey(`other_${stop.id}`)" />
          </template>
        </base-map>
        <n-bottom-screen>
          <n-layout>
            <n-button v-if="waypoints.length > 0" @click="undo" class="white span-3" type="outlined" color="warning" inverted>{{$t('routeAdjuster.undo')}}</n-button>
            <div v-else class="span-3" />
            <n-button v-if="isChanged" @click="save" class="span-3"> {{$t('routeAdjuster.continue')}}</n-button>
          </n-layout>
        </n-bottom-screen>
      </div>
    </n-bottom-sheet>
  </n-theme>
</template>

<script>
import axios from 'axios';
import mapsApi from '@/api/maps';
import debounce from 'lodash.debounce';
import commuteApi from '@/api/commute';
import * as util from '@/vendor/utils';
import NRoute from '@/components/shared/map/route';
import NMarker from '@/components/shared/map/marker';
import BaseMap from '@/components/shared/map/baseMap';
import AddRemoveStopDialog from '@/dialogs/addRemoveStopDialog';
import { namespacedTypes as appTypes } from '@/store/modules/app-types';
import { sentryMessage } from '@/vendor/sentry';

let stopIdIncrement = 0;

export default {
  components: {
    BaseMap,
    NRoute,
    NMarker,
    AddRemoveStopDialog,
  },
  data() {
    return {
      opened: false,
      originalData: {
        route: {
          stops: [],
          path: [],
          distance: null,
          duration: null,
        },
        start: null,
        stop: null,
      },
      route: {
        stops: [],
        path: [],
        distance: null,
        duration: null,
      },
      stops: null,
      waypoints: [],
      zoom: 6,
      // settings
      minZoom: 17,
      placedZoom: 15,
      // Other stops
      bounds: null,
      cancelTokenSource: null,
      otherStops: [],
      isChanged: false,
    }
  },
  computed: {
    isZoomedIn() {
      return this.zoom >= this.minZoom;
    },
    currentPosition() {
      return this.$store.getters[appTypes.LAST_KNOWN_LOCATION];
    },
    otherStopsFiltered() {
      return this.isZoomedIn ? this.otherStops : [];
    },
  },
  created() {
    this.deboucedFetchStopsAsData = debounce(this.fetchStopsAsData, 500, {
      leading: false,
      trailing: true,
    });
  },
  mounted() {
    this.$store.dispatch(appTypes.GET_CURRENT_LOCATION);
  },
  methods: {
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${stopIdIncrement++}`;
    },
    open(data) {
      this.$refs.sheet.open();
      this.route = JSON.parse(JSON.stringify(data.route));
      this.waypoints = JSON.parse(JSON.stringify(data.waypoints));
      this.originalData = JSON.parse(JSON.stringify(data.original));
      this.opened = true;
      this.zoomToRoute();
    },
    dismissed() {
      this.opened = false;
      this.route = { stops: [], path: [], distance: null, duration: null };
      this.originalData = { route: { stops: [], path: [], distance: null, duration: null }, start: null, stop: null };
    },
    mapClick(e) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      if (!this.isZoomedIn) {
        this.zoomToCoordinate({ lat, lng });
      } else {
        this.placeViaPoint({ lat, lng });
      }
    },
    zoomToCoordinate(coords, zoom) {
      this.$refs.map.$refs.map.panTo(coords);
      this.zoom = zoom || this.minZoom;
    },
    /**
     *
     * @param {Point} coordinate
     */
    async placeViaPoint(point) {
      const data = await mapsApi.reverseGeocodeMap(point);

      if (!data.result || !util.isValidAddress(data?.result)) {
        this.$error(this.$t('routeAdjuster.invalidSelection'));
        sentryMessage('[routeAdjusterSheet] User Selected invalid point on map', {
          requestPoint: point,
          response: data,
        });
        return;
      }

      const { coordinate, ...address } = data.result;
      this.waypoints.push({ address: { ...address, ...coordinate }});

      this.isChanged = true;
      this.fetchManualRoute();
    },
    fetchManualRoute() {
      if (this.reloading) {
        return;
      }

      this.reloading = true;

      const via = this.waypoints
        .map(w => ({
          lat: w.address.lat,
          lng: w.address.lng
        }))
        .filter(c => c !== null);

      commuteApi.getRouteBetweenPoints(
        this.originalData.start,
        this.originalData.stop,
        via
      )
        .then(data => {
          this.route = data;
          this.zoomToRoute();
        })
        .catch((e) => {
          // Remove the added waypoint on error
          // Error message comes from intercepter
          this.waypoints.pop();
        })
        .finally(() => {
          this.reloading = false;
        });

      return;
    },
    onZoomChanged(zoom) {
      this.zoom = zoom;
    },
    zoomToRoute() {
      let route = this.route.path;
      this.$refs.map.$refs.map.$mapPromise.then((map) => {
        if (route !== null) {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(this.originalData.start);
          bounds.extend(this.originalData.stop);
          map.fitBounds(bounds, { top: 100 });
        }
      });
    },
    async undo() {
      // Remove the waypoint
      if (this.waypoints.length >= 1) {
        this.waypoints.splice(this.waypoints.length - 1);
      }

      await this.fetchManualRoute();

      this.isChanged = true;
      this.zoomToRoute();
    },
    save() {
      this.$emit('selected', {
        route: this.route,
        waypoints: this.waypoints
      });
      this.waypoints = [];
      this.$refs.sheet.dismiss();
    },
    onBoundsChanged(bounds) {
      if (!bounds) {
        return;
      }
      // {south: 49.5138, west: 5.8012, ... }
      this.bounds = bounds.toJSON();
      this.deboucedFetchStopsAsData();
    },
    fetchStopsAsData() {
      console.log('fetchStopsAsData');
      if (!this.bounds || !this.isZoomedIn) {
        return;
      }
      // cancel previous request if still loading
      if (this.cancelTokenSource !== null) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = axios.CancelToken.source();
      commuteApi
        .getMapItems(this.bounds, this.zoom, this.cancelTokenSource.token)
        .then((data) => {
          this.otherStops = data.stops;
        })
        .catch((e) => {
          // show error, if the request was not just cancelled
          if (!axios.isCancel(e)) {
            this.$error({
              description: this.$t('c.map.loadingError'),
            });
          }
        })
        .finally(() => {
          this.cancelTokenSource = null;
        });
    },
  },
}
</script>
<style lang="scss" scoped>
.map {
  height: 100%;
}
.wrapper {
  position: relative;
  .top {
    z-index: 100;
  }
}
</style>
