/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_dollar-sign': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 34 money"><path pid="0" data-name="Path 905" d="M12.43 10.78C9.91 10.12 9.1 9.44 9.1 8.39c0-1.21 1.12-2.06 3-2.06s2.71 1 2.78 2.34h2.45a4.42 4.42 0 00-3.57-4.24V2h-3.33v2.4c-2.15.47-3.89 1.87-3.89 4 0 2.57 2.13 3.85 5.23 4.59 2.77.67 3.33 1.64 3.33 2.68 0 .76-.54 2-3 2-2.28 0-3.19-1-3.31-2.34H6.34a4.67 4.67 0 004.09 4.26V22h3.34v-2.39c2.16-.41 3.88-1.67 3.88-3.94.01-3.16-2.65-4.24-5.22-4.89z"/></g>'
  }
})
