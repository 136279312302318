<template>
  <div class="info">
    <n-text color="grey-dark"><slot /></n-text>
  </div>
</template>

<script>

  export default {
    props: {
    },
    data() {
      return {
      };
    },
    mounted() {
    },
  };
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.info {
  text-align: center;
  background: var(--color-grey-light);
  width: 100%;
  border-radius: var(--radius);
  grid-column-start: 1;
  grid-column-end: span col4-start;
  padding: 20px 0;
  box-shadow: var(--shadow);
}

</style>
