<template>
  <n-top-screen>
    <n-layout>
      <n-column :span="6">
        <div class="trip-navbar">
          <div class="trip-navbar--buttons">
            <n-button @click="end" :loading="ending" type="filled" :color="endable && passengers > 0 ? 'success' : 'error'">
              {{ $t('main.activeTrip.activeTripNavbar.endTrip') }}
            </n-button>

            <n-button v-if="pausable" @click="pause" type="outlined white" color="neutral">
              {{ $t('main.activeTrip.activeTripNavbar.pauseTrip') }}
            </n-button>
          </div>

          <div class="trip-navbar--passengers">
            <div class="seat" v-for="(seat, index) in seats" :key="index">
              <n-icon
                :outline="index >= passengers"
                name="person"
                color="accent"
              />
            </div>
          </div>
        </div>
      </n-column>
    </n-layout>
  </n-top-screen>
</template>

<script>
import { defaultSeats } from '@/constants';
import Seats from '@/components/shared/overview/parts/seats.vue';

export default {
  props: {
    passengers: {
      type: Number,
      default: 0,
      validator: (num) => num >= 0,
    },
    seats: {
      type: Number,
      default: defaultSeats,
      validator: (num) => num >= 0,
    },
    pausable: {
      type: Boolean,
      default: true,
    },
    ending: {
      type: Boolean,
      default: false,
    },
    endable: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Seats,
  },
  methods: {
    pause() {
      this.$emit('pause');
    },
    end() {
      this.$emit('end');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.trip-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &--buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    ::v-deep .button {
      padding: 0 0.75rem;
    }
  }

  &--passengers {
    gap: 0.25rem;
    display: flex;
    flex-direction: row;
  }
}
</style>