<template>
  <n-bottom-sheet
    ref="sheet"
    type="header"
    :title="$t('main.provide.tripSheet.title')"
    no-layout
    scrollable
    fill-height
    @dismissed="dismissed"
  >
    <n-column
      v-if="showAddressSearch"
      class="address-search-container"
      :span="6"
    >
      <address-search
        ref="addressSearch"
        :with-current-location="false"
        :input-label="$t('main.provide.destinationSearch.label')"
        :input-placeholder="$t('main.provide.destinationSearch.placeholder')"
        @input="onAddressSearchInput"
        @select="onAddressSearchSelect"
        @error="onAddressSearchError"
      />
    </n-column>

    <n-layout
      v-else
      class="sheet-layout"
    >
      <n-column
        :span="6"
        class="content-container"
      >
        <n-grid :bottom-gap="5">
          <n-text
            class="span-6"
            preset="header"
            color="accent"
          >
            {{ $t('main.provide.tripSheet.rideNowTitle') }}

            <n-help
              :text="$t('main.active.activeTripSheet.helpText')"
              @click="onRideNowHelpClick"
            />
          </n-text>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.provide.tripSheet.rideNowDescription') }}
          </n-text>

          <n-button
            class="span-6 button"
            @click="onRideNowClick"
          >
            {{ $t('main.provide.tripSheet.rideNowButton') }}
          </n-button>
        </n-grid>

        <n-grid :bottom-gap="5">
          <n-text
            class="span-6"
            preset="header"
            color="accent"
          >
            {{ $t('main.provide.tripSheet.createRideTitle') }}
          </n-text>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.provide.tripSheet.createRideDescription') }}
          </n-text>

          <n-button
            class="span-6 button"
            @click="onCreateRideClick"
          >
            {{ $t('main.provide.tripSheet.createRideButton') }}
          </n-button>
        </n-grid>
      </n-column>

      <n-column
        :span="6"
        class="footer-container"
      >
        <n-grid :bottom-gap="3">
          <n-text
            class="span-6"
            preset="sub"
            color="grey-dark"
          >
            {{ $t('main.provide.tripSheet.paymentExplanation') }}
          </n-text>

          <n-button
            class="button"
            block
            type="outlined"
            color="default"
            inverted
            @click="onForDriversSheetClick"
          >
            {{ $t('main.provide.tripSheet.forDriversSheetButton') }}
          </n-button>
        </n-grid>
      </n-column>
    </n-layout>
  </n-bottom-sheet>
</template>

<script>
import i18n from '@/i18n';
import store from '@/store';
import { EventBus } from '@/vendor/events';
import AddressSearch from '@/components/shared/addressSearch';

export default {
  name: 'MainStartProvideTripSheet',
  components: {
    AddressSearch,
  },
  data() {
    return {
      loading: false,
      showAddressSearch: false,
    };
  },
  mounted() {
    EventBus.$on('provide-trip-sheet-trigger', this.trigger);
    EventBus.$on('provide-trip-sheet-dismiss', this.dismiss);
  },
  beforeDestroy() {
    EventBus.$off('provide-trip-sheet-trigger', this.trigger);
    EventBus.$off('provide-trip-sheet-dismiss', this.dismiss);
  },
  methods: {
    trigger() {
      this.$refs?.sheet?.open();
    },
    dismiss() {
      this.$refs?.sheet?.dismiss();
    },
    dismissed() {
      if (!this.loading) {
        this.showAddressSearch = false;
      }
    },
    async onRideNowClick() {
      const userHasOngoingActivity = this.$store.state.commute.overview.driverTrips.some(trip => 
        trip.started_at === null
        && trip.completed_at === null
        && new Date(trip.planned_departure) < new Date()
      )

      if (userHasOngoingActivity) {
        this.$error(this.$t('error.user_has_ongoing_activity'));
        return
      }

      try {
      await store.dispatch('trip/refreshActiveTrip');
    } catch (e) { }

    if (store.state.trip?.activeDriverTrip?.id) {
        this.$error(
          i18n.t('error.user_has_ongoing_activity'),
        );
        return;
      }

      this.showAddressSearch = true;
    },
    onCreateRideClick() {
      this.$emit('createRide');
      this.dismiss();
    },
    onAddressSearchInput(selectedDestination) {
      this.loading = false;

      this.$emit('rideNow', selectedDestination);

      setTimeout(() => {
        this.showAddressSearch = false;
      }, 300);
    },
    onAddressSearchSelect() {
      this.loading = true;

      this.$emit('addressSelected');
      this.dismiss();
    },
    onAddressSearchError() {
      this.loading = false;
      this.showAddressSearch = true;

      this.$emit('addressError');

      this.$nextTick(() => {
        this.trigger();
      });
    },
    onRideNowHelpClick() {
      EventBus.$emit('board-passengers-sheet-trigger');
    },
    onForDriversSheetClick() {
      EventBus.$emit('for-drivers-sheet-trigger');
    },
  },
};
</script>

<style lang="scss">
.provide-trip-sheet-video-container {
  max-width: 100%;
  margin-bottom: 1rem;

  & > div {
    position: relative;
    padding-bottom: 60%;
    height: 0px;
  }

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.button {
  margin-top: 0.75rem;
}

.sheet-layout {
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1;
}

.footer-container {
  flex: 0;
}
</style>
