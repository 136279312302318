/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_search-info': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<circle cx="10" cy="11" r="10" fill="#F1F0EE"/><path d="M12.1714 16H8.88236V15.35H9.84436V9.825H8.88236V9.175H11.2094V15.35H12.1714V16ZM9.55836 7.069C9.55836 6.81767 9.64503 6.61833 9.81836 6.471C10.0004 6.32367 10.23 6.25 10.5074 6.25C10.802 6.25 11.0317 6.32367 11.1964 6.471C11.3697 6.61833 11.4564 6.81767 11.4564 7.069C11.4564 7.32033 11.3697 7.51967 11.1964 7.667C11.0317 7.81433 10.802 7.888 10.5074 7.888C10.23 7.888 10.0004 7.81433 9.81836 7.667C9.64503 7.51967 9.55836 7.32033 9.55836 7.069Z" fill="#4C4A41"/>'
  }
})
