/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-blank-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14v10m0-12H5V6h14v2z"/>'
  }
})
