import * as Sentry from "@sentry/vue";
import { getAppVersion, getReleaseVersion  } from "./utils";

export default function initializeSentry(vue, vueRouter) {
  if (!['staging', 'testing', 'production'].includes(process.env.VUE_APP_SENTRY_ENV)
  ) {
    return;
  }
  Sentry.init({
    Vue: vue,
    dsn: "https://9fa074f7a89741de8d1279f6bbfafe61@o304302.ingest.sentry.io/1758670",
    environment: process.env.VUE_APP_SENTRY_ENV,
    release: getReleaseVersion(),
    integrations: [
      Sentry.browserTracingIntegration({ router: vueRouter }),
    ],
    tracePropagationTargets: [/\.nabogo.com/, /^\//],
    tracesSampler: samplingContext => {
      if (['staging', 'testing'].includes(process.env.VUE_APP_SENTRY_ENV)) {
        return 1.0;
      } else {
        return 0.3;
      }
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category == 'ui.click' && !!hint.event.target.innerText) {
        breadcrumb.message =  `User clicked on a element with label ${hint.event.target.tagName}:"${hint.event.target.innerText}"`;
      }

      if (breadcrumb.category == 'ui.click' && hint.event.target.tagName == 'svg' && !!hint.event.target.__vue__) {
        breadcrumb.message =  `User clicked on an icon with name "${hint.event.target.__vue__.name}"`;
      }

      return breadcrumb;
    },
    ignoreErrors: [
      /Network Error/i,
      /Fetch Error/i,
    ],
    denyUrls: [
      /clarity\.ms/i,
      /google-analytics\.com/i,
      /doubleclick\.net/i,
      /maps\.googleapis\.com/i,
      /www\.gstatic\.com\/recaptcha/i,
    ],
    /**
     * @param {Sentry.Event} event
     * @param {Sentry.EventHint} hint
     */
    beforeSend: function(event, hint) {
      if (isAxiosError(hint)) {
        event.fingerprint = axiosFingerprint(event, hint);
      }

      return event;
    }
  });

  const appVersion = getAppVersion();
  Sentry.setTag('versionName', appVersion.name);
  Sentry.setTag('buildNumber', appVersion.build);
}

/**
 *
 * @param {Sentry.EventHint} hint
 * @returns boolean
 *
 */
function isAxiosError(hint) {
  const error = hint?.originalException;
  return error.error && error.response && error.request;
}

/**
 * @param {Sentry.Event} event
 * @param {Sentry.EventHint} hint
 */
function axiosFingerprint(event, hint) {
  const { response, request } = hint?.originalException;
  const { pathname } = url.parse(request?.path || "");

  return [
    "{{ default }}",
    response?.status,
    `${request?.method} ${pathname ?? "undefined"}`
  ];
}

/**
 * Sends a message to sentry with contextual information
 *
 * @param {string} message
 * @param {?Object} extra
 */
export function sentryMessage(message, extra = null) {
  Sentry.withScope(scope => {
    if (extra) {
      scope.setExtras(extra)
    }
    Sentry.captureMessage(message);
  })

  console.warn(`[Sentry] ${message}`, { extra});
}


/**
 * Sends exception to sentry with contextual information
 *
 * @param {Error} err
 * @param {?Object} extra
 */
export function sentryException(err, extra = null) {
  Sentry.withScope(scope => {
    scope.setExtras(extra)
    Sentry.captureException(err)
  });

  console.error(`[Sentry] ${err.message}`, { err, extra});
}
