/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_loading': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.41 3.32a9 9 0 016.26 11.1l2.89.81-4.79 2.7-.11.12-2.81-5 2.89.8a7 7 0 10-3.31 4.21l1 1.75a9 9 0 11-2-16.52z" />'
  }
})
