/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_trash-can': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico trash"><path pid="0" data-name="Path 1732" d="M5.33 19.78A2.24 2.24 0 007.56 22h8.88a2.24 2.24 0 002.23-2.22V6.44H5.33zM19.78 3.11h-3.89L14.78 2H9.22L8.11 3.11H4.22v2.22h15.56z"/></g>'
  }
})
