<template>
  <div class="view-container">
    <n-nav-bar :title="$t('history.title')" />
    <n-scrollable @scrolledToBottom="loadMore">
      <n-layout>
        <div class="span-6">
          <n-text preset="title">
            {{ $tc('history.commuteCount', profile.stats.carpools_completed) }}
          </n-text>

          <div class="stats-container first">
            <n-theme type="driver">
              <stats-display
                icon="commute-driver"
                :text="$tc('history.asDriver', profile.stats.seats_driven || 0)"
              />
            </n-theme>
          </div>

          <div class="stats-container">
            <n-theme type="passenger">
              <stats-display
                icon="commute-passenger"
                :text="$tc('history.asPassenger', profile.stats.seats_booked || 0)"
              />
            </n-theme>
          </div>

          <template v-if="incompleteTrips > 0">
            <n-dialog />
            <n-text
              color="warning"
              preset="label-2"
            >
              {{ $t('incompleteTrips.title', { trips: incompleteTrips}) }}
              <n-help
                :text="$t('incompleteTrips.helpText')" 
                :button-text="$t('incompleteTrips.closeButton')"
              />
            </n-text>
          </template>

          <n-seperator class="stats-seperator" />

          <component
            :is="element.component"
            v-for="element in elements"
            :key="element.key"
            v-bind="element.data"
            v-on="element.events"
          />
          <n-grid :bottom-gap="5">
            <n-text
              v-if="hasFetchedAll || (elements.length === 0 && !isBusy)"
              preset="sub"
              color="grey-dark"
              class="span-6 flex-center"
            >
              {{ $t('history.noMoreItems') }}
            </n-text>

            <n-grid
              v-if="isBusy && elements.length === 0"
              :top-gap="10"
            >
              <n-spinner class="span-6 flex-center" />
            </n-grid>
          </n-grid>
        </div>
      </n-layout>
    </n-scrollable>
  </div>
</template>


<script>
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import { mapState } from 'vuex';
import PassengerTripActivity from '@/components/shared/overview/passengerTripActivity';
import DriverTripActivity from '@/components/shared/overview/driverTripActivity';
import StatsDisplay from '@/components/shared/statsDisplay';
import { namespace as namespaceUser } from '@/store/modules/user-types';

export default {
  name: 'History',
  components: {
    PassengerTripActivity,
    DriverTripActivity,
    StatsDisplay,
  },
  data: () => ({
    isBusy: false,
    hasFetchedAll: false,
  }),
  computed: {
    ...mapState({
      history: state => state.commute.history,
    }),
    ...mapState(namespaceUser, ['profile', 'notifications']),
    elements() {
      if (!this.history.data) return [];
      const trips = [];
      const activities = this.history.data.sort(
        (a, b) => new Date(b.planned_departure) - new Date(a.planned_departure),
      );
      activities.forEach((element) => {
        if (element.driver_trip_id) { // passenger trip
          trips.push({
            type: 'passenger_trip',
            datetime: element.planned_departure,
            key: `pta-${element.id}`,
            component: 'passenger-trip-activity',
            data: {
              passengerTrip: element,
            },
          });
        } else { // driver trip
          trips.push({
            type: 'driver_trip',
            datetime: element.planned_departure,
            key: `dta-${element.id}`,
            component: 'driver-trip-activity',
            data: {
              driverTrip: element,
            },
          });
        }
      });
      return trips;
    },
    incompleteTrips() {
      return this.$store.state.user.profile.incompleteTrips;
    },
  },
  mounted() {
    this.fetchHistory();
  },
  methods: {
    async fetchHistory() {
      this.isBusy = true;
      await this.$store.dispatch(namespacedCommute.UPDATE_HISTORY);
      this.isBusy = false;
    },
    async loadMore() {
      if (this.isBusy || this.hasFetchedAll) {
        return;
      }
      this.isBusy = true;
      const data = await this.$store.dispatch(namespacedCommute.MORE_HISTORY);
      if (data.data.length === 0) {
        this.hasFetchedAll = true;
      }
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/style/variables.scss';
</style>

<style lang="scss" scoped>
.stats-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &.first {
    margin-top: 1rem;
  }
}

.stats-seperator {
  margin-top: 2rem;
}
</style>
