/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/>'
  }
})
