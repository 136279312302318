/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 17l5-5-5-5v10z"/>'
  }
})
