/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_facebook-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 30 Login Facebook"><path pid="0" data-name="Icon awesome-facebook-f" d="M16.65 13.26l.56-3.62h-3.48V7.3a1.82 1.82 0 011.64-2 1.79 1.79 0 01.4 0h1.58V2.26a20.09 20.09 0 00-2.8-.26 4.43 4.43 0 00-4.74 4.08 5.33 5.33 0 000 .8v2.76H6.65v3.62h3.17V22h3.91v-8.74z"/></g>'
  }
})
