/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_envelope-open': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 26 Login Email"><path pid="0" data-name="Icon ionic-md-mail-open" d="M22 9.57A1.87 1.87 0 0021.1 8L12 3.43 2.9 8A2 2 0 002 9.57v9.1a1.9 1.9 0 001.9 1.9h16.19a1.9 1.9 0 001.91-1.9zm-10 4.58l-7.66-5L12 5.33l7.65 3.81z"/></g>'
  }
})
