<template>
  <span class="loader">
    <n-icon
      :style="{ animation: `spin ${speed}s linear infinite` }"
      class="spinner"
      name="loading"
      :color="color"
      v-bind="$attrs"
    />
  </span>
</template>

<script>
export default {
  name: 'n-spinner',
  inheritAttrs: false,
  props: {
    speed: {
      type: Number,
      default: 0.7,
    },
    color: {
      type: String,
      default: 'accent',
      validator: (color) =>
        ['accent', 'white', 'warning', 'error', 'muted'].includes(color),
    },
  },
};
</script>

<style lang="scss">
.loader {
  display: inline-flex;

  .spinner {
    display: inline-block;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
