<template>
  <n-bottom-sheet
    type="header"
    :title="$t('travelPassSheet.sheetTitle')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-grid :bottom-gap="3">
      <n-text class="span-6" preset="title" color="accent">{{ $t('travelPassSheet.selectPassTypeTitle') }}</n-text>
      <n-dropdown class="span-3" :options="travelPassOptions" v-model="travelPass.type" :placeholder="$t('travelPassSheet.selectPassTypePlaceholder')" />
    </n-grid>

    <n-grid :bottom-gap="3">
      <n-text class="span-6" preset="title" color="accent">{{ $t('travelPassSheet.selectValidityPeriodTitle') }}</n-text>
        <n-date-picker
          string-format="dd/MM/yy"
          class="span-3"
          v-model="travelPass.periodStart"
          :standard="$t('travelPassSheet.startDatePlaceholder')"
        />
      <n-date-picker
        string-format="dd/MM/yy"
        :min-datetime="minEndDate"
        class="span-3"
        v-model="travelPass.periodEnd"
        :standard="$t('travelPassSheet.endDatePlaceholder')"
      />
    </n-grid>

    <n-grid v-if="!travelPass.image" :top-gap="1.5" :bottom-gap="2.5">
    </n-grid>

    <image-upload
      class="span-6"
      :src="travelPass.image"
      @input="(image) => travelPass.image = image"
    >
      <n-grid :top-gap="1.5" :bottom-gap="2.5">
        <n-text class="span-6" preset="sub" color="grey-dark">{{
          $t('travelPassSheet.consentDescription')
        }}</n-text>
      </n-grid>
      <n-button block size="lg" :disabled="!hasMinimumRequirements">{{
        $t('travelPassSheet.upload')
      }}</n-button>
    </image-upload>

    <n-grid v-if="travelPass.image">
      <n-button
        block
        type="outlined"
        @click="remove"
        inverted
        color="error"
        >{{ $t('travelPassSheet.remove') }}</n-button
      >
    </n-grid>

    <n-grid v-if="travelPass.image" :top-gap="5">
      <n-button block size="lg" :loading="isUploading" @click="storeTravelPass">{{ $t('travelPassSheet.save') }}</n-button>
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import api from '@/api/user';
import ImageUpload from '@/components/shared/imageUpload';

export default {
  data: function () {
    return {
      loadingTravelPassOptions: false,
      isUploading: false,

      travelPass: {
        type: null,
        image: null,
        periodStart: null,
        periodEnd: null,
      },

      travelPassOptions: [],
    };
  },
  components: {
    ImageUpload,
  },
  computed: {
    minEndDate() {
      return (this.travelPass.periodStart || new Date()).toISOString();
    },
    hasMinimumRequirements() {
      return Boolean(this.travelPass.type) && Boolean(this.travelPass.periodStart);
    },
    hasTravelPass() {
      return Boolean(this.travelPass.image);
    },
  },
  methods: {
    loadTravelPassOptions() {
      if (this.loadingTravelPassOptions || this.travelPassOptions.length) {
        return;
      }

      this.loadingTravelPassOptions = true;

      api.getTravelPassOptions()
        .then((options) => {
          this.travelPassOptions = options;
          this.loadingTravelPassOptions = false;
        })
        .catch((e) => {
          if (e.response?.status === 422) {
            this.$error(e.response.data.message);
            return;
          } else {
            this.$error();
          }
        });
    },
    open() {
      Object.assign(this.$data, this.$options.data.call(this)); //reset state
      this.loadTravelPassOptions();
      this.$refs.sheet.open();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
    remove() {
      this.travelPass.image = null;
    },
    async storeTravelPass() {
      if (this.isUploading) {
        return;
      }
      this.isUploading = true;
      try {
        await api.createTravelPass({
          travel_pass_id: this.travelPass.type.id,
          image: this.travelPass.image,
          period_start: this.travelPass.periodStart.toISOString(),
          period_end: this.travelPass.periodEnd ? this.travelPass.periodEnd.toISOString() : null,
        });
        this.dismiss();
      } catch (e) {
        if (e.response?.status === 422) {
          this.$error(this.$t(e.response.data.message));
          return;
        } else {
          this.$error();
        }
      } finally {
        this.isUploading = false;
      }


    },
  },
};
</script>

<style lang="scss" scoped>
.checkmark-icon {
  display: inline;
  margin-right: 0.75rem;
}
</style>
