<template>
  <n-theme type="driver">
    <activity-wrapper
      ref="wrapper"
      icon="location-alt"
      :status-color="statusColor"
      :format-clock="false"
      :clock="headerText"
      expanded="true"
      :history="isHistory"
      :class="{'not-offered' : !driverTrip.offered && !isExpanded}"
      @expandChanged="isExpanded = $event"
    >
      <template #status>
        Rate
      </template>
      <template #steps>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :time="step.time"
          :address="step.address"
        />
      </template>

      <template #buttons>
        <n-grid
          v-for="pass in passengersAccepted"
          :key="pass.id"
        >
          <n-grid class="flex-v-center">
            <rating
              size="lg"
              class="span-3"
              :editable="!pass.passenger_rating || (pass.passenger_rating ? !pass.passenger_rating.locked : false)"
              :locked="pass.passenger_rating ? pass.passenger_rating.locked : false"
              :stars="pass.passenger_rating ? pass.passenger_rating.rating : 0"
              @input="rateTrip(pass.id, $event)"
            />

            <profile-picture
              :src="pass.user.image"
              @click="openProfile(pass.user.id)"
            />
            <n-text class="flex-v-center span-2">
              {{ pass.user.name }}
            </n-text>
          </n-grid>
        </n-grid>
      </template>
    </activity-wrapper>
  </n-theme>
</template>

<script>
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import ProfilePicture from '@/components/shared/profilePicture';
import Step from '@/components/shared/overview/parts/step';
import { formatRelativeWithoutTime } from '@/vendor/date-fns';
import Rating from '@/components/shared/rating';


export default {
    name: '',
    components: {
        ActivityWrapper, Step, ProfilePicture, Rating
    },
    props: {
        driverTrip: {
            type: Object,
            required: true,
        },
        statusColor: {
          type: String,
          default: 'accent',
        },
        steps: {
          type: Array,
          default: null
        },
        isHistory: {
          type: Boolean,
          default: false
        },
        passengersAccepted:{
          type: Array,
          default: null        
        },
        openProfile: {
          type: Function,
          default: null,
        },
        rateTrip: {
          type: Function,
          default: null,
        }
    },
    computed: {
        headerText() {
            return formatRelativeWithoutTime(this.driverTrip.planned_departure);
        },
    },
};
</script>

<style lang="scss" scoped></style>