<template>
  <n-bottom-sheet
    type="header"
    :title="$t('vacation.editSheet.title')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-text block preset="header" color="accent">{{ $t('vacation.editSheet.pageTitle') }}</n-text>
    <n-text block preset="body">{{ $t('vacation.editSheet.intro') }}</n-text>

    <n-grid :top-gap="0" class="align-baseline">
      <n-column :span="1">
        <n-text preset="label-2">{{ $t('vacation.editSheet.from') }}</n-text>
      </n-column>
      <n-column :span="5" class="date-picker-container">
        <n-date-picker
          :disabled="!canEditStart"
          string-format="d. MMMM"
          class="span-3"
          v-model="vacationPeriod.start"
          :placeholder="$t('vacation.editSheet.fromPlaceholder')"
        />
      </n-column>
    </n-grid>

    <n-grid :top-gap="0" class="align-baseline">
      <n-column :span="1">
        <n-text preset="label-2">{{ $t('vacation.editSheet.to') }}</n-text>
      </n-column>
      <n-column :span="5" class="date-picker-container">
        <n-date-picker
          string-format="d. MMMM"
          :min-datetime="minEndDate"
          class="span-3"
          v-model="vacationPeriod.end"
          :placeholder="$t('vacation.editSheet.toPlaceholder')"
        />
      </n-column>
    </n-grid>

    <n-grid :bottom-gap="5" >
      <n-text block preset="sub" class="pre-line">{{ $t('vacation.editSheet.body') }}</n-text>
    </n-grid>
    <n-button
      class="span-6"
      :loading="savingVacation"
      @click="submitVacation"
      size="lg"
    >{{ $t('vacation.editSheet.saveButton') }}</n-button>
  </n-bottom-sheet>
</template>

<script>
import userApi from '@/api/user';
import { TRIP_GENERATION_DAYS } from '@/constants';
import { addDays, isAfter, isBefore } from 'date-fns';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  data: () => ({
    savingVacation: false,
    vacationPeriod: {
      start: null,
      end: null,
    },
    initialEnd: null,
  }),
  computed: {
    minEndDate() {
      const editThreshold = addDays(new Date(), TRIP_GENERATION_DAYS + 1)
      const moreThan8DaysUntilEnd = isAfter(new Date(this.initialEnd), editThreshold);

      if (moreThan8DaysUntilEnd) {
        return editThreshold.toISOString();
      }

      return new Date(this.initialEnd).toISOString();
    },
    canEditStart() {
      return false; //If its posible to edit Start date it should show the delete button instead
    },
  },
  methods: {
    edit(vacationPeriod) {
      this.vacationPeriod = vacationPeriod;
      this.initialEnd = vacationPeriod.end;
      this.$refs.sheet.open();
    },
    dismiss() { this.$refs.sheet.dismiss(); },
    async submitVacation() {
      this.savingVacation = true;
      await userApi.editVacation({
        to_date: this.vacationPeriod.end,
      });
      await this.$store.dispatch(userTypes.FETCH_PROFILE);
      this.savingVacation = false;

      this.dismiss();
    },
  }
}
</script>
