var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('n-top-screen',{attrs:{"disable-safe-spacing":""}},[_c('n-dialog'),_c('n-container',{attrs:{"preset":"top"}},[_c('n-nav-bar',{attrs:{"title":_vm.title,"shadowless":""}}),(!_vm.departureOnly)?_c('div',{staticClass:"switch",on:{"click":_vm.switchAddresses}},[_c('div',{staticClass:"swap-button-container"},[_c('n-icon',{attrs:{"name":"arrows-swap","color":"accent"}})],1)]):_vm._e(),_c('n-layout',{staticClass:"relative",attrs:{"spacing-y":false,"spacing-bottom":_vm.departureOnly}},[_c('n-button-fake-input',{staticClass:"span-6",attrs:{"loading":_vm.loadingType === 'from',"selected":_vm.fromAddress != null,"label":_vm.$t('c.searchHeader.from')},on:{"click":function($event){return _vm.openSheet('from')}}},[_vm._v(" "+_vm._s(_vm.fromLabel)+" ")]),(!_vm.departureOnly)?_c('n-button-fake-input',{staticClass:"span-6",attrs:{"loading":_vm.loadingType === 'to',"selected":_vm.toAddress != null,"label":_vm.$t('c.searchHeader.to')},on:{"click":function($event){return _vm.openSheet('to')}}},[_vm._v(" "+_vm._s(_vm.toLabel)+" ")]):_vm._e()],1),(!_vm.departureOnly)?_c('n-layout',{staticClass:"select-time-container",attrs:{"top-gap":2,"spacing-y":false}},[_c('n-grid',{staticClass:"span-6",attrs:{"top-gap":2,"bottom-gap":3}},[(_vm.datePickerType === 'datetime')?_c('div',{staticClass:"span-6",on:{"click":_vm.toggleTimePicker}},[_c('n-column',{staticClass:"select-time-area",attrs:{"span":6}},[_c('svg-icon',{staticClass:"select-time-arrow",class:{
                  'weight-bold': _vm.plannedTime || _vm.showTimePicker,
                },attrs:{"name":"arrow-right","color":_vm.plannedTime || _vm.showTimePicker
                    ? 'var(--color-green-900)'
                    : 'var(--color-green-700)'}}),_c('n-text',{class:{
                  'color-neutral-800 text-bold': _vm.plannedTrip,
                  'color-neutral-600': !_vm.plannedTrip,
                },attrs:{"preset":"sub"}},[(_vm.plannedTime)?_c('div',{staticClass:"color-green-900"},[_c('span',{staticClass:"weight-bold"},[_vm._v(_vm._s(_vm.$t('c.searchHeader.mode.' + _vm.plannedTimeBy))+": ")]),(
                      _vm.isPlannedTimeToday() && _vm.datePickerType === 'datetime'
                    )?_c('span',[_vm._v(_vm._s(_vm.$t('c.searchHeader.today')))]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate(_vm.plannedTime))+" ")]):_c('div',{class:_vm.showTimePicker
                      ? 'color-green-900 weight-bold'
                      : 'color-green-700'},[_vm._v(" "+_vm._s(_vm.$t('c.searchHeader.selectTime'))+" ")])])],1)],1):_vm._e(),_vm._t("options")],2)],1):_vm._e()],1),_c('n-layout',{attrs:{"spacing-y":false}},[_vm._t("default")],2)],1),(_vm.showTimePicker)?[_c('DatetimePicker',{attrs:{"time-mode":_vm.plannedTimeBy,"time-selected":_vm.time,"only-time":_vm.datePickerType === 'time'},on:{"datetimeChange":_vm.setPlannedTime,"modeChange":_vm.setPlannedBy,"dateTimePickerClosed":_vm.toggleTimePicker}})]:_vm._e(),_c('address-search-sheet',{ref:"searchSheet",attrs:{"title":_vm.$t(`c.searchHeader.where${_vm.sheetType}`),"input-label":_vm.$t(`c.searchHeader.${_vm.sheetType}`)},on:{"input":_vm.onAddressInput,"select":_vm.onAddressSelect,"error":_vm.onAddressError}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }