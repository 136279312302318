<template>
<div class="view-container">
  <n-nav-bar :title="$t('transactions.title')" />
  <n-layout>
    <n-column :span="2">
      <n-text preset="label-3" color="grey-dark">{{ $t('transactions.period') }}</n-text>
      <n-dropdown @input="onPeriodChange($event)" :options="periods" v-model="selectedPeriod" />
    </n-column>
    <n-column :span="2">
      <n-text preset="label-3" color="grey-dark">{{ $t('transactions.from') }}</n-text>
      <n-date-picker v-model="fromDate" @input="onCustom('from', $event)" />
    </n-column>
    <n-column :span="2">
      <n-text preset="label-3" color="grey-dark">{{ $t('transactions.to') }}</n-text>
      <n-date-picker v-model="toDate" @input="onCustom('to', $event)" />
    </n-column>
  </n-layout>
  <n-scrollable>
    <n-layout class="pad" :spacing-y="false">
      <n-spinner v-if="loading" class="span-6 flex-center" />
      <table v-else-if="transactions.length > 0"  class="table span-6">
        <thead>
          <tr>
            <th><n-text preset="sub" color="grey-dark">{{ $t('transactions.date') }}</n-text></th>
            <th><n-text preset="sub" color="grey-dark">{{ $t('transactions.action') }}</n-text></th>
            <th><n-text preset="sub" color="grey-dark">{{ $t('transactions.total') }}</n-text></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions" :key="transaction.id">
            <td><n-text preset="sub">{{ formatDate(transaction) }}</n-text></td>
            <td><n-text preset="sub">{{ formatDescription(transaction) }}</n-text></td>
            <td><n-text preset="sub">{{ $n(transaction.amount, 'currency', transaction.currency) }}</n-text></td>
          </tr>
        </tbody>
      </table>
      <n-text v-else class="span-6" align="center">{{ $t('transactions.empty') }}</n-text>
    </n-layout>
  </n-scrollable>
  </div>
</template>
<script>

  import userApi from '@/api/user';
  import { mapState} from 'vuex';
  import { namespace as userNamespace } from '@/store/modules/user-types';
  import { format } from '@/vendor/date-fns';
  import { startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear, subYears } from 'date-fns';

  export default {
    name: 'accountTransactions',
    data() {
      return {
        loading: false,
        selectedPeriod: null,
        fromDate: null,
        toDate: null,
        transactions: [],
        intervals: [
          {
          key: 'thisMonth',
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
        },
        {
          key: 'lastMonth',
          from: startOfMonth(subMonths(new Date(), 1)),
          to: endOfMonth(subMonths(new Date(), 1)),
        },
        {
          key: '3months',
          from: startOfMonth(subMonths(new Date(), 2)),
          to: endOfMonth(new Date()),
        },
        {
          key: '6months',
          from: startOfMonth(subMonths(new Date(), 5)),
          to: endOfMonth(new Date()),
        },
        {
          key: '12months',
          from: startOfMonth(subMonths(new Date(), 11)),
          to: endOfMonth(new Date()),
        },
        {
          key: 'thisYear',
          from: startOfYear(new Date()),
          to: endOfMonth(new Date()),
        },
        {
          key: 'lastYear',
          from: startOfYear(subYears(new Date(), 1)),
          to: endOfYear(subYears(new Date(), 1)),
        },
        {
          key: 'custom',
          from: null,
          to: null,
        },
        ]
      }
    },
    computed: {
      ...mapState(userNamespace, [
        'profile',
      ]),
      periods() {
        const obj = this.$t('transactions.periods'); // Lazyness. use the key value from the translation as options.
        return Object.keys(obj).map(key => {
          return {id: key, text: obj[key]};
        });
      },
    },
    mounted() {
      this.selectedPeriod = this.periods[0];
      this.onPeriodChange(this.selectedPeriod);
    },
    methods: {
      calculateAmount(transaction) {
        return transaction.amount * (transaction.meta.ingoing ? 1 : -1);
      },
      onPeriodChange(e) {
        if (e.id === 'custom') return;
        const interval = this.intervals.find(x => x.key === e.id);
        this.fromDate = interval.from;
        this.toDate = interval.to;
        this.getData();
      },
      onCustom(type, val) {
        if (val === null) return
        this.selectedPeriod = this.periods.find(x => x.id === 'custom');
        this.getData();
      },
      formatDate(transaction) {
        if (transaction.date === null) {
          return '-'
        };

        return format(transaction.date, 'dd/MM/yy')
      },
      formatDescription(transaction) {
        if (transaction.type === 'PAYMENT' && transaction.actionable_type === null) {
          return this.$t('transactions.ingoing_payment');
        }

        if (transaction.type === 'PAYOUT' && transaction.actionable_type === null) {
          return this.$t('transactions.outgoing_payment');
        }

        if (transaction.type === 'RECEIVE' && transaction.actionable_type === 'commute_passenger_trip') {
          return this.$t('transactions.commute_driver_trip');
        }

        return this.$t(`transactions.${transaction.actionable_type}`);
      },
      async getData() {
        this.loading = true;
        const result = await userApi.profileTransactionsFetch(this.fromDate, this.toDate)
        this.transactions = result.transactions;
        this.loading = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
.pad {
  padding-bottom: 10px;
}
</style>
