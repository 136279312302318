<template>
    <div class="checkbox-container">
      <input type="checkbox" v-model="valueModel"
       :id="_uid" />
      <div class="checkbox">
        <div class="check" />
      </div>
      <label :for="_uid" v-if="$slots.default">
        <n-text preset="sub"><slot /></n-text>
      </label>
    </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['value'],
  computed: {
    valueModel: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/style/styleguide.scss";

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    label {
      width: calc(100% - 15px - 20px);
      margin-left: 15px;
      color: var(--color-grey-dark);
    }

  }

  input[type="checkbox"] {
    opacity: 0;
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0;
  }
  .checkbox {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: solid var(--color-accent) 1px;
    padding: 2px;
  }

  input[type="checkbox"]:checked ~ .checkbox {
    border-color: var(--color-accent);
    .check {
      background: var(--color-accent);
    }
  }


  input[type="checkbox"]:disabled ~ .checkbox {
    border-color: var(--color-grey-dark);
  }

  input[type="checkbox"]:disabled:checked ~ .checkbox {
    .check {
      background: var(--color-grey-dark);
    }
  }

  .check {
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }

</style>
