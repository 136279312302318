<template>
  <div class="span-6">
    <div class="passenger-container">
      <profile-picture
        class="passenger-picture"
        :src="passengerTrip.user.image"
      />

      <div class="passenger-info">
        <n-text ellipsis preset="header" color="grey-dark">{{
          passengerTrip.user.display_name
        }}</n-text>
      </div>

      <div v-if="!cancelled" class="passenger-actions">
        <n-action-sheet #default="{ closeSheet }">
          <n-action-sheet-item
            v-if="passengerTrip.user.has_mandatory_properties"
            icon="person"
            @click.native="closeSheet(sendMessage)"
          >
            {{ $t('tripDetails.passenger.sendMessageButton') }}
          </n-action-sheet-item>
          <n-action-sheet-item
            icon="trash-can"
            @click.native="closeSheet(cancel)"
          >
            {{
              $t('main.activeTrip.activeTripSheet.cancelAgreement')
            }}
          </n-action-sheet-item>
        </n-action-sheet>
      </div>
    </div>

    <div class="span-6 ride-details">
      <div class="ride-waypoints">
        <div class="ride-waypoint">
          <n-text class="ride-waypoint-time" preset="body" color="grey-dark">
            {{ formatTime(passengerTrip.departure_time) }}
          </n-text>

          <div class="ride-waypoint-info">
            <n-text preset="body" color="grey-dark">{{
              formatStopAddress(passengerTrip.departure_stop)
            }}</n-text>
          </div>
        </div>

        <div class="ride-waypoint">
          <n-text class="ride-waypoint-time" preset="body" color="grey-dark">
            {{ formatTime(passengerTrip.arrival_time) }}
          </n-text>

          <div class="ride-waypoint-info">
            <n-text preset="body" color="grey-dark">{{
              formatStopAddress(passengerTrip.arrival_stop)
            }}</n-text>
          </div>
        </div>
      </div>

      <n-text preset="label-2" color="accent">
        {{ this.passengerSubtitle }}
      </n-text>
    </div>

    <n-seperator class="seperator" v-if="!lastChild" />
  </div>
</template>

<script>
import { format } from '@/vendor/date-fns';
import ProfilePicture from '@/components/shared/profilePicture';

export default {
  props: {
    passengerTrip: {
      type: Object,
      required: true,
    },
    lastChild: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProfilePicture,
  },
  computed: {
    cancelled() {
      return this.passengerTrip.status === 'CANCELED';
    },
    passengerSubtitle() {
      return this.$t('main.activeTrip.activeTripSheet.passengerSubtitle', {
        km: this.$n(Math.round(this.passengerTrip.distance / 100) / 10, 'km'),
        price: this.$n(
          this.cancelled ? 0 : this.passengerTrip.payout,
          'currency',
          this.passengerTrip.payout_currency
        ),
      });
    },
  },
  methods: {
    formatTime(time) {
      return format(time, 'HH:mm');
    },
    formatStopAddress(address) {
      const values = [address?.street, address?.city];

      return values.filter((x) => x).join(', ');
    },
    cancel() {
      this.$emit('cancel', this.passengerTrip);
    },
    sendMessage() {
      this.$router.push({
        name: 'userConversation',
        params: { userId: this.passengerTrip.user.id },
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.passenger-container {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;

  .passenger-picture {
    width: 2rem;
    height: 2rem;
  }

  .passenger-info {
    flex: 1;
    margin-left: 1rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .passenger-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
  }
}

.ride-details {
  margin-top: 0.75rem;
}

.ride-waypoints {
  margin-bottom: 0.5rem;

  .ride-waypoint {
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0;

    &-time {
      padding: 0.35rem 0.65rem 0 0;
    }

    &-info {
      display: flex;
      flex-direction: column;

      span {
        margin: 0.25rem 0;
      }
    }
  }
}

.seperator {
  margin-top: 1.7rem;
  margin-bottom: 0rem;
}
</style>