<template>
  <n-bottom-sheet
    type="header"
    :title="$t('vacation.createSheet.title')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-text block preset="header" color="accent">{{ $t('vacation.createSheet.pageTitle') }}</n-text>
    <n-text block preset="body">{{ $t('vacation.createSheet.intro') }}</n-text>

    <n-grid :top-gap="0" class="align-baseline">
      <n-column :span="1">
        <n-text preset="label-2">{{ $t('vacation.createSheet.from') }}</n-text>
      </n-column>
      <n-column :span="5" class="date-picker-container">
        <n-date-picker
          string-format="d. MMMM"
          :min-datetime="defaultStartDate"
          class="span-3"
          v-model="vacationPeriod.start"
          :standard="$t('vacation.createSheet.fromPlaceholder')"
        />
      </n-column>
    </n-grid>

    <n-grid :top-gap="0" class="align-baseline">
      <n-column :span="1">
        <n-text preset="label-2">{{ $t('vacation.createSheet.to') }}</n-text>
      </n-column>
      <n-column :span="5" class="date-picker-container">
        <n-date-picker
          string-format="d. MMMM"
          :min-datetime="minEndDate"
          class="span-3"
          v-model="vacationPeriod.end"
          :standard="$t('vacation.createSheet.toPlaceholder')"
        />
      </n-column>
    </n-grid>
    <n-button
      class="span-6"
      :loading="savingVacation"
      @click="submitVacation"
      size="lg"
    >{{ $t('vacation.createSheet.saveButton') }}</n-button>
  </n-bottom-sheet>
</template>

<script>
import userApi from '@/api/user';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  data: () => ({
    savingVacation: false,
    defaultStartDate: new Date().toISOString(),
    vacationPeriod: {
      start: null,
      end: null,
    }
  }),
  computed: {
    minEndDate() {
      if (!this.vacationPeriod.start) {
        return new Date().toISOString();
      }
      return this.vacationPeriod.start.toISOString()
    }
  },
  methods: {
    open() {
      this.$refs.sheet.open();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
    async createVacation() {
      this.savingVacation = true;
      try {
        await userApi.createVacation({
          from_date: this.vacationPeriod.start,
          to_date: this.vacationPeriod.end,
        });
        await this.$store.dispatch(userTypes.FETCH_PROFILE);

        this.dismiss();
      } catch(error) {
        if (!error?.response?.data.errors) {
          this.$error();
        } else {
          const errorMessage = error.response.data.errors.from_date[0] || error.response.data.errors.to_date[0];
          this.$error(errorMessage);
        }
      } finally {
        this.savingVacation = false;
        this.vacationPeriod.start = null;
        this.vacationPeriod.end = null;
      }
    },
    submitVacation() {
      if (this.savingVacation || !this.vacationPeriod.start || !this.vacationPeriod.end) {
        return;
      }

      this.$modal.show('dialog', {
        title: this.$t('vacation.confirmCreateDialog.title'),
        text: this.$t('vacation.confirmCreateDialog.body'),
        cancel: true,
        success: {
          text: this.$t('vacation.confirmCreateDialog.confirmButton'),
          handler: this.createVacation
        },
      });
    }
  }
}
</script>
