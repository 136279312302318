<template>
  <n-text-model v-bind="attributes">
    <slot></slot>
  </n-text-model>
</template>

<script>
const presets = {
  "title": { size: "md", weight: "medium", uppercase: true, spacing: true },
  "header": { size: "lg", weight: "regular" },
  "body": { size: "md", weight: "regular" },
  "sub": { size: "sm", weight: "regular" },
  "hero": { size: "xxl", weight: "medium" },

  "label-1": { size: "md", weight: "medium", uppercase: true, spacing: true },
  "label-2": { size: "sm", weight: "regular", uppercase: true, spacing: true },
  "label-3": { size: "xs", weight: "regular", uppercase: true, spacing: true },

  "btn-1": { size: "xl", weight: "light" },
  "btn-2": { size: "md", weight: "medium", align: "center", uppercase: true, spacing: true },
  "btn-3": { size: "sm", weight: "medium", align: "center", uppercase: true, spacing: true }
};

export default {
  inheritAttrs: false,
  props: {
    preset: {
      type: String,
      default: "body",
      validator: (size) => Object.keys(presets).includes(size),
    }
  },
  computed: {
    attributes() {
      return {
        ...this.$attrs,
        ...presets[this.preset]
      }
    },
  },
};
</script>
