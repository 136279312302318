<template>
  <n-grid :y-gap="5">
    <n-grid
      class="span-6"
      :row-gap="0"
    >
      <n-icon
        :name="icon"
        color="accent"
        class="flex-start"
      />
      <n-text
        color="accent-faded"
        class="span-4 flex-v-center"
      >
        {{ clockText }}
      </n-text>
      <div class="flex-end">
        <slot name="action" />
      </div>
      <n-text
        class="status"
        :color="statusColor"
      >
        <slot name="status" />
      </n-text>
    </n-grid>
    <slot name="steps" />
    <slot name="extra" />
    <n-grid
      class="span-6"
      :top-gap="5"
    >
      <slot name="buttons" />
    </n-grid>
    <slot name="footer" />
    <n-grid
      class="span-6"
      :y-gap="5"
    >
      <n-seperator class="span-6" />
    </n-grid>
  </n-grid>
</template>
<script>
import { formatRelativeWithoutTime } from '@/vendor/date-fns';

export default {
  name: 'ActivityWrapper',
  props: {
    clock: {
      type: String,
      required: true,
    },
    formatClock: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      required: true,
    },
    statusColor: {
      type: String,
      default: 'accent',
    },
    history: {
      type: Boolean,
      default: false,
    },
    cancelled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clockText() {
      if (this.formatClock) {
        return formatRelativeWithoutTime(this.clock).toUpperCase();
      }
      return this.clock;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.status {
  grid-column: 2 / span 5;
}
  .history {
    .clock, .details, .fade {
      opacity: 0.6;
    }
}
</style>
