<template>
  <div class="spacer" :style="{ height: height + 'rem' }"></div>
</template>

<script>
export default {
  name: 'n-seperator',
  props: {
    height: {
      type: Number,
      default: 1,
    },
  },
};
</script>
