/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 13c-2.67 0-8 1.33-8 4v3h16v-3c0-2.67-5.33-4-8-4m0-9C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 10.9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.1-2.1 6.1-2.1m0-9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2z"/>'
  }
})
