<template>
  <div class="button-fake-input-container">
    <a
      v-bind="$attrs"
      class="button-fake-input"
      :class="{'selected': selected, 'loading': loading}"
      v-on="$listeners"
    >
      <div
        v-if="leftIcon"
        class="icon-container left-icon"
      >
        <n-icon :name="leftIcon" />
      </div>

      <n-text
        v-if="label"
        class="label color-neutral-600"
        preset="sub"
      >{{ label }}</n-text>
      <n-text
        class="text color-neutral-800"
        preset="sub"
      >
        <slot />
      </n-text>

      <div
        v-if="rightIcon"
        class="icon-container right-icon"
        @click.stop="$emit('right-icon-clicked')"
      >
        <n-icon
          :name="rightIcon"
          color="grey-dark"
        />
      </div>

      <transition name="fade">
        <div
          v-show="loading"
          class="loading-container"
        >
          <n-spinner />
        </div>
      </transition>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    selected: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.button-fake-input-container {
  grid-column: 1 / -1;

  .button-fake-input {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background-color: var(--color-grey-light);
    border-radius: 9999px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-grey-light);
    color: var(--color-grey-dark);
    cursor: pointer;
    flex-direction: row;
    outline: none;
    overflow: hidden;
    padding: 0px 20px;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    @extend .feedback;
    transition: all 0.2s ease-in-out;
    position: relative;

    .icon-container {
      height: 21px;
      width: 21px;
    }

    .left-icon {
      margin-right: 8px;
    }

    .label {
      margin-right: 3px;
      min-width: 25px;
    }

    .text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      height: 100%;
      width: 100%;
      fill: var(--color-grey-dark);
    }

    &.selected {
      background-color: var(--color-white);
      border-color: var(--color-accent);
      color: var(--color-accent);
    }

    .loading-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-right: 1rem;
      background-color: rgba(255, 255, 255, 0.85);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.fade-enter-active {
        transition: opacity .5s;
        transition-delay: .25s;
      }

      &.fade-leave-active {
        transition: opacity .25s;
      }
    }

    &.selected .loading-container {
      background-color: rgba(255, 255, 255, 0.8);

      &.fade-enter-active {
        transition: opacity .75s;
        transition-delay: .5s;
      }
    }
  }
}
</style>
