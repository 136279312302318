<template>
  <n-layout class="unauth">
    <n-grid :row-gap="4" :y-gap="1">
     <n-button size="lg" block @click="showLogin">
        {{ $t("main.start.signInOrCreateAccount") }}
      </n-button>
      <n-theme type="driver" class="span-6">
        <n-button type="outlined white" inverted block @click="$emit('offerCommute')">
          {{ $t("main.start.offerCommute") }}
        </n-button>
      </n-theme>
      <n-button type="outlined white" inverted block @click="$emit('requestCommute')">
        {{ $t("main.start.whereTo") }}
      </n-button>
    </n-grid>
  </n-layout>
</template>

<script>
import { EventBus } from '@/vendor/events';
export default {
  name: 'mainStartUnauthenticatedSheet',
  methods: {
    showLogin() {
      EventBus.$emit("show-login-sheet");
    },
  },
};
</script>

<style lang="scss" scoped>
.unauth {
  position: fixed;
  bottom: 0;
}
</style>
