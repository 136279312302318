<template>
  <div v-if="horizontal" class="horizontal">
    <div v-bind="$attrs">
      <slot />
    </div>
  </div>
  <div v-else class="scrollable" :class="{'no-overflow': noOverflow }" v-on="$listeners" @scroll="onScroll">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'n-scrollable',
    props: {
      horizontal: Boolean,
      noOverflow: Boolean,
    },
    methods: {
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          this.$emit('scrolledToBottom');
        }
      },
    },
  };
</script>


<style lang="scss" scoped>
  .scrollable {
    flex: 1;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: auto;
    overscroll-behavior-x: none;
    -webkit-overflow-scrolling: touch;
  }

  .horizontal {
    width: 100%;
    display: flex;
    overflow: auto;

    & > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  .no-overflow {
    -webkit-overflow-scrolling: auto;
  }
</style>
