/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'repeat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 17H7v-3l-4 4 4 4v-3h12v-6h-2M7 7h10v3l4-4-4-4v3H5v6h2V7z"/>'
  }
})
