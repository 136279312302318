/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_location': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7zm0 9.5A2.5 2.5 0 1114.49 9 2.5 2.5 0 0112 11.5z" _fill="#b7b7b7"/>'
  }
})
