<template>
  <div class="wrapper" :class="{'overview' : overview}">
    <div class="schedule">
      <div
        v-for="(leg, index) in filteredLegs"
        :key="index"
        class="leg"
        :style="{'width': `${leg.width}%`, 'min-width': '3em'}"
      >
        <div class="line" :class="leg.type"></div>
        <div class="info-below" :class="{'first-item' : index === 0, 'last-item' : filteredLegs.length -1 === index, 'after-go' : isLegAfterGo(index)}">
          <template v-if="leg.type === 'CAR'">
            <n-text v-if="label" color="accent" preset="label-3">{{ label }}</n-text>
            <slot v-else />
          </template>
          <n-icon v-else :name="icon(leg.type)" size="md" color="grey-dark" />
        </div>
        <div class="start"> <!--  Start of leg -->
          <div v-if="index === 0 && leg.type !== 'CAR'" class="grey first" /> <!-- Very first -->
          <n-icon v-else-if="leg.type === 'CAR'" :name="icon(leg.type)" size="md" type="circle" color="accent" />
          <n-icon v-else-if="!isLegAfterGo(index)" name="target-spot" size="md" color="grey-dark" />
        </div>
        <!--  End of leg -->
        <div class="end">
          <n-icon v-if="filteredLegs.length -1 === index" name="target-spot" size="md" type="circle-inverse" :color="leg.type === 'CAR' ? 'accent':'grey-dark'"/> <!-- Last -->
           <n-icon v-else-if="leg.type === 'CAR'" name="target-spot" size="md" color="accent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants';
import { format } from '@/vendor/date-fns';
import { differenceInMinutes } from 'date-fns';

export default {
  name: '',
  components: {},
  props: {
    legs: {
      type: Array,
    },
    overview: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    filteredLegs: [],
  }),
  computed: {
    totalTime() {
      let total = differenceInMinutes(
        new Date(this.legs[this.legs.length - 1].destination.datetime),
        new Date(this.legs[0].origin.datetime),
      );
      return total <= 0 ? 1 : total;
    },
  },
  watch: {
    legs: {
      immediate: true,
      handler(newVal) {
        let addToNext = 0;
        let usedPercentage = 0;
        newVal.forEach((leg, index) => {
          if (
            leg.type === 'WALK' &&
            index !== 0 &&
            index !== newVal.length - 1
          ) {
            addToNext = this.legWidth(leg);
          } else {
            const totalWidth = this.legWidth(leg) + addToNext;
            addToNext = 0;
            usedPercentage += totalWidth;
            leg.width = totalWidth;
          }
        });
        // Waiting time is not taking into account, spread the unused between all the steps
        const percentPerLeg = (100 - usedPercentage) / newVal.length;
        const legs = newVal
          .filter(x => x.width != null)
          .map((leg) => {
            leg.width += percentPerLeg;
            return leg;
          });
        this.filteredLegs = legs;
      },
    },
  },
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    icon(type) {
      if (!(type in constants.transportIcons)) {
        console.error(`transportIcons '${type}' is not mapped in constants.js`)
        return constants.transportIcons.BUS;
      }
      return constants.transportIcons[type];
    },
    percentageOfTime(date1, date2) {
      const mins = differenceInMinutes(new Date(date1), new Date(date2));
      return (mins / this.totalTime) * 100;
    },
    legWidth(leg) {
      const width = this.percentageOfTime(
        leg.destination.datetime,
        leg.origin.datetime,
      );
      return width;
    },
    isLegAfterGo(currentIndex) {
      if (!this.filteredLegs[currentIndex - 1]) return false;
      return this.filteredLegs[currentIndex - 1].type === 'CAR';
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  min-height: 3em;
  margin-top: 0.25rem;
  width: 100%;
  .schedule {
    display: flex;
    flex: 8;
  }
  .leg {
    position: relative;
    .info-below {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-left: var(--icon-md-size);
      &.first-item {
        margin-left: -3px;
      }
      &.last-item {
        padding-right: var(--icon-md-size);}
      }
      &.after-go {
        padding-left: 0;
      }
    .line {
      margin-top: 0.7em;
      border-top: solid 0.15em var(--color-grey-dark);
      &.CAR {
        border-top-color: var(--color-accent);
      }
      &.WALK {
        border-top-style: dashed;
      }
    }
    .start {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      .first {
        border: solid var(--color-grey-dark) 2px;
        background: white;
        border-radius: 100%;
        margin-top: 2px;
        height: calc(var(--icon-md-size) - 3px);
        width: calc(var(--icon-md-size) - 3px);
        &.accent {
          border-color: var(--color-accent);
        }
      }
      .time {
        margin-left: -0.5em;
        margin-top: -0.2em;
      }
    }
    .end {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.wrapper.overview {
  .leg {
    .start {
      svg {
        background-color: $c-faded;
      }
    }
  }
}
</style>
