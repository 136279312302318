<script>
import { mapActions, mapState } from 'vuex';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
export default {
  methods: {
    ...mapActions({
      cancelTrip: userTypes.CANCEL_COMMUTE_BOOKING,
    }),
    show(driverTripId, passengerTripId, callback) {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.passenger.cancelDialog.title'),
        text: this.$t('tripDetails.passenger.cancelDialog.description'),
        color: 'error',
        cancel: true,
        success: {
          text: this.$t('tripDetails.passenger.cancelDialog.successButton'),
          handler: async () => {
            try {
              await this.cancelTrip({
                driverTripId,
                passengerTripId,
              });
            } catch(error){ 
              if (error.response?.status !== 409) {
                throw error;
              }
            }
            callback();
          },
        },
      });
    },
  },
  render() {
    return this.$slots.default
  },
}
</script>
