/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2m-1 14.5l7-7-1.41-1.41L11 13.67l-3.09-3.08L6.5 12l4.5 4.5z"/>'
  }
})
