<template>
  <landing-base>
      <n-text block preset="header" color="accent" style="padding-bottom: 0.5rem">{{ $t('landing.rejseplanenTitle') }}</n-text>
      <n-text
        block
        preset="body"
        align="center"
        style="white-space: break-spaces;"
      >{{ $t('landing.rejseplanenBody') }}</n-text>
  </landing-base>
</template>
<script>
import LandingBase from './base';
export default {
  components: { LandingBase },
}
</script>
