/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map-plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 3L3.36 4.9c-.2.07-.36.25-.36.48V20.5c0 .28.22.5.5.5.05 0 .1 0 .16-.03L9 18.9l4.16 1.46c-.1-.44-.16-.9-.16-1.36 0-.23 0-.46.04-.7L9 16.9V5l6 2.1v7.46c1.07-.96 2.47-1.56 4-1.56.7 0 1.37.13 2 .36V3.5c0-.28-.22-.5-.5-.5h-.16L15 5.1 9 3m9 12v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z"/>'
  }
})
