<template>
  <div class="address-search" :class="{ 'address-searching': isSearching }">
    <n-layout
      :spacing-y="false"
      :spacing-top="true"
      :spacing-bottom="isSearching || favorites"
    >
      <n-search-input
        class="span-6"
        ref="searchInput"
        :label="inputLabel"
        v-model="searchTerm"
        @keydown.enter="closeKeyboard"
        :placeholder="inputPlaceholder || $t('c.searchSheet.placeholder')"
      />

      <n-column :span="6">
        <address-search-favourites
          v-if="!isSearching && favorites"
          :with-current-location="withCurrentLocation"
          @selectWork="selectWork"
          @selectHome="selectHome"
          @selectCoordinate="selectCoordinate"
        />
      </n-column>
    </n-layout>

    <n-seperator v-if="!isSearching && favorites" />

    <n-layout class="address-search-results" :spacing-y="false">
      <n-column v-if="showingRecentSearches" class="recent-searches" :span="6">
        <n-text preset="label-2" color="grey">
          {{ $t('c.addressSheet.recentSearches') }}
        </n-text>
      </n-column>

      <div
        v-for="(prediction, index) in predictionList"
        @click="select(prediction)"
        :key="index"
        class="span-6 predict-item-container"
      >
        <n-grid
          class="predict-item"
          :row-gap="0"
          :top-gap="-1.5"
          :bottom-gap="2.75"
        >
          <n-text preset="body" class="span-5">
            {{ prediction.structured_formatting.main_text }}
          </n-text>
          <n-text
            v-if="prediction.distance_meters"
            class="distance"
            preset="sub"
            color="grey-dark"
            >{{ `${Math.round(prediction.distance_meters / 1000)} KM` }}</n-text
          >
          <n-text preset="sub" color="grey-dark" class="span-6">{{
            prediction.structured_formatting.secondary_text
          }}</n-text>
        </n-grid>
        <n-seperator />
      </div>
    </n-layout>

    <img
      class="powered-by-google"
      src="@/assets/powered_by_google_on_white.png"
    />
  </div>
</template>

  <script>
import debounce from 'lodash.debounce';
import mapsApi from '@/api/maps';

import * as util from '@/vendor/utils';
import { mapState, mapGetters } from 'vuex';
import {
  namespace as namespaceUser,
  namespacedTypes as namespacedUser,
} from '@/store/modules/user-types';
import AddressSearchFavourites from '@/components/shared/addressSearchFavourites';

export default {
  props: {
    favorites: {
      type: Boolean,
      default: true,
    },
    withCurrentLocation: {
      type: Boolean,
      default: true,
    },
    inputLabel: {
      type: String,
      default: null,
    },
    inputPlaceholder: {
      type: String,
      default: null,
    },
  },
  components: {
    AddressSearchFavourites,
  },
  data() {
    return {
      searchTerm: null,
      predictions: null,
      disableInput: false,
    };
  },
  computed: {
    ...mapState(namespaceUser, ['profile']),
    ...mapGetters(namespaceUser, {
      savedPredictions: 'addressPredictions',
    }),
    showingRecentSearches() {
      return !this.searchTerm || !this.predictions;
    },
    isSearching() {
      return this.predictions && !!this.searchTerm;
    },
    predictionList() {
      const list = this.showingRecentSearches
        ? this.savedPredictions
        : this.predictions;

      if (!(list instanceof Array)) {
        return [];
      }

      return list;
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTerm && this.searchTerm.length > 3) {
        this.debouncedPerformSearch(this.searchTerm);
      }

      if (!this.searchTerm) {
        this.predictions = null;
      }
    },
  },
  methods: {
    debouncedPerformSearch: debounce(
      async function debouncerPerformSearch(term) {
        this.isBusy = true;
        this.predictions = (
          await mapsApi.autocompleteAddress(term)
        ).predictions;
        this.isBusy = false;
      },
      500,
      {
        maxWait: 2000,
        leading: false,
        trailing: true,
      }
    ),
    async selectWork() {
      this.$emit('select');

      const details = await mapsApi.placeDetails(this.profile.workplace_pid);

      if (!details || !details.result) {
        this.displayError();
        return;
      }

      // The user could have saved an invalid address, before validation was implemented.
      if (!util.isValidAddress(details.result)) {
        this.$error(this.$t('c.searchSheet.invalidAddress'));
        this.$emit('error');
        return;
      }

      /** @type {AddressTransformed} */
      const { result } = details;

      this.$emit(
        'input',
        util.parseTransformedAddressToDeprecatedLocationFormat(result)
      );
    },
    /**
     * @param {Point} coordinate
     */
    async selectCoordinate(coordinate) {
      this.$emit('select');

      const details = await mapsApi.reverseGeocodeMap(coordinate);

      if (!details || !details.result) {
        this.displayError();
        return;
      }

      this.$emit(
        'input',
        util.parseTransformedAddressToDeprecatedLocationFormat(details.result)
      );
    },
    async selectHome() {
      await this.selectCoordinate({
        lat: this.profile.lat,
        lng: this.profile.long,
      });
    },
    /**
     * @param {GMapsPrediction} prediction 
     */
    async select(prediction) {
      let placeDetails;

      if (!prediction) {
        this.$emit('input', null);
        return;
      }

      this.$emit('select');

      try {
        placeDetails = await mapsApi.placeDetails(prediction.place_id);

        if (!util.isValidAddress(placeDetails.result)) {
          throw new Error('Invalid address selected');
        }
      } catch (e) {
        this.$error(this.$t('c.searchSheet.invalidAddress'));
        this.$emit('error');
        return;
      }

      prediction = {
        ...prediction,
        ...placeDetails.result,
      };

      if (prediction.geometry && !prediction.description) {
        prediction.description = prediction.formatted_address;
      }

      this.$store.commit(namespacedUser.NEW_ADDRESS_PREDICTION, prediction);

      this.$emit('input', {
        ...util.parseTransformedAddressToDeprecatedLocationFormat(
          placeDetails.result
        ),
        structured_formatting: prediction.structured_formatting,
        description: prediction.description,
      });

      this.$nextTick(() => {
        this.clear();
      });
    },
    clear() {
      this.searchTerm = null;
      this.predictions = null;
    },
    dismiss() {
      this.clear();
    },
    displayError() {
      this.$emit('error');

      this.$error({
        title: this.$t('serverErrorTitle'),
        description: this.$t('serverErrorDescription'),
      });
    },
    closeKeyboard() {
      this.$refs?.searchInput?.$refs?.input?.blur();
    },
    focus() {
      this.$refs?.searchInput?.$refs?.input?.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/styleguide.scss';

.predict-item {
  @extend .feedback;
}

.powered-by-google {
  position: absolute;
  bottom: 35px;
  right: 10px;
  height: 1rem;
}

.sheet {
  z-index: 99;
}

.recent-searches {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.distance {
  text-align: right;
}
</style>
