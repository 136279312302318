<script>
import { mapActions, mapState } from 'vuex';
import {
  namespace as userNamespace,
  namespacedTypes as userTypes,
} from '@/store/modules/user-types';
import {
  namespace as namespaceCommute,
  namespacedTypes as namespacedCommute,
} from '@/store/modules/commute-types';
export default {
  render() {
    return this.$slots.default;
  },
  data() {
    return {
      cancelling: false,
    };
  },
  methods: {
    ...mapActions({
      cancelCommute: userTypes.CANCEL_COMMUTE_TRIP,
    }),
    show(tripId, success, error) {
      if (this.cancelling) {
        return;
      }

      this.tripId = tripId;

      this.cancelling = true;

      this.$modal.show('dialog', {
        title: this.$t('dialog.cancelDriverTrip.title'),
        text: this.$t('dialog.cancelDriverTrip.description'),
        success: {
          text: this.$t('dialog.cancelDriverTrip.successButton'),
          handler: async () => {
            try {
              await this.cancelCommute(tripId);
            } catch (e) {
              // Exception handled by global error handler.
              // But we need to show the error message to the user.
              error();
              return;
            } finally {
              this.cancelling = false;
            }

            setTimeout(() => {
              this.$store.commit(
                namespacedCommute.OVERVIEW_DRIVER_TRIP_REMOVED,
                tripId
              );
            }, 0);

            success();
          },
        },
        cancelButton: {
          handler: () => {
            this.cancelling = false;
          },
        },
        color: 'error',
        cancel: true,
      });
    },
  },
};
</script>
