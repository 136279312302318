<template>
  <n-fixed-sheet>
    <n-grid :top-gap="2" :bottom-gap="1">
      <n-column class="text-center" :span="6">
        <n-text preset="title" color="grey-dark">
          {{ $t('updateAvailableTitle') }}
        </n-text>
      </n-column>
    </n-grid>

    <n-grid :top-gap="1" :bottom-gap="3">
      <n-column class="text-center" :span="6">
        <n-text preset="body" color="grey-dark">
          {{ $t('updateAvailableBody') }}
        </n-text>
      </n-column>
    </n-grid>

    <n-grid :bottom-gap="2" :top-gap="3">
      <n-column :span="6">
        <n-button @click="update" size="lg" color="default">
          {{ $t('updateAvailableButton') }}
        </n-button>
      </n-column>
    </n-grid>
  </n-fixed-sheet>
</template>

<script>
import { mapState } from 'vuex';
import { SWHelper } from '@/serviceWorkerHelper';

export default {
  name: 'mainStartUpdateSheet',
  computed: {
    ...mapState('app', ['worker']),
  },
  methods: {
    async update() {
      await SWHelper.skipWaiting();
    },
  },
}
</script>
