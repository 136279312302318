<template>
<div class="view-container">
  <n-center-layout>
    <n-grid :row-gap="8">
      <div class="span-6 logo-wrapper">
        <svg-icon class="logo" :icon="`ng-logo-nabogo`"></svg-icon>
      </div>

      <n-column :span="6">
        <n-grid :row-gap="4" :bottom-gap="2">
          <n-text
            preset="header"
            align="center"
            v-text="$t('maintenance.header')"
            class="span-6"
          ></n-text>

          <n-text
            preset="body"
            align="center"
            color="grey-dark"
            v-text="$tc('maintenance.info', timer)"
            class="span-6"
          ></n-text>
        </n-grid>
      </n-column>

      <n-button
        size="lg"
        block
        @click="triggerRefresh"
        :loading="refreshing"
      >
        {{ $t('maintenance.refresh') }}
      </n-button>
    </n-grid>
  </n-center-layout>
</div>
</template>

<script>
const DEFAULT_COUNTDOWN = 60;

import utilsApi from '@/api/utils';

export default {
  data() {
    return {
      timer: DEFAULT_COUNTDOWN,
      timeout: null,
      refreshing: false,
    };
  },
  watch: {
    timer: {
      handler(value) {
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }

        if (value > 0) {
          this.timeout = setTimeout(() => {
            this.timer -= 1;
          }, 1000);
        } else {
          this.triggerRefresh();
        }
      },
      immediate: true,
    }
  },
  computed: {
    
  },
  methods: {
    resetTimer() {
      this.timer = DEFAULT_COUNTDOWN;
    },
    triggerRefresh() {
      if (this.refreshing) {
        return;
      }

      this.refreshing = true;

      utilsApi.ping()
        .then((resp) => {
          if (resp && resp.pong) {
            this.$success(this.$t('maintenance.success'));
            this.$router.replace('/');
          }
        })
        .catch((e) => {
          this.resetTimer();
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    display: flex;
    justify-content: center;

    .logo {
      width: 70vw;
      margin-bottom: 2rem;
    }
  }
</style>