/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_commute-passenger': {
    width: 30,
    height: 30,
    viewBox: '0 0 24 24',
    data: '<path d="M11.95,2c1.11,0,2,.9,2,2s-.89,2-2,2-2-.9-2-2,.9-2,2-2m.39,12.79c1.64,0,3.07,.1,4.25,.25,.06-2.72-.18-5.12-.64-6.04-.13-.27-.31-.5-.5-.7L7.38,15.22c1.36-.22,3.07-.43,4.96-.43m-4.93,2.21c.13,1.74,.39,3.5,.81,5h2.07c-.29-.88-.5-1.91-.66-3,0,0,2.32-.44,4.64,0-.16,1.09-.37,2.12-.66,3h2.07c.44-1.55,.7-3.39,.83-5.21-1.15-.14-2.56-.25-4.17-.25-1.93,0-3.61,.21-4.93,.46M11.95,7s-3,0-4,2c-.34,.68-.56,2.15-.63,3.96l6.55-5.62c-.99-.34-1.92-.34-1.92-.34m6.57-1.33l-1.14-1.33-3.51,3.01c.55,.19,1.13,.49,1.58,.95l3.07-2.63m2.1,10.16c-.09-.03-1.53-.5-4.03-.79-.01,.57-.04,1.16-.08,1.75,2.25,.28,3.54,.71,3.56,.71l.55-1.67m-13.3-2.87l-3.94,3.38,.89,1.48s1.18-.46,3.14-.82c-.11-1.41-.14-2.8-.09-4.04Z"/>'
  }
})
