/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_chevron-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 35 arrow-forward"><path pid="0" data-name="Path 2238" d="M17 12L7 22l10-10L7 2z"/></g>'
  }
})
