<template>
  <div class="column" :class="{ellipsis, center}" :style="`grid-column: ${span} span`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    span: {
      type: Number,
      default: 1,
      validator: (num) => 1 <= num && num <= 6,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
