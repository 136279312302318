<template>
  <div class="top-screen" :class="{ 'safe-space': !disableSafeSpacing }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disableSafeSpacing: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.top-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.safe-space {
    margin-top: var(--edge-padding-top);
  }
}
</style>
