<template>
  <div class="view-container">
    <n-nav-bar
      v-if="participant.is_deleted"
      :title="$t('chat.deleted-user.name')"
    />
    <n-nav-bar
      v-else
      :title="participant.name ?? 'loading'"
    >
      <template slot="title">
        <n-grid
          v-if="!loading"
          :top-gap="2"
        >
          <profile-picture
            :src="participant.image"
            @click="openProfile"
          />
          <n-column :span="4">
            <div>{{ participant.name }}</div>
            <rating :stars="participant.ratings_average" />
          </n-column>
        </n-grid>
      </template>
    </n-nav-bar>

    <n-full-screen v-if="loading">
      <n-spinner class="span-6 flex-center" />
    </n-full-screen>
    <n-scrollable
      v-else
      ref="scrollable"
    >
      <n-layout>
        <div
          v-for="message in messages"
          :key="message.id"
          class="message span-6"
          :class="{ me: isSenderCurrentUser(message) }"
        >
          <n-text
            class="time"
            preset="label-3"
            color="grey-dark"
          >
            {{ dateFormat(message.created_at) }}
          </n-text>
          <n-text class="text">
            {{ message.content }}
          </n-text>
        </div>
      </n-layout>
    </n-scrollable>
    <div
      class="input-container shadow-reverse"
      :spacing-y="false"
      :spacing-x="false"
    >
      <n-textarea
        v-model="messageContent"
        class="textbox"
        :rows="1"
        :disabled="participant.is_deleted"
        :placeholder="textareaPlaceholder"
        :max-height="80"
      />
      <div
        class="feedback send-button"
        @click="send"
      >
        <n-spinner v-if="sending" />
        <n-icon
          v-else
          name="send"
          :color="sendButtonColor"
          size="lg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/vendor/events';
import userApi from '@/api/user';
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';
import { formatRelative } from '@/vendor/date-fns';
import { mapState } from 'vuex';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  components: { ProfilePicture, Rating },
  props: {
    userId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      messageContent: '',
      participant: {},
      sending: false,
      loading: true,
    };
  },
  computed: {
    ...mapState('user', {
      currentUser: 'profile',
    }),
    sendButtonColor() {
      return this.participant.is_deleted
        ? 'grey'
        : 'accent';
    },
    textareaPlaceholder() {
      return this.participant.is_deleted
        ? this.$t('chat.text-placeholder.disabled')
        : this.$t('chat.text-placeholder.active');
    }
  },
  created() {
    this.fetchMessages();
  },
  methods: {
    async fetchMessages() {
      try {
        const { messages, participant } = await userApi.getUserConversation(
          this.userId
        );
        this.messages = messages;
        this.participant = participant;
      } finally {
        this.loading = false;
        this.markConversationAsRead(this.participant.id);
        this.scrollToBottom();
      }
    },
    async send() {
      if (
        this.participant.is_deleted ||
        this.messageContent.trim() === '' ||
        this.sending
      ) {
        return;
      }

      this.sending = true;
      try {
        const message = await userApi.newConversationMessage(
          this.messageContent,
          this.userId
        );
        this.messages.push(message);
        this.messageContent = '';
      } catch (error) {
        this.$error();
      } finally {
        this.scrollToBottom();
        this.sending = false;
      }
    },
    dateFormat(date) {
      return formatRelative(new Date(date), new Date());
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.$refs.scrollable) {
          return;
        }

        const el = this.$refs.scrollable.$el;
        el.scrollTop = el.scrollHeight;
      });
    },
    markConversationAsRead(participantId) {
      this.$store.dispatch(userTypes.READ_NOTIFICATIONS, {
        type: 'userConversation',
        reference: participantId,
      });
    },
    openProfile() {
      EventBus.$emit('open-profile', this.participant.id);
    },
    isSenderCurrentUser(message) {
      return message.sender_id === this.currentUser.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: column;

  &.me {
    align-items: flex-end;

    .text {
      background: var(--color-accent);
      color: var(--color-white);
    }
  }

  .time {
    width: 80%;
    text-align: center;
  }

  .text {
    background: var(--color-grey-light);
    padding: 0.85em 1.2rem;
    border-radius: 34px;
    width: 80%;
  }
}

.profile-seperator {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.input-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-top: 1px solid var(--color-grey-light);
}

.textbox {
  margin: 0.75rem 0 0.75rem 1rem;
}

.send-button {
  margin: 0.75rem;
  display: flex;

  .icon-wrapper {
    align-items: flex-end;
    margin-bottom: 4px;
  }

  & > div {
    width: 1.75rem;
  }
}
</style>
