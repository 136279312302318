<template>
  <div class="avatar-with-icon">
    <div class="avatar">
      <img
        :src="avatarSrc"
        alt="User avatar"
      >
    </div>
    <div class="icon">
      <n-icon
        :name="iconName"
        v-bind="iconProps"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarWithIcon',
  props: {
    avatarSrc: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    iconProps: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.avatar-with-icon {
  position: relative;
  display: inline-block;
}

.avatar {
  position: relative;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  padding:4px;
  border-radius:50%;
  background-color: #C3FFE2;
}
</style>
