/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_car': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Icon awesome-car"><path pid="0" data-name="Icon awesome-car-2" d="M21.54 9H19.2l-.65-1.55a4.36 4.36 0 00-4.07-2.62h-5a4.36 4.36 0 00-4.03 2.65L4.8 9H2.47a.45.45 0 00-.47.46.37.37 0 000 .11l.23.9a.48.48 0 00.46.34h.78a2.31 2.31 0 00-.85 1.78v1.79a2.26 2.26 0 00.62 1.56v2a1.22 1.22 0 001.26 1.2h1.25A1.21 1.21 0 007 18v-1.24h10V18a1.21 1.21 0 001.25 1.19h1.25A1.22 1.22 0 0020.76 18v-2a2.34 2.34 0 00.63-1.56V12.6a2.35 2.35 0 00-.86-1.79h.78a.46.46 0 00.46-.34l.22-.9a.45.45 0 00-.35-.57zM7.78 8.36a1.87 1.87 0 011.74-1.12h5a1.87 1.87 0 011.74 1.12l.74 1.86H7zm-2 6A1.19 1.19 0 015.6 12h.15a2.36 2.36 0 011.87 1.78c0 .72-1.12.6-1.87.6zm12.5 0c-.75 0-1.87.12-1.87-.6A2.36 2.36 0 0118.25 12a1.19 1.19 0 01.15 2.38z"/></g>'
  }
})
