<script>
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';

export default {
  render() {
    return this.$slots.default;
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
  },
  methods: {
    show() {
      const availibleForPayout =  this.profile.balance_available - this.profile.credit_available;

      this.$modal.show('dialog', {
        title: this.$t('dialog.accountPayout.title'),
        text: `${this.$t('dialog.accountPayout.amount')}: ${this.$n(availibleForPayout, 'currency', this.profile.currency)} \n\n${this.$t('dialog.accountPayout.description')}`,
        success: {
          text: this.$t('dialog.accountPayout.successButton'),
          handler: () => {
            this.$emit('payout');
          },
        },
        closable: true,
        cancel: true,
      });
    },
  },
};
</script>
