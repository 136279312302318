<template>
  <a :href="$t('landingSignUp.downloadLink')">
    <div>
      <img
        v-if="store === 'google'"
        alt="Get it on Google Play"
        src="@/assets/img/play-store-badge.png"
      />

      <img
        v-else-if="store === 'apple'"
        alt="Get it on App store"
        src="@/assets/img/app-store-badge.png"
      />
    </div>
  </a>
</template>

<script>
export default {
  name: 'storeLink',
  props: {
    store: {
      type: String,
      required: true,
      validator: (store) => ['apple', 'google'].includes(store),
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  height: 50px;
}
</style>