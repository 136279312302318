/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g id="Ico_21_cross" data-name="Ico 21 cross"><path id="Path_2235" data-name="Path 2235" d="M12,12,22,22ZM12,12,2,22ZM2,2,12,12ZM12,12,22,2Z" -width="1.5"/></g>'
  }
})
