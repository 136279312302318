<template>
  <n-grid>
    <n-text preset="sub" class="span-6" color="warning">{{
      $t('tripDetails.zeroSubsidyArea.note', {
        name,
      })
    }}</n-text>
  </n-grid>
</template>

<script>
export default {
  name: 'blockedArea',
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return this.contract?.public_area_name || this.$t('tripDetails.zeroSubsidyArea.fallbackName');
    },
  },
};
</script>