var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrap",staticClass:"sheet-wrapper",class:[_vm.pointerEvents && _vm.isOpen ? 'pointer-events' : ''],on:{"mousemove":_vm.dragMoving,"touchmove":_vm.dragMoving,"mouseUp":_vm.dragFinish,"touchend":_vm.dragFinish,"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.dismiss.apply(null, arguments)}}},[_c('n-container',_vm._g({directives:[{name:"swipe",rawName:"v-swipe:up",value:(_vm.goUp),expression:"goUp",arg:"up"},{name:"swipe",rawName:"v-swipe:down",value:(_vm.goDown),expression:"goDown",arg:"down"},{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"sheet",staticClass:"sheet",class:[`type-${_vm.type}`]},_vm.dragBody ? {
        mousedown: _vm.dragStart,
        touchstart: _vm.dragStart
      } : {}),[_c('div',_vm._g({class:[_vm.type === 'header' ? 'header' : 'handlebar']},!_vm.dragBody ? {
          mousedown: _vm.dragStart,
          touchstart: _vm.dragStart
        } : {}),[(_vm.type === 'header')?_c('n-layout',{attrs:{"spacing-y":false}},[_c('n-icon',{attrs:{"name":"chevron-down","outline":"","color":"white"},on:{"click":_vm.dismiss}}),_c('n-column',{attrs:{"span":5}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(_vm._s(_vm.title))])],1)],1):_c('n-layout',{attrs:{"spacing-y":false,"spacing-x":false}},[_c('div',{staticClass:"handlebar-lever"})])],1),(_vm.hasTopSlot)?_c('n-layout',{staticClass:"top",attrs:{"spacing-y":false}},[_vm._t("top")],2):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"scroll",class:{'disabled' : !_vm.scrollable, 'pad' : _vm.padBottom || _vm.padBottomExtra, 'extra': _vm.padBottomExtra},on:{"scroll":_vm.onScroll}},[_c('n-layout',{class:{'fillHeight' : _vm.fillHeight},attrs:{"spacing-x":!_vm.noLayout,"spacing-y":!_vm.noLayout}},[_vm._t("default")],2)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading-container"},[_c('n-spinner',{attrs:{"color":"accent"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }