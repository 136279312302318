import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist/dist/umd';
import user from './modules/user';
import commute from './modules/commute';
import app from './modules/app';
import trip from './modules/trip';
import { reduceWithoutKeys } from '../vendor/utils';
import { sentryException, sentryMessage } from '@/vendor/sentry';

Vue.use(Vuex);

const excludedStateKeys = [
  'app.routerHistory',
  'app.locations',
  'app.worker',
  'app.isUpdateAvailable',
  'app.isNativeUpdateAvailable',
  'user.updating',
  'commute.marketplace.booking',
  'commute.editRecurring',
  'commute.editOffer',
  'commute.history',
];

function stateCounter(obj, depth = 2) {
  obj = JSON.parse(JSON.stringify(obj));
  let keysCount = 0;
  Object.keys(obj).forEach(el => {
    // Count array items
    if (Array.isArray(obj[el])) {
      const arrayCount = `Array count: ${obj[el].length}`;
      let elementKeys = '';
      // Count keys of array items
      if (obj[el].length > 0) {
        elementKeys = JSON.stringify(stateCounter(obj[el][0]));
      }
      obj[el] = `${arrayCount} ${elementKeys}`;
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      // Count keys & nested
      if (depth <= 0) {
        obj[el] = Object.keys(obj[el]).length;
      } else {
        obj[el] = stateCounter(obj[el], depth - 1);
      }
    } else {
      // Remove keys and sum
      delete obj[el];
      keysCount++;
    }
  });
  obj['Value keys'] = keysCount;
  return obj;
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => reduceWithoutKeys(state, excludedStateKeys),
  saveState: (key, state, storage) => {
    try {
      storage.setItem(key, JSON.stringify(state));
    } catch (e) {
      const countedState = stateCounter(state);
      if(e.name === 'QUOTA_EXCEEDED_ERR') {
        sentryMessage('Failed to save state to local storage', { countedState })
      } else {
        sentryException(e, { countedState })
      }
    }
  }
});

export default new Vuex.Store({
  modules: {
    user,
    commute,
    app,
    trip
  },
  plugins:
    window.location.href.indexOf('localhost') > 0
      ? [vuexLocal.plugin]
      : [vuexLocal.plugin]
});
