<template>
  <n-top-screen>
    <n-layout>
      <template v-if="showHelp">
        <n-column :span="4" class="left-aligned-button">
          <n-button inline inverted @click="showHowto">
            {{ $t('main.start.howTo') }}
          </n-button>
        </n-column>
      </template>
      <template v-else>
        <n-column :span="2">
          <n-theme type="driver">
            <n-button :loading="isOfferLoading" @click="$emit('offerCommute')" inverted size="sm" no-padding>
              {{ $t('main.start.offerCommute') }}
            </n-button>
          </n-theme>
        </n-column>
        <n-column :span="3">
          <n-theme type="passenger">
            <n-button @click="$emit('requestCommute')" inverted size="sm" no-padding>
              {{ $t('main.start.whereTo') }}
            </n-button>
          </n-theme>
        </n-column>
      </template>
    </n-layout>
    <n-button-menu
      v-if="isAuthenticated"
      :notice="$store.getters['user/hasUnread']"
      :has-active-vacation="!!$store.getters['user/activeVacation']" />
  </n-top-screen>
</template>

<script>
import { EventBus } from '@/vendor/events';
export default {
  name: 'mainStartNavigation',
  props: {
    hasActivities: {
      type: Boolean,
      default: false,
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    isOfferLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showHowto() {
      EventBus.$emit('show-howto');
    },
  },
  computed: {
    showHelp() {
      return !this.isAuthenticated || !this.hasActivities;
    },
  },
}
</script>

<style lang="scss" scoped>
.left-aligned-button {
  text-align: left;
}
</style>
