<template>
  <div class="layout" :class="`justify-${justify}`">
    <n-layout>
      <slot />
    </n-layout>
  </div>
</template>

<script>
export default {
  props: {
    justify: {
      type: String,
      default: 'center',
      validator: (justify) => ['center', 'start', 'end'].includes(justify),
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.layout.justify-start {
  justify-content: flex-start;
}

.layout.justify-center {
  justify-content: center;
}

.layout.justify-end {
  justify-content: flex-end;
}
</style>
