<template>
  <n-theme>
    <activity-wrapper
      ref="wrapper"
      icon="location-alt"
      :arrow-color="arrowColor"
      :clock="passengerTrip.planned_departure"
      :history="isHistory"
    >
      <template #status>
        <template v-if="!hasRatedTrip">
          <n-text :color="status.color">{{ status.text }}</n-text>
          <n-icon
            v-if="passengerTrip.is_driver_trip_auto_bookable"
            name="repeat"
            inline
            :color="passengerTrip.auto_booked ? 'accent' : 'grey-dark'"
            size="md"
          />
        </template>
      </template>
      <template #action>
        <n-action-sheet #default="{closeSheet}" v-if="!isHistory">
          <n-action-sheet-item v-if="passengerTrip.is_driver_trip_auto_bookable && autoBooked" icon="repeat" @click.native="closeSheet(deleteAuto)">
            {{ $t('overview.single.passenger.actionSheet.deleteAuto') }}
          </n-action-sheet-item>
          <n-action-sheet-item v-if="passengerTrip.is_driver_trip_auto_bookable && !autoBooked" icon="repeat" @click.native="closeSheet(createAuto)">
            {{ $t('overview.single.passenger.actionSheet.createAuto') }}
          </n-action-sheet-item>
          <n-action-sheet-item v-if="isStatus('AWAITING_APPROVAL')" icon="trash-can" @click.native="closeSheet(cancelTrip)">
            {{ $t('overview.single.passenger.actionSheet.cancelRequest') }}
          </n-action-sheet-item>
          <n-action-sheet-item v-if="isStatus('ACCEPTED') && !isOnDemand" icon="trash-can" @click.native="closeSheet(cancelBookedTrip)">
            {{ $t('overview.single.passenger.actionSheet.cancelAgreement') }}
          </n-action-sheet-item>
          <n-action-sheet-item icon="vacation" @click.native="closeSheet(() => $router.push({'name': 'vacation'}))">
            {{ $t('overview.actionSheet.vacationSettings') }}
          </n-action-sheet-item>
        </n-action-sheet>
        <cancel-booked-passenger-trip-dialog ref="cancelBookedDialog" />
        <cancel-passenger-trip-dialog ref="cancelTripDialog" />
      </template>
      <template #steps>
        <step v-for="(step, index) in steps" :key="index" :time="step.time" :address="step.address"></step>
      </template>
      <template #buttons>
        <template v-if="isHistory">
          <n-grid class="flex-v-center">
            <rating
              @input="rateTrip($event)"
              size="lg"
              class="span-4"
              :editable="!hasRatedTrip || !ratingIsLocked"
              :locked="ratingIsLocked"
              :stars="passengerTrip.driver_rating ? passengerTrip.driver_rating.rating : 0"
            />
            <profile-picture
              :src="passengerTrip.driver.image"
              @click="openProfile(passengerTrip.driver.id)"
            />
            <n-text class="flex-v-center span-2">{{passengerTrip.driver.name}}</n-text>
          </n-grid>
        </template>
        <template v-else-if="passengerTrip.status === 'ACCEPTED'">
          <n-button
            class="span-6"
            size="lg"
            type="outlined"
            @click="openTrip"
            inverted
          >{{ $t('overview.single.passenger.detailsButton') }}</n-button>
        </template>
        <n-button
          v-else
          size="lg"
          block
          :color="status.color"
          type="outlined"
          @click="openTrip"
          inverted
        >{{ $t('overview.single.passenger.detailsButton') }}</n-button>
      </template>

    </activity-wrapper>
  </n-theme>
</template>

<script>
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import { EventBus } from '@/vendor/events';
import Step from '@/components/shared/overview/parts/step';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';
import constants from '@/constants';
import { mapActions } from 'vuex';
import { bookingStatus } from '@/constants';
import CancelPassengerTripDialog from '@/dialogs/cancelPassengerTripDialog';
import CancelBookedPassengerTripDialog from '@/dialogs/cancelBookedPassengerTripDialog';
import i18n from '@/i18n/index';
import { format } from '@/vendor/date-fns';
import { differenceInMinutes } from 'date-fns';

export default {
  components: {
    ActivityWrapper,
    Step,
    Rating,
    ProfilePicture,
    CancelPassengerTripDialog,
    CancelBookedPassengerTripDialog,
  },
  props: {
    passengerTrip: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isRating: false,
    };
  },
  computed: {
    autoBooked() {
      return this.passengerTrip.auto_booked;
    },
    status() {
      if (this.isHistory) {
        return {
          text: this.$t('overview.single.passenger.rate'),
          color: 'accent',
        }
      }
      if (this.passengerTrip.status === 'AWAITING_APPROVAL') {
        return {
          text: this.$t('overview.single.passenger.awaiting'),
          color: 'warning'
        };
      }
      return {
        text: this.$t('overview.single.passenger.booked'),
        color: 'accent'
      };
    },
    minutesToDeparture() {
      return differenceInMinutes(new Date(this.passengerTrip.planned_departure), new Date());
    },
    hasRatedTrip() {
      return this.passengerTrip.driver_rating !== null;
    },
    ratingIsLocked() {
      return this.passengerTrip.driver_rating?.locked === true;
    },
    isHistory() {
      return this.passengerTrip.completed_at !== null;
    },
    isOnDemand() {
      return this.passengerTrip.prospect_id !== null;
    },
    arrowColor() {
      if (this.passengerTrip.status === constants.commuteBookingStatus.awaitingApproval) {
        return '#ef6a17';
      }
      if (this.passengerTrip.status === constants.commuteBookingStatus.accepted) {
        return '#009600';
      }
      return null;
    },
    steps() {
      // ready for expansion
      const steps = [];
      steps.push({
        time: format(this.passengerTrip.planned_departure, 'HH:mm'),
        address: this.passengerTrip.departure_stop.street,
      });
      steps.push({
        time: format(this.passengerTrip.arrival_time, 'HH:mm'),
        address: this.passengerTrip.arrival_stop.street,
      });
      return steps;
    },
  },
  methods: {
    ...mapActions({
      rateDriverTrip: namespacedCommute.RATE_DRIVER_IN_OVERVIEW,
    }),
    openTrip() {
      this.$router.push({
        name: 'trip-details-passenger',
        params: {
          passengerTripId: this.passengerTrip.id,
          driverTripId: this.passengerTrip.driver_trip_id,
        }
      })
    },
    rateTrip(stars) {
      if (this.isRating) {
        return;
      }
      this.isRating = true;

      this.rateDriverTrip({ passengerTripId: this.passengerTrip.id, rating: stars })
        .finally(() => {
          this.isRating = false;
        });
    },
    openProfile(id) {
      EventBus.$emit('open-profile', id);
    },
    async createAuto() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.autoPassenger.createDialog.title'),
        text: this.$t('tripDetails.autoPassenger.createDialog.description'),
        color: 'default',
        success: {
          text: this.$t('tripDetails.autoPassenger.createDialog.deleteButton'),
          handler: async () => {
            await this.$store
              .dispatch(namespacedCommute.CREATE_AUTO_PASSENGER, {
                driverTripId: this.passengerTrip.driver_trip_id,
                fromStop: this.passengerTrip.departure_stop_id,
                toStop: this.passengerTrip_arrival_stop_id,
              })
              .then((autoPassengerTrip) => {
                this.passengerTrip.auto_booked = autoPassengerTrip;
                this.$success(i18n.t('tripDetails.autoPassenger.snackbarCreate'));
              })
          },
        },
      });
    },
    async deleteAuto() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.autoPassenger.deleteDialog.title'),
        text: this.$t('tripDetails.autoPassenger.deleteDialog.description'),
        color: 'error',
        success: {
          text: this.$t('tripDetails.autoPassenger.deleteDialog.deleteButton'),
          handler: async () => {
            this.isBusy = true;
            await this.$store
              .dispatch(namespacedCommute.DELETE_AUTO_PASSENGER, this.autoBooked.id)
              .then(() => {
                this.passengerTrip.auto_booked = null;
                this.$success(i18n.t('tripDetails.autoPassenger.snackbarDelete'));
              })
          },
        },
      });
    },
    cancelBookedTrip() {
      this.$refs.cancelBookedDialog.show(
        this.passengerTrip.driver_trip_id,
        this.passengerTrip.id,
        () => {
          this.$success(this.$t('tripDetails.passenger.cancelBookedDialog.snackbar'));
        }
      );
    },
    cancelTrip() {
      this.$refs.cancelTripDialog.show(
        this.passengerTrip.driver_trip_id,
        this.passengerTrip.id,
        () => {
          this.$success(this.$t('tripDetails.passenger.cancelDialog.snackbar'));
        }
      );
    },
    isStatus(status) {
      if (!Object.keys(bookingStatus).includes(status)) {
        throw Error(`Status is not valid {status}'`);
      }

      return this.passengerTrip.status === status;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
</style>
