/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_linkedin-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 34 linked-in login"><path pid="0" d="M2 3.43A1.46 1.46 0 013.48 2h17A1.46 1.46 0 0122 3.43v17.14A1.46 1.46 0 0120.52 22h-17A1.46 1.46 0 012 20.57z" /><path pid="1" d="M8 18.74v-9H5v9zM6.52 8.48A1.57 1.57 0 008.22 7v-.08a1.55 1.55 0 00-1.55-1.56h-.13A1.58 1.58 0 004.85 6.8v.12a1.57 1.57 0 001.54 1.57h.13z" fill="#fff" /><path pid="2" d="M9.78 18.74h3v-5a1.8 1.8 0 01.1-.73 1.63 1.63 0 011.54-1.1c1.08 0 1.52.83 1.52 2v4.83h3v-5.17c0-2.78-1.49-4.07-3.46-4.07a3 3 0 00-2.72 1.5V9.71h-3v9z" fill="#fff"/></g>'
  }
})
