<template>
  <div class="screen" :class="{ 'overlay': overlay }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.screen {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}
</style>
