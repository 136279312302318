/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format-list-bulleted': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 5h14v2H7V5m0 8v-2h14v2H7M4 4.5c.83 0 1.5.67 1.5 1.5S4.83 7.5 4 7.5 2.5 6.83 2.5 6 3.17 4.5 4 4.5m0 6c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5M7 19v-2h14v2H7m-3-2.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z"/>'
  }
})
