/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_apple-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 28 Login Apple"><path pid="0" data-name="Icon awesome-apple" d="M17.64 12.57a4.19 4.19 0 012.24-3.79 4.81 4.81 0 00-3.78-2c-1.59-.13-3.32.92-4 .92a32.79 32.79 0 00-3.41-.88c-2.49 0-5.13 2-5.13 5.95a11.23 11.23 0 00.64 3.63c.61 1.65 2.68 5.67 4.8 5.6 1.12 0 1.91-.8 3.38-.8s2.15.79 3.41.79c2.17 0 4-3.68 4.58-5.32a4.44 4.44 0 01-2.73-4.1zm-2.52-7.33A4.23 4.23 0 0016.19 2a4.69 4.69 0 00-3 1.56A4.28 4.28 0 0012 6.77a3.79 3.79 0 003.12-1.53z"/></g>'
  }
})
