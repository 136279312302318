<template>
  <n-fixed-sheet>
    <n-grid :top-gap="2" :bottom-gap="1">
      <n-column class="text-center" :span="6">
        <n-text preset="title" color="grey-dark">
          {{ $t('nativeUpdateAvailableTitle') }}
        </n-text>
      </n-column>
    </n-grid>

    <n-grid :top-gap="1" :bottom-gap="3">
      <n-column class="text-center" :span="6">
        <n-text preset="body" color="grey-dark">
          {{ $t('nativeUpdateAvailableBody') }}
        </n-text>
      </n-column>
    </n-grid>

    <n-grid :bottom-gap="2" :top-gap="3">
      <n-column :span="6">
        <n-button @click="update" size="lg" color="default">
          {{ $t('nativeUpdateAvailableButton') }}
        </n-button>
      </n-column>
    </n-grid>
  </n-fixed-sheet>
</template>

<script>
import nativeBridge from '@/native-bridge';
export default {
  methods: {
    update() {
      nativeBridge.send.openURL(this.$t("landingSignUp.downloadLink"));
    },
  },
}
</script>
