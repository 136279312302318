<template>
  <n-full-screen v-if="!trip">
    <n-spinner class="flex-center span-6" />
  </n-full-screen>

  <n-full-screen v-else>
    <wrapper
      icon="person"
      theme="driver"
      :trip="trip"
      :loading="loading"
      :price="price"
      :title="title"
      :map-fun="mapData"
    >
      <template #topRight>
        <n-text
          v-if="unreadPassengersCanceled.length > 0"
          class="span-2"
          align="right"
          color="error"
        >
          {{
            $tc(
              'tripDetails.driver.canceledCount',
              unreadPassengersCanceled.length
            )
          }}
        </n-text>
        <n-text
          v-else-if="pendingCount > 0"
          color="warning"
          class="span-2"
          align="right"
        >
          {{ $tc('tripDetails.driver.pendingCount', pendingCount) }}
        </n-text>
      </template>
      <template #topRightInfo>
        {{ $tc('tripDetails.driver.seats', trip.seats) }}
      </template>
      <template #schedule>
        <vertical-schedule-stops
          class="span-6"
          :trip="trip"
        />
      </template>
      <template #custom>
        <n-layout :spacing-y="false">
          <n-grid
            :top-gap="5"
            class="span-6"
          >
            <div class="span-6 inline-items">
              <seats
                :accepted="bookedSeats"
                :waiting="0"
                :total="trip.seats"
              />
              <n-text
                color="accent-faded"
                preset="title"
              >
                {{
                  `${bookedSeats}/${trip.seats} ${$t(
                    'tripDetails.driver.bookedSeats'
                  )}`
                }}
              </n-text>
            </div>
          </n-grid>
        </n-layout>
        <passenger-list
          :items="trip.passenger_trips"
          :trip="trip"
          :refresh="refresh"
        />
        <n-layout>
          <n-button
            v-if="!trip.completed_at"
            size="lg"
            class="span-6"
            color="error"
            type="outlined"
            inverted
            :loading="isCancelling"
            @click="cancelTrip"
          >
            {{ $t('tripDetails.driver.cancel') }}
          </n-button>
          <n-button
            size="lg"
            block
            type="outlined"
            inverted
            @click="openBroadcastSheet"
          >
            {{
              $t('tripDetails.driver.sendToAll') }}
          </n-button>
        </n-layout>
      </template>
    </wrapper>

    <broadcast-message-to-passenger-sheet
      ref="broadcastMessageToPassengerSheet"
      :trip-id="driverTripId"
      :passengers="trip.passenger_trips"
    />
  </n-full-screen>
</template>
<script>
import store from '@/store';
import constants from '@/constants';
import { EventBus } from '@/vendor/events';
import { mapActions } from 'vuex';
import Wrapper from '@/screens/tripDetails/wrapper';
import { currencyCodeToSymbol } from '@/vendor/utils';
import Seats from '@/components/shared/overview/parts/seats';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import PassengerList from '@/components/shared/tripDetails/passengerList';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import VerticalScheduleStops from '@/components/shared/tripDetails/verticalScheduleStops';
import BroadcastMessageToPassengerSheet from '@/sheets/broadcastMessageToPassengerSheet';

export default {
  name: 'TripDetailsDriverTrip',
  components: { Wrapper, PassengerList, Seats, VerticalScheduleStops, BroadcastMessageToPassengerSheet },
  beforeRouteEnter(to, from, next) {
    if (
      to.params.driverTrip == null ||
      to.params.driverTrip.route == null ||
      to.params.refresh
    ) {
      store
        .dispatch(userTypes.FETCH_SINGLE_COMMUTE, to.params.driverTripId)
        .then((driverTrip) => {
          next((vm) => {
            vm.driverTrip = driverTrip;
          });
        })
        .catch(() => {
          next('/content-not-found');
        });
    } else {
      next((vm) => {
        vm.driverTrip = to.params.driverTrip;
      });
    }
  },
  beforeRouteLeave(from, to, next) {
    this.unreadPassengersCanceled.forEach((item) => {
      this.readNotifications({
        type: 'commute_booking',
        reference: item.id,
      });
    });

    next();
  },
  props: {
    driverTripId: {
      // Important naming, also comming from notification params, DriverTripStartReminder.php
      type: [Number, String],
      required: true,
    },
    scrollToPassengerTripId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      isCancelling: false,
      driverTrip: null,
    };
  },
  computed: {
    trip() {
      return this.driverTrip;
    },
    bookedSeats() {
      return this.trip.passenger_trips
        .filter((x) => x.status === 'ACCEPTED')
        .reduce((sum, passengerTrip) => sum + passengerTrip.seats, 0);
    },
    title() {
      return `${this.trip.to_street}, ${this.trip.to_zipcode} ${this.trip.to_city}`;
    },
    price() {
      const trips = this.trip.passenger_trips.filter(
        (x) => x.status === 'ACCEPTED' || x.status === 'AWAITING_APPROVAL'
      );
      const total = trips.reduce((a, b) => a + b.payout, 0);
      return {
        price: `${total} ${currencyCodeToSymbol(
          this.$store.state.user.profile.currency
        )}`,
      };
    },
    unreadPassengersCanceled() {
      return this.trip.passenger_trips.filter(
        (p) =>
          p.status === constants.commuteBookingStatus.canceled &&
          this.hasUnreadNotification(
            'NaboLift\\BookingPassengerCancelled',
            p.id
          )
      );
    },
    pendingCount() {
      return this.trip.passenger_trips.filter(
        (p) => p.status === constants.commuteBookingStatus.awaitingApproval
      );
    },
  },
  mounted() {
    this.loading = false;

    if (this.scrollToPassengerTripId) {
      this.scrollToPassengerTrip(this.scrollToPassengerTripId);
    }

    EventBus.$on('refresh-trip-details-page', this.refresh);
  },
  beforeUnmount() {
    EventBus.$off('refresh-trip-details-page', this.refresh);
  },
  methods: {
    ...mapActions({
      cancelCommute: userTypes.CANCEL_COMMUTE_TRIP,
      readNotifications: userTypes.READ_NOTIFICATIONS,
    }),
    refresh() {
      return store
        .dispatch(userTypes.FETCH_SINGLE_COMMUTE, this.driverTripId)
        .then((driverTrip) => {
          this.driverTrip = driverTrip;
        });
    },
    scrollToPassengerTrip(passengerTripId) {
      setTimeout(() => {
        this.$scrollTo(`#passengerTrip-${passengerTripId}`, 500, {
          container: '.scrollable',
        });
      }, 100);
    },
    mapData() {
      if (!this.trip.route) {
        return {};
      }
      return {
        routes: [
          {
            route: typeof this.trip.route === 'string'
              ? window.google.maps.geometry.encoding.decodePath(this.trip.route)
              : this.trip.route,
            theme: 'driver',
          },
        ],
        stops: [
          ...this.trip.driver_stops,
          { type: 'start', lat: this.trip.from_lat, lng: this.trip.from_lng },
          { type: 'end', lat: this.trip.to_lat, lng: this.trip.to_lng },
        ],
      };
    },
    hasUnreadNotification(type, id) {
      return this.$store.getters[userTypes.HAS_UNREAD_NOTIFICATION_FOR_NAME](
        type,
        id
      );
    },
    openBroadcastSheet() {
      this.$refs.broadcastMessageToPassengerSheet.openSheet();
    },
    cancelTrip() {
      if (this.isCancelling) {
        return;
      }

      this.$modal.show('dialog', {
        title: this.$t('tripDetails.driver.cancelDialog.title'),
        text: this.$t('tripDetails.driver.cancelDialog.description'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('tripDetails.driver.cancelDialog.successButton'),
          handler: async () => {
            this.isCancelling = true;

            try {
              await this.cancelCommute(this.trip.id);
            } catch (error) {
              if (error.response?.status !== 409) {
                throw error;
              }
            } finally {
              this.isCancelling = false;
            }

            this.$store.commit(
              namespacedCommute.OVERVIEW_DRIVER_TRIP_REMOVED,
              this.trip.id
            );

            this.$success(this.$t('tripDetails.driver.cancelDialog.snackbar'));

            this.$router.resetToHome();
          },
        },
      });
    },
  },
};
</script>
