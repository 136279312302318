<script>
import Icon from '@/components/core/typography/icon.vue';

export default {
  methods: {
    show(successHandler, cancelHandler) {

      this.$modal.show('dialog', {
          icon: {
            component: Icon,
            props: {
              name: 'car-left-shield',
              color: 'white',
              size: 'xxl',
            },
          },
          title: [
            { 
              text: "Create",
              color: '#093527',
            },
            {
              text: "return ride alerts",
              color: '#1A9F74',
            },
          ],
          text: "You’re ride alerts are set up. Would you like to create alerts for your return trips?",
          textPreset: 'sub',
          buttonsInline: true,
          success: {
            text: "Create return alert",
            fitContent: true,
            handler: () => successHandler(),
          },
          cancelButton: {
            text: "Later",
            fitContent: true,
            handler: () => cancelHandler(),
          },
          color: 'success',
          cancel: true,
          closeButton: true,
          clickOutside: false,
        });
    },
  },
  render() {
    return this.$slots.default
  },
}
</script>
