export const SWHelper = {
  async getRegistrations() {
    return (await navigator?.serviceWorker?.getRegistrations()) || [];
  },

  async update() {
    const registrations = await this.getRegistrations();
    registrations[0]?.update();
  },

  async getWaitingWorker() {
    const registrations = await this.getRegistrations();
    const registrationWithWaiting = registrations.find(reg => reg.waiting);
    return registrationWithWaiting?.waiting;
  },

  async skipWaiting() {
    const worker = await SWHelper.getWaitingWorker();

    if (!worker) {
      console.log('[SWHelper] skipWaiting: no waiting worker');

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }

    return worker?.postMessage({
      type: 'SKIP_WAITING'
    });
  },

  async prepareCachesForUpdate() {
    return (await SWHelper.getWaitingWorker())?.postMessage({
      type: 'PREPARE_CACHES_FOR_UPDATE'
    });
  }
};
// Used for debugging
window.SWHelper = SWHelper

export function scheduleSWUpdate() {
    const THIRTY_MINUTES = 60 * 30 * 1000;
    setTimeout(async () => {
      try {
        const response = await fetch('/service-worker.js', { cache: 'no-store', 'cache-control': 'no-cache' });
        if (response.status === 200) {
          await SWHelper.update();
        }
      } catch (error) {
        console.error('Error updating service worker:', error);
      } finally {
        scheduleSWUpdate();
      }
    }, THIRTY_MINUTES);
}
