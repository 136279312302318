<template>
  <div class="vertical-schedule">
    <div class="leg" v-for="(stop, index) in stops" :key="index">
      <div
        :class="`row ${stop.isOrigin ? 'origin' : ''} ${
          stop.isDestination ? 'destination' : ''
        }`"
      >
        <n-icon
          v-if="stop.isOrigin"
          name="location-alt"
          type="circle-inverse"
          class="line-icon align-start"
          color="accent"
        />
        <n-icon
          v-else-if="stop.isDestination"
          name="target-spot"
          type="circle-inverse"
          class="line-icon align-start"
          color="accent"
        />
        <n-icon
          v-else
          name="location-alt"
          size="md"
          type="circle"
          color="accent"
          class="line-icon align-start"
        />

        <n-grid class="info" :row-gap="0">
          <n-text preset="sub" color="grey-dark" class="time">{{
            formatTime(stop.planned_time)
          }}</n-text>
          <n-text
            preset="header"
            :color="stop.isOrigin || stop.isDestination ? undefined : 'accent'"
            class="span-5"
            >{{ stop.name || stop.street }}</n-text
          >
          <div />
          <n-text
            :color="
              stop.isOrigin || stop.isDestination ? 'grey-dark' : 'accent-faded'
            "
            class="span-5"
            >{{ subText(stop) }}</n-text
          >
        </n-grid>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants';
import { format, formatDistanceStrict } from '@/vendor/date-fns';

export default {
  name: '',
  components: {},
  props: {
    trip: {
      type: Object,
    },
    presetStops: {
      type: Array,
      required: false,
    },
  },
  computed: {
    origin() {
      return `${this.trip.from_zipcode} ${this.trip.from_city}`;
    },
    destination() {
      return `${this.trip.to_zipcode} ${this.trip.to_city}`;
    },
    stops() {
      if (this.presetStops) {
        return this.presetStops;
      }

      if (this.trip && this.trip.driver_stops) {
        return this.trip.driver_stops
          .sort((a, b) => {
            return a.time > b.time ? 1 : -1;
          })
          .map((stop) => {
            stop.isOrigin =
              stop.via_stop && this.trip.from_via_stop_id == stop.id;
            stop.isDestination =
              stop.via_stop && this.trip.to_via_stop_id == stop.id;

            return stop;
          });
      }

      return [];
    },
  },
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    subText(stop) {
      return `${stop.street}, ${stop.zipcode} ${stop.city}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.vertical-schedule {
  .leg {
    padding-left: 20px;
    .row {
      border-left: solid 2px;
      position: relative;
      padding-bottom: 10px;
      border-color: var(--color-accent);

      .line-icon {
        position: absolute;
        left: calc(-0.55 * var(--icon-md-size));
        top: -2px;
      }
      .first {
        border: solid var(--color-grey-dark) 2px;
        background: white;
        border-radius: 100%;
        position: absolute;
        left: calc(-0.5 * var(--icon-md-size));
        height: calc(var(--icon-md-size) - 3px);
        width: calc(var(--icon-md-size) - 3px);
        &.accent {
          border-color: var(--color-accent);
        }
      }
      .info {
        padding: 0 20px;
        .time {
          margin-top: 3px;
        }
      }
    }

    .destination {
      border-color: transparent;
      // .line-icon {
      //   position: absolute;
      //   left: calc(-0.5 * var(--icon-md-size));
      //   top: -30px;
      // }
    }
  }
}
</style>
