/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_vacation': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path d="M9,10.75H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18v-2H16v2H8v-2H6v2H5a2,2,0,0,0-2,2H3v14a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-14a2,2,0,0,0-2-2Zm0,16H5v-11H19Z"/>'
  }
})
