import store from '@/store';

export let pendingRoutingAction = null;
import { EventBus } from '@/vendor/events';

function handleRouterError(error) {
  console.warn('[router-implementation]', {error})
  pendingRoutingAction = null;
}

export function replaceRouterMethods(Router) {
  Router.prototype.originalReplace = Router.prototype.replace;

  Router.prototype.push = function push(route) {
    pendingRoutingAction = {
      type: 'push',
      data: { route }
    };

    this
      .originalReplace(route)
      .catch(handleRouterError);
  };

  Router.prototype.replace = function replace(route) {
    pendingRoutingAction = {
      type: 'replace',
      data: { route }
    };

    this
      .originalReplace(route)
      .catch(handleRouterError);
  };

  Router.prototype.go = function go(steps) {
    if (steps >= 0) {
      throw new Exception('Navigating forwards is not implemented');
    }

    const route =
      store.state.app.routerHistory.length > steps * -1
        ? store.state.app.routerHistory[
            store.state.app.routerHistory.length + steps - 1
          ]
        : null;

    if (route) {
      pendingRoutingAction = {
        type: 'go',
        data: { steps, route }
      };

      if (steps < 0) {
        route.params.back = true;
      }

      this
        .originalReplace(route)
        .catch(handleRouterError);
    } else { // If route not found, replace to home. Eg. direct to booking - then navigating back, to what?
      this.resetToHome();
    }
  };

  Router.prototype.back = function back() {
    this.go(-1);
  };

  Router.prototype.forward = function forward() {
    this.go(1);
  };

  /**
   * Custom routing method. Navigates back to the latest route in history,
   * which matches the route name specified as the routeName parameter.
   *
   * @param {string} routeName
   */
  Router.prototype.backToNearest = function backToNearest(routeName) {
    const steps = [...store.state.app.routerHistory]
      .reverse()
      .findIndex(route => route.name === routeName);

    // don't proceed if no route was found
    if (steps === -1) {
      throw new Error('Route did not exist in routing history!');
    }

    const route =
      store.state.app.routerHistory[
        store.state.app.routerHistory.length + -1 * steps - 1
      ];

    pendingRoutingAction = {
      type: 'go',
      data: { steps: steps * -1, route }
    };

    this
      .originalReplace(route)
      .catch(handleRouterError);
  };

  /**
   * Close an entire group of routes. Eg. all auth.
   * (Not finshed. Not splicing history in state)
   * @param {String} routePrefix
   */
  Router.prototype.closeGroup = function closeGroup(routePrefix) {
    const index = [...store.state.app.routerHistory]
      .findIndex(route => route.name.startsWith(routePrefix)) - 1;

    if (index === -1) {
      throw new Error(`No route in history/route before with prefix: ${routePrefix}`);
    }

    const route = store.state.app.routerHistory[index];

    pendingRoutingAction = {
      type: 'go',
      data: { steps: index * -1, route }
    };
    this
      .originalReplace(route)
      .catch(handleRouterError);
  };

  /**
   * Custom routing method. Empties the routing history and replaces to the
   * specified route, making it the root.
   *
   * @param {object} route
   */
  Router.prototype.resetTo = function resetTo(route) {
    pendingRoutingAction = {
      type: 'reset',
      data: { route }
    };

    this
      .originalReplace(route)
      .catch(handleRouterError);
  }

  /**
   * Custom routing method. Uses the resetTo method above, to navigate home.
   */
  Router.prototype.resetToHome = function resetToHome(tab = 0) {
    this.resetTo({
      name: 'main.start',
      params: { tab },
    });
  };
}

export function setRouterImplementationHooks(router) {
  router.beforeEach((to, from, next) => {
    const eventPath = '/events/';

    // enable navigation events, as triggered by the MobilePay url + others
    // example: emit a "router-event-payment-success" event, when navigating
    // to the "/events/payment-success" path in the app
    if (to.path.startsWith(eventPath)) {
      console.log('router.beforeEach: has event', to.path);
      const eventName = to.path.split('events/')[1] || '';

      console.log('router.beforeEach: has router history', store.state.app.routerHistory.length);
      if (store.state.app.routerHistory.length) {
        router.replace(
          store.state.app.routerHistory[
            store.state.app.routerHistory.length - 1
          ]
        );
      } else {
        console.log('router.beforeEach: reset to home', to.path);
        router.resetToHome();
      }
      //Give the app a chance to load before emitting the event
      return setTimeout(() =>{
        console.log('router.beforeEach: emit event router-event', `router-event-${eventName}`);
        return EventBus.$emit(`router-event-${eventName}`);
      }, 200);

    }

    next();
  });

  router.afterEach((to, from) => {
    // add initial route to history, to enable back navigation
    if (
      to &&
      store.state.app.routerHistory.length === 0 &&
      pendingRoutingAction === null
    ) {
      // routerHistory.push(to);
      store.commit('app/HISTORY_PUSH', to);
    }

    // push to history
    if (pendingRoutingAction && pendingRoutingAction.type === 'push') {
      // routerHistory.push(to);
      store.commit('app/HISTORY_PUSH', to);
      pendingRoutingAction = null;
    }

    // replace history
    if (pendingRoutingAction && pendingRoutingAction.type === 'replace') {
      // routerHistory[routerHistory.length - 1] = to;
      store.commit('app/HISTORY_REPLACE', to);
      pendingRoutingAction = null;
    }

    // go backwards
    if (pendingRoutingAction && pendingRoutingAction.type === 'go') {
      // routerHistory.splice(
      //   routerHistory.length + pendingRoutingAction.data.steps
      // );
      store.commit('app/HISTORY_BACK', pendingRoutingAction.data.steps);
      pendingRoutingAction = null;
    }

    if (pendingRoutingAction && pendingRoutingAction.type === 'reset') {
      store.commit('app/HISTORY_RESET', to);
      pendingRoutingAction = null;
    }
  });
}
