/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_globe': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 33 country"><path pid="0" data-name="Path 2125" d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm-1 17.93A8 8 0 014 12a7.75 7.75 0 01.21-1.79L9 15v1a2 2 0 002 2zm6.9-2.54A2 2 0 0016 16h-1v-3a1 1 0 00-1-1H8v-2h2a1 1 0 001-1V7h2a2 2 0 002-2v-.41a8 8 0 012.9 12.8z"/></g>'
  }
})
