/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_shield-warning': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path d="M13.5496 2.88039L7.10419 5.30872C5.61877 5.86414 4.4046 7.62081 4.4046 9.19664V18.7937C4.4046 20.3179 5.4121 22.32 6.63918 23.2371L12.1934 27.3833C14.0146 28.7525 17.0113 28.7525 18.8325 27.3833L24.3867 23.2371C25.6138 22.32 26.6213 20.3179 26.6213 18.7937V9.19664C26.6213 7.60789 25.4071 5.85122 23.9217 5.29581L17.4763 2.88039C16.3784 2.47997 14.6217 2.47997 13.5496 2.88039Z" fill="#FF9B00" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.5 10.06L16.276 16.822H14.624L14.386 10.06H16.5ZM15.492 20.098C15.1373 20.098 14.8433 19.9907 14.61 19.776C14.386 19.552 14.274 19.2767 14.274 18.95C14.274 18.6233 14.386 18.3527 14.61 18.138C14.8433 17.914 15.1373 17.802 15.492 17.802C15.8373 17.802 16.122 17.914 16.346 18.138C16.57 18.3527 16.682 18.6233 16.682 18.95C16.682 19.2767 16.57 19.552 16.346 19.776C16.122 19.9907 15.8373 20.098 15.492 20.098Z" fill="white"/>    '
  }
})


