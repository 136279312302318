/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-rhombus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.5 10.8l-8.3-8.3c-.7-.7-1.7-.7-2.4 0l-8.3 8.3c-.7.7-.7 1.7 0 2.4l8.3 8.3c.7.7 1.7.7 2.4 0l8.3-8.3c.6-.7.6-1.7 0-2.4m-9 6.2H11v-1.5h1.5V17m1.8-5.2c-.4.5-1.1.8-1.4 1.3-.3.4-.3.9-.3 1.4H11c0-.8 0-1.5.3-2s1-.8 1.4-1.1c1.2-1.1.9-2.7-.8-2.8-.8 0-1.5.7-1.5 1.5H9c0-1.7 1.3-3 3-3 2.7 0 3.9 2.7 2.3 4.7z"/>'
  }
})
