<template>
<div class="view-container">
  <n-nav-bar :title="$t('accountPayout.title')" />
  <n-scrollable>
    <n-layout>
      <balance-display />
      <n-seperator class="span-6" />

      <n-grid :bottom-gap="2" v-if="hasPendingPayouts">
        <n-text block preset="label-1" color="accent">
          {{ $t('accountPayout.pendingPayout') }}
        </n-text>

        <n-text block preset="body" color="grey-dark">
          {{ $t('accountPayout.pendingPayoutDescription') }}
        </n-text>
      </n-grid>

      <n-text block preset="label-1" color="accent">
        {{ $t('accountPayout.accountInformation') }}
      </n-text>

      <n-grid v-if="!hasBankInformation" :y-gap="2">
        <n-button @click="addBankInformation" block size="lg">
          {{ $t('accountPayout.addAccountInformation') }}
        </n-button>
      </n-grid>

      <n-grid v-else :y-gap="2">
        <n-input
          disabled
          :value="bankInformation"
          :placeholder="$t('accountPayout.accountInformationSheet.registrationNo')"
        />
        <n-button v-if="!hasPendingPayouts" @click="payout" :loading="payoutLoading" block size="lg">
          {{ $t('accountPayout.payout') }}
        </n-button>

        <n-button @click="changeBankInformation" block size="md" color="warning" type="outlined" inverted>
          {{ $t('accountPayout.changeAccountInformation') }}
        </n-button>
      </n-grid>

      <n-text block preset="label-1" color="accent">
        {{ $t('accountPayout.payouts') }}
      </n-text>

      <n-text block preset="body" color="grey-dark">
        {{ $t('accountPayout.payoutsDescription') }}
      </n-text>

      <n-text block preset="body" color="grey-dark">
        <n-ordered-list class="span-6">
          <li>{{ $t('accountPayout.payoutsPoint1') }}</li>
          <li>{{ $t('accountPayout.payoutsPoint2') }}</li>
          <li>{{ $t('accountPayout.payoutsPoint3') }}</li>
        </n-ordered-list>
      </n-text>
    </n-layout>

    <n-dialog />
    <account-payout-dialog ref="payoutDialog" @payout="payoutConfirm" />
  </n-scrollable>
  <account-information-sheet ref="accountInformationSheet" />
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AccountPayoutDialog from '@/dialogs/accountPayoutDialog';
import AccountInformationSheet from '@/sheets/accountInformationSheet';
import BalanceDisplay from '@/components/shared/account/balanceDisplay';
import { namespace as userNamespace } from '@/store/modules/user-types';
import { namespacedTypes as namespacedUser } from '@/store/modules/user-types';

export default {
  name: 'accountPayout',
  components: {
    BalanceDisplay,
    AccountPayoutDialog,
    AccountInformationSheet,
  },
  data() {
    return {
      payoutLoading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.reg_number = vm.$store.state.user.profile.reg_number;
      vm.account_number = vm.$store.state.user.profile.account_number;
    });
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    bankInformation() {
      const { bank_information_type, reg_number, account_number, iban_number } = this.profile;

      if (bank_information_type === 'IBAN') {
        return iban_number;
      }

      if (bank_information_type === 'REG_ACCOUNT') {
        return !reg_number ? null : `${reg_number} ${account_number}`;
      }

      return null;
    },
    hasBankInformation() {
      return this.bankInformation !== null;
    },
    hasPendingPayouts() {
      return this.profile.has_pending_payouts;
    },
  },
  methods: {
    ...mapActions({
      processPayout: namespacedUser.PROCESS_PAYOUT,
    }),
    addBankInformation() {
      this.$refs.accountInformationSheet.open();
    },
    changeBankInformation() {
      this.$refs.accountInformationSheet.open();
    },
    payout() {
      this.$refs.payoutDialog.show();
    },
    async payoutConfirm() {
      if (this.payoutLoading) {
        return;
      }

      this.payoutLoading = true;

      try {
        await this.processPayout();
      } catch (e) {
          const hasPendingPayoutTransactions = e.response?.data?.error == 'pending_payout_transactions';

          if (hasPendingPayoutTransactions) {
            this.$error(this.$t('dialog.accountPayout.pendingError'));
          }
      } finally {
        this.payoutLoading = false;
      }
    }
  },
};
</script>
