/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_person-walking': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" data-name="Path 344" d="M12.93 5.71a1.86 1.86 0 10-1.86-1.85 1.85 1.85 0 001.86 1.85zM9.49 8.88L6.88 22h2l1.67-7.44 2 1.86V22h1.85v-7l-2-1.85.56-2.79a6.81 6.81 0 005.12 2.32v-1.84a4.57 4.57 0 01-4-2.23l-.93-1.49a1.89 1.89 0 00-1.57-.93c-.29 0-.47.09-.75.09L6 8.33v4.37h1.81V9.53l1.68-.65"/>'
  }
})
