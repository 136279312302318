/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_verified': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path d="m8.9 21.5-1.7-2.9-3.3-.7.3-3.4L2 12l2.2-2.5-.3-3.4 3.3-.7 1.7-2.9L12 3.8l3.1-1.3 1.7 2.9 3.3.7-.3 3.4L22 12l-2.2 2.5.3 3.4-3.3.7-1.7 2.9-3.1-1.3-3.1 1.3zm.8-2.3 2.3-1 2.4 1 1.3-2.2 2.5-.6-.2-2.5 1.7-1.9-1.7-2 .2-2.5-2.6-.5-1.3-2.2-2.3 1-2.4-1L8.4 7l-2.5.5.2 2.5-1.7 2 1.7 1.9-.2 2.6 2.5.5 1.3 2.2zm1.3-4 5.1-5.1-1.3-1.3-3.8 3.8-2-1.9L7.8 12l3.2 3.2z"/>'
  }
})
