import formbricks from "@formbricks/js/app";
import store from '@/store';

let formbricksInitiated = false;
const formbricksBackdrops = [];
let formbricksInitQueue = [];

export const initFormbricks = async () => {
  if (typeof window === 'undefined' || formbricksInitiated) {
    return;
  }

  if (
    !process.env.VUE_APP_FORMBRICKS_ENVIRONMENT_ID ||
    !process.env.VUE_APP_FORMBRICKS_API_HOST
  ) {
    console.warn('No environment set up for formbricks');
    return;
  }

  const user = store.state.user.profile;
  if (!user.id) {
    console.warn('No user provided for formbricks');
    return;
  }

  // Ensure we don't have a formbricks session stored in localStorage
  if (localStorage.getItem('formbricks-js')) {
    localStorage.removeItem('formbricks-js');
  }

  const { id, language, country, vouchers, signupVoucher, municipalityId } = user;

  await formbricks.init({
    environmentId: process.env.VUE_APP_FORMBRICKS_ENVIRONMENT_ID,
    apiHost: process.env.VUE_APP_FORMBRICKS_API_HOST,
    userId: id + '',
    debug: process.env.NODE_ENV !== 'production',
    attributes: {
      language,
      country,
      vouchers: vouchers ?? '',
      signupVoucher: signupVoucher ?? '',
      municipalityId: municipalityId + ''
    },
  });

  formbricksInitiated = true;
  if (formbricksInitQueue.length > 0) {
    const queue = formbricksInitQueue.map( async (eventName) => await triggerFormbricksAction(eventName));

    await Promise.all(queue)
      .then(() => {
        formbricksInitQueue = [];
      });
  }
};

export function enqueueFormbricksInitEvent(event){
  if(formbricksInitiated) {
    triggerFormbricksAction(event);
    return;
  }

  formbricksInitQueue.push(event)
}

export const resetFormbricks = () => {
  if (typeof window === 'undefined') {
    return;
  }

  formbricks.logout();
  formbricksInitiated = false;
};
/**
 *
 * @param {string} eventName
 * @param {Object.<string|number, any>} attributes
 * @returns
 */
export const triggerFormbricksAction = async (eventName, attributes = {}) => {
  if (typeof window === 'undefined' || !formbricksInitiated) {
    console.warn('Formbricks has not been initiated');
    return;
  }

  overwriteFormbricksTriggerForBackdrop();

  // TODO: Once Formbricks has implemented survey attributes, we should send attributes in track() function.
  // We need to trigger the event ASAP, so we don't wait for the attribute API calls to finish.
  await formbricks.track(eventName);
};

const overwriteFormbricksTriggerForBackdrop = () => {
  const backdrop = document.querySelector('.formbricks-form > div > div.relative');
  const closeButton = document.querySelector('.formbricks-form button.text-close-button');

  // wait for the backdrop to be available
  if (!backdrop || !closeButton) {
    return setTimeout(() => {
      overwriteFormbricksTriggerForBackdrop();
    }, 250);
  }

  // avoid setting the event listener multiple times
  if (formbricksBackdrops.includes(backdrop)) {
    return;
  }

  formbricksBackdrops.push(backdrop);

  setTimeout(() => {
    backdrop.addEventListener('click', (e) => {
      if (e.target !== backdrop) {
        return;
      }
      
      closeButton.click();
    });
  }, 1000);
};
