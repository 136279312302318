/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_commute-driver': {
    width: 30,
    height: 30,
    viewBox: '0 0 24 24',
    data: '<path d="M13,19.92c1.8-.22,3.35-.97,4.65-2.27,1.3-1.3,2.05-2.85,2.27-4.65h-3c-.22,1-.68,1.84-1.38,2.54s-1.54,1.16-2.54,1.38v3m-3-11.92h4l3,3h2.92c-.25-1.95-1.13-3.62-2.65-5-1.51-1.34-3.27-2-5.27-2s-3.76,.66-5.27,2c-1.52,1.38-2.4,3.05-2.65,5h2.92l3-3m1,11.92v-3c-1-.22-1.84-.68-2.54-1.38s-1.16-1.54-1.38-2.54h-3c.22,1.77,.97,3.3,2.27,4.6,1.3,1.3,2.85,2.07,4.65,2.32M12,2c2.75,0,5.1,1,7.05,2.95,1.95,1.95,2.95,4.3,2.95,7.05s-1,5.1-2.95,7.05c-1.95,1.95-4.3,2.95-7.05,2.95s-5.1-1-7.05-2.95c-1.95-1.95-2.95-4.3-2.95-7.05s1-5.1,2.95-7.05c1.95-1.95,4.3-2.95,7.05-2.95Z"/>'
  }
})
