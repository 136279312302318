import { EchoClient } from '@/vendor/echo';
import store from '@/store';

const listeners = [];

const refreshOverview = () => {
  store.dispatch('commute/UPDATE_OVERVIEW', null, { root: true });
};

export const setupEchoListeners = () => {
  if (!store.state.user.profile.id) return;

  if (!listeners.includes("passengerTripRequests")) {
    EchoClient()
      .private(`users.${store.state.user.profile.id}`)
      .listen('PassengerJoinedTrip', refreshOverview)
      .listen('PassengerCancelledBooking', refreshOverview);

    listeners.push("passengerTripRequests")
  }
};
