<template>
  <n-grid class="span-6 card-outer">
    <n-grid
      class="card" 
      :row-gap="1"
    >
      <n-grid class="span-6">
        <div class="title">
          <div class="date">
            <n-text preset="header">
              {{ formatRelativeWithoutTime(trip.departure_time) }}
            </n-text>
          </div>
          <div
            v-if="price"
            :span="3"
            class="pice-container"
          >
            <n-text
              :striked="price.discounted_price != null"
              color="accent"
              preset="header"
              block
            >
              {{ price.price }}
            </n-text>
            <n-text
              v-if="price.discounted_price"
              color="accent"
              preset="header"
            >
              {{ price.discounted_price }}
            </n-text>
          </div>
        </div>
      </n-grid>
      <n-grid class="span-6 subtitle">
        <div class="line" />
        <div class="time">
          <n-text preset="body">
            {{ travelTimeFormatted }}
          </n-text>
        </div>
        <div class="duration">
          <n-text preset="body">
            {{ travelTimeTotal }}
          </n-text>
        </div>
      </n-grid>

      <n-grid
        class="span-6"
        :top-gap="3"
        :bottom-gap="3"
      >
        <vertical-schedule-trip
          class="span-6"
          :legs="trip.legs"
          :destination="toAddress"
        />
      </n-grid>


      <n-grid
        v-if="trip.driver"
        class="span-6"
        :bottom-gap="3"
      >
        <n-column :span="5">
          <n-grid class="span-5">
            <n-column
              :span="5"
              ellipsis
            >
              <n-text preset="body">
                {{ formatDriverName }}
              </n-text>
            </n-column>
          </n-grid>
          <n-grid class="span-5">
            <n-column :span="5">
              <rating
                :stars="trip.driver.ratings_average"
                :rated="true"
              />
            </n-column>
          </n-grid>
          <n-grid class="span-5 ">
            <n-column :span="5">
              <n-text
                preset="sub"
                class="rating"
                color="grey-dark"
              >
                {{ $t('marketplace.driverTripReviews',
                      {
                        ratings:
                          trip.driver.ratings_count,
                        carpools: trip.driver.stats.carpools_completed
                      }) }}
              </n-text>
            </n-column>
          </n-grid>
        </n-column>
        <n-column
          :span="1"
          class="profile-picture"
        >
          <profile-picture
            :src="trip.driver.image"
            @click="openProfile(trip.user_id)"
          />
        </n-column>
      </n-grid>
      <n-grid
        v-if="trip.provider"
        class="text-center"
      >
        <n-column
          :span="6"
          class="provider-logo text-center"
        >
          <img
            :src="providerLogo"
            :class="trip.provider.toLowerCase()"
          >
        </n-column>
      </n-grid>
      <n-button
        block
        size="lg"
        @click="$emit('select')"
      >
        {{ showButtonText }}
      </n-button>
    </n-grid>
  </n-grid>
</template>

<script>
import { formatRelativeWithoutTime, format } from '@/vendor/date-fns';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { EventBus } from '@/vendor/events';
import ProfilePicture from '@/components/shared/profilePicture';
import VerticalScheduleTrip from '@/components/shared/tripDetails/verticalScheduleTrip';
import {
  parseTripAddressToLocation,
  formatPrice
} from '@/vendor/utils';
import Rating from '@/components/shared/rating';
import constants from '@/constants';

export default {
  name: 'MainDemandMarketplaceTripCard',
  components: { ProfilePicture, Rating, VerticalScheduleTrip },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    seperator: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    providerLogo() {
      if (!this.trip.provider) {
        return;
      }

      return constants.publicTransportProviderLogos[this.trip.provider];
    },
    price() {
      if (!this.trip.price) {
        return null;
      }
      return formatPrice(this.trip);
    },
    departureTime() {
      return this.tripOrigin.datetime;
    },
    tripOrigin() {
      const relevantLeg = this.trip.legs
        .find(x => x.type !== 'WALK');

      if (!relevantLeg) {
        return this.trip.legs[0].origin;
      }

      return relevantLeg.origin;
    },
    arrivalTime() {
      return this.tripDestination.datetime;
    },
    tripDestination() {
      const relevantLeg = [...this.trip.legs]
        .reverse()
        .find(x => x.type !== 'WALK');

      if (!relevantLeg) {
        return this.trip.legs[this.trip.legs.legnth - 1].destination;
      }

      return relevantLeg.destination;
    },
    shifts() {
      return this.trip.legs.filter(x => x.type !== 'WALK').length - 1;
    },
    showButtonText() {
      return this.trip.public ? this.$t('marketplace.showTrip') : this.$t('marketplace.bookTrip');
    },
    toAddress() {
      // Used to trick vue to update value after fetching details.
      // https://vuejs.org/v2/guide/reactivity.html#For-Objects
      this.loading;

      if (!this.trip.public && this.trip.legs && this.trip.legs.length > 0) {
        return parseTripAddressToLocation(
          this.trip.legs[this.trip.legs.length - 1].destination
        );
      }

      return this.$store.state.commute.tripDetails?.toAddress || {};
    },
    travelTimeFormatted() {
      return `${format(this.trip.departure_time, "HH:mm")} - ${format(this.trip.arrival_time, "HH:mm")}`;
    },
    travelTimeTotal() {
      const destinationEndDate = new Date(this.trip.legs[this.trip.legs.length - 1].destination.datetime);
      const originStartDate = new Date(this.trip.legs[0].origin.datetime);

      const hours = differenceInHours(destinationEndDate, originStartDate);
      const minutes = differenceInMinutes(destinationEndDate, originStartDate) % 60;

      return (hours > 0 ? this.$t('marketplace.travelTime.hoursShort', { hours }) : "") + (minutes > 0 ? ` ${this.$t('marketplace.travelTime.minutesShort', { minutes })}` : "");
    },
    formatDriverName() {
      if (!this.trip.driver) {
        return "";
      }
      return this.trip.driver.name + ' ' + this.trip.driver.last_name.charAt(0) + '.';
    }
  },
  methods: {
    formatRelativeWithoutTime,
    format,
    openProfile() {
      EventBus.$emit('open-profile', this.trip.driver.id);
    },
  }
}
</script>

<style lang="scss" scoped>
.card-outer {
  padding: 15px;
}

.card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px 25px;
  position: relative;


  .title {
  display:flex;
  width:100%;
  min-width:0;
  grid-column: 1 / -1;

    .date {
      flex-grow:1;

    }

    .price-container {
flex-shrink:1;
    }

    .price {
      color: var(--color-green);
    }
  }

  .price {
    color: var(--color-green);
  }

  .subtitle {
    display: flex;
    flex: 8;
    position: relative;
    height: 24px;
    color: var(--color-grey-dark);
    clear: both;

    .line {
      margin-top: 0.7em;
      border-top: 1px solid var(--color-grey);
      width: 100%;
      position: absolute;
    }

    .time {
      position: absolute;
      left: 0;
      background: #fff;
      padding-right: 5px;
    }

    .duration {
      position: absolute;
      right: 0;
      background: #fff;
      padding-left: 5px;
    }

  }

  .rating {
    color: var(--color-grey)
  }

  .profile-picture {
    justify-self: center;
    align-self: center;
  }

  .provider-logo {

    .trafiklab,
    .rejseplanen,
    .entur {
      height: auto;
      width: 150px;
    }

    .negentwee {
      height: auto;
      width: 50px;
    }
  }
}
</style>
