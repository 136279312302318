<template>
  <n-text v-if="readonly" preset="sub" color="grey-dark">
    <i18n path="c.terms.readonly">
      <template v-slot:termsLink><a class="link" :href="$t('c.terms.termsUrl')" target="_blank">{{$t('c.terms.termsLink')}}</a></template>
      <template v-slot:conditionsLink><a class="link" :href="$t('c.terms.conditionsUrl')" target="_blank">{{$t('c.terms.conditionsLink')}}</a></template>
    </i18n>
  </n-text>
  <n-switch v-else class="span-6" v-model="valueModel">
    <i18n path="c.terms.terms" tag="div" class="">
      <template v-slot:termsLink><a class="link" :href="$t('c.terms.termsUrl')" target="_blank">{{$t('c.terms.termsLink')}}</a></template>
      <template v-slot:conditionsLink><a class="link" :href="$t('c.terms.conditionsUrl')" target="_blank">{{$t('c.terms.conditionsLink')}}</a></template>
    </i18n>
  </n-switch>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueModel: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
  },
}
</script>
