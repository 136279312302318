/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_info': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.27 4.59A8.86 8.86 0 005.74 17.13 27.66 27.66 0 0012 22a26.22 26.22 0 006.27-4.87 8.94 8.94 0 000-12.54z" _fill="#b7b7b7"/><path pid="1" d="M13.75 15.46l-.16.66c-.48.19-.87.33-1.16.43a3 3 0 01-1 .15 2 2 0 01-1.37-.42 1.41 1.41 0 01-.49-1.09 4.91 4.91 0 010-.53c0-.17.07-.38.12-.6l.6-2.14c.06-.2.1-.4.14-.58a2.34 2.34 0 00.06-.5.76.76 0 00-.17-.57 1 1 0 00-.65-.16 1.59 1.59 0 00-.48.07 3.41 3.41 0 00-.42.14L9 9.66c.39-.16.77-.3 1.13-.42a3.63 3.63 0 011-.17 2 2 0 011.35.42 1.42 1.42 0 01.47 1.1v.49a5.23 5.23 0 01-.12.64l-.61 2.14a5.68 5.68 0 00-.13.58 3.09 3.09 0 00-.06.5.76.76 0 00.19.58 1.11 1.11 0 00.66.15 1.62 1.62 0 00.5-.08 2.68 2.68 0 00.37-.13zm.15-9.08a1.25 1.25 0 01-.42 1 1.52 1.52 0 01-2 0 1.28 1.28 0 010-1.9 1.49 1.49 0 012 0 1.25 1.25 0 01.42.9z" fill="#fff"/>'
  }
})
