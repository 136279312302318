<template>
  <div>
    <n-layout
      v-for="item in items"
      :key="item.id"
      :id="`passengerTrip-${item.id}`"
    >
      <n-grid class="span-6" :bottom-gap="3" :row-gap="0">
        <profile-summary :user="item.user" />

        <n-grid :top-gap="2">
          <n-text color="grey-dark">{{
            formatTime(item.departure_stop.planned_time)
          }}</n-text
          ><n-text color="accent-faded" class="span-5">{{
            item.departure_stop.name || item.departure_stop.street
          }}</n-text>
          <n-text color="grey-dark">{{
            formatTime(item.arrival_stop.planned_time)
          }}</n-text
          ><n-text color="accent-faded" class="span-5">{{
            item.arrival_stop.name || item.arrival_stop.street
          }}</n-text>
        </n-grid>
      </n-grid>

      <template v-if="item.status === 'AWAITING_APPROVAL'">
        <n-button
          @click="deny(item.id)"
          :loading="denyLoading === item.id"
          type="outlined"
          inverted
          color="error"
          >{{ $t('tripDetails.driver.deny') }}</n-button
        >
        <n-button
          @click="accept(item.id)"
          :loading="acceptLoading === item.id"
          color="success"
          >{{ $t('tripDetails.driver.accept') }}</n-button
        >
        <n-button @click="showDetails(item.id)" type="outlined" inverted>{{
          $t('tripDetails.driver.details')
        }}</n-button>

        <n-side-space v-if="acceptableWithoutPayment(item)">
          <n-button
            @click="acceptWithoutPay(item.id)"
            :loading="acceptWithLoading === item.id"
            type="outlined"
            inverted
            color="success"
            block
          >
            {{
              $t('tripDetails.driver.acceptWithSubsidyWithoutPay', {
                subsidy: getSubsidyFromPassengerTrip(item),
              })
            }}
          </n-button>

          <template slot="space">
            <n-help
              :text="
                $t('tripDetails.driver.helpIcon', {
                  subsidy: getSubsidyFromPassengerTrip(item),
                })
              "
            ></n-help>
          </template>
        </n-side-space>
      </template>
      <n-button
        v-else-if="item.status === 'CANCELED'"
        @click="showDetails(item.id)"
        block
        type="outlined"
        inverted
        color="error"
        >{{ $t('tripDetails.driver.showDetails') }}</n-button
      >
      <n-button
        v-else-if="item.status === 'ACCEPTED'"
        @click="showDetails(item.id)"
        block
        type="outlined"
        inverted
        color="default"
        >{{ $t('tripDetails.driver.showDetails') }}</n-button
      >
    </n-layout>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { format } from '@/vendor/date-fns';
import { currencyCodeToSymbol } from '@/vendor/utils';
import ProfileSummary from '@/components/shared/profileSummary';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  components: { ProfileSummary },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      denyLoading: null,
      acceptLoading: null,
      acceptWithLoading: null,
    };
  },
  computed: {
    isBusy() {
      return this.acceptLoading || this.acceptWithLoading || this.denyLoading;
    },
    tripWithCharge() {
      this.trip.charge_for_trip == true;
    },
  },
  methods: {
    ...mapActions({
      acceptWithoutIncomeBooking:
        userTypes.ACCEPT_WITHOUT_INCOME_COMMUTE_BOOKING,
      acceptBooking: userTypes.ACCEPT_COMMUTE_BOOKING,
      denyBooking: userTypes.DENY_COMMUTE_BOOKING,
    }),
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    getSubsidyFromPassengerTrip(passengerTrip) {
      return `${passengerTrip.subsidy} ${currencyCodeToSymbol(
        passengerTrip.subsidy_currency
      )}`;
    },
    acceptableWithoutPayment(passengerTrip) {
      return (
        passengerTrip.subsidy > 0 ||
        passengerTrip.subsidy_eligibility_verdict !== 'eligible'
      );
    },

    accept(id) {
      if (this.isBusy) {
        return;
      }

      this.acceptLoading = id;

      return this.acceptBooking({
        driverTripId: this.trip.Id,
        passengerTripId: id,
      })
        .then(() => {
          return this.refresh();
        })
        .then(() => {
          this.$success(this.$t('tripDetails.driver.acceptSnackbar'));
        })
        .finally(() => {
          this.acceptLoading = null;
          this.$forceUpdate();
        });
    },
    acceptWithoutPay(id) {
      if (this.isBusy) {
        return;
      }

      this.acceptWithLoading = id;

      return this.acceptWithoutIncomeBooking({
        driverTripId: this.trip.id,
        passengerTripId: id,
      })
        .then(() => {
          return this.refresh();
        })
        .then(() => {
          this.$success(this.$t('tripDetails.driver.acceptSnackbar'));
        })
        .finally(() => {
          this.acceptWithLoading = null;
          this.$forceUpdate();
        });
    },
    deny(id) {
      if (this.isBusy) {
        return;
      }

      this.denyLoading = id;

      return this.denyBooking({
        driverTripId: this.trip.id,
        passengerTripId: id,
      })
        .then(() => {
          return this.refresh();
        })
        .then(() => {
          this.$success(this.$t('tripDetails.driver.denySnackbar'));
        })
        .finally(() => {
          this.denyLoading = null;
          this.$forceUpdate();
        });
    },
    showDetails(id) {
      this.$router.push({
        name: 'trip-details-driver-passenger',
        params: { driverTripId: this.trip.id, passengerTripId: id },
      });
    },
  },
};
</script>
