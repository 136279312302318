import config from "@shared/config.json";

export const countryOptions = [
  {
    id: 'DK',
    text: 'Danmark'
  },
  {
    id: 'SE',
    text: 'Sverige'
  },
  {
    id: 'NL',
    text: 'Nederland'
  },
  {
    id: 'BE',
    text: 'België'
  },
  {
    id: 'NO',
    text: 'Norge'
  }
];

export function getCountryOption(countryAlpha2)
{
  return countryOptions.find(x => x.id === countryAlpha2);
}

export const languageOptions = [
  {
    id: 'DA',
    text: 'Dansk',
    defaultCountryId: 'DK',
    defaultPhoneCountryId: '+45'
  },
  {
    id: 'SV',
    text: 'Svenska',
    defaultCountryId: 'SE',
    defaultPhoneCountryId: '+46'
  },
  {
    id: 'EN',
    text: 'English',
    defaultCountryId: 'DK',
    defaultPhoneCountryId: '+45'
  },
  {
    id: 'NL',
    text: 'Nederlands',
    defaultCountryId: 'NL',
    defaultPhoneCountryId: '+31'
  },
  {
    id: 'NB',
    text: 'Norsk',
    alternates: ['NO', 'NN'],
    defaultCountryId: 'NO',
    defaultPhoneCountryId: '+47'
  }
];


export function getPhoneCountry(countryAlpha2)
{
  const item = phoneCountryOptions.find(x => x.countryId === countryAlpha2);

  return item || phoneCountryOptions.find(x => x.countryId === 'DK');
}

export const phoneCountryOptions = Object.entries(config.country_calling_codes).map(([key, value]) => ({
  id: value,
  text: value,
  countryId: key
}));

export const currencyOptions = [
  {
    id: 'DKK',
    text: 'DKK'
  },
  {
    id: 'SEK',
    text: 'SEK'
  },
  {
    id: 'EUR',
    text: 'EUR'
  },
  {
    id: 'NOK',
    text: 'NOK'
  }
];

export const currencyPaymentOptions = {
  DKK: [{ id: 'mobilepay', name: 'Mobilepay' }],
  SEK: [{ id: 'swish', name: 'Swish' }],
  EUR: [{ id: 'ideal', name: 'iDEAL' }],
  NOK: [{ id: 'vippspsp', name: 'Vipps' }]
};

export const currencyPaymentAmounts = {
  DKK: [50, 100, 250, 500, 1000],
  SEK: [50, 100, 250, 500, 1000],
  NOK: [50, 100, 250, 500, 1000],
  EUR: [10, 15, 25, 50, 100]
};
