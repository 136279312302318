/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-rhombus-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.5 10.8l-8.3-8.3c-.7-.7-1.7-.7-2.4 0l-8.3 8.3c-.7.7-.7 1.7 0 2.4l8.3 8.3c.7.7 1.7.7 2.4 0l8.3-8.3c.6-.7.6-1.7 0-2.4M20.3 12L12 20.3 3.7 12 12 3.7l8.3 8.3M11 15.5h1.5V17H11v-1.5m1-8.55c2.7.11 3.87 2.83 2.28 4.86-.42.5-1.09.83-1.43 1.26-.35.43-.35.93-.35 1.43H11c0-.85 0-1.56.35-2.06.33-.5 1-.8 1.42-1.13 1.23-1.13.91-2.72-.77-2.85-.82 0-1.5.67-1.5 1.51H9c0-1.67 1.35-3.02 3-3.02z"/>'
  }
})
