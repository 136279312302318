/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_checkmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 33 check"><path pid="0" data-name="Path 2163" d="M8.36 16.41l-4.75-4.74L2 13.27l6.36 6.35L22 6l-1.6-1.6z"/></g>'
  }
})
