import ax from 'axios';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n/index'
import { EventBus } from '@/vendor/events';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

const axios = ax.create({
  baseURL: process.env.VUE_APP_ROOT_API_URL,
  timeout: 20000,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

axios.interceptors.request.use(
  (request) => {
    request.headers.Accept = 'application/json';

    const { token } = store.state.user;

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(null, handleError);

export function handleError(error) {
  const { response, request } = error;
  error.handled = false;

  if (error.code === 'ECONNABORTED') {
    emitMessage('error', i18n.t('error.generic'));
  }

  // Unauthenticated. Log out!
  if (response?.status === 401 && response?.data?.message === 'Unauthenticated.' && !request?.responseURL.includes('logout')) {
    store.dispatch(userTypes.SIGN_OUT);
    router.replace('/');
    error.handled = true;
  }

  if (response?.status === 503) {
    router.replace({
      name: 'maintenance',
    });
    error.handled = true;
  }

  if (response?.status === 425 || response?.status === 429) {
    emitMessage('error', i18n.t('error.too_many_requests'));
    error.handled = true;
  }

  const errorTranslationKey = response?.data?.error;

  if (errorTranslationKey) {
    // Auto error to i18n
    if (i18n.te(`error.${errorTranslationKey}`)) {
      emitMessage('error', i18n.t(`error.${errorTranslationKey}`));
      error.handled = true;
    }
    else {
      emitMessage('error', i18n.t('error.generic'));
    }
  }

  return Promise.reject(error);
};

/**
 * Ignore handled errors
 * @param {Error} error
 */
export function ignoreHandledErrors(error) {
  if (error.handled) {
    return;
  }
  throw error;

}

function emitMessage(type, message) {
  EventBus.$emit(type, message);
}

EventBus.$on('unhandledResponseError', (message) => {
  handleError(message);
});

export default axios;
