/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_location-alt': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" data-name="Nabogo Map ikoner Mødested 84x84" d="M18.18 4.56A8.88 8.88 0 005.61 17.12 28 28 0 0011.89 22a26.62 26.62 0 006.29-4.88 8.81 8.81 0 00.27-12.53c-.05-.05-.1-.1-.27-.03zm-6.29 10.57a4.52 4.52 0 114.53-4.52 4.52 4.52 0 01-4.53 4.52z" _fill="#b7b7b7"/>'
  }
})
