<template>
  <n-layout v-if="!driverTrip || !passengerTrip">
    <n-spinner class="flex-center span-6" />
  </n-layout>
  <wrapper
    v-else
    icon="car"
    :title="title"
    theme="passenger"
    :trip="passengerTrip"
    :map-fun="mapData"
    :price="formatPrice(passengerTrip)"
  >
    <template #topRight>
      <n-text
        v-if="passengerTrip.status === 'AWAITING_APPROVAL'"
        preset="label-1"
        color="warning"
        class="span-2"
        align="right"
      >
        {{ $t('tripDetails.passenger.waiting') }}
      </n-text>
      <n-text
        v-else-if="passengerTrip.status === 'CANCELED'"
        color="error"
        class="span-2"
        align="right"
        preset="label-1"
      >
        {{ $t('tripDetails.passenger.canceled') }}
      </n-text>
    </template>
    <template #topRightInfo>
      <n-text preset="sub">
        {{ duration }}
      </n-text>
    </template>
    <template #schedule>
      <auto-passenger
        :is-loading="stateLoading"
        :is-auto-bookable="isAutoBookable"
        :auto-booked="autoBooked"
        :trip-id="driverTrip.id"
        :from_stop_id="passengerTrip.from_driver_stop_id"
        :to_stop_id="passengerTrip.to_driver_stop_id"
        :date-time="new Date(passengerTrip.departure_time)"
      />
      <vertical-schedule-stops
        class="span-6"
        :trip="passengerTrip"
        :preset-stops="stops"
      />
    </template>
    <template #custom>
      <n-layout>
        <profile-summary :user="driverTrip.user" />
        <n-button
          v-if="passengerTrip.status === 'ACCEPTED'"
          type="outlined"
          inverted
          size="lg"
          :class="[canCancel ? 'span-3' : 'span-6']"
          @click="sendMessage"
        >
          {{ $t('tripDetails.passenger.sendMessageButton') }}
        </n-button>
        <n-button
          v-if="canCancel"
          type="outlined"
          inverted
          color="error"
          size="lg"
          class="span-3"
          @click="cancelBooked"
        >
          {{ $t('tripDetails.passenger.cancelBookedButton') }}
        </n-button>
        <template v-if="passengerTrip.status === 'AWAITING_APPROVAL'">
          <n-button
            type="outlined"
            inverted
            color="error"
            size="lg"
            block
            @click="cancel"
          >
            {{ $t('tripDetails.passenger.cancelButton') }}
          </n-button>
        </template>

        <cancel-booked-passenger-trip-dialog ref="cancelBookedDialog" />
        <cancel-passenger-trip-dialog ref="cancelTripDialog" />
      </n-layout>
    </template>
  </wrapper>
</template>
<script>
import Wrapper from '@/screens/tripDetails/wrapper';
import { formatPrice } from '@/vendor/utils';
import VerticalScheduleStops from '@/components/shared/tripDetails/verticalScheduleStops';
import AutoPassenger from '@/components/shared/tripDetails/autoPassenger';
import { mapActions } from 'vuex';
import ProfileSummary from '@/components/shared/profileSummary';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { formatDistanceStrict } from '@/vendor/date-fns';
import CancelPassengerTripDialog from '@/dialogs/cancelPassengerTripDialog';
import CancelBookedPassengerTripDialog from '@/dialogs/cancelBookedPassengerTripDialog';
import store from '@/store';
import commuteApi from '@/api/commute';

export default {
  name: 'TripDetailsPassengerTrip',
  components: {
    Wrapper,
    VerticalScheduleStops,
    ProfileSummary,
    AutoPassenger,
    CancelPassengerTripDialog,
    CancelBookedPassengerTripDialog,
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.driverTrip == null) {
      store
        .dispatch(userTypes.FETCH_SINGLE_COMMUTE, to.params.driverTripId)
        .then(driverTrip => {
          to.params.driverTrip = driverTrip;
          next();
        })
        .catch(() => {
          next('/content-not-found');
        });
    } else {
      next();
    }
  },
  beforeRouteLeave(from, to, next) {
    this.readNotifications({
      type: 'commute_booking',
      reference: this.passengerTripId,
    });
    next();
  },
  props: {
    passengerTripId: {
      type: [Number, String],
      required: true,
    },
    driverTripId: {
      type: [Number, String],
      required: true,
    },
    driverTrip: {
      type: Object,
      required: true
    },
  },
  data: () => {
    return {
      acceptWithLoading: false,
      acceptLoading: false,
      stateLoading: true,
      isAutoBookable: false,
      autoBooked: null,
    };
  },
  computed: {
    passengerTrip() {
      const trip = this.driverTrip.passenger_trips.find(
        (x) => x.id == this.passengerTripId
      );
      if (!trip) {
        return null;
      }
      trip.planned_departure = trip.departure_stop.planned_time;
      trip.planned_arrival = trip.arrival_stop.planned_time;
      trip.from_zipcode = trip.departure_stop.zipcode;
      trip.from_city = trip.departure_stop.city;
      trip.from_street = trip.departure_stop.name || trip.departure_stop.street;
      trip.to_zipcode = trip.arrival_stop.zipcode;
      trip.to_city = trip.arrival_stop.city;
      trip.to_street = trip.arrival_stop.name || trip.arrival_stop.street;
      return trip;
    },
    stops() {
      return [
        {
          ...this.passengerTrip.departure_stop,
          planned_time: this.passengerTrip.departure_time,
          type: 'start',
          isOrigin: true
        },
        {
          ...this.passengerTrip.arrival_stop,
          planned_time: this.passengerTrip.arrival_time,
          type: 'end',
          isDestination: true
        },
      ];
    },
    title() {
      if (!this.driverTrip) {
        return '...';
      }

      return this.driverTrip.user.name;
    },
    duration() {
      return formatDistanceStrict(
        new Date(this.passengerTrip.departure_time),
        new Date(this.passengerTrip.arrival_time)
      );
    },
    canCancel() {
      return this.passengerTrip.status === 'ACCEPTED' && this.driverTrip.completed_at == null;
    },
  },
  created() {
    this.stateLoading = true;
      commuteApi
        .driverTripState(this.driverTripId)
        .then(({ is_auto_bookable, auto_booked }) => {
          this.isAutoBookable = is_auto_bookable;
          this.autoBooked = auto_booked;
          this.stateLoading = false;
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            return this.$router.push('/content-not-found');
          }
          throw error;
        }).finally(() => {
          this.stateLoading = false;
        });


  },
  methods: {
    formatPrice,
    ...mapActions({
      readNotifications: userTypes.READ_NOTIFICATIONS,
    }),
    mapData() {
      return {
        routes: [
          {
            route: this.passengerTrip.route,
            theme: 'passenger',
          },
        ],
        stops: this.stops,
      };
    },
    sendMessage() {
      this.$router.push({
        name: 'userConversation',
        params: { userId: this.driverTrip.user.id },
      });
    },
    cancelBooked() {
      this.$refs.cancelBookedDialog.show(
        this.driverTripId,
        this.passengerTripId,
        async () => {
          await this.$store.dispatch('commute/UPDATE_OVERVIEW', null, { root: true });

          this.$success(
            this.$t('tripDetails.passenger.cancelBookedDialog.snackbar')
          );

          this.$router.back();
        }
      );
    },
    cancel() {
      this.$refs.cancelTripDialog.show(
        this.driverTripId,
        this.passengerTripId,
        () => {
          this.$success(this.$t('tripDetails.passenger.cancelDialog.snackbar'));
          this.$router.back();
        }
      );
    },
  },
};
</script>
