/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_google-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 29 Login Google"><path pid="0" data-name="Icon awesome-google" d="M21.83 12.23A9.28 9.28 0 0113.05 22h-.9a10 10 0 010-20 9.61 9.61 0 016.71 2.62l-2.72 2.61C12.58 3.8 6 6.37 6 12a6.26 6.26 0 006.19 6.32A5.4 5.4 0 0017.83 14h-5.68v-3.44h9.52a8.51 8.51 0 01.16 1.67z"/></g>'
  }
})
