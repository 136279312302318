<template>
  <div class="search-input-container">
    <a v-on="$listeners" v-bind="$attrs" class="search-input">
      <div v-if="leftIcon" class="icon-container left-icon">
        <n-icon :name="leftIcon" />
      </div>

      <n-text v-if="label" class="label" preset="label-2">{{ label }}</n-text>

      <input ref="input" class="input" v-on="$listeners" v-bind="$attrs" @input="onInput($event.target.value)"  />

      <div v-if="clearable" class="icon-container right-icon">
        <n-icon v-if="value && value !== ''" @click="onClearClick" name="cross" size="sm" outline color="grey-darker" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
    prop: 'value',
  },
  props: {
    leftIcon: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    focus() {
      this.$refs?.input?.focus();
    },
    onInput(val) {
      this.value = val;
      this.$emit('update', val);
    },
    onClearClick() {
      this.onInput(null);
      this.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.search-input-container {
  grid-column: 1 / -1;

  .search-input {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background-color: var(--color-grey-light);
    border-radius: 9999px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-grey-light);
    color: var(--color-grey-dark);
    cursor: pointer;
    flex-direction: row;
    outline: none;
    overflow: hidden;
    padding: 0px 20px;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;

    .icon-container {
      height: 21px;
      width: 21px;

      &.right-icon {
        --icon-sm-size: 1.1rem;
      }
    }

    .left-icon {
      margin-right: 8px;
    }

    .label {
      margin-right: 0.35rem;
    }

    .input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      font-weight: 300;
      font-size: var(--text-lg-font-size);
      color: var(--color-grey-darker);

      &::placeholder {
        font-style: normal;
        color: var(--color-grey-darker);
        transition: color 0.25s ease-in-out;
      }

      &:focus::placeholder {
        color: var(--color-grey);
      }
    }

    svg {
      height: 100%;
      width: 100%;
      fill: var(--color-grey-dark);
    }
  }
}
</style>
