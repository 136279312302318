<template>
  <div
    class="wrapper span-6"
    @click="$refs.input.focus()"
  >
    <n-text
      v-if="label"
      preset="sub"
      class="color-grey-dark"
    >
      {{ label }}
    </n-text>
    <div :class="stateClass">
      <div class="input">
        <input
          ref="input"
          :value="value"
          :placeholder="placeholder"
          v-bind="$attrs"
          v-on="$listeners"
          @input="onInput($event.target.value)"
          @focus="focused = true"
          @blur="focused = false"
        />
        <svg-icon
          v-if="invalid"
          name="close"
          color="var(--color-red-500)"
          size="sm"
        />
        <svg-icon
          v-if="approved && !invalid"
          name="check"
          color="var(--color-green-500)"
          size="sm"
        />
        <n-icon
          v-if="!invalid && !approved && icon && !focused"
          :name="icon"
          size="sm"
        />
      </div>
    </div>
    <n-text
      v-if="(invalid || approved) && message"
      preset="sub"
      :class="[invalid ? 'message__error' : '', approved ? 'message__success' : '']"
    >
      {{ message }}
    </n-text>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
    prop: 'value'
  },
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number]
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    approved: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    stateClass() {
      if (this.invalid) {
        return 'inner-wrapper__error';
      }
      if (this.approved) {
        return 'inner-wrapper__success';
      }
      if (this.focused) {
        return 'inner-wrapper__focused';
      }
      return 'inner-wrapper__default';
    }
  },
  methods: {
    onInput(val) {
      this.$emit('update', val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.wrapper {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  gap: 8px;

  %inner-wrapper {
    height: 50px;
    background-color: #FBFBFB;
    border: 1px solid #B7B3AA;
    border-radius: 10px;
  }

  .inner-wrapper__default {
    @extend %inner-wrapper;
  }

  .inner-wrapper__success {
    @extend %inner-wrapper;
    border: 1px solid var(--color-green-700);
    box-shadow: 0 0 0 3px var(--color-green-50);
  }

  .inner-wrapper__focused {
    @extend %inner-wrapper;
    border: 1px solid var(--color-grey-500);
    box-shadow: 0 0 0 3px #B7B7B74D;
  }

  .inner-wrapper__error {
    @extend %inner-wrapper;
    border: 1px solid var(--color-red-700);
    box-shadow: 0 0 0 3px var(--color-red-50);
  }


  .message {
    &__success {
      color: var(--color-green-700)
    }

    &__error {
      color: var(--color-red-700)
    }
  }
}

.input {
  display: flex;
  flex: 0 0 100%;
  padding: 15px;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 21px;
    fill: var(--color-grey-darker);
  }

  .label {
    color: var(--color-grey-dark);
  }

  input {
    height: 20px;
    border: none;
    background-color: #FBFBFB;
    padding: 0;
    color: var(--color-grey-darker);
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
    font-weight: 400;
    letter-spacing: var(--letter-spacing-sm);
    outline: none;
    width: 100%;

    &::placeholder {
      font-style: normal;
      color: var(--color-grey-dark);
    }

    &:disabled {
      background-color: transparent;
      color: var(--color-grey-dark);
    }
  }
}
</style>
