/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_send': {
    width: 24,
    height: 24,
    viewBox: '0 0 256 256',
    data: '<path d="M254.3 10a12.4 12.4 0 0 0-.1-1.5V8l-.5-1.3-.1-.4a8.2 8.2 0 0 0-4.3-4.3L249 2l-1.4-.4h-.3a8.3 8.3 0 0 0-2.8 0h-.3l-1.3.3L7 90.5a8.4 8.4 0 0 0-.7 15.5l96.8 46.7 46.7 96.8a8.4 8.4 0 0 0 15.5-.7l88.6-236 .3-1.3v-.3c.2-.4.2-.8.2-1.2zm-41.9 21.4L107.6 136.2 31.2 99.4l181.2-68zm-56 193-36.9-76.3L224.3 43.3l-68 181.2z" stroke-width="0" stroke-miterlimit="10"/>'
  }
})
