<template>
 <div
  class="toggle" :class="{'shadow': shadow}">
  <div class="items">
    <div @click="onClick(0)"><n-text preset="btn-3" :class="{'selected': this.state === 0}" >{{ textLeft }}</n-text></div>
    <div @click="onClick(1)"><n-text preset="btn-3" :class="{'selected': this.state === 1}">{{ textRight }}</n-text></div>
  </div>
  <div
    class="draggable"
    :class="{'right' : state > 0}"
    @mousedown.prevent="dragStart">
  </div>
</div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    textLeft: {
      type: String,
      required: true,
    },
    textRight: {
      type: String,
      required: true,
    },
    shadow: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    value() {
      this.state = this.value;
      this.toggle(this.state);
    },
  },
  data() {
    return {
      state: this.value,
    }
  },
  mounted() {
    this.toggle(this.state)
  },
  methods: {
    onClick(index) {
      this.toggle(index)
      this.emit()
    },
    toggle(state) {
      this.state = state
      this.position = state === 0
        ? 0
        : 100
    },
    emit() {
      this.$emit('input', this.state)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.toggle {
  position: relative;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  background: var(--color-grey-light);
  border-radius: 15px;
  padding: 2px;
  transition: background 0.8s;

  &.shadow {
    box-shadow: 0px 3px 6px var(--color-shadow);
  }

  .items {
    position: relative;
    display: flex;
    z-index: 10;
    align-items: center;
    height: 100%;
    div {
      width: 50%;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
    }
    span:not(.selected) {
      color: var(--color-accent);
    }
  }

  .draggable {
    width: 50%;
    height: 26px;
    position: absolute;
    z-index: 9;
    top: 2px;
    border-radius: 15px;
    background: var(--color-accent);
    transform: translateX(0%);
    transition: transform 0.15s ease-in-out;
  }

  .right {
    transform: translateX(calc(100% - 4px));
  }
}

</style>
