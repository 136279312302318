<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <!-- v-html bc of styled word in i18n -->
  <div>
    <n-top-safe-spacer class="howto-skip">
      <n-layout>
        <n-column :span="6">
          <n-button
            inline
            type="outlined"
            :inverted="isOnWhite"
            @click="reachedEnd"
          >
            {{ $t('howtoSlides.skip') }}
          </n-button>
        </n-column>
      </n-layout>
    </n-top-safe-spacer>
    <swiper-container 
      ref="swiper" 
      class="howto-swiper" 
      pagination="true"
      navigation="false" 
      @swiperslidechange="onSlideChange"
    >
      <swiper-slide class="slide slide-1">
        <div class="view-container">
          <n-scrollable>
            <n-layout>
              <n-text
                class="span-6"
                preset="hero"
                v-html="$t('howtoSlides.one.title')"
              />
              <n-text
                class="span-6"
                preset="sub"
                v-html="$t('howtoSlides.one.body')"
              />
            </n-layout>
          </n-scrollable>
        </div>
      </swiper-slide>
      <swiper-slide class="slide slide-2">
        <div class="view-container">
          <n-scrollable>
            <n-layout>
              <n-text
                class="span-6"
                preset="hero"
                color="white"
                v-html="$t('howtoSlides.two.title')"
              />
              <n-text
                class="span-6"
                preset="sub"
                color="white"
                v-html="$t('howtoSlides.two.body')"
              />
            </n-layout>
          </n-scrollable>
        </div>
      </swiper-slide>
      <swiper-slide class="slide slide-3">
        <div class="view-container">
          <n-scrollable>
            <n-layout>
              <n-text
                class="span-6"
                preset="hero"
                color="white"
                v-html="$t('howtoSlides.three.title')"
              />
              <n-text
                class="span-6"
                preset="sub"
                color="white"
                v-html="$t('howtoSlides.three.body')"
              />
            </n-layout>
          </n-scrollable>
        </div>
      </swiper-slide>
      <swiper-slide class="slide slide-4">
        <div class="view-container">
          <n-scrollable>
            <n-layout>
              <n-text
                class="span-6"
                preset="hero"
                v-html="$t('howtoSlides.four.title')"
              />
              <n-text
                class="span-6"
                preset="sub"
                v-html="$t('howtoSlides.four.body')"
              />
            </n-layout>
          </n-scrollable>
        </div>
      </swiper-slide>
      <!-- Fake slide, silent close when this is reached -->
      <swiper-slide />
      <div
        slot="pagination"
        class="swiper-pagination swiper-pagination-bullets"
        :class="[isOnWhite ? 'greenNav' : 'whiteNav']"
      />
    </swiper-container>
  </div>
</template>

<script>
import { register } from 'swiper/element/bundle';
register();

export default {
    data() {
    return {
        isOnWhite: true,
    };
  },
  methods: {
    reachedEnd() {
      this.$emit('close');
    },
    onSlideChange(e) {
      const swiper = e.detail[0];
      this.isOnWhite = swiper.activeIndex === 0 || swiper.activeIndex === 3;

      if (swiper.isEnd) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss"> // Not scoped because of override swiper styles

.howto-swiper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  .swiper-pagination-bullets {
    &.whiteNav {
      .swiper-pagination-bullet {
        background: var(--color-white) !important;
      }
    }
    &.greenNav {
      .swiper-pagination-bullet {
        background: var(--color-green) !important;
        }
    }
  }

  .slide {
    white-space: pre-line;
    padding-top: 6em;
    padding-bottom: 2.5em;
    .grid {
      margin-top: -1em !important;
    }
  }
  .slide-1 {
    background: var(--color-white);
  }
  .slide-2 {
    background: var(--color-blue);
  }
  .slide-3 {
    background: var(--color-green);
  }
  .slide-4 {
    background: var(--color-white);
  }
  .list {
    list-style: none;
    margin-top: -0.5em;
    margin-left: -1.4em;
  }
  .list li::before {
    content: "\2022"; /* \2022 is the CSS Code/unicode for a bullet */
    color: var(--color-red);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .theme, .button, .button > a {
    display: inline !important;
  }
  .button {
    padding: 0 0.2em;
  }
}
.howto-skip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
