/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_person': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 08 person"><path pid="0" data-name="Icon material-person" d="M12 12a5 5 0 10-5-5 5 5 0 005 5zm0 2.5c-3.34 0-10 1.67-10 5V22h20v-2.5c0-3.33-6.66-5-10-5z"/></g>'
  }
})
