<template>
  <div class="grid" :style="`grid-row-gap:${rowGap * 5}px; margin-top: ${tGap}px; margin-bottom: ${bGap}px`">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    rowGap: {
      type: Number,
      default: 2,
    },
    topGap: {
      type: Number,
    },
    bottomGap: {
      type: Number,
    },
    yGap: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    bGap() {
      return (this.bottomGap ?? this.yGap) * 5;
    },
    tGap() {
      return (this.topGap ?? this.yGap) * 5;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.grid {
  width: 100%;
  display: grid;
  grid-column-gap: var(--grid-gutter);
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
  @extend .span-6;
}
</style>
