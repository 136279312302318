<template>
  <base-map
    ref="map"
    :center="center"
    :zoom="zoom"
    @bounds_changed="onBoundsChanged"
    @zoom_changed="onZoomChanged"
  >
    <template v-if="!portalHasContent">
      <n-marker @click="markerClick('stop', stop, tripsFromStop)" v-for="stop in stops" :key="getMarkerKey(stop.id)" :position="stop" type="stop" :tooltip="tooltipFor === 'stop_' + stop.id" />
      <n-group-marker @click="zoomToGroup(group)" v-for="group in groups" :key="getMarkerKey(group.id)" :group="group" />
      <n-marker v-if="currentPosition" type="current-position" :position="currentPosition" />
    </template>

    <portal-target name="main-map">
    </portal-target>
  </base-map>
</template>

<script>
import axios from 'axios';
import store from '@/store';
import { EventBus } from '@/vendor/events';
import BaseMap from '@/components/shared/map/baseMap';
import { PortalTarget, Wormhole } from 'portal-vue'
import debounce from 'lodash.debounce';
import commuteApi from '@/api/commute';
import NMarker from '@/components/shared/map/marker';
import { getMapMarkerIncrementingId } from '@/vendor/maps';
import NGroupMarker from '@/components/shared/map/groupMarker';
import GmapCustomMarker from '@/vendor/components/gmap-custom-marker';
import { namespacedTypes as appTypes } from '@/store/modules/app-types';

export default {
  data() {
    // by default the map displays stops,
    return {
      stops: [],
      groups: [],
      bounds: null,
      cancelTokenSource: null,
      routes: [],
      tooltipFor: null,
      // Default: all of Denmark
      center: {
        lat: 55.70924713420419,
        lng: 9.536194891585472,
      },
      zoom: 6,
      zoomedTo: null,
    };
  },
  created() {
    this.deboucedFetchStopsAsData = debounce(this.fetchStopsAsData, 500, {
      leading: false,
      trailing: true,
    });
  },
  mounted() {
    EventBus.$on('map-pan-to', this.panTo);
    EventBus.$on('map-zoom-to-min-level', this.zoomToMinLevel);

    this.$refs.map.$refs.map.$mapPromise.then((map) => {
      setTimeout(() => {
        EventBus.$emit('map-ready');
      }, 50);
    });
  },
  beforeDestroy() {
    EventBus.$off('map-pan-to', this.panTo);
    EventBus.$off('map-zoom-to-min-level', this.zoomToMinLevel);
  },
  components: {
    NMarker,
    NGroupMarker,
    GmapCustomMarker,
    PortalTarget,
    BaseMap
  },
  computed: {
    portalHasContent() {
      return Wormhole.hasContentFor('main-map');
    },
    elements() {
      return [];
    },
    currentPosition() {
      return this.$store.getters[appTypes.LAST_KNOWN_LOCATION];
    },
  },
  methods: {
    onMapLoaded() {
      return new Promise((resolve) => {
        const attemptResolve = () => {
          if (this.$refs?.map?.$refs?.map?.$mapPromise) {
            this.$refs.map.$refs.map.$mapPromise.then(resolve);
            return true;
          }
          return false;
        };

        if (attemptResolve()) {
          return;
        }

        const intervalId = setInterval(() => {
          if (attemptResolve()) {
            clearInterval(intervalId);
          }
        }, 100);
      });
    },
    /**
     * @param {{ lat: number, lng: number }[]} coordinates
     * @param {{ bottom: number, left: number, right:number, top: number }} boundingPadding
     */
    panTo(coordinates, boundingPadding) {
      boundingPadding = Object.assign({
        bottom: 50,
        left: 50,
        right: 50,
        top: 50,
      }, boundingPadding);

      coordinates = !Array.isArray(coordinates) ?
        [coordinates] :
        coordinates;

      if (!coordinates || coordinates?.length === 0) {
        return;
      }

      this.zoomedTo = coordinates;

      this.onMapLoaded().then((map) => {
        if (coordinates.length === 1) {
          map.panTo(coordinates[0]);
          map.panBy(
            (boundingPadding.left - boundingPadding.right) / 2,
            (boundingPadding.bottom - boundingPadding.top) / 2
          );

          return;
        }

        const bounds = new window.google.maps.LatLngBounds();

        for (const coordinate of coordinates) {
          bounds.extend(coordinate);
        }

        map.fitBounds(bounds, boundingPadding);
      });
    },
    markerClick(type, item, callback) {
      if (this.tooltipFor === `${type}_${item.id}`) {
        callback(item);
        this.tooltipFor = null;
      } else {
        this.tooltipFor = `${type}_${item.id}`;
      }
    },
    /**
     * This generally does not seem like the best way to achive this, then actual map data is displayed by portal
     * Set the bound according to provided positions.
     * If only 1 is sent, add some fakes to adjust for zoom level
     */
    onPositions(data) {
      const setBounds = new Set(
        data
        .filter(x => x != null)
        .map(item => ({lat: item.lat, lng: item.lng}))
      );

      if (setBounds.size === 0) {
        return;
      }

      this.onMapLoaded().then((map) => {
        const bounds = new window.google.maps.LatLngBounds();

        for (const entry of setBounds.entries()) {
          bounds.extend({ lat: entry[0].lat, lng: entry[0].lng });
        }

        if (setBounds.size === 1) {
          var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.05, bounds.getNorthEast().lng() + 0.05);
          var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.05, bounds.getNorthEast().lng() - 0.05);
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }

        map.fitBounds(bounds, 50);
      });
    },
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return getMapMarkerIncrementingId(id);
    },
    onBoundsChanged(bounds) {
      if (!bounds) {
        return;
      }

      // {south: 49.5138, west: 5.8012, ... }
      this.bounds = bounds.toJSON();

      if (!this.portalHasContent) {
        this.deboucedFetchStopsAsData();
      }
    },
    onZoomChanged(zoom) {
      this.zoom = zoom;
    },
    zoomToGroup(group) {
      this.onMapLoaded().then((map) => {
        map.setZoom(this.zoom < 10 ? this.zoom + 1 : 13);
        map.panTo({lat: group.lat, lng: group.lng})
      });
    },
    zoomToMinLevel(level = 13) {
      if (this.zoom <= level) {
        return;
      }

      this.onMapLoaded().then((map) => {
        map.setZoom(level);
      });
    },
    fetchStopsAsData() {
      if (!this.bounds) {
        return;
      }

      // cancel previous request if still loading
      if (this.cancelTokenSource !== null) {
        this.cancelTokenSource.cancel();
      }

      this.cancelTokenSource = axios.CancelToken.source();

      commuteApi
        .getMapItems(this.bounds, this.zoom, this.cancelTokenSource.token, !this.$store.state.app.showStops)
        .then((data) => {
          this.stops = data.stops;
          this.groups = this.parseGroups(data.groups);
        })
        .catch((e) => {
          // show error, if the request was not just cancelled
          if (!axios.isCancel(e)) {
            this.$error({
              description: this.$t('c.map.loadingError'),
            });
          }
        })
        .finally(() => {
          this.cancelTokenSource = null;
        });
    },
    tripsFromStop(stop) {
      this.$router.push({
        name: 'main.demand',
        params: {
          stop
        }
      });
    },
    parseGroups(groups) {
      const lowest = groups.reduce((lowest, current) => current.count < lowest ? current.count : lowest, Number.MAX_SAFE_INTEGER);
      const highest = groups.reduce((highest, current) => current.count > highest ? current.count : highest, 0);

      return groups.map((group) => {
        group.size = (group.count - lowest) / (highest - lowest);
        return group;
      });
    },
  },
  watch: {
    '$store.state.app.showStops': function() {
      const show = this.$store.state.app.showStops;
      if (show) {
        this.fetchStopsAsData();
      } else {
        this.stops = [];
        this.groups = [];
      }
    },
    currentPosition: {
        immediate: true,
        handler(newValue, oldValue) {
          let position = newValue ?? oldValue;
          if (!this.zoomedTo && position) {
            // Needed the position immediate to zoom to the map, but the refs wasnt ready at that time, ergo the timeout.
            setTimeout(() => {
              this.onMapLoaded().then((map) => {
                // Was unable to get reactive props with maps to work. Zoom worked, but not center.
                // If tricking to draw again, map was blank and an error in console appears.
                map.setCenter(new google.maps.LatLng(position.lat, position.lng));
                map.setZoom(14);
                this.zoomedTo = position;
              });
            }, 200);
          }
        }
      }
    },
};
</script>
