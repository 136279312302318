<template>
  <div class="seperator" :class="{ thin }">
    <hr/>
  </div>
</template>

<script>
export default {
  name: 'n-seperator',
  props: {
    thin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.seperator {
  margin: 10px 0;

  hr {
    border: none;
    border-bottom: 1px solid var(--color-grey-light);
  }

  &.thin {
    margin: 0;
  }
}
</style>
