<template>
  <n-grid>
    <n-text preset="sub" class="span-6" color="warning">{{
      $t('tripDetails.minimumDistanceNotReached.note', {
        kilometers,
      })
    }}</n-text>
  </n-grid>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'minimumDistanceNotReached',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    kilometers() {
      const { minimum_distance } = this.payload;

      return i18n.n(minimum_distance / 1000, 'km');
    },
  },
};
</script>