<template>
  <n-full-screen overlay>
    <n-top-screen v-if="!trip">
      <n-container preset="top">
        <n-layout>
          <n-spinner class="span-6 flex-center" />
        </n-layout>
      </n-container>
    </n-top-screen>
    <n-theme
      v-else
      type="passenger"
    >
      <search-header-with-arrival
        :title="$t('edit.request.title')"
        :from-address="fromAddress"
        :to-address="toAddress"
        :time="new Date(trip.planned_time)"
        :time-mode="trip.planned_time_by.toLowerCase()"
        @address-selected="addressSelected"
        @switch-addresses="switchAddresses"
        @planned-time="setPlannedTime"
      />
      <n-bottom-screen>
        <n-layout v-if="toAddress != null && fromAddress != null">
          <n-button
            class="span-3"
            size="lg"
            color="error"
            type="outlined white"
            inverted
            @click="deleteRequest"
          >
            {{ $t('edit.request.delete') }}
          </n-button>
          <n-button
            :loading="loading"
            class="span-3"
            size="lg"
            @click="save"
          >
            {{
              $t('edit.request.save')
            }}
          </n-button>
        </n-layout>
      </n-bottom-screen>
      <portal to="main-map">
        <n-marker
          v-if="fromAddress"
          theme="passenger"
          :position="{ lat: fromAddress.lat, lng: fromAddress.lng }"
        />
        <n-marker
          v-if="toAddress"
          theme="passenger"
          type="end"
          :position="{ lat: toAddress.lat, lng: toAddress.lng }"
        />
      </portal>
    </n-theme>
  </n-full-screen>
</template>

<script>
import { Portal } from 'portal-vue';
import commuteApi from '@/api/commute';
import NMarker from '@/components/shared/map/marker';
import { parseTripToLocation, parseTripDataV4 } from '@/vendor/utils';
import { mapActions, mapState } from 'vuex';
import { unifyPoint, getDistanceBetweenPoints } from '@/vendor/maps';
import {
  namespace as namespaceCommute,
  namespacedTypes as namespacedCommute,
} from '@/store/modules/commute-types';
import {
  namespace as userNamespace,
  namespacedTypes as userTypes,
} from '@/store/modules/user-types';
import SearchHeaderWithArrival from '@/components/shared/searchHeaderWithArrival.vue';

export default {
  name: 'MainEditRequest',
  components: {
    Portal,
    SearchHeaderWithArrival,
    NMarker,
  },
  props: {
    request: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      fromAddress: null,
      toAddress: null,
      trip: null,
      loading: false,
    };
  },
  async mounted() {
    if (!this.request) {
      this.trip = await this.fetchSingleRequest(this.id);
    } else {
      this.trip = this.request;
    }
    const parsed = parseTripToLocation(this.trip);
    this.fromAddress = parsed.fromAddress;
    this.toAddress = parsed.toAddress;
    this.$emit('positions', [this.fromAddress, this.toAddress]);
  },
  methods: {
    ...mapActions({
      fetchSingleRequest: userTypes.FETCH_SINGLE_COMMUTE_REQUEST,
      cancelRequest: userTypes.CANCEL_COMMUTE_REQUEST,
    }),
    addressSelected(adr) {
      if (adr.sheetType == 'from') {
        this.fromAddress = adr;
      } else {
        this.toAddress = adr;
      }
    },
    setPlannedTime(value) {
      console.log(value)
      if (value === null) {
        return;
      }

      this.trip.planned_time = value.time;
      this.trip.planned_time_by = value.by;
    },
    switchAddresses() {
      [this.toAddress, this.fromAddress] = [this.fromAddress, this.toAddress];
    },
    save() {
      console.log(this.request)
      const parsed = parseTripDataV4(
        {
          fromAddress: this.fromAddress,
          toAddress: this.toAddress,
          plannedTime: this.trip.planned_time,
          plannedTimeBy: this.trip.planned_time_by,
        },
        false
      );

      const proceedHandler = () => {
        this.loading = true;

        commuteApi
          .updateRequest(this.trip.id, parsed)
          .then(() => {
            this.$success(this.$t('edit.request.changes-saved'));
            this.$router.back();
          })
          .finally(() => {
            this.loading = false;
          });
      };

      const from = unifyPoint({ lat: parsed.from_lat, lng: parsed.from_lng });
      const to = unifyPoint({ lat: parsed.to_lat, lng: parsed.to_lng });

      if (getDistanceBetweenPoints(from, to) < 50) {
        setTimeout(() => {
          this.$modal.show('dialog', {
            title: this.$t('marketplace.short-trip-dialog.title'),
            text: this.$t('marketplace.short-trip-dialog.description'),
            cancel: true,
            success: {
              text: this.$t('marketplace.short-trip-dialog.success'),
              handler: proceedHandler,
            },
            cancelButton: {
              text: this.$t('marketplace.short-trip-dialog.cancel'),
            },
          });
        }, 500);
      } else {
        proceedHandler();
      }
    },
    deleteRequest() {
      this.$modal.show('dialog', {
        title: this.$t('overview.single.request.cancelDialogTitle'),
        text: this.$t('overview.single.request.cancelDialogDescription'),
        success: {
          text: this.$t('overview.single.request.cancelDialogSuccessButton'),
          handler: async () => {
            await this.cancelRequest(this.trip.id).then(() => {
              this.$router.back();
              this.$store.commit(
                namespacedCommute.OVERVIEW_REQUEST_REMOVED,
                this.trip.id
              );
              this.$success(this.$t('overview.single.request.snackbar'));
            });
          },
        },
        color: 'error',
        cancel: true,
      });
    },
  },
};
</script>
