<script>
export default {
  render() {
    return this.$slots.default
  },
  methods: {
    show(stop, handler) {
      const title = stop.deactivated ? 'addStop' : 'removeStop';
      const successButton = stop.deactivated ? 'addStopButton' : 'removeStopButton';
      const dialogColor = stop.deactivated ? 'default' : 'error';
      this.$modal.show('dialog', {
        title: this.$t(`main.provide.${title}`),
        text: `${stop.name} - ${stop.street}`,
        success: {
          text: this.$t(`main.provide.${successButton}`),
          handler: handler,
        },
        color: dialogColor,
        cancel: true,
      });
    }
  }
}
</script>
