<template>
  <n-column class="trip-code-button" :span="6">
    <n-button
      @click="retry"
      :loading="loading"
      block
      type="filled"
      color="default"
    >
      <slot />
    </n-button>

    <n-text
      class="trip-code-button--retry"
      align="center"
      preset="label-2"
      color="grey"
    >
      <i18n path="c.tripCodeButton.retryIn">
        <template v-slot:time>
          <n-text preset="label-2" color="accent">
            {{ time }}
          </n-text>
        </template>
      </i18n>
    </n-text>
  </n-column>
</template>

<script>
import { addSeconds, differenceInSeconds } from 'date-fns';
import { format } from '@/vendor/date-fns';

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    retrySeconds: {
      type: Number,
      required: false,
      default: 15,
    },
  },
  data() {
    return {
      time: null,
      nextRetry: null,
      animationFrameRequest: null,
    };
  },
  mounted() {
    this.animationFrameRequest = requestAnimationFrame(this.updateTime);
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrameRequest);
  },
  methods: {
    updateTime(timestamp) {
      if (this.nextRetry === null) {
        this.resetNextRetry();
      }

      const now = new Date();
      let seconds = differenceInSeconds(this.nextRetry, now);

      if (now > this.nextRetry) {
        this.retry();
      }

      this.time = format(
        new Date(
          0,
          0,
          0,
          0,
          0,
          seconds > this.retrySeconds ? this.retrySeconds : seconds
        ),
        'mm:ss'
      );

      this.animationFrameRequest = requestAnimationFrame(this.updateTime);
    },
    resetNextRetry() {
      this.nextRetry = addSeconds(new Date(), this.retrySeconds + 1);
    },
    retry() {
      this.resetNextRetry();
      this.$emit('retry');
    },
  },
};
</script>

<style lang="scss" scoped>
.trip-code-button {
  margin-top: 1.5rem;

  &--retry {
    display: block;
    margin-top: 1.5rem;

    .time {
      font-weight: bold;
    }
  }
}
</style>
