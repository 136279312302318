/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_phono-landline': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 32 phone"><path pid="0" data-name="Path 1347" d="M6 10.66A16.86 16.86 0 0013.34 18l2.45-2.45a1.1 1.1 0 011.13-.26 12.73 12.73 0 004 .63A1.11 1.11 0 0122 17v3.88A1.11 1.11 0 0120.89 22 18.88 18.88 0 012 3.12 1.11 1.11 0 013.11 2H7a1.11 1.11 0 011.11 1.11 12.73 12.73 0 00.63 4 1.12 1.12 0 01-.27 1.13z"/></g>'
  }
})
