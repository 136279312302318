import Vue from 'vue';

export const EventBus = new Vue({
  name: 'EventBus',
  created() {
    window.addEventListener('focus', this.onWindowFocus);
    window.addEventListener('blur', this.onWindowBlur);
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocus);
    window.removeEventListener('blur', this.onWindowBlur);
  },
  methods: {
    onWindowFocus() {
      this.$emit('window-focus');
    },
    onWindowBlur() {
      this.$emit('window-blur');
    },
  },
});
