<template>
  <div
    class="info"
    :class="{ marginPlace }"
    @click.stop="iconClick"
  >
    <n-icon
      v-if="icon === 'question'"
      name="help-question"
      color="grey"
    />
    <n-icon
      v-if="icon === 'warning'"
      name="info"
      color="warning"
    />
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  props: {
    icon: {
      type: String,
      default: 'question',
      validator: (icon) => ['question', 'warning'].includes(icon),
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    text: {
      type: String,
      default: null,
      required: false,
    },
    buttonColor: {
      type: String,
      default: null,
      required: false,
    },
    buttonText: {
      type: String,
      default: i18n.t('c.dialog.ok'),
      required: false,
    },
    marginPlace: {
      type: Boolean,
      default: false,
    },
    dialogName: {
      type: String,
      default: 'dialog',
    },

  },
  methods: {
    iconClick() {
      if (typeof this.$listeners?.click === 'function') {
        this.$emit('click');
        return;
      }

      this.$modal.show(this.dialogName, {
        title: this.title,
        text: this.text,
        color: this.buttonColor,
        success: {
          text: this.buttonText,
          color: 'default'
        },
        cancel: false,
        theme: "driver"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 0.3em;
  &.marginPlace {
    // Parent needs to have .relative
    position: absolute;
    right: -35px;
    padding: 10px;
  }
}
</style>
