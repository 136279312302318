<template>
  <div class="radio-container">
    <input type="radio" v-model="localModel" :value="value" :id="_uid" v-on="listeners" v-bind="$attrs" />
    <div class="radio" :class="{ 'checked': localModel == this.value}">
      <div class="check" />
    </div>
    <label :for="_uid" v-if="$slots.default">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'model',
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    model: {
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return this.model; },
      set(model) { this.$emit('input', model); },
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value, event.target.name),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/style/styleguide.scss";

  .radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    label {
      width: calc(100% - 15px - 20px);
      margin-left: 15px;
      color: var(--color-grey-dark);
    }

  }

  input[type="radio"] {
    opacity: 0;
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0;
  }
  .radio {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: solid var(--color-accent) 1px;
    padding: 2px;
  }

  .radio.checked {
    border-color: var(--color-accent);
    .check {
      background: var(--color-accent);
    }
  }


  input[type="radio"]:disabled ~ .radio {
    border-color: var(--color-grey-dark);
  }

  input[type="radio"]:disabled:checked ~ .radio {
    .check {
      background: var(--color-grey-dark);
    }
  }

  .check {
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }

</style>
