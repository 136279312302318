<template>
  <n-layout v-if="isLoading" :spacing-y="false" :spacing-bottom="true" style="min-height: 120px;">
    <n-text preset="label-2" class="span-6 flex-v-center" color="grey-dark">{{
      $t('tripDetails.autoPassenger.checkingForAutoPassengerTrip')
    }}</n-text>
  </n-layout>
  <n-layout v-else-if="booked" :spacing-y="false" :spacing-bottom="true">
    <n-text preset="label-2" color="accent" class="span-5">
      <i18n path="tripDetails.autoPassenger.title">
        <template v-slot:day>{{ $t(`days.${day}`) }}</template>
      </i18n>
    </n-text>
    <n-text preset="sub" class="span-6" color="grey-dark">
      {{ $t('tripDetails.autoPassenger.createdDescription') }}
    </n-text>
    <n-button
      type="outlined"
      :loading="isBusy"
      inverted
      block
      @click="deleteAuto"
      color="error"
      icon="repeat"
      >{{ $t('tripDetails.autoPassenger.deleteButton') }}</n-button
    >
  </n-layout>
  <n-layout
    v-else-if="isAutoBookable"
    :spacing-y="false"
    :spacing-bottom="true"
  >
    <n-text preset="label-2" color="accent" class="span-5">
      <i18n path="tripDetails.autoPassenger.title">
        <template v-slot:day>{{ $t(`days.${day}`) }}</template>
      </i18n>
    </n-text>
    <n-text preset="sub" class="span-6" color="grey-dark">
      {{ $t('tripDetails.autoPassenger.description') }}
    </n-text>
    <n-button
      type="outlined"
      :loading="isBusy"
      inverted
      block
      @click="createAuto"
      icon="repeat"
      >{{ $t('tripDetails.autoPassenger.button') }}</n-button
    >
  </n-layout>
  <n-layout v-else :spacing-y="false" :spacing-bottom="true">
    <n-text preset="label-2" class="span-6" color="grey-dark">{{
      $t('tripDetails.autoPassenger.unavavilable')
    }}</n-text>
  </n-layout>
</template>

<script>
import { namespacedTypes as commuteTypes } from '@/store/modules/commute-types';
import i18n from '@/i18n/index';
export default {
  name: 'autoPassenger',
  props: {
    isAutoBookable: {
      type: Boolean,
      default: false,
    },
    autoBooked: {
      type: Object,
      default: null,
    },
    tripId: {
      type: Number,
      required: true,
    },
    fromStopId: {
      type: Number,
      default: null,
    },
    toStopId: {
      type: Number,
      default: null,
    },
    dateTime: {
      type: Date,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      booked: this.autoBooked,
    };
  },
  created() {
    this.$watch('autoBooked', (newVal) => {
      this.booked = newVal;
    })
  },
  computed: {
    day() {
      return new Date(this.dateTime)
        .toLocaleString('en-US', { weekday: 'long' })
        .toLocaleLowerCase();
    },
  },
  methods: {
    async createAuto() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.autoPassenger.createDialog.title'),
        text: this.$t('tripDetails.autoPassenger.createDialog.description'),
        color: 'default',
        success: {
          text: this.$t('tripDetails.autoPassenger.createDialog.deleteButton'),
          handler: async () => {
            this.isBusy = true;
            await this.$store
              .dispatch(commuteTypes.CREATE_AUTO_PASSENGER, {
                driverTripId: this.tripId,
                fromStop: this.fromStopId,
                toStop: this.toStopId,
              })
              .then((autoPassengerTrip) => {
                this.booked = autoPassengerTrip;
                this.$success(i18n.t('tripDetails.autoPassenger.snackbarCreate'));
                this.emitChange();
              })
              .finally(() => {
                this.isBusy = false;
              });
          },
        },
      });
    },
    async deleteAuto() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.autoPassenger.deleteDialog.title'),
        text: this.$t('tripDetails.autoPassenger.deleteDialog.description'),
        color: 'error',
        success: {
          text: this.$t('tripDetails.autoPassenger.deleteDialog.deleteButton'),
          handler: async () => {
            this.isBusy = true;
            await this.$store
              .dispatch(commuteTypes.DELETE_AUTO_PASSENGER, this.booked.id)
              .then(() => {
                this.booked = null;
                this.$success(i18n.t('tripDetails.autoPassenger.snackbarDelete'));
                this.emitChange();
              })
              .finally(() => {
                this.isBusy = false;
              });
          },
        },
      });
    },
    emitChange() {
      this.$emit('bookedChanged', this.booked);
    },
  },
};
</script>
