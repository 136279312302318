/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_suitcase': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 05 briefcase"><path pid="0" data-name="Icon material-work" d="M20 6.5h-4v-2a2 2 0 00-2-2h-4a2 2 0 00-2 2v2H4a2 2 0 00-2 2v11a2 2 0 002 2h16a2 2 0 002-2v-11a2 2 0 00-2-2zm-6 0h-4v-2h4z"/></g>'
  }
})
