/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-edit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v-2H5V8h14v1h2V5c0-1.1-.9-2-2-2m2.7 10.35l-1 1-2.05-2 1-1c.2-.21.54-.22.77 0l1.28 1.28c.19.2.19.52 0 .72M12 18.94l6.07-6.06 2.05 2L14.06 21H12v-2.06z"/>'
  }
})
