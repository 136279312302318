/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_train': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 14 train"><path pid="0" data-name="Path 342" d="M12 2c-4.65 0-8.42.53-8.42 4.21v10a3.68 3.68 0 003.68 3.68l-1.58 1.58V22h12.64v-.53l-1.58-1.57a3.69 3.69 0 003.68-3.69v-10C20.42 2.53 16.65 2 12 2zM7.26 17.79a1.58 1.58 0 111.58-1.58 1.56 1.56 0 01-1.56 1.58zM11 11.47H5.68V6.21H11zm5.79 6.32a1.58 1.58 0 111.58-1.58 1.57 1.57 0 01-1.57 1.58zm1.58-6.32h-5.32V6.21h5.27z"/></g>'
  }
})
