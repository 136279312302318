<template>
  <div>
    <n-top-screen disable-safe-spacing>
      <n-dialog />
      <n-container preset="top">
        <n-nav-bar
          :title="title"
          shadowless
        />
        <div
          v-if="!departureOnly"
          class="switch"
          @click="switchAddresses"
        >
          <div class="swap-button-container">
            <n-icon
              name="arrows-swap"
              color="accent"
            />
          </div>
        </div>
        <n-layout
          class="relative"
          :spacing-y="false"
          :spacing-bottom="departureOnly"
        >
          <n-button-fake-input
            :loading="loadingType === 'from'"
            class="span-6"
            :selected="fromAddress != null"
            :label="$t('c.searchHeader.from')"
            @click="openSheet('from')"
          >
            {{ fromLabel }}
          </n-button-fake-input>
          <n-button-fake-input
            v-if="!departureOnly"
            :loading="loadingType === 'to'"
            class="span-6"
            :selected="toAddress != null"
            :label="$t('c.searchHeader.to')"
            @click="openSheet('to')"
          >
            {{ toLabel }}
          </n-button-fake-input>
        </n-layout>
        <n-layout
          v-if="!departureOnly"
          class="select-time-container"
          :top-gap="2"
          :spacing-y="false"
        >
          <n-grid
            :top-gap="2"
            :bottom-gap="3"
            class="span-6"
          >
            <div
              v-if="datePickerType === 'datetime'"
              class="span-6"
              @click="toggleTimePicker"
            >
              <n-column
                :span="6"
                class="select-time-area"
              >
                <svg-icon
                  name="arrow-right"
                  class="select-time-arrow"
                  :class="{
                    'weight-bold': plannedTime || showTimePicker,
                  }"
                  :color="
                    plannedTime || showTimePicker
                      ? 'var(--color-green-900)'
                      : 'var(--color-green-700)'
                  "
                />
                <n-text
                  :class="{
                    'color-neutral-800 text-bold': plannedTrip,
                    'color-neutral-600': !plannedTrip,
                  }"
                  preset="sub"
                >
                  <div
                    v-if="plannedTime"
                    class="color-green-900"
                  >
                    <span class="weight-bold">{{ $t('c.searchHeader.mode.' + plannedTimeBy) }}:
                    </span>
                    <span
                      v-if="
                        isPlannedTimeToday() && datePickerType === 'datetime'
                      "
                    >{{ $t('c.searchHeader.today') }}</span>
                    {{ formatDate(plannedTime) }}
                  </div>
                  <div
                    v-else
                    :class="
                      showTimePicker
                        ? 'color-green-900 weight-bold'
                        : 'color-green-700'
                    "
                  >
                    {{ $t('c.searchHeader.selectTime') }}
                  </div>
                </n-text>
              </n-column>
            </div>
            <slot name="options" />
          </n-grid>
        </n-layout>
      </n-container>
      <n-layout :spacing-y="false">
        <slot />
      </n-layout>
    </n-top-screen>
    <template v-if="showTimePicker">
      <DatetimePicker
        :time-mode="plannedTimeBy"
        :time-selected="time"
        :only-time="datePickerType === 'time'"
        @datetimeChange="setPlannedTime"
        @modeChange="setPlannedBy"
        @dateTimePickerClosed="toggleTimePicker"
      />
    </template>
    <address-search-sheet
      ref="searchSheet"
      :title="$t(`c.searchHeader.where${sheetType}`)"
      :input-label="$t(`c.searchHeader.${sheetType}`)"
      @input="onAddressInput"
      @select="onAddressSelect"
      @error="onAddressError"
    />
  </div>
</template>

<script>
import AddressSearchSheet from '@/components/shared/addressSearchSheet';
import DatetimePicker from '@/components/core/overlays/datetimePicker.vue';
import { format } from '@/vendor/date-fns';
import { isToday } from 'date-fns';

export default {
  components: {
    AddressSearchSheet,
    DatetimePicker,
  },
  props: {
    fromAddress: {
      type: Object,
      default: null,
    },
    toAddress: {
      type: Object,
      default: null,
    },
    time: {
      type: Date,
      required: false,
      default: null,
    },
    timeMode: {
      type: String,
      default: 'departure',
    },
    datePickerType: {
      type: String,
      default: 'datetime',
    },
    departureOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sheetType: null,
      loadingType: null,
      plannedTrip: false,
      plannedTime: this.time,
      plannedTimeBy: this.timeMode ?? 'departure',
      showTimePicker: false,
    };
  },
  computed: {
    departureWidth() {
      if (this.$slots.options) {
        return 6 / (this.$slots.options.length + 1);
      }
      return 6;
    },
    stringFormat() {
      return this.isPlannedTimeToday() ? 'HH:mm' : 'd. MMM HH:mm';
    },
    toLabel() {
      return this.toAddress?.completeAddress ?? '';
    },
    fromLabel() {
      return this.fromAddress?.completeAddress ?? '';
    },
  },
  watch: {
    plannedTime() {
      this.$emit('planned-time', {
        time: this.plannedTime,
        by: this.plannedTimeBy,
      });
    },
    plannedTimeBy() {
      this.$emit('planned-time', {
        time: this.plannedTime,
        by: this.plannedTimeBy,
      });
    },
  },
  methods: {
    openSheet(type) {
      if (this.departureOnly) {
        return;
      }

      if (this.sheetType !== type) {
        this.$refs.searchSheet.clear();
      }

      this.sheetType = type;
      this.$refs.searchSheet.open();
    },
    onAddressInput(adr) {
      this.loadingType = null;
      adr.sheetType = this.sheetType;
      this.$emit('address-selected', adr);
    },
    onAddressSelect() {
      this.loadingType = this.sheetType;
    },
    onAddressError() {
      this.loadingType = null;
    },
    switchAddresses() {
      this.$emit('switch-addresses');
    },
    setDepartureTime(time) {
      this.plannedTime = time;
      this.plannedTrip = true;
    },
    isPlannedTimeToday() {
      return isToday(this.plannedTime);
    },
    setPlannedBy(mode) {
      this.plannedTimeBy = mode;
    },
    setPlannedTime(time) {
      if (time === null) {
        this.plannedTrip = false;
        return;
      }

      this.plannedTrip = true;

      if (time.dateTime) {
        this.plannedTime = time.dateTime;
        this.plannedTimeBy = time.mode;
        return;
      }

      this.plannedTime = time;
    },
    triggerDatePicker() {
      this.$refs.datepicker?.open();
    },
    toggleTimePicker() {
      this.showTimePicker = !this.showTimePicker;
    },
    formatDate(val) {
      return this.datePickerType === 'datetime'
        ? format(val, this.stringFormat)
        : format(val, 'HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.backbutton {
  position: absolute;
  top: 40px;
  left: 15px;
}

.switch {
  position: absolute;
  top: 4.5rem;
  right: 0.8rem;
}

.help-margin-extra {
  margin-left: 0.6em;
}
.swap-button-container {
  position: relative;
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border: 1px solid #00cc6d;
  border-radius: 50%;
  top: 34.5px;
  right: 45px;

  @supports (-webkit-touch-callout: none) {
    top: 62.5px;
  }
}

.select-time-container {
  min-height: 45px;

  .select-time-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .weight-bold {
      font-weight: bold;
    }

    .select-time-arrow {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }

}
</style>
