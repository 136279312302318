<template>
  <div class="seats-container span-6">
    <div class="seat" v-for="(seat, index) in seats" :key="index">
      <n-icon :outline="seat.outlined" name="person" :color="seat.color" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    accepted: {
      type: Number,
      required: true,
    },
    waiting: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    seats() {
      const colors = [];
      for(let i = 0; i < this.accepted; i++) {
        colors.push({
          color: 'accent-faded'
        });
      }

      for(let i = 0; i < this.waiting; i++) {
        colors.push({
          color: 'warning'
        });
      }

      const emptySeats = this.total - this.accepted - this.waiting;

      for(let i = 0; i < emptySeats; i++) {
        colors.push({
          outlined: true,
          color: 'accent-faded'
        });
      }
      return colors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.seats-container {
  display: inline-flex;
  flex-direction: row;
  padding-right: 10px;
}
</style>
