<template>
  <n-grid :row-gap="1" :y-gap="4">
    <n-text class="span-6" align="center" preset="header">{{
      $n(balance, 'currency', profile.currency)
    }}</n-text>
    <n-text
      v-if="balance_available !== balance"
      class="span-6"
      align="center"
      preset="sub"
      color="grey-dark"
    >
      {{ $t('account.available') }}:
      {{ $n(balance_available, 'currency', profile.currency) }}
    </n-text>
    <n-text
      v-else
      class="span-6"
      align="center"
      preset="sub"
      color="grey-dark"
      >{{ $t('account.description') }}</n-text >
    <n-text
      v-if="profile.credit_available"
      class="span-6"
      align="center"
      preset="sub"
      color="grey-dark"
    >
    {{ $t('account.OfWhichCredit', [$n(profile.credit_available, 'currency', profile.currency)])}}
    </n-text>
  </n-grid>
</template>

<script>
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';

export default {
  computed: {
    ...mapState(userNamespace, ['profile']),
    balance() {
      return Number.isNaN(this.profile.balance) ? 0 : this.profile.balance;
    },
    balance_available() {
      return Number.isNaN(this.profile.balance_available) ? 0 : this.profile.balance_available;
    }
  },
}
</script>
