<template>
  <div class="upload-wrapper"  :class="{'height': source !== null}">
    <div @click="onClick" ref="container" class="upload-container">
      <slot v-if="uploading || source === null" />
      <img v-if="source && !canvas" :src="source" class="span-6" />
      <input ref="input" type="file" @change="onFileUpload" accept="image/*"/>
      <canvas v-show="!uploading" ref="canvas" class="image-canvas span-4"></canvas>
    </div>
  </div>
</template>

<script>
import { handleOrientation } from '@/vendor/canvasHelper';
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: (size) => ["sm", "md", "lg"].includes(size),
    },
    editIcon: {
      type: Boolean,
      default: false,
    },
    src: {
      type: [String, File],
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      source: null,
      canvas: null,
    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.updateSource();
      }
    },
  },
  methods: {
    onClick() {
      this.$refs.input.click();
    },
    updateSource() {
      if (this.src && typeof(this.src) == 'string' && this.src.includes('http')) {
        this.source = this.src;
      } else if (this.src) {
        this.source = process.env.VUE_APP_IMAGE_URL + this.src;
      } else {
        this.source = null;
        if (!this.$refs.input) {
          return;
        }
        this.$refs.input.value = "";
        this.clearCanvas();
      }
    },
    onFileUpload(e) {
      const files = e.target.files;
     if (files.length !== 1) {
        return;
      }
     const file = files[0];
      const processed = new Promise((resolve) => {
        resolve(file);
        return;
      });
      processed
        .then((value) => {
          this.source = value;
          this.updateImage();
          this.$emit('input', value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateImage() {
      if (!this.canvas && this.$refs.canvas) {
        this.canvas = this.$refs.canvas;
      }
      this.setImage(this.source);
    },
    setImage(image) {
      let canvas = this.$refs.canvas;
      canvas.className = 'image-canvas';
      handleOrientation(image, canvas, 'image');
      this.canvas.parentNode.replaceChild(canvas, this.canvas);
      this.canvas = canvas;
    },
    clearCanvas() {
      let canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0,0, ctx.canvas.width, ctx.canvas.height);
      this.canvas = canvas;
    },
  }
};
</script>

<style lang="scss" scoped>
.upload-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  &.height {
    height: 120px;
  }
}

.upload-container {
  position: relative;
  width: 100%;
}

canvas.image-canvas,
img {
  object-fit: contain;
  height: 120px;

  /* Due to Safari rendering bug */
  -webkit-transform: translate3d(0,0,0);
}

img {
  display: flex;
  margin: auto;
}

input {
    display: none;
}

</style>
