import { render, staticRenderFns } from "./discountEligibilityWarning.vue?vue&type=template&id=4faf3329&scoped=true"
import script from "./discountEligibilityWarning.vue?vue&type=script&lang=js"
export * from "./discountEligibilityWarning.vue?vue&type=script&lang=js"
import style0 from "./discountEligibilityWarning.vue?vue&type=style&index=0&id=4faf3329&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4faf3329",
  null
  
)

export default component.exports