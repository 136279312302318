<template>
  <n-grid class="span-6">
    <n-text color="grey-dark">{{time}}</n-text>
    <n-text color="grey-dark" class="span-5">{{address}}</n-text>
  </n-grid>
</template>

<script>
export default {
  name: '',
  props: {
    time: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
