export function handleOrientation(image, canvas, imageClass = '') {
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.className = imageClass;
  img.src = URL.createObjectURL(image);
  const self = this;
  img.onload = function () {
    ctx.canvas.width = img.width;
    ctx.canvas.height = img.height;
    const { width } = canvas;
    const { height } = canvas;

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, width, height);
    orientation(img, canvas);
  };
}

/**
 * https://medium.com/wassa/handle-image-rotation-on-mobile-266b7bd5a1e6
 * @param {*} img
 * @param {*} canvas
 */
function orientation(img, canvas) {
  // Set variables
  const ctx = canvas.getContext('2d');
  const { width, height } = img;

  // Draw img into canvas
  ctx.drawImage(img, 0, 0, width, height);
  return;
}
