<template>
  <n-bottom-sheet type="header" :title="$t('main.start.login.header')" class="sheet" dismissible :closed="true" ref="sheet" @dismissed="dismissed">
    <n-grid :row-gap="4">
      <n-text preset="body" class="span-6" color="grey-dark">
        {{ $t("main.start.login.description") }}
      </n-text>

      <phone-auth
        @otp-requested="onOTPRequested"
        @otp-error="onOTPError"
      />
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import store from '@/store';
import userApi from '@/api/user';
import { EventBus } from '@/vendor/events';
import Terms from '@/components/shared/terms';
import PhoneAuth from '@/components/shared/auth/phoneAuth';
import { namespacedTypes as namespacedApp } from '@/store/modules/app-types';
import { phoneCountryOptions } from '@/vendor/static-options';

export default {
  name: 'mainStartLoginSheet',
  components: {
    Terms,
    PhoneAuth,
  },
  data() {
    return {
      phoneNumber: null,
      phoneCountryOptions: phoneCountryOptions,
      checkingPhone: false,
      termsAccepted: false,
      termsNotAcceptedAnimation: false,
    }
  },
  beforeDestroy() {
    EventBus.$off('show-login-sheet', this.onShowLoginSheet);
  },
  mounted() {
    EventBus.$on('show-login-sheet', this.onShowLoginSheet);
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    onOTPError(description) {
      if (!description) {
        this.$error();
        return;
      }

      this.$error({description});
    },
    onOTPRequested({ phoneNumber, phoneCountryCode, exists }) {
      this.$refs.sheet.dismiss();

      const routeName = exists ? 'auth.phone-login' : 'sign-up.validate-otp';

      setTimeout(() => {
        this.$router.push({name: routeName, query: { phoneCountryCode, phoneNumber }});
      }, 300);
    },
    dismissed() {
      this.reset();
      this.$emit('dismissed');
    },
    onShowLoginSheet({doAfterLogin, sheetProps = {}} = {}) {
      setTimeout(() => {
        const { phoneNumber, phoneCountryCode: phoneCountryCodeId } = sheetProps;
        const phoneCountryCode = phoneCountryOptions.find(x => x.id == phoneCountryCodeId);

        this?.$refs.auth?.setPhoneNumber(phoneNumber);

        if (phoneCountryCode) {
        this?.$refs.auth?.setPhoneCountryCode(phoneCountryCode);
        }
      }, 50)

      if (doAfterLogin) {
        store.commit(namespacedApp.SET_LOGIN_REDIRECT, doAfterLogin);
      }

      this.$refs.sheet.open();
    },
  },
}
</script>
