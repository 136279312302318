<template>
  <n-grid>
    <n-text preset="sub" class="span-5" color="warning">
      {{
        $t('fairUseSubsidyCap.violationIntroSubtext', {
          firstName: user.name,
        })
      }}

      <span v-if="fairUseViolation">
        {{ $t(`fairUseSubsidyCap.violationDescription${fairUseViolation}`) }}
      </span>
    </n-text>

    <n-column class="column">
      <n-help
        :dialog-name="dialogName"
        :title="$t('fairUseSubsidyCap.policyModalTitle')"
        :text="$t('fairUseSubsidyCap.policyModalDescription')"
      ></n-help>
    </n-column>
  </n-grid>
</template>

<script>
import { EventBus } from '@/vendor/events';
import HtmlSheet from '@/components/shared/htmlSheet';

export default {
  name: 'fairUseExceeded',
  components: {
    HtmlSheet,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    payload: {
      type: Array,
      required: false,
    },
    dialogName: {
      type: String,
      default: 'dialog',
    },
  },
  computed: {
    fairUseViolation() {
      if (!this.payload) {
        return null;
      }

      return this.payload[
        this.payload.length - 1
      ];
    },
  },
  methods: {
    trigger() {
      EventBus.$emit('outside-sponsored-area-sheet-trigger');
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  justify-content: flex-end;
}
</style>