/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_paper-plane': {
    width: 78,
    height: 95,
    viewBox: '0 0 78 95',
    data: '<path pid="0" d="M1.7417 89.8316C-7.63487 94.0264 23.1269 95.692 39.6798 96.0004C42.7641 96.0004 53.1277 95.6303 69.9068 94.1498C86.6859 92.6693 76.6924 89.1745 69.9068 88.2894C41.2219 84.5476 13.1539 86.9528 1.7417 89.8316Z" fill="#FFEBF0"/><path pid="1" d="M26.4169 30.6102L25.8 15.8051L37.8291 14.7115L38.1376 1L52.9427 13.3376L43.9979 82.1196L42.7642 83.6618C43.5867 84.4843 44.8615 86.191 43.381 86.4378C42.88 86.6048 41.9719 86.8668 41.6486 86.827C42.0416 87.3352 43.2362 89.5705 42.4557 89.8306L39.3713 91.6813L33.8194 91.3728C33.511 91.27 33.2025 90.8176 34.4363 89.8306C35.9785 88.5969 34.4363 87.98 32.8941 87.0547L31.3519 86.4378C32.4828 85.5125 34.3746 83.6001 32.8941 83.3534L31.3519 83.045L16.8552 35.8537L26.4169 30.6102Z" fill="white"/><path pid="2" d="M16.8552 35.8537L37.2122 27.2174M16.8552 35.8537L26.4169 30.6102L25.8 15.8051M16.8552 35.8537L31.3519 83.045L32.8941 83.3534C34.3746 83.6001 32.4828 85.5125 31.3519 86.4378L32.8941 87.0547C34.4363 87.98 35.9785 88.5969 34.4363 89.8306C33.2025 90.8176 33.511 91.27 33.8194 91.3728L39.3713 91.6813M37.2122 27.2174L25.8 15.8051M37.2122 27.2174L38.7544 77.493L36.5954 80.8859C37.6235 81.2971 39.3713 82.4898 38.1376 83.9703L36.5954 86.1293C37.2122 86.7462 38.6311 88.7202 39.3713 91.6813M25.8 15.8051L37.8291 14.7115M52.9427 13.3376L43.9979 82.1196L42.7642 83.6618C43.5867 84.4843 44.8615 86.191 43.381 86.4378C42.7642 86.6434 41.5304 86.993 41.5304 86.7462C41.5304 86.4378 43.381 89.5222 42.4557 89.8306L39.3713 91.6813M52.9427 13.3376L38.1376 1L37.8291 14.7115M52.9427 13.3376L37.8291 14.7115" stroke="#FF5283" stroke-width="2" stroke-linejoin="round"/><path pid="3" d="M37.8291 14.5713L38.1375 1L52.9426 13.3376L37.8291 14.5713Z" fill="#FFD1DE" stroke="#FF5283" stroke-width="2" stroke-linejoin="round"/><path pid="4" d="M36.9037 27.2173L17.1636 35.8536L26.1083 30.3017V16.4219L36.9037 27.2173Z" fill="#FFD1DE" stroke="#FF5283" stroke-width="2" stroke-linejoin="round"/>'
  }
})


