/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_watch-wrist': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 03 clock"><path pid="0" data-name="Path 478" d="M18.36 12A6.36 6.36 0 1112 5.64h0A6.36 6.36 0 0118.36 12z" _fill="none" _stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" data-name="Path 479" d="M12 9.27V12l1.36 1.36" _fill="none" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" data-name="Path 480" d="M16.1 16.86l-.32 3.48A1.82 1.82 0 0114 22h-4a1.81 1.81 0 01-1.82-1.65l-.32-3.49m0-9.72l.32-3.48A1.81 1.81 0 0110 2h4a1.82 1.82 0 011.8 1.65l.32 3.49"/></g>'
  }
})
