/* eslint-disable no-console */
import { register } from 'register-service-worker';
import { EventBus } from '@/vendor/events';
import { isBrowser, getReleaseVersion } from './vendor/utils';
import { SWHelper } from './serviceWorkerHelper';

function canUseServiceWorker() {
  if (isBrowser()) {
    console.log('[SW] Browser detected, skipping service worker registration');

    return false;
  }

  if (window.navigator.userAgent.includes('iPhone OS 16_4')) {
    console.log('[SW] iOS 16.4 detected, skipping service worker registration');

    return false;
  }

  return true;
}

if (canUseServiceWorker()) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info('[SW] App is being served from cache by a service worker.');
    },
    registered(registration) {
      console.info('[SW] Service worker has been registered.');
    },
    cached() {
      console.info('[SW] Content has been cached for offline use.');
    },
    updatefound() {
      console.info('[SW] New content is downloading.');
    },
    updated(registration) {
      console.info('[SW] New content is available; please refresh.', {
        currentVersion: getReleaseVersion()
      });
      EventBus.$emit('content-update-available');
    },
    offline() {
      console.warn(
        '[SW] No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('[SW] Error during service worker registration:', error);
    }
  });

  if ('serviceWorker' in navigator) {
    var refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', function() {
      console.info('[SW]: Acquired a new worker.', { refreshing });
      if (refreshing) {
        return;
      }
      refreshing = true;
      window.location.reload();
    });
  }
} else {
  SWHelper.getRegistrations()
    .then(registrations => {
      for (let registration of registrations) {
        console.log('[SW] Unregistering service worker');

        registration.unregister();
      }
    })
    .catch(console.error);
}
