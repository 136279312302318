<template>
  <n-grid v-bind="$attrs" :class="{
    'spacing-left': spacingX || spacingLeft,
    'spacing-right': spacingX || spacingRight,
    'spacing-top': spacingY || spacingTop,
    'spacing-bottom': spacingY || spacingBottom,
  }">
    <slot />
  </n-grid>
</template>

<script>
export default {
  props: {
    spacingX: {
      type: Boolean,
      default: true,
    },
    spacingY: {
      type: Boolean,
      default: true,
    },
    spacingTop: {
      type: Boolean,
      default: false,
    },
    spacingBottom: {
      type: Boolean,
      default: false,
    },
    spacingLeft: {
      type: Boolean,
      default: false,
    },
    spacingRight: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.spacing-left {
  padding-left: var(--grid-padding-x);
}

.spacing-right {
  padding-right: var(--grid-padding-x);
}

.spacing-top {
  padding-top: var(--grid-padding-y);
}

.spacing-bottom {
  padding-bottom: var(--grid-padding-y);
}
</style>
