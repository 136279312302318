<template>
  <div class="vertical-schedule">
      <div class="leg" v-for="(leg, index) in legs" :key="index">
        <div class="row" :class="[leg.type === 'CAR' ? 'accent' : 'grey-dark', leg.type]">
          <div v-if="index === 0 && leg.type !== 'CAR'" class="grey first" />
          <n-icon v-else-if="leg.type === 'CAR'" :name="icon(leg.type)" size="md" type="circle" color="accent" class="line-icon align-start" />
          <n-icon v-else name="target-spot" class="line-icon align-start" :color="legs[index-1].type === 'CAR' ? 'accent' : 'grey-dark'" />
          <n-grid class="info" :row-gap="0">
              <n-text preset="sub" color="grey-dark" class="time">{{ formatTime(leg.origin.datetime) }}</n-text>
              <n-text preset="header" class="span-5">{{ leg.origin.name }}</n-text>
              <n-icon v-if="leg.type !== 'CAR'" :name="icon(leg.type)" color="grey-dark" class="flex-start align-start" /> <div v-else />
              <n-text color="grey-dark" class="span-5">{{ legSubText(leg) }}</n-text>
          </n-grid>
        </div>
        <!-- FINAL DESTINATION -->
        <div v-if="legs.length -1 === index" class="destination">
          <n-icon name="target-spot" type="circle-inverse" class="line-icon align-start" :color="leg.type === 'CAR' ? 'accent' : 'grey-dark'" />
          <n-grid class="info" :row-gap="0">
            <n-text preset="sub" color="grey-dark" class="time">{{ formatTime(leg.destination.datetime) }}</n-text>
            <n-text preset="header" class="span-5">{{ leg.destination.name }}</n-text>
            <div />
            <n-text color="grey-dark" class="span-5">{{ destination.completeAddress }}</n-text>
          </n-grid>
        </div>
      </div>
  </div>
</template>

<script>
import constants from '@/constants';
import { format, formatDistanceStrict } from '@/vendor/date-fns';

export default {
  name: '',
  components: {},
  props: {
    legs: {
      type: Array,
    },
    destination: {
      type: Object,
    },
  },
  data: () => ({
    filteredLegs: [],
  }),
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    icon(type) {
      return constants.transportIcons[type];
    },
    legDuration(leg) {
      return formatDistanceStrict(
          new Date(leg.origin.datetime),
          new Date(leg.destination.datetime)
        );
    },
    extractDistance(leg) {
      if (!leg.notes) return '';
      const text = leg.notes.text;
      const regExp = /\(([^()]*)\)/g;
      const match = regExp.exec(text);
      if (match && match[1]) {
        return match[1].replace('Afstand:' , '').trim()
      }
      return '';
    },
    legSubText(leg) {
      let text = this.legDuration(leg);

      // Bybus 4 etc.
      if (leg.type !== 'CAR' && leg.type !== 'WALK' && leg.type !== 'FOOT') {
        text += `, ${leg.name}`;
      }

      // Distance for walks except Entur (foot)
      if (leg.type === 'WALK') {
        text += `, ${this.extractDistance(leg)}`;
      }

      return text;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.vertical-schedule {
  .leg {
    padding-left: 20px;
    .row {
      border-left: solid 2px;
      position: relative;
      padding-bottom: 10px;
      &.grey {
        border-color: var(--color-grey-dark);
      }
      &.accent {
        border-color: var(--color-accent);
      }
      &.WALK {
        border-left-style: dashed;
      }
      .line-icon {
        position: absolute;
        left: calc(-0.55 * var(--icon-md-size));
        top: -2px;
      }
      .first {
        border: solid var(--color-grey-dark) 2px;
        background: white;
        border-radius: 100%;
        position: absolute;
        left: calc(-0.50 * var(--icon-md-size));
        height: calc(var(--icon-md-size) - 3px);
        width: calc(var(--icon-md-size) - 3px);
        &.accent {
          border-color: var(--color-accent);
        }
      }
      .info {
        padding: 0 20px;
        .time {
          margin-top: 3px;
        }
      }
    }
  }
  .destination {
    padding-left: 20px;
    position: relative;
    .line-icon {
      position: absolute;
      left: calc(-0.50 * var(--icon-md-size));
      // top: -30px;
    }
  }
}
</style>
