<template>
  <n-theme type="driver">
    <activity-wrapper
      ref="wrapper"
      icon="location-alt"
      :status-color="statusColor" 
      :format-clock="false"
      :clock="headerText"
      :expanded="passengersAwaitingApproval.length > 0"
      @expandChanged="isExpanded = $event"
    >
      <template #status>
        {{
          `${$tc('overview.single.driver.bookedSeats', passengersAcceptedSeats)}` }}
      </template>
      <template #steps>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :time="step.time" 
          :address="step.address"
        />
      </template>
      <template #action>
        <n-action-sheet v-slot="{ closeSheet }">
          <n-action-sheet-item
            icon="trash-can"
            @click.native="closeSheet(cancelTrip)"
          >
            {{ $t(`overview.buttons.${passengersAwaitingApprovalSeats == 0 &&
              passengersAcceptedSeats == 0 ? 'delete'
              : 'cancel'}`) }}
          </n-action-sheet-item>
          <n-action-sheet-item
            v-if="!canRateTrip"
            icon="vacation"
            @click.native="closeSheet(() => $router.push({ 'name': 'vacation' }))"
          >
            {{ $t('overview.actionSheet.vacationSettings') }}
          </n-action-sheet-item>
        </n-action-sheet>
      </template>

      <template #extra>
        <n-column
          :span="6"
          class="flex-v-center"
        >
          <seats
            :accepted="passengersAcceptedSeats"
            :waiting="passengersAwaitingApprovalSeats"
            :total="driverTrip.seats"
          />
          <n-text 
            :color="passengersAwaitingApprovalSeats > 0 ? 'warning' : 'accent-faded'"
            preset="title"
          >
            {{ `${$tc('overview.single.driver.bookedSeats', passengersAcceptedSeats)}` }}
          </n-text>
        </n-column>
      </template>

      <template #buttons>
        <template v-if="isQueued">
          <template v-if="passengersAwaitingApprovalSeats > 0">
            <n-button
              size="lg"
              block
              color="warning" 
              type="filled" 
              @click="openTripDetails"
            >
              {{ $t('overview.buttons.showDetails') }}
            </n-button>
          </template>
          <template v-else>
            <template v-if="passengersAcceptedSeats > 0">
              <n-button
                size="lg"
                block
                type="outlined" 
                inverted 
                @click="openTripDetails"
              >
                {{ $t('overview.buttons.showDetails') }}
              </n-button>
            </template>
            <template v-else>
              <n-button
                size="lg"
                block
                type="outlined"
                inverted 
                @click="editTrip"
              >
                {{ $t('overview.buttons.edit') }}
              </n-button>
            </template>
          </template>
        </template>
        <template v-else>
          <template
            v-if="passengersAwaitingApprovalSeats > 0 || passengersAcceptedSeats > 0"
          >
            <n-button
              size="lg"
              :color="passengersAwaitingApprovalSeats > 0 ? 'warning' : undefined"
              :type="passengersAwaitingApprovalSeats > 0 ? 'filled' : 'outlined'"
              :inverted="passengersAwaitingApprovalSeats > 0 ? false : true"
              @click="openTripDetails"
            >
              {{ $t('overview.buttons.showDetails') }}
            </n-button>
          </template>
          <template v-else>
            <n-button
              size="lg"
              type="outlined"
              inverted
              @click="editTrip"
            >
              {{ $t('overview.buttons.edit') }}
            </n-button>
          </template>
          <n-button 
            size="lg" 
            :inverted="isQueued" 
            :type="isQueued ? 'outlined' : 'filled'"
            :loading="isActivating" 
            @click="activateTrip"
          >
            {{ $t('overview.buttons.start') }}
          </n-button>
        </template>
      </template>
    </activity-wrapper>
  </n-theme>
</template>

<script>
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import Step from '@/components/shared/overview/parts/step';
import Seats from '@/components/shared/overview/parts/seats';
import { formatRelativeWithoutTime } from '@/vendor/date-fns';

export default {
    name: '',
    components: {
        ActivityWrapper, Step, Seats
    },
    props: {
        driverTrip: {
            type: Object,
            required: true,
        },
        statusColor: {
          type: String,
          default: 'accent',
        },
        passengersAwaitingApproval: {
          type: Array,
          default: null
        },
        passengersAwaitingApprovalSeats: {
          type: Number,
          default: 0
        },
        passengersAcceptedSeats: {
          type: Number,
          default: 0
        },
        steps: {
          type: Array,
          default: null
        },
        openTrip: {
          type: Function,
          default: null,
        },
        isQueued: {
          type: Boolean,
          default: false,
        },
        editTrip: {
          type: Function,
          default: null,
        },
        canRateTrip: {
          type: Boolean,
          default: null,
        },
        isActivating: {
          type: Boolean,
          default: false,
        },
        activateTrip: {
          type: Function,
          default: null,
        },
        openTripDetails: {
          type: Function,
          default: null,
        },
        cancelTrip: {
          type: Function,
          default: null,
        }
    },
    computed: {
        headerText() {
            return formatRelativeWithoutTime(this.driverTrip.planned_departure);
        },
    }
};
</script>

<style lang="scss" scoped>
.footer-disclaimer {
    color: var(--color-orange);
    font-size: 10px;
    text-align: center;

}
</style>