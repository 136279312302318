/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map-marker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"/>'
  }
})
