<template>
  <n-layout class="item">
    <slot name="replace">
      <n-icon v-if="icon" :name="icon" class="span-1 icon" />
      <n-text :class="[icon ? 'span-5' : 'span-6']"><slot/></n-text>
    </slot>
  </n-layout>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
  },
};
</script>


<style scoped>
.item {
  transition: opacity 0.2s ease-in-out;
}
.item.icon {
  justify-content: start;
}
.item:active {
  opacity: 0.75;
}
</style>
