/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_help-question': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#B7B7B7" d="M18.6 4.5a9.61 9.61 0 00-13.2 0c-3.5 3.3-3.6 8.9-.3 12.3l.3.3C7.3 19 9.6 20.7 12 22c2.4-1.3 4.6-3 6.6-4.9 3.5-3.3 3.6-8.9.3-12.4-.1 0-.2-.1-.3-.2z"/><path pid="1" fill="#FFF" d="M15.2 6.2c.7.6 1.1 1.5 1.1 2.6s-.4 2-1.1 2.5c-.7.6-1.7.9-2.8.9l-.1 1.3h-2.1l-.1-2.9h.8c1 0 1.7-.1 2.3-.4.5-.2.8-.7.8-1.4 0-.5-.1-.9-.4-1.2s-.7-.4-1.2-.4-.9.1-1.2.4c-.3.3-.4.7-.4 1.2H8.5c0-.7.1-1.3.4-1.9s.8-1 1.4-1.3c.6-.3 1.3-.5 2.1-.5 1.1.1 2.1.4 2.8 1.1zm-5 11c-.3-.3-.4-.6-.4-1s.1-.7.4-1c.3-.3.6-.4 1.1-.4.4 0 .8.1 1 .4.3.3.4.6.4 1s-.1.7-.4 1c-.3.3-.6.4-1 .4-.5 0-.8-.1-1.1-.4z"/>'
  }
})
