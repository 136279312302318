<template>
  <landing-base>
      <n-text block preset="header" color="accent">{{ $t('landing.voucherTitle') }}</n-text>
      <div>
        <input
          :readonly="true"
          :value="code"
          style="text-align: center; padding: .5rem; font-size: 18px; margin: 1rem 0px;"
        />
        <button
          @click="copyVoucher"
          type="button"
          style="text-align: center; padding: .5rem; font-size: 18px; margin: 1rem 0px;"
        >{{ $t('landing.voucherCopy') }}</button>
      </div>
      <n-text
        block
        preset="body"
        align="center"
        style="white-space: break-spaces;"
      >{{ $t('landing.voucherBody') }}</n-text>
  </landing-base>
</template>
<script>
import LandingBase from './base';
export default {
  components: { LandingBase },
  props: ['code'],
  methods: {
    copyVoucher() {
      window.navigator.clipboard.writeText(this.code)
        .then(() => {
          this.$success(this.$t('landing.voucherCopySuccess'));
        })
        .catch(err => {
          this.$error(this.$t('landing.voucherCopyError'));
        });
    }

  },

}
</script>
