<template>
  <n-bottom-screen>
    <n-layout>
    <n-grid :row-gap="4" :y-gap="1">
      <n-theme type="driver" class="span-6">
        <n-button :loading="isOfferLoading" size="lg" type="filled" block @click="$emit('offerCommute')">
          {{ $t("main.start.offerCommute") }}
        </n-button>
      </n-theme>
      <n-button size="lg" type="filled" block @click="$emit('requestCommute')">
        {{ $t("main.start.whereTo") }}
      </n-button>
    </n-grid>
  </n-layout>
  </n-bottom-screen>
</template>

<script>
export default {
  name: 'mainStartNoActivitiesContainer',
  props: {
    isOfferLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
