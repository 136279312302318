<template>
  <div class="view-container">
    <n-nav-bar :title="$t('account.title')" />
    <n-scrollable>
      <n-layout>
        <balance-display />

        <n-grid :top-gap="1" :bottom-gap="2">
          <n-button @click="payout" size="lg" type="outlined" inverted>{{
            $t('account.payout')
          }}</n-button>
          <n-button @click="payment" size="lg">{{
            $t('account.refill')
          }}</n-button>
        </n-grid>

        <n-seperator class="span-6" />

        <n-grid :top-gap="2">
          <n-button
            @click="$router.push('account/transactions')"
            size="lg"
            type="outlined"
            inverted
            block
            >{{ $t('account.transactions') }}</n-button
          >

          <n-button
            @click="openVoucherSheet"
            size="lg"
            type="outlined"
            inverted
            block
            >{{ $t('voucher.voucher') }}</n-button
          >

          <n-button
            v-if="isTravelPassEnabled"
            @click="$router.push({name: 'travel-pass'})"
            size="lg"
            type="outlined"
            inverted
            block
            >{{ $t('account.commuterPass') }}</n-button
          >
        </n-grid>
      </n-layout>
    </n-scrollable>

    <n-dialog />
    <voucher-sheet ref="voucherSheet" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from '@/vendor/events';
import { namespace as userNamespace, namespacedTypes as userTypes } from '@/store/modules/user-types';
import VoucherSheet from '@/sheets/voucherSheet.vue';
import BalanceDisplay from '@/components/shared/account/balanceDisplay';

export default {
  name: 'accountAccount',
  props: ['showVoucherSheet'],
  mounted() {
    if (this.showVoucherSheet) {
      this.openVoucherSheet();
    }
  },
  components: {
    VoucherSheet,
    BalanceDisplay,
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    ...mapState(userNamespace, ['profile']),
    balance() {
      return Number.isNaN(this.profile.balance) ? 0 : this.profile.balance;
    },
    balance_available() {
      return Number.isNaN(this.profile.balance_available)
        ? 0
        : this.profile.balance_available;
    },
    isTravelPassEnabled() {
      return this.$store.getters[userTypes.HAS_FEATURE_FLAG]('CommuterPass');
    },
  },
  methods: {
    payment() {
      EventBus.$emit('open-payment-sheet');
    },
    openVoucherSheet() {
      this.$refs.voucherSheet.open();
    },
    payout() {
      this.$router.push({
        name: 'account-payout',
      });
    },
  },
};
</script>
