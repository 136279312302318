<template>
  <div class="button-social-container">
    <a v-on="$listeners" v-bind="$attrs" class="button-social">
      <n-icon v-if="icon" :name="icon" color="grey-dark" />
      <div v-else class="circle" />

      <n-text preset="body">
        <slot></slot>
      </n-text>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.button-social-container {
  grid-column: 1 / -1;

  .button-social {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    background-color: var(--color-grey-light);
    border-radius: 9999px;
    color: var(--color-grey-dark);
    cursor: pointer;
    display: flex;
    outline: none;
    overflow: hidden;
    padding: 0px 20px;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    @extend .feedback;

    svg {
      height: 21px;
      width: 21px;
      fill: var(--color-grey-dark);
    }

    span {
      margin-left: 5px;
    }

    .circle {
      height: 18px;
      width: 18px;
      background: var(--color-grey-dark);
      border-radius: 100%;
    }
  }
}
</style>
