<script>
export default {
  methods: {
    show(successHandler, cancelHandler) {
      this.$modal.show('dialog', {
        title: this.$t('dialog.offerReturnTripDialog.title'),
        text: this.$t('dialog.offerReturnTripDialog.description'),
        successButton: {
          text: this.$t('dialog.offerReturnTripDialog.yes'),
          handler: () => successHandler(),
        },
        cancelButton: {
          text: this.$t('dialog.offerReturnTripDialog.no'),
          handler: () => cancelHandler(),
        },
        cancel: true,
        clickOutside: false,
        theme: "driver"
      });
    },
  },
  render() {
    return this.$slots.default
  },
}
</script>
