/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_sms': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<path d="M900.608 60.928H123.392c-82.944 0-123.392 40.96-123.392 122.88V675.84c0 81.92 37.888 120.832 119.296 120.832h119.296l-14.848 125.44c0 15.36 8.192 29.184 22.016 36.352 6.144 3.072 12.8 5.12 19.456 5.12 8.192 0 15.872-2.048 23.04-6.656l236.544-159.232h380.928c82.432 0 119.296-38.912 119.296-120.832V183.808c-1.024-81.92-41.984-122.88-124.416-122.88zM220.672 501.76c-39.936 0-72.704-32.768-72.704-72.704 0-39.936 32.768-72.704 72.704-72.704 39.936 0 72.704 32.768 72.704 72.704 0 39.936-32.768 72.704-72.704 72.704z m291.328 0c-40.448 0-73.216-32.768-73.216-72.704 0-39.936 32.768-72.704 73.216-72.704s73.216 32.768 73.216 72.704c-0.512 39.936-33.28 72.704-73.216 72.704z m291.328 0c-40.448 0-73.216-32.768-73.216-72.704 0-39.936 32.768-72.704 73.216-72.704s73.216 32.768 73.216 72.704c0 39.936-32.768 72.704-73.216 72.704z" />'
  }
})
