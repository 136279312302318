/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4z"/>'
  }
})
