<template>
  <div class="drawer-wrapper">
    <transition name="slide">
      <div v-show="open" class="drawer">
        <n-layout>
          <n-grid>
            <n-text @click="close" preset="title" color="accent" class="span-5">{{
              $t('main.nav.title')
            }}</n-text>
            <n-icon
              @click="close"
              name="cross"
              color="accent"
              outline
              class="flex-end feedback"
            />
          </n-grid>
          <div class="span-6 scrollable">
            <n-grid :y-gap="5" @click.native="to('profile')">
              <profile-picture class="profile-picture" :src="profile.image" />
              <n-column :span="5">
                <div class="profile-preview">
                  <div class="profile-info ellipsis">
                    <n-text preset="body">
                      {{ `${profile.name} ${profile.last_name || ''}` }}
                    </n-text>
                    <rating :stars="ratings_average" />
                    <n-text preset="label-2" color="accent">
                      {{ $t('main.nav.balance') }}:
                      {{ $n(balance_available, 'currency', profile.currency) }}
                    </n-text>
                  </div>

                  <div v-if="profile.stats" class="profile-stats">
                    <n-theme type="driver">
                      <stats-display
                        icon="commute-driver"
                        :text="profile.stats.seats_driven || 0"
                      />
                    </n-theme>
                    <n-theme type="passenger">
                      <stats-display
                        icon="commute-passenger"
                        :text="profile.stats.seats_booked || 0"
                      />
                    </n-theme>
                  </div>
                </div>
              </n-column>
            </n-grid>
            <n-grid class="menu-items">
              <div @click="to('profile')" class="menu-item feedback">
                <n-text preset="title">{{ $t('main.nav.profile') }}</n-text>
              </div>
              <span
                :class="{
                  notice: notifications.some((x) => x.type.includes('message')),
                }"
              />
              <div @click="to('conversations')" class="span-5 feedback">
                <n-text @click="to('conversations')" preset="title">{{
                  $t('main.nav.messages')
                }}</n-text>
              </div>
              <span
                :class="{
                  notice: notifications.some((x) => !x.type.includes('message')),
                }"
              />
              <div @click="to('notifications')" class="span-5 feedback">
                <n-text @click="to('notifications')" preset="title">{{
                  $t('main.nav.notifications')
                }}</n-text>
              </div>
              <div @click="to('trip-history')" class="menu-item feedback">
                <n-text preset="title">{{ $t('main.nav.tripHistory') }}</n-text>
              </div>
              <div @click="to('account')" class="menu-item feedback">
                <n-text preset="title">{{ $t('main.nav.myAccount') }}</n-text>
              </div>
              <span
                :class="{
                  'active-vacation': !!$store.getters['user/activeVacation'],
                }"
              />
              <div @click="to('vacation')" class="menu-item feedback">
                <n-text preset="title">{{ $t('main.nav.vacation') }}</n-text>
              </div>
              <div @click="showHowto" class="menu-item feedback">
                <n-text preset="title">{{ $t('main.nav.howTo') }}</n-text>
              </div>
              <a
                :href="$t('main.nav.helpLink')"
                target="_blank"
                class="menu-item feedback"
                ><n-text preset="title">{{ $t('main.nav.help') }}</n-text>
              </a>
            </n-grid>
          </div>
        </n-layout>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-show="open"
        @click="close"
        class="backdrop"
      >
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/vendor/events';
import ProfilePicture from '@/components/shared/profilePicture';
import Rating from '@/components/shared/rating';
import { mapState } from 'vuex';
import {
  namespace as namespaceUser,
  namespacedTypes as namespacedUser,
} from '@/store/modules/user-types';
import StatsDisplay from '@/components/shared/statsDisplay';

export default {
  name: 'mainStartNavDrawer',
  components: { ProfilePicture, Rating, StatsDisplay },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState(namespaceUser, ['profile', 'notifications']),
    balance_available() {
      return Number.isNaN(this.profile.balance_available)
        ? 0
        : this.profile.balance_available;
    },
    ratings_average() {
      return Math.round(this.profile.ratings_average);
    },
  },
  mounted() {
    EventBus.$on('toggle-drawer', () => {
      this.open = !this.open;
    });
  },
  methods: {
    to(dest) {
      this.close();

      setTimeout(() => {
        this.$router.push({ name: dest });
      }, 300);
    },
    close() {
      if (!this.open) {
        return;
      }

      this.open = false;
    },
    showHowto() {
      this.close();

      setTimeout(() => {
        EventBus.$emit('show-howto');
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  pointer-events: none !important;
}

.drawer {
  top: 20px;
  left: 20px;
  z-index: 9999;
  position: fixed;
  max-height: initial;
  pointer-events: all;
  border-radius: 34px 0 0 0;
  width: calc(100vw - 20px);
  background: var(--color-white);
  top: calc(var(--edge-padding-top) + 14px);
  height: calc(100vh - var(--edge-padding-top));

  .menu-items {
    > * {
      padding: 10px 0;
    }
    .menu-item {
      grid-column: 2 / span 5;
    }
  }

  .scrollable {
    /* - parent spacing - outer spacing - header height - grid padding */
    height: calc(100vh - 10px - 10px - 25px - 30px);
    overflow: auto;
  }

  .active-vacation {
    height: 13px;
    width: 13px;
    display: flex;
    justify-self: flex-end;
    align-self: center;
    border-radius: 100%;
    background: var(--color-orange);
    padding: 0;
  }

  .notice {
    height: 13px;
    width: 13px;
    display: flex;
    justify-self: flex-end;
    align-self: center;
    border-radius: 100%;
    background: var(--color-red);
    padding: 0;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.backdrop {
    top: 0;
    left: 0;
    opacity: 0.25;
    width: 100vw;
    z-index: 9998;
    height: 100vh;
    position: fixed;
    background-color: black;
  }

// slide animation
.slide-leave-active,
.slide-enter-active {
  transition-timing-function: ease-in-out;
  transition: transform 0.25s;
}
.slide-enter,
.slide-leave-to {
  transform: translate(100%, 0);
}

// fade animation
.fade-enter-active,
.fade-leave-active {
  transition-timing-function: ease-in-out;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.profile-picture {
  margin-top: 0.25rem;
}

.profile-preview {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .profile-info {
    padding-right: 0.25rem;
  }

  .profile-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
</style>
