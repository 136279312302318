/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_layers': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Group 5206"><path pid="0" data-name="Path 355" d="M12 17.46zm0-2.35l6.8-5.3 1.52-1.17L12 2.16 3.66 8.64l1.51 1.17z" _fill="none" _stroke="#b7b7b7" stroke-width="1.5"/><path pid="1" data-name="Path 2220" d="M12 19l-8.2-6.34-1.8 1.4 10 7.78 10-7.78-1.81-1.41L12 19z"/></g>'
  }
})
