<template>
  <n-grid>
    <n-text preset="sub" class="span-6" color="warning">{{
      $t('tripDetails.blockedArea.note', {
        name,
      })
    }}</n-text>
  </n-grid>
</template>

<script>
export default {
  name: 'blockedArea',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return this.payload[0][1];
    },
  },
};
</script>