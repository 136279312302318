<template>
  <div class="pill shadow">
    <n-text preset="sub" color="accent">
      <slot />
    </n-text>
    <span class="icon">
      <n-icon v-if="arrow" size="sm" name="chevron-right" color="accent" />
    </span>
    <span class="triangle">▼</span>
  </div>
</template>

<script>

  export default {
    props: {
      arrow: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
      };
    },
    mounted() {
    },
  };
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.pill {
  display: inline-flex;
  background-color: var(--color-white);
  padding: 5px 15px;
  border-radius: 200px;
  z-index: 800;
  position: relative;
  .triangle {
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -8px;
    margin-bottom: -15px;
    text-shadow: 0 4px 6px var(--color-shadow);
    color: var(--color-white);
    z-index: 801;
  }
  .icon {
    margin-left: 3px;
  }
}

</style>
