<template>
  <div class="view-container">
    <n-nav-bar :title="$t('profile.title')" />
    <n-scrollable>
      <n-layout>
        <n-grid
          :bottom-gap="3"
          class="flex-center"
        >
          <n-column :span="6">
            <profile-picture-upload
              class="profile-picture"
              :edit-icon="true"
              size="lg"
              :src="profile.image"
              @input="imageChanged('image', $event)"
            />
          </n-column>
        </n-grid>
        <n-textarea
          v-model="profile.description"
          class="span-6"
          maxlength="240"
          :placeholder="$t('profile.descriptionPlaceholder')"
          :label="$t('profile.description')"
          @blur="patchProfile('description')"
        />

        <n-grid
          :top-gap="3"
          :bottom-gap="1"
        >
          <n-dropdown
            v-model="profile.language"
            class="span-3"
            :options="languageOptions"
            icon="globe"
            @input="patchDropdown('language')"
          />
        </n-grid>

        <n-input
          v-model="profile.name"
          class="span-6"
          icon="pencil"
          :label="$t('profile.name')"
          @blur="patchProfile('name')"
        />
        <n-input
          v-model="profile.last_name"
          class="span-6"
          icon="pencil"
          :label="$t('profile.lastName')"
          @blur="patchProfile('last_name')"
        />
        <n-input
          :value="homeAddressFormatted"
          readonly
          class="span-6"
          icon="pencil"
          :label="$t('profile.address')"
          @click="$refs.homeAddressSearch.open()"
        />
        <!-- <n-grid :y-gap="10"> -->
        <n-input
          readonly
          class="span-6"
          icon="pencil"
          :value="storedPhoneNumber"
          :label="$t('profile.phone')"
          @click="$refs.phoneSheet.open()"
        />
        <!-- <n-button @click="() => { $refs.phoneSheet.open() }" class="span-6" type="outlined" inverted>{{ $t('profile.changePhone') }}</n-button> -->
        <!-- </n-grid> -->
        <n-grid :bottom-gap="5">
          <n-input
            v-model="profile.email"
            class="span-6"
            icon="pencil"
            :label="$t('profile.email')"
            @blur="patchProfile('email')"
          />
        </n-grid>
        <n-grid :bottom-gap="8">
          <n-text
            class="span-6 flex-center"
            preset="title"
            color="accent"
          >
            {{ $t('profile.workplaceTitle') }}
          </n-text>
          <n-input
            readonly
            :value="profile.workplace_name"
            class="span-6"
            icon="pencil"
            :label="$t('profile.workplace')"
            @click="$refs.workAddressSearch.open()"
          />
        </n-grid>
        <n-grid :bottom-gap="3">
          <terms
            class="span-6"
            readonly
          />
        </n-grid>
        <n-grid
          :bottom-gap="10"
          :row-gap="3"
        >
          <n-button
            :loading="deleting"
            color="error"
            type="outlined"
            block
            inverted
            @click="logout"
          >
            {{ $t('profile.logOut') }}
          </n-button>
          <n-button
            :loading="deleting"
            color="error"
            type="filled"
            block
            @click="deleteAccount"
          >
            {{ $t('profile.deleteAccount') }}
          </n-button>
        </n-grid>

        <n-grid>
          <n-text
            class="span-6"
            preset="title"
            color="grey-dark"
          >
            {{ $t('profile.version.title') }}
          </n-text>
          <n-text
            class="span-6"
            preset="sub"
            color="grey-dark"
          >
            <span @click="goToStore">{{ $t('profile.version.app') }} {{ version.name }}/{{
              version.build
            }}
              {{ isNativeUpdateAvailable ? '⬆️' : '✅️' }}</span>
          </n-text>
          <n-text
            class="span-6"
            preset="sub"
            color="grey-dark"
          >
            <span @click="updateFrontend">{{ $t('profile.version.release') }} {{ release }}
              {{ isUpdateAvailable ? '⬆️' : '✅️' }}
            </span>
          </n-text>
        </n-grid>
      </n-layout>
    </n-scrollable>
    <!-- PHONE SHEET -->
    <n-bottom-sheet
      ref="phoneSheet"
      type="header"
      :title="$t('profile.changePhone')"
    >
      <n-grid :bottom-gap="3">
        <n-text
          class="span-6"
          color="accent"
          preset="title"
        >
          {{ $t('profile.phoneSheet.header') }}
        </n-text>
        <n-text
          class="span-6"
          color="grey-dark"
          preset="sub"
        >
          {{ $t('profile.phoneSheet.description') }}
        </n-text>
      </n-grid>
      <n-grid>
        <n-dropdown
          v-model="profile.phoneCountry"
          class="span-6"
          :options="phoneCountryOptions"
          icon="phono-landline"
        />
      </n-grid>
      <n-grid :top-gap="4">
        <n-input
          v-model="tmpPhone"
          :placeholder="$t('profile.phone')"
        />
        <recaptcha-button
          class="span-6"
          :loading="smsCodeLoading"
          type="outlined"
          inverted
          size="md"
          @click="sendSmsCode"
        >
          {{ $t('profile.phoneSheet.sendSMSButton') }}
        </recaptcha-button>
        <n-input
          v-if="isPhoneCodeSent"
          v-model="smsCode"
          :placeholder="$t('profile.phoneSheet.smsCodePlaceHolder')"
        />
      </n-grid>
      <n-grid
        v-if="isPhoneCodeSent"
        :top-gap="4"
      >
        <n-button
          class="span-6"
          :loading="changePhoneLoading"
          size="lg"
          @click="changePhone()"
        >
          {{ $t('profile.phoneSheet.changePhone') }}
        </n-button>
      </n-grid>

      <recaptcha-terms />
    </n-bottom-sheet>
    <!-- ADDRESS SHEETS -->
    <address-search-sheet
      ref="homeAddressSearch"
      :favorites="false"
      :title="$t('profile.findAddress')"
      @input="homeAddressSelected"
    />
    <address-search-sheet
      ref="workAddressSearch"
      :favorites="false"
      :title="$t('profile.findAddress')"
      @input="workAddressSelected"
    />
    <n-dialog />
  </div>
</template>
<script>
import {
  getAppVersion,
  getReleaseVersion,
  getAllAddressComponents,
} from '@/vendor/utils';
import Terms from '@/components/shared/terms';
import AddressSearchSheet from '@/components/shared/addressSearchSheet';
import ProfilePictureUpload from '@/components/shared/profilePictureUpload';
import { namespacedTypes as namespacedUser } from '@/store/modules/user-types';
import {
  countryOptions,
  languageOptions,
  phoneCountryOptions,
} from '@/vendor/static-options';
import { handleError } from '@/vendor/axios';
import { mapState } from 'vuex';
import nativeBridge from '@/native-bridge';
import { SWHelper } from '@/serviceWorkerHelper';
import RecaptchaTerms from '@/components/shared/recaptchaTerms';
import RecaptchaButton from '@/components/shared/recaptchaButton';
import AvatarWithIcon from '@/components/shared/account/avatarWithIcon.vue';
import Icon from '@/components/core/typography/icon.vue';

export default {
  name: 'ProfileScreen',
  components: {
    Terms,
    ProfilePictureUpload,
    AddressSearchSheet,
    RecaptchaTerms,
    RecaptchaButton,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setData();
    });
  },
  data() {
    return {
      countryOptions,
      languageOptions,
      smsCodeLoading: false,
      changePhoneLoading: false,
      deleting: false,
      phoneCountryOptions,
      smsCode: null,
      tmpPhone: null,
      isPhoneCodeSent: false,
      profile: {
        name: null,
        last_name: null,
        email: null,
        phoneCountry: null,
        phone_number: null,
        address: null,
        language: null,
      },
      selectedLanguage: null,
      release: getReleaseVersion(),
      version: getAppVersion(),
    };
  },
  computed: {
    ...mapState('app', ['isUpdateAvailable', 'isNativeUpdateAvailable']),
    storedPhoneNumber() {
      const { phoneCountry, phone_number } = this.$store.state.user.profile;

      if (phone_number.indexOf('+') === 0) {
        return phone_number;
      }

      return `${phoneCountry ?? ''}${phone_number}`;
    },
    homeAddressFormatted() {
      const { street, zipcode, city } = this.$store.state.user.profile;

      if (!street || !city) {
        return null;
      }

      return [street, `${zipcode} ${city}`]
        .map((x) => x?.trim())
        .filter((x) => x)
        .join(', ');
    },
  },
  methods: {
    setData() {
      this.profile = Object.assign({}, this.$store.state.user.profile);
      this.profile.phoneCountry = this.phoneCountryOptions.find(
        (x) => x.id === this.profile.phoneCountry
      );
      this.tmpPhone = this.profile.phone_number;
      this.profile.language = this.languageOptions.find(
        (x) => x.id === this.profile.language
      );
    },
    patchProfile(prop) {
      let value = this.profile[prop];

      // Don't try to save empty required fields
      if (['name', 'last_name', 'email'].includes(prop) && value.trim() === '') {
        return;
      }
      this.patch({ [prop]: value });
    },
    patchDropdown(prop) {
      let value = this.profile[prop] ? this.profile[prop].id : null;
      this.patch({ [prop]: value });

      // Keep switch up to date with dropdown
      if (prop === 'language') {
        this.$store.commit(namespacedUser.SELECT_LANGUAGE, {
          lang: this.profile.language.id,
          skipSet: true,
        });
      }
    },
    patch(obj) {
      this.$store.dispatch(namespacedUser.PATCH_PROFILE, obj);
    },
    async sendSmsCode(recaptcha) {
      if (this.smsCodeLoading) {
        return;
      }

      this.smsCodeLoading = true;

      try {
        await this.$store.dispatch(namespacedUser.REQUEST_UPDATE_PHONE_OTP, {
          phone_number: `${this.profile.phoneCountry.id}${this.tmpPhone}`,
          recaptcha,
        });

        this.isPhoneCodeSent = true;
      } catch (error) {
        const res = error.response;
        if (res && res.data.errors) {
          console.log(res.data.errors);
          const errorMessage = res.data.errors.phone_number
            ? this.$t('error.existing_phone_number')
            : Object.values(res.data.errors)
                .map((values) => values.join(' '))
                .join(' ');

          this.$error(errorMessage);
        } else {
          handleError(error);
        }
      } finally {
        this.smsCodeLoading = false;
      }
    },
    async changePhone() {
      if (this.changePhoneLoading) {
        return;
      }

      this.changePhoneLoading = true;

      try {
        await this.$store.dispatch(namespacedUser.UPDATE_PHONE, {
          phone_number: `${this.profile.phoneCountry.id}${this.tmpPhone}`,
          code: this.smsCode,
        });
        this.$refs.phoneSheet.dismiss();
        this.$set(this.profile, 'phone_number', this.tmpPhone);
        // Reset sheet
        this.isPhoneCodeSent = false;
        this.smsCode = null;
        this.$success(this.$t('profile.changesSaved'));
      } catch (error) {
        const errorCode = error.response?.data?.errors?.phone_number
          ? 'existing_phone_number'
          : 'invalid_phone_code';
        this.$error(this.$t(`error.${errorCode}`));
      } finally {
        this.changePhoneLoading = false;
      }
    },
    homeAddressSelected(address) {
      const addrData = address.transformed
        ? address.parts
        : getAllAddressComponents(address.placeObject);

      this.patch({
        street: addrData.street,
        zipcode: addrData.zipcode,
        city: addrData.city,
        country: addrData.country,
        lat: addrData.lat,
        long: addrData.lng,
      });
    },
    workAddressSelected(address) {
      const newData = {
        workplace_name: address.structured_formatting
          ? address.structured_formatting.main_text
          : address.description,
        workplace_pid: address.parts.place_id,
      };

      this.patch(newData);

      this.$set(this.profile, 'workplace_name', newData.workplace_name);
      this.$set(this.profile, 'workplace_pid', newData.workplace_pid);
    },
    logout() {
      this.$store.dispatch(namespacedUser.SIGN_OUT);
      this.$router.resetToHome();
    },
    deleteAccount() {
      this.$modal.show('dialog', {
        title: this.$t('profile.delete.title'),
        text: this.$t('profile.delete.text'),
        success: {
          text: this.$t('profile.delete.successButton'),
          handler: async () => {
            this.deleting = true;
            await this.$store.dispatch(namespacedUser.DELETE_ACCOUNT);
            this.$router.resetToHome();
            this.deleting = false;
          },
        },
        color: 'error',
        cancel: true,
      });
    },
    // Keep swotch up to date with dropdown
    async imageChanged(type, e) {
      this[`${type}_loading`] = true;
      const resp = await this.$store.dispatch(
        namespacedUser.PATCH_PROFILE_FORM,
        { [type]: e }
      );
      this.$set(this.profile, type, resp[type]);
      this[`${type}_loading`] = false;
    },
    goToStore() {
      nativeBridge.send.openURL(this.$t('landingSignUp.downloadLink'));
    },
    async updateFrontend() {
      await SWHelper.skipWaiting();
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  margin-bottom: 10px;
}
</style>
