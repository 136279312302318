<template>
  <n-layout v-if="visible" :spacing-y="false" :spacing-x="!compact">
    <outside-sponsored-area
      v-if="booking?.discount_eligible === 'outside_sponsored_area'"
    />

    <fair-use-exceeded
      v-else-if="booking?.discount_eligible === 'fair_use_exceeded' && booking?.driver"
      :user="booking.driver"
      :payload="booking?.discount_eligible_payload"
    />

    <minimum-distance-not-reached
      v-else-if="booking?.discount_eligible === 'minimum_distance_not_reached'"
      :payload="booking.discount_eligible_payload"
    />

    <blocked-area
      v-else-if="booking?.discount_eligible === 'blocked_area'"
      :payload="booking.discount_eligible_payload"
    />

    <zero-subsidy-area
      v-else-if="booking?.discount_eligible === 'eligible' && !discounted"
      :contract="booking.contract"
    />

    <n-spacer />
  </n-layout>
</template>

<script>
import BlockedArea from '@/components/shared/tripDetails/blockedArea';
import FairUseExceeded from '@/components/shared/tripDetails/fairUseExceeded';
import ZeroSubsidyArea from '@/components/shared/tripDetails/zeroSubsidyArea';
import OutsideSponsoredArea from '@/components/shared/tripDetails/outsideSponsoredArea';
import MinimumDistanceNotReached from '@/components/shared/tripDetails/minimumDistanceNotReached';

export default {
  name: 'discountEligibilityWarning',
  components: {
    BlockedArea,
    FairUseExceeded,
    ZeroSubsidyArea,
    OutsideSponsoredArea,
    MinimumDistanceNotReached,
  },
  props: {
    booking: {
      type: Object,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    discounted() {
      return this.booking?.price !== this.booking?.actual_price;
    },
    visible() {
      return this.booking?.discount_eligible !== 'eligible' || !this.discounted;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  justify-content: flex-end;
}
</style>