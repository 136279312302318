/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_arrows-swap': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 35 swap" transform="translate(2.5, 2)">><path d="M5.8355 4.15125L2.86463 7.12211L2.12192 6.36614L6.36601 2.12204L10.6101 6.36614L9.86739 7.12212L6.89652 4.15125L6.89652 10.6102L5.8355 10.6102L5.8355 4.15125Z" fill="#00CC6D" /><path d="M11.8533 16.5522L11.8533 17.8207L12.7189 16.8934L15.321 14.1054L15.3858 14.1761L11.8228 17.9936L8.25987 14.1761L8.3247 14.1054L10.9268 16.8934L11.7923 17.8207L11.7923 16.5522L11.7923 10.1319L11.8533 10.1319L11.8533 16.5522Z" fill="#00CC6D" stroke="#00CC6D" /></g>'
  }
})
