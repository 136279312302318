<template>
  <n-theme type="driver">
    <activity-wrapper
      ref="wrapper"
      icon="location-alt"
      :status-color="statusColor"
      :clock="onDemandTrip.planned_departure"
    >
      <template #status>
        {{
          $tc(
            `overview.single.driver.bookedSeats`,
            passengersAcceptedSeats
          )
        }}
      </template>

      <template #steps>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :time="step.time"
          :address="step.address"
        />
      </template>

      <template #extra>
        <n-column
          :span="6"
          class="flex-v-center"
        >
          <seats
            :waiting="0"
            :accepted="passengersAcceptedSeats"
            :total="onDemandTrip.seats"
          />

          <n-text
            color="accent-faded"
            preset="title"
          >
            {{
              $tc('overview.single.driver.bookedSeats', passengersAcceptedSeats)
            }}
          </n-text>
        </n-column>
      </template>

      <template #buttons>
        <n-button
          size="lg"
          type="filled"
          block
          @click="openTrip"
        >
          {{ $t('overview.single.onDemand.showTripButton') }}
        </n-button>

        <n-grid
          :bottom-gap="-4"
          class="on-demand-end-notice"
        >
          <n-column
            :span="6"
            center
          >
            <n-text
              color="warning"
              preset="sub"
            >
              {{ $t('overview.single.onDemand.rememberToEndNotice') }}
            </n-text>
          </n-column>
        </n-grid>
      </template>
    </activity-wrapper>
  </n-theme>
</template>

<script>
import constants from '@/constants';
import Step from '@/components/shared/overview/parts/step';
import Seats from '@/components/shared/overview/parts/seats';
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import { format } from 'date-fns';

export default {
  name: 'OnDemandTripActivity',
  components: {
    Step,
    Seats,
    ActivityWrapper,
  },
  props: {
    onDemandTrip: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      isRating: false,
      offering: false,
      isExpanded: false,
      statusColor: 'accent',
    };
  },
  computed: {
    passengersAccepted() {
      return this.onDemandTrip.passenger_trips.filter(
        (p) => p.status === constants.commuteBookingStatus.accepted
      );
    },
    passengersAcceptedSeats() {
      return this.passengersAccepted.reduce(
        (sum, passengerTrip) => sum + passengerTrip.seats,
        0
      );
    },
    steps() {
      const steps = [];

      steps.push({
        time: format(new Date(this.onDemandTrip.planned_departure), 'HH:mm'),
        address: this.onDemandTrip.from_street,
      });

      steps.push({
        time: null,
        address: this.onDemandTrip.to_street,
      });

      return steps;
    },
  },
  methods: {
    openTrip() {
      this.$router.push({
        name: 'main.active-trip',
        params: {
          id: this.onDemandTrip.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.on-demand-end-notice {
  padding: 5px;
}
</style>
