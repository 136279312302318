<template>
  <div v-show="hasElements">
    <n-icon name="dots-horizontal" color="grey-dark" @click="show = true" />
    <portal to="action-sheet" :disabled="!show" ref="actionSheetPortal">
      <div class="action-sheet">
        <div v-show="show" class="overlay" @click="close"></div>
        <div v-swipe:down="close" class="items" v-show="show">
          <slot :closeSheet="closeSheet" v-if="!loading">
            <n-action-sheet-item
              v-for="(item, index) in items"
              :key="index"
              :icon="item.icon"
              @click.native="itemClick(item)">
              {{ item.text }}
            </n-action-sheet-item>
          </slot>
          <slot name="loading" v-if="loading"/>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { Portal } from 'portal-vue';
import { Swipe } from '@/directives/SwipeDirective.js';
import TransitionSlideVertical from '@/components/shared/transition/transitionSlideVertical';
export default {
  directives: { Swipe },
  components: { Portal, TransitionSlideVertical },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      isMounted: false,
      loading: false,
    };
  },
  methods: {
    async closeSheet(closeAction) {
      const isPromise = closeAction[Symbol.toStringTag] === 'Promise';
      if (isPromise) {
        this.loading = true;
        try {
          await Promise.resolve(closeAction);
        } finally {
          this.loading = false;
        }
      } else if (typeof closeAction === 'function') {
        closeAction();
      }
      this.show = false;
    },
    itemClick(item) {
      this.show = false;
      item.action();
    },
    close() {
      this.show = false;
    }
  },
  computed: {
    hasElements() {
      if(!this.isMounted) {
        return;
      }
      return !!this.$refs?.actionSheetPortal?.$children?.length || !!this.items.length;
    }
  },
  mounted(){
    this.isMounted = true;
  }
};
</script>


<style scoped>
.overlay {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  position: fixed;
}
.items {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 50px;
  z-index: 10;
  padding-bottom: calc(var(--edge-padding-bottom) + 25px);
}
</style>
