/* eslint-disable no-param-reassign,no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable max-len */

import { format } from '@/vendor/date-fns';
import Vue from 'vue';
import commuteApi from '@/api/commute';
import * as util from '@/vendor/utils';
import constants from '@/constants';
import typesCommute from './commute-types';

// initial state
const initialState = () => ({
  create: {
    startAddress: null,
    endAddress: null,
    seats: 2, // part of COVID-19 measures (normally 3 seats by default)
    autoOffer: true,
    commuteType: constants.commuteTypes.singleTrip,
    departureTime: null,
    returnTime: null,
    weekdays: {},
    selectedDepartureRoute: null,
    selectedDepartureStops: null,
    selectedReturnStops: null,
  },
  confirm: {
    confirming: 'single',
    trip: null,
    type: null,
    date: null,
    leaving: null,
    returning: null,
    automatic: true,
    multiple: [],
  },
  book: {
    startAddress: null,
    endAddress: null,
    seats: 1,
    commuteType: constants.commuteTypes.singleTrip,
    time: null,
    timeType: constants.timeTypes.departure,
    weekdays: {},
  },
  recurring: {},
  editRecurring: {
    id: null,
    startAddress: null,
    endAddress: null,
    seats: 1,
    commuteType: constants.commuteTypes.singleTrip,
    time: null,
    timeType: constants.timeTypes.departure,
    weekdays: {},
    selectedDepartureRoute: null,
    selectedDepartureStops: null,
    selectedReturnStops: null,
    trip: null,
    changedRoute: false,
  },
  editOffer: {
    commuteType: constants.commuteTypes.singleTrip,
  },
  marketplace: {
    filter: {
      from: null,
      to: null,
    },
    booking: {
      disregardAgents: false,
      departureRequest: null,
      returnRequest: null,
      departureTrip: null,
      returnTrip: null,
    },
    checkout: {
      departure: {
        request_id: null,
        driver_trip_id: null,
        from_driver_stop_id: null,
        to_driver_stop_id: null,
        seats: null,
      },
      return: {
        request_id: null,
        driver_trip_id: null,
        from_driver_stop_id: null,
        to_driver_stop_id: null,
        seats: null,
      },

      departure_trip_count: 0,
      return_trip_count: 0,

      payment_type: null,
      payment_links: {
        departure: null,
        return: null,
      },
    },
    departureTime: null,
  },
  overview: {
    requests: [],
    driverTrips: [],
    passengerTrips: [],
  },
  history: { },
});

// actions
const actions = {
  [typesCommute.FETCH_RECURRING]({ commit }) {
    return commuteApi.fetchRecurring()
      .then((data) => {
        commit(typesCommute.UPDATE_RECURRING, data);

        return data;
      });
  },
  [typesCommute.DELETE_RECURRING]({ commit, dispatch }, trip) {
    return commuteApi.deleteRecurring(trip.id)
      .then(async (data) => {
        await dispatch(typesCommute.UPDATE_OVERVIEW);
        commit(typesCommute.UPDATE_RECURRING, data);
        return data;
      });
  },
  [typesCommute.UPDATE_OVERVIEW]({ commit }) {
    return commuteApi.getOverviewList()
      .then((data) => {
        commit(typesCommute.OVERVIEW_UPDATED, data);

        if (data?.on_demand_trip === null) {
          commit('trip/invalidateDriverTrip', null, { root: true });
        }
      });
  },
  [typesCommute.UPDATE_HISTORY]({ commit }) {
    return commuteApi.getHistory()
      .then((data) => {
        commit(typesCommute.HISTORY_UPDATED, data);
      });
  },
  [typesCommute.MORE_HISTORY]({ commit, state }) {
    return commuteApi.getHistory(state.history.current_page + 1)
      .then((data) => {
        commit(typesCommute.HISTORY_PAGED, data);

        return data;
      });
  },
  [typesCommute.RATE_DRIVER_IN_OVERVIEW]({ commit }, { passengerTripId, rating }) {
    return commuteApi.rateCommuteUser(passengerTripId, rating, null)
      .then((data) => {
        commit(typesCommute.OVERVIEW_PASSENGER_TRIP_UPDATED, {
          passengerTripId,
          data: {
            driver_rating: data,
          },
        });
      });
  },
  [typesCommute.RATE_PASSENGER_IN_OVERVIEW]({ commit }, { passengerTripId, rating }) {
    return commuteApi.rateCommuteUser(passengerTripId, rating, null)
      .then((data) => {
        commit(typesCommute.OVERVIEW_DRIVER_TRIP_PASSENGER_UPDATED, {
          driverTripId: data.driver_trip_id,
          passengerTripId: data.passenger_trip_id,
          data: {
            passenger_rating: data,
          },
        });
      });
  },
  [typesCommute.START_TRIP]({ commit, dispatch }, driverTripId) {
    return commuteApi.startTrip(driverTripId).then(async (data) => {
      await dispatch(typesCommute.UPDATE_OVERVIEW);
      return true;
    });
  },
  [typesCommute.END_TRIP]({ commit, dispatch }, driverTripId) {
    return commuteApi.endTrip(driverTripId).then(async (data) => {
        await dispatch(typesCommute.UPDATE_OVERVIEW);
        return true;
      });
  },
  // V4
  [typesCommute.CREATE_COMMUTE_REQUEST]({ commit }, requestData) {
    if (!requestData.type) {
      requestData.type = constants.commuteTypes.singleTrip;
    }
    return commuteApi.postPassengerRequest(requestData)
      .then((result) => {
        commit(typesCommute.SET_MARKETPLACE_BOOKING, {
          departure: result.departure_request,
        });
        return result;
      });
  },
  async [typesCommute.UPDATE_RECURRING]({ commit }, updateData) {
    return commuteApi.saveRecurring(updateData)
      .then((data) => {
        commit(typesCommute.CLEAR_RECURRING_EDIT);
        commit(typesCommute.UPDATE_RECURRING, data);

        return data;
      });
  },
  async [typesCommute.OFFER_TRIP]({ commit }, id) {
    return commuteApi.offerTrip(id).then(() => {
      commit(typesCommute.DRIVER_TRIP_OFFERED_MANUEL, id);
    });
  },
  async [typesCommute.CREATE_AUTO_PASSENGER]({ commit }, { driverTripId, fromStop, toStop }) {
    return commuteApi.createAutoPassengerRequest({
      driver_trip_id: driverTripId,
      from_driver_stop_id: fromStop,
      to_driver_stop_id: toStop
    }).then((data) => {
      return data;
    });
  },
  async [typesCommute.DELETE_AUTO_PASSENGER]({ commit }, id) {
    return commuteApi.deleteAutoPassengerTrip(id)
      .then((data) => { return data; });
  },
};

// mutations
const mutations = {
  /* eslint-disable */
  [typesCommute.CLEAR](currentState) {
    Object.assign(currentState, initialState());
  },
  [typesCommute.OVERVIEW_UPDATED](state, data) {
    state.overview.requests = data.requests;
    state.overview.driverTrips = data.driver_trips;
    state.overview.passengerTrips = data.passenger_trips;
    state.overview.onDemandTrip = data.on_demand_trip;
  },
  [typesCommute.HISTORY_UPDATED](state, data) {
    state.history = data;
  },
  [typesCommute.HISTORY_PAGED](state, data) {
    state.history.current_page = data.current_page;
    state.history.data.push(...data.data);
  },
  [typesCommute.OVERVIEW_DRIVER_TRIP_REMOVED](state, tripId) {
    const index = state.overview.driverTrips.findIndex(x => x.id === tripId);
    state.overview.driverTrips.splice(index, 1);
  },
  [typesCommute.OVERVIEW_REQUEST_REMOVED](state, requestId) {
    const index = state.overview.requests.findIndex(x => x.id === requestId);
    state.overview.requests.splice(index, 1);
  },
  [typesCommute.SET_MARKETPLACE_BOOKING](state, trips) {
    if (trips.departureRequest) {
      state.marketplace.booking.departureRequest = trips.departureRequest;
    } else {
      state.marketplace.booking.departureRequest = trips.departure;
    }
    state.marketplace.booking.returnRequest = trips.return;

    state.marketplace.booking.disregardAgents = false;
  },
  [typesCommute.UPDATE_RECURRING](state, recurring) {
    state.recurring = recurring;
  },
  [typesCommute.SET_OFFER_EDIT](state, trip) {
    const data = {
      ...state.editOffer,
    };

    Object.keys(trip).forEach((key) => {
      data[key] = trip[key];
    });

    data.startAddress = {
      description: util.formatAddressComponents({
        street: trip.from_street,
        zipcode: trip.from_zipcode,
        city: trip.from_city,
      }),
      geometry: {
        location: {
          lat: trip.from_lat,
          lng: trip.from_lng,
        },
      },
      existing: true,
    };

    data.endAddress = {
      description: util.formatAddressComponents({
        street: trip.to_street,
        zipcode: trip.to_zipcode,
        city: trip.to_city,
      }),
      geometry: {
        location: {
          lat: trip.to_lat,
          lng: trip.to_lng,
        },
      },
      existing: true,
    };

    data.time = null;
    data.trip = trip;
    data.commuteType = constants.commuteTypes.singleTrip;
    data.type = constants.commuteTypes.singleTrip;
    data.departureTime = format(trip.planned_departure, '');
    data.arrivalTime = format(trip.planned_arrival, '');
    data.from_lng = trip.from_lng;
    data.from_lat = trip.from_lat;
    data.to_lng = trip.to_lng;
    data.to_lat = trip.to_lat;
    Vue.set(state, 'editOffer', {
      ...data,
      original: {
        startAddress: data.startAddress,
        endAddress: data.endAddress,
      },
    });
    return data;
  },
  [typesCommute.CLEAR_RECURRING_EDIT](currentState) {
    currentState.editRecurring = initialState().editRecurring;
  },
  [typesCommute.OVERVIEW_PASSENGER_TRIP_UPDATED](
    state,
    { passengerTripId, data },
  ) {
    let passengerTrip = state.overview.passengerTrips.find(
      trip => trip.id === passengerTripId,
    );
    if (!passengerTrip && state?.history?.data) { // find in history
      passengerTrip = state.history.data.find(
        trip => trip.id === passengerTripId && trip.driver_trip_id,
      );
    }

    if (passengerTrip) {
      const keys = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        passengerTrip[key] = data[key];
      }
    }
  },
  [typesCommute.OVERVIEW_DRIVER_TRIP_PASSENGER_UPDATED](
    state,
    { driverTripId, passengerTripId, data },
  ) {
    let driverTrip = state.overview.driverTrips.find(
      trip => trip.id === driverTripId,
    );
    if (!driverTrip && state.history.data) { // Check the history state
      driverTrip = state.history.data.find(
        trip => trip.id === driverTripId && !trip.driver_trip_id,
      );
    }

    const passengerTrip = driverTrip.passenger_trips.find(
      trip => trip.id === passengerTripId,
    );
    if (passengerTrip) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        passengerTrip[key] = data[key];
      }
    }
  },
  // V4
  [typesCommute.TRIP_DETAILS](state, { trip, fromAddress, toAddress, seats, requestId }) {
    state.tripDetails = {trip, fromAddress, toAddress, seats, requestId};
  },
  [typesCommute.DRIVER_TRIP_OFFERED_MANUEL](state, id) {
    state.overview.driverTrips.find(x => x.id === id).offered = true;
  },
};

// getters
const getters = {
  [typesCommute.CREATE](state) {
    return state.create;
  },
  [typesCommute.BOOK](state) {
    return state.book;
  },
  [typesCommute.OFFER_EDIT](state) {
    return state.editOffer;
  },
  // V4
  [typesCommute.HAS_ACTIVITY](state) {
    if (state.overview.driverTrips.length > 0 ||
        state.overview.passengerTrips.length > 0 ||
        state.overview.requests.length > 0 ||
        Object.keys(state.recurring).length > 0 ||
        !!state.overview.onDemandTrip
      )
      {
        return true;
      }
      return false;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
