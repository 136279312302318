import axios from '@/vendor/axios';
import store from '@/store';
import { sanitizedLocation } from '@/vendor/utils';

export default {

  async autocompleteAddress(term) {
    const location = store.getters['user/userLocation'];
    if (!location) {
      store.dispatch('user/LOCATION_FROM_ZIP');
    }
    return axios
      .get('/v4/maps/place/autocomplete/json', {
        params: {
          language: store.getters['user/language'], input: term, origin: location, location, distance: true, fields: 'geometry'
        },
      })
      .then((response) => response.data);
  },
  /**
   *  @deprecated - Use reverseGeocodeMap instead
   */
  async reverseGeocode(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    return axios
      .get('/v4/maps/geocode/json', { params: { language: store.getters['user/language'], latlng: `${lat},${lng}` } })
      .then((response) => response.data);
  },
  /**
   *  @deprecated - Use reverseGeocodeMap instead
   */
  async reverseGeocodeLatLng(latLng) {
    const location = sanitizedLocation(latLng);
    const position = {
      coords: {
        latitude: location.lat,
        longitude: location.lng
      }
    };

    return this.reverseGeocode(position);
  },
  /**
   *
   * @param {Point} coordinate
   * @returns {ReverseGeocodeResponse}
   */
  async reverseGeocodeMap(coordinate) {
    const params = {
      language: store.getters['user/language'],
      lat: coordinate.lat,
      lng: coordinate.lng,
    };

    return axios
      .get('/reverse-geocode', { params })
      .then((response) => response.data);

  },
  async placeDetails(placeId) {
    return axios
      .get(`/place/${placeId}`, { params: { language: store.getters['user/language'] } })
      .then((response) => response.data);
  },
  /**
   * @deprecated - Implement a custom endpoint instead
   * @param {string} searchTerm
   * @returns
   */
  async geocode(searchTerm) {
    return axios
      .get('/v4/maps/geocode/json', { params: { language: store.getters['user/language'], address: searchTerm } })
      .then((response => response.data));
  },
};
