<template>
  <n-column v-if="loading" class="trip-code loading" :span="6">
    <n-spinner />
  </n-column>
  <n-column v-else class="trip-code" :span="6">
    <n-text-model
      v-if="code"
      class="code"
      align="center"
      size="4xl"
      weight="regular"
      color="accent"
    >
      {{ code }}
    </n-text-model>

    <n-text class="renewal" align="center" preset="label-2" color="grey">
      <i18n path="c.tripCode.renewsIn">
        <template v-slot:time>
          <n-text preset="label-2" color="accent">
            {{ time }}
          </n-text>
        </template>
      </i18n>
    </n-text>

    <div v-if="link" class="qr-container">
      <qrcode-vue class="qr" :value="link" level="L" render-as="svg" />
    </div>
  </n-column>
</template>

<script>
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { format } from '@/vendor/date-fns';
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    code: {
      type: String,
      required: false,
      default: null,
    },
    renewsAt: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      time: null,
      prevRenewsAt: null,
      prevTimestamp: null,
      animationFrameRequest: null,
    };
  },
  mounted() {
    this.runUpdateTimeNextFrame();
  },
  beforeDestroy() {
    this.cancelUpdateTimeNextFrame();
  },
  methods: {
    cancelUpdateTimeNextFrame() {
      cancelAnimationFrame(this.animationFrameRequest);
    },
    runUpdateTimeNextFrame() {
      this.animationFrameRequest = requestAnimationFrame(this.updateTime);
    },
    updateTime(timestamp) {
      if (!this.prevTimestamp || timestamp - this.prevTimestamp >= 1000 || this.prevRenewsAt !== this.renewsAt) {
        this.prevRenewsAt = this.renewsAt;
        this.prevTimestamp = timestamp;

        const now = new Date();
        const renewsAt = new Date(this.renewsAt);

        let minutes = differenceInMinutes(renewsAt, now);
        let seconds = differenceInSeconds(renewsAt, now) % 60;

        if (now > renewsAt || (minutes === 0 && seconds === 0)) {
          this.$emit('renew');

          minutes = 0;
          seconds = 0;
        }

        this.time = format(new Date(0, 0, 0, 0, minutes, seconds), 'mm:ss');
      }

      this.runUpdateTimeNextFrame();
    },
  },
};
</script>

<style lang="scss" scoped>
.trip-code {
  margin-top: 1rem;
  margin-bottom: 2rem;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(40vw + 5.625rem);
  }

  .code {
    display: block;
    letter-spacing: 0.15em;
  }

  .renewal {
    display: block;
    margin-top: -0.25rem;

    .time {
      font-weight: bold;
    }
  }
}

.qr-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  .qr {
    width: 100%;
    max-width: 40vw;

    ::v-deep svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
