/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_pencil': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 31 edit-2"><g data-name="Group 2859-2"><path pid="0" data-name="Icon feather-edit-2-2" d="M17 3a2.84 2.84 0 014.24 3.78l-.19.22L7.51 20.5 2 22l1.5-5.51z" _fill="#b7b7b7"/><path pid="1" data-name="Path 2204-2" d="M14.55 5.39l4 4.05"/></g></g>'
  }
})
