<template>
  <html-sheet
    ref="sheet"
    :title="$t('tripDetails.outsideSponsoredArea.helpSheetTitle')"
    :content="$t('tripDetails.outsideSponsoredArea.helpSheetBody')"
  />
</template>

<script>
import { EventBus } from '@/vendor/events';
import HtmlSheet from '@/components/shared/htmlSheet';

export default {
  name: 'outsideSponsoredArea',
  components: {
    HtmlSheet,
  },
  mounted() {
    EventBus.$on('outside-sponsored-area-sheet-trigger', this.trigger);
    EventBus.$on('outside-sponsored-area-sheet-dismiss', this.dismiss);
  },
  beforeDestroy() {
    EventBus.$off('outside-sponsored-area-sheet-trigger', this.trigger);
    EventBus.$off('outside-sponsored-area-sheet-dismiss', this.dismiss);
  },
  methods: {
    trigger() {
      this.$refs.sheet.trigger();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
  },
};
</script>
