/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z"/>'
  }
})
