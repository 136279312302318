<template>
    <n-bottom-sheet
      type="header"
      :title="title"
      scrollable
      ref="sheet"
    >
      <n-column v-if="loadVideo && video" :span="6">
        <div class="html-sheet-video-container">
          <div v-html="video"></div>
        </div>
      </n-column>
  
      <n-column v-if="content" :span="6">
        <n-text preset="body" color="grey-dark">
          <span v-html="content"></span>
        </n-text>
      </n-column>
    </n-bottom-sheet>
  </template>
  
  <script>
  export default {
    name: 'htmlSheet',
    props: {
        title: {
            type: String,
            required: true,
        },
        video: {
            type: String,
            required: false,
        },
        content: {
            type: String,
            required: false,
        },
    },
    data() {
      return {
        loadVideo: false,
      };
    },
    methods: {
      trigger() {
        // avoid loading video if the sheet is never opened
        this.loadVideo = true;

        this.$refs.sheet.open();
      },
      dismiss() {
        this.$refs.sheet.dismiss();
      },
    },
  };
  </script>
  
  <style lang="scss">
  .html-sheet-video-container {
    max-width: 100%;
    margin-bottom: 1rem;
  
    & > div {
      position: relative;
      padding-bottom: 60%;
      height: 0px;
    }
  
    iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
  </style>
  