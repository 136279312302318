<template>
  <n-bottom-sheet
    type="header"
    :title="$t('voucher.sheetTitle')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-text class="span-6" color="accent" preset="title">{{ $t('voucher.sheetHelpTitle') }}</n-text>
    <n-text class="span-6" color="grey-dark" preset="sub">{{ $t('voucher.sheetDescription') }}</n-text>
    <n-grid :y-gap="4">
      <n-input
        v-model="voucher"
        :placeholder="$t('voucher.voucher')"
      />
    </n-grid>
    <n-button
      class="span-6"
      :loading="addingVoucher"
      @click="addVoucher"
      size="lg"
    >{{ $t('voucher.activateVoucher') }}</n-button>
  </n-bottom-sheet>
</template>

<script>
import store from '@/store';
import userApi from '@/api/user';

export default {
  data: () => {
    return {
      voucher: store.state.user.voucher,
      addingVoucher: false,
    }
  },
  methods: {
    open() {
      this.$refs.sheet.open();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
    async addVoucher() {
      if (this.addingVoucher) {
        return;
      }

      this.addingVoucher = true;

      try {
        store.commit('user/SET_VOUCHER', null);

        const resp = await userApi.addVoucher(this.voucher);

        this.voucher = null;
        store.dispatch('user/FETCH_PROFILE');

        this.dismiss();

        if (resp.voucher_type === 'FREE_KILOMETERS') {
          this.$success({
            description: this.$t('voucher.added_free_km'),
          });
        } else {
          this.$success({
            description: this.$t('voucher.added'),
          });
        }
      } catch (error) {
        this.$error({
          description: this.$t("voucher.failed"),
        });
      } finally {
        this.addingVoucher = false;
      }
    },
  }
}
</script>
