<template>
  <div>
    <n-top-screen disableSafeSpacing>
      <n-dialog />
      <n-container preset="top">
        <n-nav-bar :title="title" shadowless />
        <div v-if="!departureOnly" class="switch" @click="switchAddresses">
          <div class="swap-button-container">
            <n-icon name="arrows-swap" color="accent" />
          </div>
        </div>
        <n-layout
          class="relative"
          :spacing-y="false"
          :spacing-bottom="departureOnly"
        >
          <n-button-fake-input
            @click="openSheet('from')"
            :loading="loadingType === 'from'"
            class="span-6"
            :selected="fromAddress != null"
            :label="$t('c.searchHeader.from')"
            >{{ fromLabel }}</n-button-fake-input
          >
          <n-button-fake-input
            v-if="!departureOnly"
            @click="openSheet('to')"
            :loading="loadingType === 'to'"
            class="span-6"
            :selected="toAddress != null"
            :label="$t('c.searchHeader.to')"
            >{{ toLabel }}</n-button-fake-input
          >
        </n-layout>
        <n-layout v-if="!departureOnly" :top-gap="2" :spacing-y="false">
          <n-grid :bottom-gap="5" class="span-6">
            <n-column :span="departureWidth">
              <n-text preset="label-2" color="grey-dark">
                {{ $t('main.searchHeader.departure') }}
              </n-text>
              <n-date-picker
                ref="datepicker"
                :string-format="stringFormat"
                :min-datetime="
                  datePickerType === 'time' ? null : new Date().toISOString()
                "
                :type="datePickerType"
                v-model="departureTime"
                :title="
                  datePickerType === 'time'
                    ? $t('c.searchHeader.departureTime')
                    : null
                "
                :standard="$t('c.searchHeader.leaveNow')"
              >
                <n-help
                  v-if="showDepartureTimeTooltip"
                  class="help-margin-extra"
                  :text="$t('marketplace.departureHelpText')"
                />
              </n-date-picker>
            </n-column>
            <slot name="options"></slot>
          </n-grid>
        </n-layout>
      </n-container>
      <n-layout :spacing-y="false">
        <slot />
      </n-layout>
    </n-top-screen>
    <address-search-sheet
      ref="searchSheet"
      :title="$t(`c.searchHeader.where${sheetType}`)"
      @input="onAddressInput"
      :input-label="$t(`c.searchHeader.${sheetType}`)"
      @select="onAddressSelect"
      @error="onAddressError"
    />
  </div>
</template>

<script>
import AddressSearchSheet from '@/components/shared/addressSearchSheet';
export default {
  components: {
    AddressSearchSheet,
  },
  props: {
    fromAddress: {
      type: Object,
      default: null,
    },
    toAddress: {
      type: Object,
      default: null,
    },
    time: {
      type: Date,
      required: false,
    },
    datePickerType: {
      type: String,
      default: 'datetime',
    },
    departureOnly: {
      type: Boolean,
      default: false,
    },
    showDepartureTimeTooltip: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sheetType: null,
      loadingType: null,
      departureTime: this.time,
    };
  },
  computed: {
    departureWidth() {
      if (this.$slots.options) {
        return 6 / (this.$slots.options.length + 1);
      }
      return 6;
    },
    stringFormat() {
      return this.datePickerType === 'time' ? 'HH:mm' : 'd. MMM HH:mm';
    },
    toLabel() {
      return !this.toAddress
        ? this.$t('c.searchHeader.findAddress')
        : this.toAddress.completeAddress;
    },
    fromLabel() {
      return !this.fromAddress
        ? this.$t('c.searchHeader.findAddress')
        : this.fromAddress.completeAddress;
    },
  },
  methods: {
    openSheet(type) {
      if (this.departureOnly) {
        return;
      }

      if (this.sheetType !== type) {
        this.$refs.searchSheet.clear();
      }

      this.sheetType = type;
      this.$refs.searchSheet.open();
    },
    onAddressInput(adr) {
      this.loadingType = null;
      adr.sheetType = this.sheetType;
      this.$emit('address-selected', adr);
    },
    onAddressSelect() {
      this.loadingType = this.sheetType;
    },
    onAddressError() {
      this.loadingType = null;
    },
    switchAddresses() {
      this.$emit('switch-addresses');
    },
    setDepartureTime(time) {
      this.departureTime = time;
    },
    triggerDatePicker() {
      this.$refs.datepicker?.open();
    },
  },
  watch: {
    departureTime() {
      this.$emit('departure-time', this.departureTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.backbutton {
  position: absolute;
  top: 40px;
  left: 15px;
}

.switch {
  position: absolute;
  top: 4.5rem;
  right: 0.8rem;
}

.help-margin-extra {
  margin-left: 0.6em;
}
.swap-button-container {
  position: relative;
  z-index: 99;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border: 1px solid #00CC6D;
  border-radius: 50%;
  top:34.5px;
  right:45px;

  @supports (-webkit-touch-callout: none) {
    top: 62.5px;
  }
}
</style>
