import { render, staticRenderFns } from "./centerLayout.vue?vue&type=template&id=38832c8c&scoped=true"
import script from "./centerLayout.vue?vue&type=script&lang=js"
export * from "./centerLayout.vue?vue&type=script&lang=js"
import style0 from "./centerLayout.vue?vue&type=style&index=0&id=38832c8c&prod&lang=scss&scoped=true"
import style1 from "./centerLayout.vue?vue&type=style&index=1&id=38832c8c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38832c8c",
  null
  
)

export default component.exports