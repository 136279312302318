<template>
  <n-full-screen>
    <n-alert />
    <n-map ref="map" />

    <keep-alive :include="keepAliveIncludedComponents">
      <router-view @positions="onPositions" />
    </keep-alive>
  </n-full-screen>
</template>

<script>
import { mapGetters } from 'vuex';
import NMap from '@/components/shared/map/map';

export default {
  name: 'mainIndex',
  components: {
    NMap,
  },
  computed: {
    ...mapGetters('app', {
      keepAliveIncludedComponents: 'KEEP_ALIVE_COMPONENTS',
    }),
  },
  methods: {
    onPositions(data) {
      this.$refs.map.onPositions(data);
    },
  },
};
</script>
