/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_chevron-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Ico 15 down caret"><path pid="0" data-name="Path 2237" d="M12 17L2 7l10 10L22 7z"/></g>'
  }
})
