<template>
  <n-theme type="driver">
    <activity-wrapper
      ref="wrapper"
      icon="location-alt"
      :status-color="statusColor"
      :format-clock="false"
      :clock="headerText"
      :expanded="passengersAwaitingApproval.length > 0"
      @expandChanged="isExpanded = $event"
    >
      <template #status>
        {{ `${$tc('overview.single.driver.bookedSeats', passengersAcceptedSeats)}` }}
      </template>
      <template #steps>
        <step
          v-for="(step, index) in steps"
          :key="index"
          :time="step.time"
          :address="step.address"
        />
      </template>

      <template #extra>
        <n-column
          :span="6"
          class="flex-v-center"
        >
          <seats
            :accepted="passengersAcceptedSeats"
            :waiting="passengersAwaitingApprovalSeats"
            :total="4"
          />
          <n-text
            color="accent-faded"
            preset="title"
          >
            {{ `${$tc('overview.single.driver.bookedSeats', passengersAcceptedSeats)}` }}
          </n-text>
        </n-column>
      </template>

      <template #buttons>
        <n-button
          size="lg"
          type="outlined"
          inverted
          @click="endTripRedirect"
        >
          {{ $t('overview.buttons.endTrip') }}
        </n-button>
        <n-button
          size="lg"
          type="filled"
          @click="openTrip"
        >
          {{ $t('overview.buttons.showTrip') }}
        </n-button>
      </template>

      <template #footer>
        <n-grid class="span-6">
          <n-text-model
            size="sm"
            color="warning"
            align="center"
            class="span-6"
          >
            {{ $t('overview.tripStates.active.footer') }}
          </n-text-model>
        </n-grid>
      </template>
    </activity-wrapper>
  </n-theme>
</template>

<script>
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import Step from '@/components/shared/overview/parts/step';
import Seats from '@/components/shared/overview/parts/seats';


export default {
    name: '',
    components: {
        ActivityWrapper, Step, Seats,
    },
    props: {
        driverTrip: {
            type: Object,
            required: true,
        },
        statusColor: {
          type: String,
          default: 'accent',
        },
        passengersAwaitingApproval: {
          type: Array,
          default: []
        },
        passengersAwaitingApprovalSeats: {
          type: Number,
          default: 0
        },
        passengersAcceptedSeats: {
          type: Number,
          default: 0
        },
        steps: {
          type: Array,
          default: []
        },
        endTripRedirect: {
          type: Function,
          default: null
        },
        openTrip: {
          type: Function,
          default: null,
        }
    },
    computed: {
        headerText() {
            return this.$t('overview.tripStates.active.header').toUpperCase();
        },
    },

};
</script>

<style lang="scss" scoped>
.footer-disclaimer {
    color: var(--color-orange);
    font-size:10px;
    text-align:center;

}
</style>