<template>
  <div class="view-container">
    <n-nav-bar :title="$t('vacation.title')" />
    <n-scrollable>
      <n-layout>
        <n-grid v-if="!latestVacation">
          <n-grid :row-gap="3" :bottom-gap="4">
            <n-text block preset="header" color="accent">{{ $t('vacation.pageTitle') }}</n-text>
            <n-text block preset="body">{{ $t('vacation.intro') }}</n-text>
            <n-text block preset="sub" class="pre-line">{{ $t('vacation.body') }}</n-text>
          </n-grid>
          <n-button @click="openCreateVacationSheet" block>{{ $t('vacation.createButton') }}</n-button>
        </n-grid>

        <n-grid v-if="latestVacation">
          <n-grid :row-gap="3" :bottom-gap="4" v-if="latestVacation">
            <n-text block preset="header" color="accent">{{ $t('vacation.pageTitle') }}</n-text>
            <n-text block preset="body">{{ $t('vacation.activeIntro') }}</n-text>

            <n-grid :top-gap="0" class="align-baseline">
              <n-column :span="1">
                <n-text preset="label-2">{{ $t('vacation.from') }}</n-text>
              </n-column>
              <n-column :span="5" class="date-picker-container">
                <n-text
                  preset="label-2"
                  color="accent"
                >{{ $d(new Date(latestVacation.start), 'dateWithYear') }}</n-text>
              </n-column>
            </n-grid>
            <n-grid :top-gap="0" class="align-baseline">
              <n-column :span="1">
                <n-text preset="label-2">{{ $t('vacation.to') }}</n-text>
              </n-column>
              <n-column :span="5" class="date-picker-container">
                <n-text
                  preset="label-2"
                  color="accent"
                >{{ $d(new Date(latestVacation.end), 'dateWithYear') }}</n-text>
              </n-column>
            </n-grid>

            <n-text block preset="sub" class="pre-line">{{ $t('vacation.body') }}</n-text>
          </n-grid>
          <n-button
            v-if="canDeleteVacation"
            @click="deleteActiveVacation"
            type="outlined"
            color="error"
            block
            inverted
          >{{ $t('vacation.deleteButton') }}</n-button>
          <n-button
            v-else
            @click="openEditVacationSheet"
            block
          >{{ $t('vacation.editButton') }}</n-button>
        </n-grid>
      </n-layout>
    </n-scrollable>

    <create-vacation-sheet ref="createVacationSheet" />
    <edit-vacation-sheet ref="editVacationSheet" v-if="!canDeleteVacation"/>
    <n-dialog />
  </div>
</template>

<script>
import CreateVacationSheet from '@/sheets/createVacationSheet.vue';
import EditVacationSheet from '@/sheets/editVacationSheet.vue';
import { mapGetters } from 'vuex';
import { format } from '@/vendor/date-fns';
import userApi from '@/api/user';
import { TRIP_GENERATION_DAYS } from '@/constants';
import { addDays, isAfter } from 'date-fns';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';


export default {
  components: { CreateVacationSheet, EditVacationSheet },
  computed: {
    ...mapGetters('user', ['latestVacation']),
    canDeleteVacation() {
      const deleteThreshold = addDays(new Date(), TRIP_GENERATION_DAYS + 1)
      return this.latestVacation && isAfter(new Date(this.latestVacation.start), deleteThreshold);
    },
  },
  methods: {
    format,
    openCreateVacationSheet() {
      this.$refs.createVacationSheet.open()
    },
    openEditVacationSheet() {
      this.$refs.editVacationSheet.edit({...this.latestVacation, start: new Date(this.latestVacation.start), end: new Date(this.latestVacation.end)})
    },
    async deleteActiveVacation() {
      await userApi.deleteVacation();
      await this.$store.dispatch(userTypes.FETCH_PROFILE);
    }
  },
};
</script>
