<template>
<div  class="view-container">
  <n-nav-bar :title="$t('notifications.title')" />
  <n-layout :spacing-y="false" :spacing-top="true">
    <span><n-spinner v-if="loading" /></span>
    <n-button @click="markAllAsRead" class="span-5" inverted right size="md">{{ $t('notifications.mark-all') }}</n-button>
  </n-layout>
  <n-scrollable @scrolledToBottom="fetchMore">
    <n-layout>
      <div v-for="(notification, i) in notificationsCenter" :key="notification.id" class="span-6 feedback"
        @click="navigate(notification)">
        <n-grid :row-gap="0" :bottom-gap="5">
          <n-text class="span-4">{{ notification.data.title }}</n-text>
          <n-text color="grey-dark" preset="label-2" align="right" class="span-2">{{ dateFormat(notification.created_at) }}</n-text>
          <n-text preset="sub" :color=" notification.read_at ? 'grey-dark' : 'accent'" class="span-6">{{ notification.data.body }}</n-text>
        </n-grid>

        <n-seperator v-if="i !== notificationsCenter.length - 1" class="span-6" />
      </div>
    </n-layout>
  </n-scrollable>
</div>
</template>
<script>
import {
  namespace as userNamespace,
  namespacedTypes as userTypes,
} from '@/store/modules/user-types';
import { mapState } from 'vuex';
import { formatDistanceToNow } from '@/vendor/date-fns';
import { sentryException } from '@/vendor/sentry';
import { navigateFromNotification } from '@/router';

export default {
  name: 'notifications',
  components: {
  },
  data() {
    return  {
      loading: false,
      currentPage: 1,
      hasMore: true,
    }
  },
  computed: {
    ...mapState(userNamespace, ['notificationsCenter']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loading = true;
      vm.$store.dispatch(userTypes.FETCH_NOTIFICATIONS).then((t) => {
        vm.loading = false;
        if (!t.next_page_url) {
          vm.hasMore = false;
        }
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.currentPage = 1;
    this.hasMore = true;
    next();
  },
  mounted() {
  },
  methods: {
    /**
     * @param {Object} notification
     * @param {number} notification.id
     * @param {string} notification.name
     * @param {string} notification.type
     * @param {string} notification.reference
     * @param {string} notification.read_at
     * @param {string} notification.created_at
     * @param {UserNotification} notification.data
     */
    navigate(notification) {
      if (!notification.read_at) {
        this.$store.dispatch(userTypes.MARK_AS_READ, notification.id);
      }

      navigateFromNotification(notification.data);
    },
    async markAllAsRead() {
      this.loading = true;
      await this.$store.dispatch(userTypes.MARK_ALL_NOTIFICATIONS_AS_READ)
      this.loading = false;
    },
    async fetchMore() {
      if (!this.hasMore) return;
      this.loading = true;
        this.currentPage += 1;
        const data = await this.$store.dispatch(userTypes.FETCH_NOTIFICATIONS, this.currentPage);
        this.loading = false;
        if (!data.next_page_url) {
          this.hasMore = false;
        }
    },
    dateFormat(date) {
      try {
        return formatDistanceToNow(new Date(date), { addSuffix: true });
      } catch (e) {
        sentryException(e, {
          date
        });

        return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
