<template>
  <n-grid :row-gap="1" :bottom-gap="1" @click.native="openProfile(user.id)">
    <profile-picture :src="user.image" />
    <n-column :span="5" class="name-rating-wrapper">
      <rating :stars="user.ratings_average" size="sm" />
      <n-text preset="body">{{ `${user.name} ${user.last_name || ''}` }}</n-text>
    </n-column>
    <n-column :span="6" class="profile-description" v-if="user.description">
      <n-text class='text-ellipsis' preset="sub" color="grey-dark">{{ user.description }}</n-text>
      <n-text preset="sub" color="accent">{{ $t('profileSummary.showMore') }}</n-text>
    </n-column>
  </n-grid>
</template>
<script>
import { EventBus } from '@/vendor/events';
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';

export default {
  components: { Rating, ProfilePicture, },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    openProfile(id) {
      EventBus.$emit('open-profile', id);
    },
  }
}
</script>

<style scoped>
.profile-description {
  display: flex;
  white-space: nowrap;
}
.name-rating-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
