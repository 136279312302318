import { createTypes, createTypesNamespaced } from '@/vendor/utils';

const types = [
  // mutations
  'CLEAR',
  'CLEAR_DATA',
  'CLEAR_SIGN_UP',
  'MARK_ALL_NOTIFICATIONS_AS_READ',
  'NEW_ADDRESS_PREDICTION',
  'PATCHED_PROFILE',
  'PATCHED_SIGN_UP',
  'PATCHED_UPDATE',
  'UPDATED_NOTIFICATIONS',
  'UPDATED_NOTIFICATION_CENTER',
  'UPDATED_ONESIGNAL_PLAYER_ID',
  'UPDATED_PROFILE',
  'UPDATED_PUBLIC_PROFILE',
  'UPDATED_SINGLE_NOTIFICATION',
  'UPDATED_TOKEN',
  'SELECT_LANGUAGE',
  'UPDATED_GMAP_PLACES',
  'DELETED_WORKPLACE',
  'SET_VOUCHER',
  // V4
  'MARK_MESSAGES_AS_READ',

  // actions
  'ACCEPT_COMMUTE_BOOKING',
  'ACCEPT_WITHOUT_INCOME_COMMUTE_BOOKING',
  'CANCEL_COMMUTE_BOOKING',
  'CANCEL_COMMUTE_REQUEST',
  'CANCEL_COMMUTE_TRIP',
  'DELETE_ACCOUNT',
  'DENY_COMMUTE_BOOKING',
  'FETCH_MESSAGES',
  'FETCH_NOTIFICATIONS',
  'FETCH_PROFILE',
  'FETCH_PUBLIC_PROFILE',
  'FETCH_SINGLE_COMMUTE',
  'FETCH_SINGLE_COMMUTE_REQUEST',
  'MARK_ALL_NOTIFICATIONS_AS_READ',
  'MARK_AS_READ',
  'PING_FETCH_PROFILE',
  'REGISTER_DEVICE',
  'READ_NOTIFICATIONS',
  'SIGN_IN_OTP',
  'SIGN_OUT',
  'SIGN_OUT_QUIET',
  'UPDATE_PHONE',
  'UPDATE_BANK_INFORMATION',
  'PROCESS_PAYOUT',
  'GMAP_PLACES',
  'LOCATION_FROM_ZIP',
  // V4
  'PATCH_PROFILE',
  'PATCH_PROFILE_FORM',
  'MARK_ALL_MESSAGES_AS_READ',
  'REQUEST_PHONE_OTP',
  'REQUEST_UPDATE_PHONE_OTP',
  'REQUEST_PHONE_AUTH_OTP',
  'REGISTER_USER',
  'UPDATE_MISSING_INFORMATION',

  // getters
  'GET_TOKEN',
  'HAS_UNREAD_NOTIFICATION_FOR_NAME',
  'HAS_FEATURE_FLAG',
  'FEATURE_FLAG_VALUE',
];

export const namespace = 'user';

export const namespacedTypes = createTypesNamespaced(namespace, types);

export default createTypes(types);
