/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo-variant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 7a6.5 6.5 0 1 1 0 13H10v-2h3.5c2.5 0 4.5-2 4.5-4.5S16 9 13.5 9H7.83l3.08 3.09L9.5 13.5 4 8l5.5-5.5 1.42 1.41L7.83 7h5.67M6 18h2v2H6v-2z"/>'
  }
})
